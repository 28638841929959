import React from 'react';
import { View } from 'react-native';
import { DynamicTipProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';

const DynamicTip = (props: DynamicTipProps): React.ReactElement => {
  return (
    <View
      style={[
        {
          display: props.visible === false ? 'none' : 'flex',
          backgroundColor: props.config?.backgroundColor ?? '',
        },
        ControlsStyleSheet.tipContainer,
      ]}>
      {props.config?.iconSvg && (
        <img
          src={`data:image/svg+xml;utf8,${encodeURIComponent(
            props.config?.iconSvg,
          )}`}
          style={{ width: 32 }}
        />
      )}
      <View style={{ flex: 1 }}>
        {/* This "htmlSource" should come from EHS only, users should not be able to create or edit this */}
        <div
          dangerouslySetInnerHTML={{
            __html: props.config?.htmlSource ?? '',
          }}></div>
      </View>
    </View>
  );
};

export default DynamicTip;
