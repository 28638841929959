import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  Modal,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { useIsFocused } from '@react-navigation/native';
import Icon from '../Shared/Icon';
import { Template } from '../../Models/RealmModels/Template';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { CATEGORY } from '../../Constants/AppConstants';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import DynamicDatePicker from '../DynamicControls/DynamicDatePicker';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { FilterTypes } from '../../Constants/General';
import DynamicSelect from '../DynamicControls/DynamicSelect';

export declare type Filter = { label: string; type: string };
export declare type FilterStatuses =
  | 'in-progress'
  | 'completed'
  | 'overdue'
  | 'dismissed';
export declare type ReportType = {
  id: string;
  name?: string;
  icon: string;
  isQuickAdd?: boolean;
};
export declare type FilterValue = {
  startDate?: string;
  endDate?: string;
  statuses?: FilterStatuses[];
  types?: string[];
  person?: string;
  project?: string;
  equipmentType?: string;
  equipment?: string;
};

type SearchBoxProps = {
  onChangeSearch?: (newSearch: string) => void;
  onChangeFilter?: (filterValue: FilterValue) => void;
  onFocus?: (position: number) => void;
  disabled?: boolean;
  showFilters?: boolean;
  filters?: Filter[];
  category?: string;
};

const SearchBox = (props: SearchBoxProps): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [position, setPosition] = useState(0);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [statuses, setStatuses] = useState<FilterStatuses[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [person, setPerson] = useState('');
  const [project, setProject] = useState('');
  const [equipmentType, setEquipmentType] = useState('');
  const [equipment, setEquipment] = useState('');

  const [types, setTypes] = useState<ReportType[]>([]);

  const [filtersKey, setFiltersKey] = useState(0);

  const { getFilteredTemplates } = useSync();
  const isFocused = useIsFocused();

  useEffect(() => {
    const getReportTypes = async () => {
      let newTypes: ReportType[] = [];

      let reportTypeFilter = props.filters?.find(
        f => f.type === FilterTypes.REPORT_TYPE,
      );
      if (reportTypeFilter) {
        let filteredTemplates: Template[] = [];
        let quickaddTemplates: string[] = [];
        if (props.category == CATEGORY.REPORTS) {
          let quickadd = (await getFilteredTemplates())
            .filter(
              t =>
                t.templateCategory.templateCategoryName ===
                TemplateCategoryTypes.QUICKADD,
            )
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
          quickaddTemplates = quickadd.map(q => q._id.toHexString());

          let fullReports = (await getFilteredTemplates())
            .filter(
              t =>
                t.templateCategory.templateCategoryName ===
                TemplateCategoryTypes.REPORTS,
            )
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

          filteredTemplates = quickadd.concat(fullReports);
        } else if (props.category == CATEGORY.PEOPLE) {
          filteredTemplates = (await getFilteredTemplates())
            .filter(
              t =>
                t.templateCategory.templateCategoryName ===
                TemplateCategoryTypes.PEOPLE,
            )
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        } else if (props.category == CATEGORY.EQUIPMENT) {
          filteredTemplates = (await getFilteredTemplates())
            .filter(
              t =>
                t.templateCategory.templateCategoryName ===
                TemplateCategoryTypes.EQUIPMENT,
            )
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        } else if (props.category == CATEGORY.OPERATIONS) {
          filteredTemplates = (await getFilteredTemplates())
            .filter(
              t =>
                t.templateCategory.templateCategoryName ===
                TemplateCategoryTypes.OPERATIONS,
            )
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        }

        newTypes = filteredTemplates.map(t => ({
          id: t._id.toHexString(),
          name: t.name,
          icon: t.iconSvg
            ? t.iconSvg
                .replaceAll(Colors.green, Colors.darkestGreen)
                .replaceAll('currentColor', Colors.darkestGreen)
            : '',
          isQuickAdd: quickaddTemplates.includes(t._id.toHexString()),
        }));
      }

      setTypes(newTypes);
    };

    getReportTypes();
  }, []);

  useEffect(() => {
    if (!isFocused) {
      clearFilters();
    }
  }, [isFocused]);

  function change(newSearch: string): void {
    setSearch(newSearch);
    if (props.onChangeSearch) props.onChangeSearch(newSearch);
  }

  function focus(): void {
    if (props.onFocus) props.onFocus(position);
  }

  function clear(): void {
    change('');
  }

  function showFilters(): void {
    setShowFiltersModal(true);
  }

  function clearFilters(): void {
    setStartDate('');
    setEndDate('');
    setStatuses([]);
    setSelectedTypes([]);
    setPerson('');
    setProject('');
    setEquipmentType('');
    setEquipment('');

    if (props.onChangeFilter)
      props.onChangeFilter({
        startDate: '',
        endDate: '',
        statuses: [],
        types: [],
        person: '',
        project: '',
        equipmentType: '',
        equipment: '',
      });

    // Due to performance issues components are not listening for changes in values
    // So we need to force a re-render after cleaning filters
    setFiltersKey(filtersKey + 1);
  }

  function applyFilters(): void {
    let personId = '';
    let projectId = '';
    let equipmentId = '';
    try {
      if (person) {
        let personObj: MongoIdSqlId = JSON.parse(person);
        personId = personObj.mongoId ?? '';
      }

      if (project) {
        let projectObj: MongoIdSqlId = JSON.parse(project);
        projectId = projectObj.mongoId ?? '';
      }

      if (equipment) {
        let equipmentObj: MongoIdSqlId = JSON.parse(equipment);
        equipmentId = equipmentObj.mongoId ?? '';
      }
    } catch (error) {}

    if (props.onChangeFilter)
      props.onChangeFilter({
        startDate: startDate,
        endDate: endDate,
        statuses: statuses,
        types: selectedTypes,
        person: personId,
        project: projectId,
        equipmentType: equipmentType,
        equipment: equipmentId,
      });

    setShowFiltersModal(false);
  }

  function toggleStatus(status: FilterStatuses): void {
    let selectedStatuses = [...statuses];
    let index = selectedStatuses.indexOf(status);

    if (index !== -1) selectedStatuses.splice(index, 1);
    else selectedStatuses.push(status);

    setStatuses(selectedStatuses);
  }

  function toggleSelectedTypes(typeId: string): void {
    let newSelectedTypes = [...selectedTypes];

    let index = newSelectedTypes.indexOf(typeId);
    if (index !== -1) newSelectedTypes.splice(index, 1);
    else newSelectedTypes.push(typeId);

    setSelectedTypes(newSelectedTypes);
  }

  const renderFiltersModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showFiltersModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View key={filtersKey} style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Filter
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setShowFiltersModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView style={{ marginVertical: 20 }}>
              {renderDateRangeFilter()}
              {renderTaskStatusFilter()}
              {renderReportTypeFilter()}
              {renderReportTypeLegend()}
              {renderPersonFilter()}
              {renderProjectFilter()}
              {renderEquipmentFilters()}
            </ScrollView>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingVertical: 20,
              }}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={clearFilters}>
                <Text style={CommonStyleSheet.greenButtonText}>Clear</Text>
              </Pressable>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={applyFilters}>
                <Text style={CommonStyleSheet.greenButtonText}>Apply</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  const renderDateRangeFilter = (): React.ReactElement => {
    let dateRangeFilter = props.filters?.find(
      f => f.type === FilterTypes.DATE_RANGE,
    );

    if (!dateRangeFilter) return <></>;

    return (
      <View>
        <Text style={ControlsStyleSheet.label}>{dateRangeFilter.label}</Text>
        <View style={{ flexDirection: 'row', gap: 12 }}>
          <View style={{ flex: 1 }}>
            <Text style={[ControlsStyleSheet.label, { marginBottom: -16 }]}>
              From:
            </Text>
            <DynamicDatePicker
              value={startDate}
              config={{ maxDate: endDate }}
              onChange={(controlId, controlTypeId, value) => {
                setStartDate(value);
              }}
            />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={[ControlsStyleSheet.label, { marginBottom: -16 }]}>
              To:
            </Text>
            <DynamicDatePicker
              value={endDate}
              config={{ minDate: startDate }}
              onChange={(controlId, controlTypeId, value) => {
                setEndDate(value);
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  const renderTaskStatusFilter = (): React.ReactElement => {
    let taskStatusFilter = props.filters?.find(
      f => f.type === FilterTypes.TASK_STATUS,
    );

    if (!taskStatusFilter) return <></>;

    return (
      <View style={CommonStyleSheet.statusFilterContainer}>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.statusFilterPill,
            {
              borderColor: Colors.green,
              backgroundColor: Colors.white,
            },
            pressed && { opacity: 0.8 },
          ]}
          onPress={() => toggleStatus('in-progress')}>
          <Text
            style={[
              CommonStyleSheet.statusFilterText,
              {
                color: Colors.green,
              },
            ]}>
            In Progress
          </Text>
          <View
            style={[
              CommonStyleSheet.statusFilterCheckBox,
              statuses.includes('in-progress') && {
                backgroundColor: Colors.darkestGreen,
              },
            ]}>
            <Icon icon={'checked'} color={Colors.white} size={16} />
          </View>
        </Pressable>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.statusFilterPill,
            {
              borderColor: Colors.green,
              backgroundColor: Colors.green,
            },
            pressed && { opacity: 0.8 },
          ]}
          onPress={() => toggleStatus('completed')}>
          <Text
            style={[
              CommonStyleSheet.statusFilterText,
              {
                color: Colors.white,
              },
            ]}>
            Completed
          </Text>
          <View
            style={[
              CommonStyleSheet.statusFilterCheckBox,
              statuses.includes('completed') && {
                backgroundColor: Colors.darkestGreen,
              },
            ]}>
            <Icon icon={'checked'} color={Colors.white} size={16} />
          </View>
        </Pressable>
        <View style={{ flexBasis: '100%' }}></View>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.statusFilterPill,
            {
              borderColor: Colors.red,
              backgroundColor: Colors.red,
            },
            pressed && { opacity: 0.8 },
          ]}
          onPress={() => toggleStatus('overdue')}>
          <Text
            style={[
              CommonStyleSheet.statusFilterText,
              {
                color: Colors.white,
              },
            ]}>
            Overdue
          </Text>
          <View
            style={[
              CommonStyleSheet.statusFilterCheckBox,
              statuses.includes('overdue') && {
                backgroundColor: Colors.darkestGreen,
              },
            ]}>
            <Icon icon={'checked'} color={Colors.white} size={16} />
          </View>
        </Pressable>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.statusFilterPill,
            {
              borderColor: Colors.gray,
              backgroundColor: Colors.gray,
            },
            pressed && { opacity: 0.8 },
          ]}
          onPress={() => toggleStatus('dismissed')}>
          <Text
            style={[
              CommonStyleSheet.statusFilterText,
              {
                color: Colors.white,
              },
            ]}>
            Dismissed
          </Text>
          <View
            style={[
              CommonStyleSheet.statusFilterCheckBox,
              statuses.includes('dismissed') && {
                backgroundColor: Colors.darkestGreen,
              },
            ]}>
            <Icon icon={'checked'} color={Colors.white} size={16} />
          </View>
        </Pressable>
      </View>
    );
  };

  const renderReportTypeFilter = (): React.ReactElement => {
    let reportTypeFilter = props.filters?.find(
      f => f.type === FilterTypes.REPORT_TYPE,
    );

    if (!reportTypeFilter || types.length === 0) return <></>;

    return (
      <View>
        <Text style={ControlsStyleSheet.label}>{reportTypeFilter!.label}</Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          {types.map((t, index) => (
            <View key={index} style={{ marginRight: 18, marginBottom: 18 }}>
              <Pressable
                id={t.name}
                style={({ pressed }) => [
                  CommonStyleSheet.filterButton,
                  {
                    backgroundColor: Colors.lightgray,
                    width: 64,
                    height: 64,
                    borderRadius: 64,
                  },
                  selectedTypes.includes(t.id) && {
                    backgroundColor: Colors.teal,
                  },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => toggleSelectedTypes(t.id)}>
                {t.icon && (
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      // Site inspection icon has a white dot in the middle
                      // We need to replace it to create the effect of select/unselect
                      t.icon.replaceAll(
                        Colors.white,
                        selectedTypes.includes(t.id)
                          ? Colors.teal
                          : Colors.lightgray,
                      ),
                    )}`}
                    style={{ width: 28 }}
                  />
                )}
              </Pressable>
            </View>
          ))}
        </View>
      </View>
    );
  };

  const renderReportTypeLegend = (): React.ReactElement => {
    let reportTypeFilter = props.filters?.find(
      f => f.type === FilterTypes.REPORT_TYPE,
    );
    let hasQuickAdds = types.some(t => t.isQuickAdd);

    if (!reportTypeFilter || types.length === 0) return <></>;

    return (
      <View>
        {types.map((t, index) => (
          <View key={index}>
            {!t.isQuickAdd && (
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 6,
                }}>
                {t.icon && (
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      t.icon,
                    )}`}
                    style={{ width: 28 }}
                  />
                )}
                <Text
                  style={[
                    ControlsStyleSheet.label,
                    { marginLeft: 6, marginTop: 6 },
                  ]}>
                  {t.name
                    ?.replace('Full - ', '')
                    .replace('Add ', '')
                    .replace('Create a ', '')}
                </Text>
              </View>
            )}
          </View>
        ))}
        {hasQuickAdds && (
          <View key={types.length}>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 6,
              }}>
              <Icon icon={'quick-add'} color={Colors.darkestGreen} size={24} />
              <Text
                style={[
                  ControlsStyleSheet.label,
                  { marginLeft: 6, marginTop: 6 },
                ]}>
                Indicates a Quick Report
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  const renderPersonFilter = (): React.ReactElement => {
    let personFilter = props.filters?.find(f => f.type === FilterTypes.PERSON);

    if (!personFilter) return <></>;

    return (
      <DynamicAutocomplete
        label={personFilter.label}
        config={{ optionSource: ListDataTypes.PEOPLE }}
        value={person ? person : ''}
        onChange={(controlId, controlTypeId, value) => setPerson(value)}
      />
    );
  };

  const renderProjectFilter = (): React.ReactElement => {
    let projectFilter = props.filters?.find(
      f => f.type === FilterTypes.PROJECT,
    );

    if (!projectFilter) return <></>;

    return (
      <DynamicAutocomplete
        label={projectFilter.label}
        config={{ optionSource: ListDataTypes.PROJECTS }}
        value={project ? project : ''}
        onChange={(controlId, controlTypeId, value) => setProject(value)}
      />
    );
  };

  const renderEquipmentFilters = (): React.ReactElement => {
    let equipmentFilter = props.filters?.find(
      f => f.type === FilterTypes.EQUIPMENT,
    );

    if (!equipmentFilter) return <></>;

    return (
      <>
        <DynamicSelect
          label="Equipment Type"
          config={{
            options: [
              {
                label: 'Vehicles',
                value: ListDataTypes.EQUIPMENTVEHICLEPROFILES,
              },
              {
                label: 'Heavy Equipment',
                value: ListDataTypes.EQUIPMENTHEAVYPROFILES,
              },
              { label: 'Other', value: ListDataTypes.EQUIPMENTOTHERPROFILES },
            ],
          }}
          value={equipmentType}
          onChange={(controlId, controlTypeId, value) => {
            setEquipmentType(value);
          }}
        />
        {equipmentType === ListDataTypes.EQUIPMENTVEHICLEPROFILES && (
          <DynamicAutocomplete
            label={'Vehicle'}
            config={{ optionSource: ListDataTypes.EQUIPMENTVEHICLEPROFILES }}
            value={equipment ? equipment : ''}
            onChange={(controlId, controlTypeId, value) => setEquipment(value)}
          />
        )}
        {equipmentType === ListDataTypes.EQUIPMENTHEAVYPROFILES && (
          <DynamicAutocomplete
            label={'Heavy Equipment'}
            config={{ optionSource: ListDataTypes.EQUIPMENTHEAVYPROFILES }}
            value={equipment ? equipment : ''}
            onChange={(controlId, controlTypeId, value) => setEquipment(value)}
          />
        )}
        {equipmentType === ListDataTypes.EQUIPMENTOTHERPROFILES && (
          <DynamicAutocomplete
            label={'Other Equipment'}
            config={{ optionSource: ListDataTypes.EQUIPMENTOTHERPROFILES }}
            value={equipment ? equipment : ''}
            onChange={(controlId, controlTypeId, value) => setEquipment(value)}
          />
        )}
      </>
    );
  };

  return (
    <View
      onLayout={e => setPosition(e.nativeEvent.layout.y)}
      style={CommonStyleSheet.searchContainer}>
      <View style={CommonStyleSheet.searchBoxContainer}>
        <View style={CommonStyleSheet.searchBoxIcon}>
          <Icon icon={'input-search'} color={Colors.darkGreen} size={24} />
        </View>
        <TextInput
          returnKeyType="search"
          style={CommonStyleSheet.searchBoxInput}
          placeholder="Search"
          placeholderTextColor={Colors.gray}
          value={search}
          onChangeText={newText => change(newText)}
          onFocus={() => focus()}
          editable={props.disabled !== true}
        />
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.searchBoxClear,
            { display: search === '' ? 'none' : 'flex' },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
              borderRadius: 14,
            },
          ]}
          onPress={clear}>
          <Icon icon={'input-clear'} color={Colors.darkGreen} size={18} />
        </Pressable>
      </View>
      {props.showFilters && (
        <View style={[CommonStyleSheet.searchFilters]}>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.searchFiltersContent,
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
                borderRadius: 40,
              },
              (startDate !== '' ||
                endDate !== '' ||
                statuses.length > 0 ||
                selectedTypes.length > 0 ||
                person !== '' ||
                project !== '' ||
                equipmentType !== '' ||
                equipment !== '') && {
                backgroundColor: Colors.yellow,
                borderRadius: 40,
              },
            ]}
            onPress={showFilters}>
            <Text style={CommonStyleSheet.searchFiltersText}>Filter</Text>
            <Icon icon={'input-filters'} color={Colors.green} size={18} />
          </Pressable>
          {renderFiltersModal()}
        </View>
      )}
    </View>
  );
};

export default SearchBox;
