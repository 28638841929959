/**
 * Hook for getting data to populate dynamic controls
 */

import { LabelValue, MongoIdSqlId } from '../Types/DtoTypes';

import { useSync } from '../Providers/SyncProvider';
import { ListDataTypes } from '../Constants/ListDataTypes';
import { DynamicFacepileItem } from '../Types/ControlTypes';
import { Person } from '../Models/RealmModels/Person';
import { GeneralDynamicConfig } from '../Constants/General';

type DynamicDataHook = {
  getData(
    tableName: string,
    selectedValue?: string,
    multiselect?: boolean,
    incidentType?: string,
    filter?: string,
  ): Promise<LabelValue<string>[]>;

  getMultiselectionData(
    tableName: string,
    selectedValue?: string,
    orgId?: string,
  ): Promise<DynamicFacepileItem[]>;
};

const useDynamicData = (): DynamicDataHook => {
  const {
    getProjectSites,
    getPeople,
    getSQLUsers,
    getAnimalTypes,
    getCertificationTypes,
    getClassificationUnits,
    getCrews,
    getDepartments,
    getDispositions,
    getEquipments,
    getEquipmentTypes,
    getEquipmentsVehicle,
    getEquipmentsHeavy,
    getEquipmentsOther,
    getHazardTypes,
    getIncidentProbabilities,
    getIncidentTypes,
    getIncidentClassifications,
    getJobs,
    getLocations,
    getLocationTypes,
    getMechanisms,
    getOrganisations,
    getOrgRelations,
    getOrgId,
    getPeopleTypes,
    getRoles,
    getSeverityLevels,
    getShifts,
    getTasks,
    getTaskTypes,
    getTrainingTypes,
    getOIICSBodyParts,
    getOIICSNatures,
    getDocumentTypes,
    cities,
    getProvStates,
  } = useSync();

  function sortOptions(options: LabelValue<string>[]): LabelValue<string>[] {
    return options.sort((a, b) =>
      a && b && a!.label!.toLowerCase() > b!.label!.toLowerCase()
        ? 1
        : a && b && a!.label!.toLowerCase() < b!.label!.toLowerCase()
        ? -1
        : 0,
    );
  }

  function sortFacePile(options: DynamicFacepileItem[]): DynamicFacepileItem[] {
    return options.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : a.label.toLowerCase() < b.label.toLowerCase()
        ? -1
        : 0,
    );
  }

  //Data for multiselection components
  const getMultiselectionData = async (
    tableName: string,
    selectedValue: string,
  ) => {
    let newOptions: DynamicFacepileItem[] = [];
    let selectedValues: string[] = [];

    if (selectedValue) {
      let parsedArray = JSON.parse(selectedValue!) as string[];
      let parsedValues = parsedArray.map(
        x => (JSON.parse(x) as MongoIdSqlId).mongoId!,
      );
      selectedValues = parsedValues;
    }

    if (tableName === ListDataTypes.CLASSIFICATIONUNITS) {
      let cuArray = await getClassificationUnits();

      newOptions = cuArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
        avatar: '',
      }));

      newOptions = sortFacePile(newOptions);
    } else if (tableName === ListDataTypes.PEOPLE) {
      let peopleArray = await getPeople({});

      peopleArray = peopleArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );

      newOptions = peopleArray.map(p => ({
        label: p.firstName + ' ' + p.lastName,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.firstName + ' ' + p.lastName,
          SQLServerId: p.SQLServerId,
        }),
        avatar: p.photoThumbnail ?? '',
      }));

      newOptions = sortFacePile(newOptions);
    } else if (tableName === ListDataTypes.PROJECTS) {
      let projectArray = await getProjectSites({});

      projectArray = projectArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );

      newOptions = projectArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
        avatar: '',
      }));

      newOptions = sortFacePile(newOptions);
    }

    return newOptions;
  };

  //Data for populating dropdowns/ autocomplete that show a label and have a string value
  const getData = async (
    tableName: string,
    selectedValue: string,
    multiselect: boolean,
    incidentType: string,
    filter?: string,
  ): Promise<LabelValue<string>[]> => {
    let newOptions: LabelValue<string>[] = [];
    let selectedValues: string[] = [];

    if (selectedValue) {
      if (multiselect) {
        let parsedArray = JSON.parse(selectedValue!) as Array<string>;
        let parsedValues = parsedArray.map(
          x => (JSON.parse(x) as MongoIdSqlId).mongoId!,
        );
        selectedValues = parsedValues;
      } else {
        let parsedValues = JSON.parse(selectedValue!) as MongoIdSqlId;
        selectedValues = [parsedValues.mongoId!];
      }
    }

    if (tableName === ListDataTypes.PEOPLE) {
      let peopleArray = await getPeople({});

      peopleArray = peopleArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = peopleArray.map(p => ({
        label:
          p.firstName + ' ' + p.lastName + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label:
            p.firstName + ' ' + p.lastName + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.SQLUser) {
      let projectsArray = await getSQLUsers();
      projectsArray = projectsArray.filter(
        x => x.isActive || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = projectsArray.map(p => ({
        label:
          p.firstName + ' ' + p.lastName + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label:
            p.firstName + ' ' + p.lastName + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.PROJECTS) {
      let projectsArray = await getProjectSites({});
      projectsArray = projectsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = projectsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.ANIMALTYPES) {
      let animalTypesArray = await getAnimalTypes();
      animalTypesArray = animalTypesArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = animalTypesArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.CERTIFICATIONTYPES) {
      let certsArray = await getCertificationTypes();
      certsArray = certsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = certsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.CLASSIFICATIONUNITS) {
      let cuArray = await getClassificationUnits();
      cuArray = cuArray.filter(x =>
        selectedValues.includes(x._id.toHexString()),
      );
      newOptions = cuArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.CREWS) {
      let crewsArray = await getCrews();
      crewsArray = crewsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = crewsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.JOBS) {
      let jobsArray = await getJobs();
      jobsArray = jobsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = jobsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.DEPARTMENTS) {
      let departmentsArray = await getDepartments();
      departmentsArray = departmentsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = departmentsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.EQUIPMENTVEHICLEPROFILES) {
      let array = await getEquipmentsVehicle();

      newOptions = array.map(a => ({
        label: a.name + ' - ' + a.number + getEquipmentStatus(a.status),
        value: JSON.stringify({
          mongoId: a._id.toHexString(),
          label: a.name + ' - ' + a.number + getEquipmentStatus(a.status),
          SQLServerId: a.SQLServerId,
        }),
      }));
    } else if (tableName === ListDataTypes.EQUIPMENTHEAVYPROFILES) {
      let array = await getEquipmentsHeavy();

      newOptions = array.map(a => ({
        label: a.name + ' - ' + a.number + getEquipmentStatus(a.status),
        value: JSON.stringify({
          mongoId: a._id.toHexString(),
          label: a.name + ' - ' + a.number + getEquipmentStatus(a.status),
          SQLServerId: a.SQLServerId,
        }),
      }));
    } else if (tableName === ListDataTypes.EQUIPMENTOTHERPROFILES) {
      let array = await getEquipmentsOther();

      newOptions = array.map(a => ({
        label: a.name + ' - ' + a.number + getEquipmentStatus(a.status),
        value: JSON.stringify({
          mongoId: a._id.toHexString(),
          label: a.name + ' - ' + a.number + getEquipmentStatus(a.status),
          SQLServerId: a.SQLServerId,
        }),
      }));
    } else if (tableName === ListDataTypes.HAZARDTYPES) {
      let hazardsArray = await getHazardTypes();
      hazardsArray = hazardsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = hazardsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.TASKS) {
      let tasksArray = await getTasks();
      if (filter) {
        try {
          let valObj = JSON.parse(filter) as MongoIdSqlId;
          tasksArray = tasksArray.filter(
            x => x.taskTypeSQLServerId === valObj.SQLServerId,
          );
        } catch (error) {}
      }

      newOptions = tasksArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.TRAININGTYPES) {
      let trainTypesArray = await getTrainingTypes();
      trainTypesArray = trainTypesArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = trainTypesArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.EQUIPMENTTYPES) {
      let equipmentTypesArray = await getEquipmentTypes();
      newOptions = equipmentTypesArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.EQUIPMENTS) {
      let equipmentArray = await getEquipments();
      newOptions = equipmentArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.ROLES) {
      let rolesArray = await getRoles();
      newOptions = rolesArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.SHIFTS) {
      let shiftsArray = await getShifts();
      shiftsArray = shiftsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = shiftsArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.SEVERITYLEVELS) {
      let sevLevelsArray = await getSeverityLevels();
      sevLevelsArray = sevLevelsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = sevLevelsArray.map(p => ({
        label: p.name + ' ' + p.value + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + ' ' + p.value + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.MECHANISMS) {
      let mechArray = await getMechanisms();
      mechArray = mechArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = mechArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.DISPOSTIONS) {
      let disArray = await getDispositions();
      disArray = disArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = disArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.TASKTYPES) {
      let taskTypeArray = await getTaskTypes();
      taskTypeArray = taskTypeArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = taskTypeArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.INCIDENTPROBABILITIES) {
      let incProbsArray = await getIncidentProbabilities();
      incProbsArray = incProbsArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = incProbsArray.map(p => ({
        label: p.name + ' ' + p.value + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + ' ' + p.value + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.INCIDENTCLASSIFICATIONS) {
      let incidentClasses = await getIncidentClassifications();
      incidentClasses = incidentClasses.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      if (incidentType) {
        let incidentTypes = await getIncidentTypes();
        let filteredTypes = incidentTypes
          .filter(x => x.name.toLowerCase() == incidentType.toLowerCase())
          .map(x => x.SQLServerId);

        let filteredClasses = incidentClasses.filter(
          x =>
            x.incidentTypeSQLServerId &&
            filteredTypes.includes(x.incidentTypeSQLServerId),
        );
        newOptions = filteredClasses.map(p => ({
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          value: JSON.stringify({
            mongoId: p._id.toHexString(),
            label: p.name + (!p.isActive ? ' - Inactive' : ''),
            SQLServerId: p.SQLServerId,
          }),
        }));
      } else {
        newOptions = incidentClasses.map(p => ({
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          value: JSON.stringify({
            mongoId: p._id.toHexString(),
            label: p.name + (!p.isActive ? ' - Inactive' : ''),
            SQLServerId: p.SQLServerId,
          }),
        }));
      }
      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.INCIDENTTYPES) {
      let incTypesArray = await getIncidentTypes();
      incTypesArray = incTypesArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = incTypesArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.LOCATIONS) {
      let locArray = await getLocations();
      locArray = locArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = locArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.LOCATIONTYPES) {
      let locTypeArray = await getLocationTypes();
      locTypeArray = locTypeArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = locTypeArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.ORGANISATIONS) {
      let orgId = getOrgId();
      let relatedOrgs = (await getOrgRelations())
        .filter(r => r.childOrgId === orgId)
        .map(r => r.parentOrgId);

      let orgArray = await getOrganisations();
      orgArray = orgArray.filter(
        x =>
          (!x.isDeleted || selectedValues.includes(x._id.toHexString())) &&
          relatedOrgs.includes(x.SQLServerId),
      );
      newOptions = orgArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.NATURES) {
      let natArray = await getOIICSNatures();
      newOptions = natArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.BODYPARTS) {
      let bpArray = await getOIICSBodyParts();
      newOptions = bpArray.map(p => ({
        label: p.name,
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name,
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.TRAININGTYPES) {
      let ttArray = await getTrainingTypes();
      newOptions = ttArray.map(t => ({
        label: t.name,
        value: JSON.stringify({
          mongoId: t._id.toHexString(),
          label: t.name,
          SQLServerId: t.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.CERTIFICATIONTYPES) {
      let ctArray = await getCertificationTypes();
      newOptions = ctArray.map(c => ({
        label: c.name,
        value: JSON.stringify({
          mongoId: c._id.toHexString(),
          label: c.name,
          SQLServerId: c.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.PEOPLETYPES) {
      let peopleTypeArray = await getPeopleTypes();
      peopleTypeArray = peopleTypeArray.filter(
        x => !x.isDeleted || selectedValues.includes(x._id.toHexString()),
      );
      newOptions = peopleTypeArray.map(p => ({
        label: p.name + (!p.isActive ? ' - Inactive' : ''),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          label: p.name + (!p.isActive ? ' - Inactive' : ''),
          SQLServerId: p.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.DOCUMENTTYPES) {
      let dtArray = await getDocumentTypes();
      newOptions = dtArray.map(t => ({
        label: t.name,
        value: JSON.stringify({
          mongoId: t._id.toHexString(),
          label: t.name,
          SQLServerId: t.SQLServerId,
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.CITIES) {
      newOptions = cities.map(t => ({
        label: t.cityName ?? '',
        value: JSON.stringify({
          mongoId: t._id.toHexString(),
          label: t.cityName ?? '',
          SQLServerId: t.SQLServerId ?? '',
        }),
      }));

      newOptions = sortOptions(newOptions);
    } else if (tableName === ListDataTypes.PROVSTATES) {
      newOptions = (await getProvStates()).map(t => ({
        label: t.provState ?? '',
        value: JSON.stringify({
          mongoId: t._id.toHexString(),
          label: t.provState ?? '',
          SQLServerId: t.SQLServerId ?? '',
        }),
      }));

      newOptions = sortOptions(newOptions);
    }

    return newOptions;
  };

  function getEquipmentStatus(status: string): string {
    let result = '';

    let statusObj = GeneralDynamicConfig.SEGMENT_STATUS.segments.find(
      x => x.value == status,
    );
    if (statusObj && statusObj.value != 'Active')
      result = ' - ' + statusObj.name;

    return result;
  }

  return { getData: getData, getMultiselectionData: getMultiselectionData };
};

export default useDynamicData;
