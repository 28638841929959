import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Pressable } from 'react-native';
import moment from 'moment';
import Icon from '../Shared/Icon';
import { DynamicDatePickerProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Formats } from '../../Constants/Formats';
import { IGeneralHelperService } from '../../Services/Interfaces/IGeneralHelperService';
import GeneralHelperService from '../../Services/GeneralHelperService';

const DynamicDatePicker = (
  props: DynamicDatePickerProps,
): React.ReactElement => {
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
  const [date, setDate] = useState<Date | null>(getValueFromProps(props.value));
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');

  const inputRef = React.createRef<HTMLInputElement>();
  const helperService: IGeneralHelperService = new GeneralHelperService();

  useEffect(() => {
    if (props.config?.minDate === 'today')
      setMinDate(moment().startOf('day').toDate());
    else {
      let momentDate = moment(props.config?.minDate, Formats.BACKEND_DATE);
      if (momentDate.isValid()) setMinDate(momentDate.startOf('day').toDate());
      else setMinDate(undefined);
    }
  }, [props.config?.minDate]);

  useEffect(() => {
    let momentDate = moment(props.config?.maxDate, Formats.BACKEND_DATE);
    if (momentDate.isValid()) setMaxDate(momentDate.startOf('day').toDate());
    else setMaxDate(undefined);
  }, [props.config?.maxDate]);

  useEffect(() => {
    let newValue = date ? moment(date).format(Formats.BACKEND_DATE) : '';
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, newValue, valid());

    if (
      props.config?.hasMetaData &&
      props.config?.metaDataKey &&
      props.updateMetaData
    )
      props.updateMetaData(props.config.metaDataKey, newValue);
  }, [date]);

  useEffect(() => {
    if (isFocused === false) valid();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function getValueFromProps(propsValue: string | undefined): Date | null {
    let newValue = null;

    let momentDate = moment(propsValue, Formats.BACKEND_DATE);
    if (momentDate.isValid()) newValue = momentDate.startOf('day').toDate();

    return newValue;
  }

  function showPicker(): void {
    if (props.disabled) return;

    if (inputRef.current) {
      inputRef.current.showPicker();
      setIsFocused(true);
    }
  }

  function change(value: string): void {
    setIsFocused(false);

    setDate(getValueFromProps(value));
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (date) {
      if (minDate && date < minDate) {
        isValid = false;
        error =
          (props.label ?? 'This field') +
          ' must be after ' +
          moment(minDate).format(Formats.FRONTEND_DATE);
      }

      if (maxDate && date > maxDate) {
        isValid = false;
        error =
          (props.label ?? 'This field') +
          ' must be before ' +
          moment(maxDate).format(Formats.FRONTEND_DATE);
      }
    } else if (props.config?.required) {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);
    return isValid;
  }

  const safariDateChange = (value: string) => {
    setDate(moment(value).toDate());
  };

  const getSafariDate = () => {
    let dateStyle: any = { ...ControlsStyleSheet.input };
    dateStyle.borderStyle = 'solid';

    if (isFocused) {
      dateStyle = { ...dateStyle, ...ControlsStyleSheet.inputFocused };
    }

    if (error !== '') {
      dateStyle = { ...dateStyle, ...ControlsStyleSheet.inputError };
    }

    if (isFocused && error !== '') {
      dateStyle = { ...dateStyle, ...ControlsStyleSheet.inputErrorFocused };
    }

    //If no date set color to invisible so placeholder date text doesn't show
    if (!date) dateStyle.color = 'rgba(0,0,0,0)';

    let dateControl = (
      <div
        style={{
          display: props.visible === false ? 'none' : 'flex',
          flexDirection: 'column',
        }}>
        <Text style={ControlsStyleSheet.label}>
          {props.label ?? ''}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
        <div
          style={{
            display: props.visible === false ? 'none' : 'flex',
            flexDirection: 'column',
          }}>
          <input
            ref={inputRef}
            type="date"
            style={dateStyle}
            value={date ? moment(date).format('YYYY-MM-DD') : ''}
            min={minDate ? moment(minDate).format('YYYY-MM-DD') : ''}
            max={maxDate ? moment(maxDate).format('YYYY-MM-DD') : ''}
            placeholder={props.config?.placeholder}
            onChange={date => safariDateChange(date.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            top: '-35px',
            marginRight: 12,
            position: 'relative',
            alignSelf: 'end',
          }}>
          <Icon
            icon={'calendar'}
            color={error !== '' ? Colors.red : Colors.green}
            size={21}
          />
        </div>
        <Text
          style={{
            ...ControlsStyleSheet.error,
            position: 'relative',
            top: -20,
            paddingLeft: 3,
          }}>
          {error}
        </Text>
      </div>
    );

    return dateControl;
  };

  //Safari doesn't like react native date use default html date control
  if (helperService.isSafari()) {
    return getSafariDate();
  }

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <View>
        <TextInput
          showSoftInputOnFocus={false}
          style={[
            ControlsStyleSheet.input,
            isFocused && ControlsStyleSheet.inputFocused,
            error !== '' && ControlsStyleSheet.inputError,
            isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
          ]}
          placeholder={props.config?.placeholder}
          placeholderTextColor={ControlsStyleSheet.placeholder.color}
          value={date ? moment(date).format(Formats.FRONTEND_DATE) : ''}
          editable={!props.disabled}
        />
        <input
          ref={inputRef}
          type="date"
          style={{
            width: 0,
            height: 0,
            borderStyle: 'none',
          }}
          value={date ? moment(date).format('YYYY-MM-DD') : ''}
          min={minDate ? moment(minDate).format('YYYY-MM-DD') : ''}
          max={maxDate ? moment(maxDate).format('YYYY-MM-DD') : ''}
          onChange={event => change(event.target.value)}
          onInput={() => change(inputRef.current?.value ?? '')} //This will allow to create an automated test for this component
        />
        <Pressable style={ControlsStyleSheet.datePicker} onPress={showPicker}>
          <View style={{ marginLeft: 'auto', marginRight: 12 }}>
            <Icon
              icon={'calendar'}
              color={error !== '' ? Colors.red : Colors.green}
              size={21}
            />
          </View>
        </Pressable>
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicDatePicker;
