import React, { useState, useEffect } from 'react';
import { ScrollView, View, Image, Text, Pressable } from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { useIsFocused } from '@react-navigation/native';
import SearchBox from '../Shared/SearchBox';
import Icon from '../Shared/Icon';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import CustomPressable from '../Shared/CustomPressable';
import { Navigation } from '../../Constants/Navigation';
import { STORAGE_KEYS } from '../../Constants/AppConstants';
import LoadingSpinner from '../Shared/LoadingSpinner';

export declare type ListManagementProps = {
  navigation: any;
};

export type RecordType = 'basic' | 'readonly' | 'master-detail' | 'name-value';

export type Record = {
  label: string;
  name: string;
  key: string;
  count: number;
  type: RecordType;
};

const ListManagement = (props: ListManagementProps): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState<Record[]>([]);
  const [selectedRecord, setSelectedRecord] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [showAll, setShowAll] = useState(false);

  const { getListData } = useSync();

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) fetchRecords();
    else setIsLoading(false);
  }, [isFocused]);

  const fetchRecords = async () => {
    let mappedRecords: Record[] = [];

    mappedRecords.push({
      label: 'Animal Types',
      name: ListDataTypes.ANIMALTYPES,
      key: STORAGE_KEYS.REALM_ANIMALTYPES,
      count: getListData(STORAGE_KEYS.REALM_ANIMALTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Certification Types',
      name: ListDataTypes.CERTIFICATIONTYPES,
      key: STORAGE_KEYS.REALM_CERTIFICATIONTYPES,
      count: getListData(STORAGE_KEYS.REALM_CERTIFICATIONTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Classification Units',
      name: ListDataTypes.CLASSIFICATIONUNITS,
      key: STORAGE_KEYS.REALM_CLASSIFICATIONUNITS,
      count: getListData(STORAGE_KEYS.REALM_CLASSIFICATIONUNITS).length,
      type: 'readonly',
    });

    mappedRecords.push({
      label: 'Crews',
      name: ListDataTypes.CREWS,
      key: STORAGE_KEYS.REALM_CREWS,
      count: getListData(STORAGE_KEYS.REALM_CREWS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Departments',
      name: ListDataTypes.DEPARTMENTS,
      key: STORAGE_KEYS.REALM_DEPARTMENTS,
      count: getListData(STORAGE_KEYS.REALM_DEPARTMENTS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Dispositions',
      name: ListDataTypes.DISPOSTIONS,
      key: STORAGE_KEYS.REALM_DISPOSITIONS,
      count: getListData(STORAGE_KEYS.REALM_DISPOSITIONS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Document Types',
      name: ListDataTypes.DOCUMENTTYPES,
      key: STORAGE_KEYS.REALM_DOCUMENTTYPES,
      count: getListData(STORAGE_KEYS.REALM_DOCUMENTTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'HazardTypes',
      name: ListDataTypes.HAZARDTYPES,
      key: STORAGE_KEYS.REALM_HAZARDTYPES,
      count: getListData(STORAGE_KEYS.REALM_HAZARDTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Incident Classifications',
      name: ListDataTypes.INCIDENTCLASSIFICATIONS,
      key: STORAGE_KEYS.REALM_INCIDENTCLASSIFICATIONS,
      count: getListData(STORAGE_KEYS.REALM_INCIDENTCLASSIFICATIONS).length,
      type: 'master-detail',
    });

    mappedRecords.push({
      label: 'Incident Probabilities',
      name: ListDataTypes.INCIDENTPROBABILITIES,
      key: STORAGE_KEYS.REALM_INCIDENTPROBABILITIES,
      count: getListData(STORAGE_KEYS.REALM_INCIDENTPROBABILITIES).length,
      type: 'name-value',
    });

    mappedRecords.push({
      label: 'Incident Types',
      name: ListDataTypes.INCIDENTTYPES,
      key: STORAGE_KEYS.REALM_INCIDENTTYPES,
      count: getListData(STORAGE_KEYS.REALM_INCIDENTTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Jobs',
      name: ListDataTypes.JOBS,
      key: STORAGE_KEYS.REALM_JOBS,
      count: getListData(STORAGE_KEYS.REALM_JOBS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Mechanisms',
      name: ListDataTypes.MECHANISMS,
      key: STORAGE_KEYS.REALM_MECHANISMS,
      count: getListData(STORAGE_KEYS.REALM_MECHANISMS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Locations',
      name: ListDataTypes.LOCATIONS,
      key: STORAGE_KEYS.REALM_LOCATIONS,
      count: getListData(STORAGE_KEYS.REALM_LOCATIONS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'LocationTypes',
      name: ListDataTypes.LOCATIONTYPES,
      key: STORAGE_KEYS.REALM_LOCATIONTYPES,
      count: getListData(STORAGE_KEYS.REALM_LOCATIONTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Body Parts',
      name: ListDataTypes.BODYPARTS,
      key: STORAGE_KEYS.REALM_OIICS_BODYPARTS,
      count: getListData(STORAGE_KEYS.REALM_OIICS_BODYPARTS).length,
      type: 'readonly',
    });

    mappedRecords.push({
      label: 'Natures',
      name: ListDataTypes.NATURES,
      key: STORAGE_KEYS.REALM_OIICS_NATURES,
      count: getListData(STORAGE_KEYS.REALM_OIICS_NATURES).length,
      type: 'readonly',
    });

    mappedRecords.push({
      label: 'Severity Levels',
      name: ListDataTypes.SEVERITYLEVELS,
      key: STORAGE_KEYS.REALM_SEVERITYLEVELS,
      count: getListData(STORAGE_KEYS.REALM_SEVERITYLEVELS).length,
      type: 'name-value',
    });

    mappedRecords.push({
      label: 'Shifts',
      name: ListDataTypes.SHIFTS,
      key: STORAGE_KEYS.REALM_SHIFTS,
      count: getListData(STORAGE_KEYS.REALM_SHIFTS).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Tasks',
      name: ListDataTypes.TASKS,
      key: STORAGE_KEYS.REALM_TASKS,
      count: getListData(STORAGE_KEYS.REALM_TASKS).length,
      type: 'master-detail',
    });

    mappedRecords.push({
      label: 'Task Types',
      name: ListDataTypes.TASKTYPES,
      key: STORAGE_KEYS.REALM_TASKTYPES,
      count: getListData(STORAGE_KEYS.REALM_TASKTYPES).length,
      type: 'basic',
    });

    mappedRecords.push({
      label: 'Training Types',
      name: ListDataTypes.TRAININGTYPES,
      key: STORAGE_KEYS.REALM_TRAININGTYPES,
      count: getListData(STORAGE_KEYS.REALM_TRAININGTYPES).length,
      type: 'basic',
    });

    mappedRecords = sortItems(mappedRecords);
    setRecords(mappedRecords);

    setIsLoading(false);
  };

  function sortItems(itemsToSort: Record[]): Record[] {
    return itemsToSort.sort((a, b) =>
      (a.label?.toLowerCase() ?? '') > (b.label?.toLowerCase() ?? '')
        ? 1
        : (a.label?.toLowerCase() ?? '') < (b.label?.toLowerCase() ?? '')
        ? -1
        : 0,
    );
  }

  function viewRecords(record: Record): void {
    props.navigation.push(Navigation.EDITLISTMANAGEMENT, {
      listName: record.name,
      listKey: record.key,
      listLabel: record.label,
      listType: record.type,
    });
  }

  const renderRecords = (): React.ReactElement => {
    let filteredRecords = records.filter(
      r =>
        // Search filter
        search === '' || r.label?.toLowerCase().includes(search.toLowerCase()),
    );

    let visibleRecords = showAll
      ? filteredRecords
      : filteredRecords.slice(0, 10);
    if (visibleRecords.length === 0) return <React.Fragment></React.Fragment>;

    return (
      <View style={ReportsStyleSheet.list}>
        {visibleRecords.map((record, index) => {
          return renderRecord(record, index);
        })}
        {filteredRecords.length > visibleRecords.length && (
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.smallGreenButton,
              { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() => setShowAll(true)}>
            <Text style={CommonStyleSheet.smallGreenButtonText}>Show All</Text>
          </Pressable>
        )}
      </View>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            { flex: 1 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => setSelectedRecord(record.name)}>
          <Text style={ReportsStyleSheet.listItemName} numberOfLines={2}>
            {record.label} ({record.count} records)
          </Text>
        </Pressable>
        {selectedRecord && selectedRecord === record.name
          ? renderActions(record)
          : null}
      </View>
    );
  };

  const renderActions = (record: Record): React.ReactElement => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <CustomPressable
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'View Records'}
          onPress={() => viewRecords(record)}>
          <Icon icon={'eye'} color={Colors.white} size={20} />
        </CustomPressable>
      </View>
    );
  };

  return (
    <ScrollView>
      <View style={{ height: 260, backgroundColor: Colors.darkestGreen }}>
        <Text style={ReportsStyleSheet.headerTitle}>List Management</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          {renderRecords()}
        </View>
        <View style={{ flex: 1 }}>
          <View style={CommonStyleSheet.bcsfFooter}>
            <Image
              source={Images.BCSF}
              style={CommonStyleSheet.bcsfFooterImage}
            />
            <Text style={CommonStyleSheet.bcsfFooterText}>
              BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
            </Text>
          </View>
        </View>
      </View>
      <LoadingSpinner message="Loading" visible={isLoading} />
    </ScrollView>
  );
};

export default ListManagement;
