import React, { useState, useEffect } from 'react';
import { View, Text, Image, Pressable, Modal } from 'react-native';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';
import Icon from '../Shared/Icon';
import { DynamicSignatureProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';

const DynamicSignature = (props: DynamicSignatureProps): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '');
  const [error, setError] = useState('');

  const [isShowingModal, setIsShowingModal] = useState(false);
  const [modalError, setModalError] = useState('');

  const signatureRef = React.createRef<ReactSignatureCanvas>();

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function showModal(): void {
    if (props.disabled) return;

    setIsShowingModal(true);
    setModalError('');
  }

  function hideModal(): void {
    setIsShowingModal(false);
  }

  function clearSignature(): void {
    if (signatureRef.current) signatureRef.current.clear();
  }

  function saveSignature(): void {
    let signature = '';
    if (signatureRef.current)
      signature = signatureRef.current.getCanvas().toDataURL();

    // Validations
    if (signatureRef.current && signatureRef.current.isEmpty()) {
      setModalError('Signature cannot be empty');
      return;
    }

    setValue(signature);
    setIsShowingModal(false);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View
            style={[ControlsStyleSheet.groupSelectorModal, { height: 'auto' }]}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={hideModal}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <View>
              <View style={{ marginTop: 12 }}>
                <Text style={ControlsStyleSheet.label}>Signature</Text>
                <View style={{ width: 400 }}>
                  <View
                    style={{
                      backgroundColor: Colors.white,
                      borderColor: Colors.darkGreen,
                      borderWidth: 1,
                      borderRadius: 20,
                    }}>
                    <SignatureCanvas
                      ref={signatureRef}
                      canvasProps={{ height: 240 }}
                    />
                  </View>
                  <View
                    style={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      backgroundColor: Colors.white,
                      borderRadius: 6,
                    }}>
                    <Pressable
                      style={({ pressed }) => [
                        ControlsStyleSheet.autocompleteButton,
                        pressed && {
                          backgroundColor: Colors.darkGreenTransparent,
                        },
                      ]}
                      onPress={clearSignature}>
                      <Text style={ControlsStyleSheet.autocompleteButtonText}>
                        Clear
                      </Text>
                    </Pressable>
                  </View>
                </View>
                <Text style={ControlsStyleSheet.error}>{modalError}</Text>
              </View>
            </View>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 'auto', marginBottom: 24 },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              onPress={saveSignature}>
              <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 24,
        }}>
        <Text style={ControlsStyleSheet.labelLarge}>{props.label}</Text>
        {!props.disabled && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.linkRecordAddButton,
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
              },
            ]}
            disabled={props.disabled}
            onPress={showModal}>
            <Icon icon={'pencil'} color={Colors.darkestGreen} size={24} />
          </Pressable>
        )}
      </View>
      {renderModal()}
      {value ? (
        <View>
          <View
            style={{
              width: 400,
              backgroundColor: Colors.white,
              borderColor: Colors.white,
              borderWidth: 1,
              borderRadius: 20,
            }}>
            <Image
              resizeMode={'contain'}
              style={{ width: '100%', height: 240 }}
              source={{ uri: value }}
            />
          </View>
        </View>
      ) : null}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicSignature;
