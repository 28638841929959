import React, { useState, useEffect } from 'react';
import { View, Text, Keyboard, TextInput } from 'react-native';
import { ObjectId } from 'bson';
import * as Realm from 'realm-web';
import {
  DynamicSelectItem,
  DynamicSelectProps,
} from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { METADATA_KEYS, STORAGE_KEYS } from '../../Constants/AppConstants';
import LocalStorageService from '../../Services/LocalStorageService';
import { ILocalStorageService } from '../../Services/Interfaces/ILocalStorageService';
import { LabelValue, MongoIdSqlId } from '../../Types/DtoTypes';
import useDynamicData from '../../Hooks/useDynamicData';
import { useSync } from '../../Providers/SyncProvider';
import { useRelation } from '../../Providers/SubmissionRelationProvider';

const DynamicSelect = (props: DynamicSelectProps): React.ReactElement => {
  const storageService: ILocalStorageService = new LocalStorageService();

  const { getData } = useDynamicData();
  const { getTasks, getTaskTypes } = useSync();
  const { updateSubmissionRelation } = useRelation();

  const [multiselect, setMultiselect] = useState(
    props.config?.multiselect ?? false,
  );
  const [options, setOptions] = useState<LabelValue<string>[]>(
    props.config?.options ?? [],
  );
  //DropDownPicker selected values can't go from single to multiple dynamically,
  //we need two states of selected values.
  const [multiSelection, setMultiSelection] = useState<string[]>([]);
  const [value, setValue] = useState<string>(props.value!);
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState(props.filter);

  const selectRef = React.createRef<HTMLSelectElement>();

  useEffect(() => {
    //If select is for project/sites and no project/sites is selected and active site exists, default project/sites to active site
    if (
      props.config?.optionSource! &&
      props.config.optionSource == ListDataTypes.PROJECTS &&
      !value
    ) {
      let jsonActiveSite = storageService.get(STORAGE_KEYS.ACTIVE_SITE_KEY);
      if (jsonActiveSite) {
        let storedActiveSite = JSON.parse(jsonActiveSite);
        setValue(storedActiveSite.value);
      }
    } else if (value) {
      const getSelectedValue = async () => {
        let data = await getData(
          props.config?.optionSource!,
          '',
          false,
          props.config?.incidentType,
        );

        try {
          if (multiselect) {
            let valueArray = JSON.parse(value) as Array<string>;
            let newValueArray: string[] = [];

            valueArray.forEach(v => {
              let valObj = JSON.parse(v) as MongoIdSqlId;

              let item = data.find(
                r =>
                  r.value &&
                  (JSON.parse(r.value) as MongoIdSqlId).mongoId ===
                    valObj!.mongoId,
              );

              if (item && item.value) newValueArray.push(item.value);
            });

            setValue(JSON.stringify(newValueArray));
          } else {
            let valObj = JSON.parse(value) as MongoIdSqlId;

            let selectedItem = data.find(
              r =>
                r.value &&
                (JSON.parse(r.value) as MongoIdSqlId).mongoId ===
                  valObj!.mongoId,
            );

            if (selectedItem && selectedItem.value)
              setValue(selectedItem.value);
          }
        } catch (error) {
          console.log('Error: Bad Select value serialization');
        }
      };

      getSelectedValue();
    }
  }, []);

  useEffect(() => {
    setMultiselect(props.config?.multiselect ?? false);
  }, [props.config?.multiselect]);

  useEffect(() => {
    setOptions(props.config?.options ?? new Array<DynamicSelectItem>());
  }, [props.config?.options]);

  useEffect(() => {
    if (props.config?.optionSource)
      getOptionsFromSource().then(result => {
        let newOptions = result;

        if (props.disabled && value && value !== '') {
          if (multiselect) {
            let valueArray = JSON.parse(value) as string[];
            valueArray.forEach(v => {
              let valObj = JSON.parse(v) as MongoIdSqlId;
              newOptions.push({ label: valObj.label, value: value });
            });
          } else {
            let valObj = JSON.parse(value) as MongoIdSqlId;
            newOptions.push({ label: valObj.label, value: value });
          }
        }

        setOptions(newOptions);
      });
  }, [props.config?.optionSource]);

  useEffect(() => {
    let newValueArray = new Array<string>();

    if (multiselect) {
      if (multiSelection) newValueArray = multiSelection;
      setValue(JSON.stringify(newValueArray));
    }
  }, [multiSelection]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value!, valid());

    if (props.updateMetaData && options.length > 0) {
      let parsedValue = '';

      if (value) {
        if (props.config?.multiselect) {
          let parsedValues: string[] = [];
          parsedValues = JSON.parse(value);

          if (parsedValues && parsedValues.length > 0)
            parsedValue = parsedValues[0];
        } else parsedValue = value;
      }

      let projectName = options.find(x => x.value === parsedValue)?.label;

      if (props.config?.hasMetaData && props.config?.metaDataKey)
        props.updateMetaData(props.config.metaDataKey, value);

      //If it doesn't have a specific metaDataKey but its source is PROJECTS
      //Update metadata with key "projectName" by default
      if (props.config!.optionSource == ListDataTypes.PROJECTS)
        props.updateMetaData(METADATA_KEYS.PROJECT_NAME, projectName!);
    }

    //Update relation record
    if (
      value !== props.value &&
      props.config!.relationType &&
      props.config?.optionSource
    ) {
      let valObj = JSON.parse(value) as MongoIdSqlId;

      updateSubmissionRelation(
        props.submissionId!,
        new ObjectId(valObj.mongoId),
        props.config!.relationType,
      );
    }
  }, [value]);

  useEffect(() => {
    if (isFocused === false) valid();
    else if (isFocused) Keyboard.dismiss();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  useEffect(() => {
    if (props.config?.optionSource)
      getOptionsFromSource().then(result => {
        let newOptions = result;

        if (props.disabled && value && value !== '') {
          if (multiselect) {
            let valueArray = JSON.parse(value) as string[];
            valueArray.forEach(v => {
              let valObj = JSON.parse(v) as MongoIdSqlId;
              newOptions.push({ label: valObj.label, value: value });
            });
          } else {
            let valObj = JSON.parse(value) as MongoIdSqlId;
            newOptions.push({ label: valObj.label, value: value });
          }
        }

        setOptions(newOptions);
      });
  }, [filter]);

  useEffect(() => {
    if (props.filter && JSON.stringify(props.filter) !== JSON.stringify(filter))
      setFilter(props.filter);
  }, [props.filter]);

  ///TODO: should probably refactor and move data lodaing logic out of this component
  async function getOptionsFromSource(): Promise<LabelValue<string>[]> {
    let newOptions: LabelValue<string>[] = [];

    newOptions = await getData(
      props.config?.optionSource!,
      props.value,
      multiselect,
      props.config?.incidentType,
      filter?.value,
    );

    return newOptions;
  }

  function getText(): string {
    let text = '';

    if (value && value !== '') {
      if (multiselect) {
        let valueArray = JSON.parse(value) as string[];
        valueArray.forEach(v => {
          let valObj = JSON.parse(v) as MongoIdSqlId;

          if (text) text += valObj.label ? ', ' + valObj.label : '';
          else text = valObj.label ?? '';
        });
      } else {
        try {
          if (props.config?.optionSource) {
            let valObjSrc = JSON.parse(value) as MongoIdSqlId;
            text = valObjSrc.label ?? '';
          } else if (props.config?.options) {
            let valObj = JSON.parse(value) as { label: string; value: string };
            text = valObj.label ?? '';
          }
        } catch (error) {
          console.log('Error: Bad Select value serialization');
        }
      }
    }

    return text;
  }

  function change(): void {
    setIsFocused(false);

    let newValueArray = new Array<string>();
    if (selectRef.current) {
      let selectOptions = selectRef.current.options;
      for (let i = 0; i < selectOptions.length; i++) {
        if (selectOptions[i].selected && selectOptions[i].value !== '')
          newValueArray.push(selectOptions[i].value);
      }
    }

    let newValue = '';
    if (multiselect) {
      newValue = JSON.stringify(newValueArray);
    } else if (newValueArray[0]) {
      newValue = newValueArray[0];
    }

    setValue(newValue);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && (!value || value === '[]')) {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);

    return isValid;
  }

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      {props.label ? (
        <Text style={ControlsStyleSheet.label}>
          {props.label}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
      ) : null}
      {props.disabled ? (
        <TextInput
          style={[
            ControlsStyleSheet.input,
            isFocused && ControlsStyleSheet.inputFocused,
            error !== '' && ControlsStyleSheet.inputError,
            isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
          ]}
          placeholder={props.config?.placeholder}
          placeholderTextColor={ControlsStyleSheet.placeholder.color}
          value={getText()}
          editable={false}
        />
      ) : (
        <select
          ref={selectRef}
          multiple={multiselect}
          style={{
            color: Colors.darkGreen,
            fontFamily: 'Lato',
            fontSize: 14,
            fontWeight: '700',
            letterSpacing: 0.7,
            backgroundColor:
              error === ''
                ? Colors.darkGreenTransparent
                : Colors.redTransparent,
            borderColor: isFocused
              ? error === ''
                ? Colors.darkGreen
                : Colors.red
              : error === ''
              ? Colors.darkGreenTransparent
              : Colors.redTransparent,
            borderRadius: 6,
            borderWidth: 1,
            padding: 12,
            paddingTop: 8,
            outlineStyle: 'none',
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={() => change()}
          value={value}
          disabled={props.disabled}>
          <option key={-1} value="" style={ControlsStyleSheet.selectText}>
            Select an option
          </option>
          {options.map((option, index) => {
            return (
              <option
                key={index}
                value={option.value}
                style={ControlsStyleSheet.selectText}>
                {option.label}
              </option>
            );
          })}
        </select>
      )}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicSelect;
