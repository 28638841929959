import { IGeneralHelperService } from "./Interfaces/IGeneralHelperService";

//General miscellaneous helper functions
class GeneralHelperService implements IGeneralHelperService {
  //Check if browser is safari
  isSafari(): boolean {
    let isSafari =
      !!navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      !!navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1;

    return isSafari;
  }
}

export default GeneralHelperService;
