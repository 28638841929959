import React, { useEffect, useState } from "react";
import { Modal, View, Text, Pressable } from "react-native";
import Colors from "../../Styles/Shared/Colors";
import { ModalStyleSheet } from "../../Styles/ModalStyles";

type ConfirmModalProps = {
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
  okClick?: () => void;
  cancelClick?: () => void;
  setOpenModal: (func: () => void) => void;
};

const ConfirmModal = (props: ConfirmModalProps): React.ReactElement => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    props.setOpenModal(() => {
      setVisible(true);
    });
  }, []);

  const cancelClick = () => {
    if (props.cancelClick) props.cancelClick();
    setVisible(false);
  };

  const okClick = () => {
    if (props.okClick) props.okClick();
    setVisible(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        transparent={true}
        animationType={"none"}
        statusBarTranslucent={true}
      >
        <View style={ModalStyleSheet.modalBackgroundContainer}>
          <View style={ModalStyleSheet.modalContainer}>
            <View
              style={{
                backgroundColor: Colors.darkGreen,
                padding: 10,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Text
                style={{
                  color: Colors.white,
                  fontWeight: "700",
                }}
              >
                {props.title}
              </Text>
            </View>
            <View style={{ padding: 10 }}>
              <Text style={{ color: Colors.darkestGreen }}>
                {props.message}
              </Text>
            </View>
            <View style={ModalStyleSheet.modalActionsContainer}>
              {props.cancelText && (
                <Pressable
                  style={({ pressed }) => [
                    pressed && {
                      opacity: 0.6,
                    },
                    ModalStyleSheet.modalAction,
                  ]}
                  onPress={() => {
                    cancelClick();
                  }}
                >
                  <Text style={{ color: Colors.white }}>
                    {props.cancelText}
                  </Text>
                </Pressable>
              )}
              <Pressable
                style={({ pressed }) => [
                  pressed && {
                    opacity: 0.6,
                  },
                  ModalStyleSheet.modalAction,
                ]}
                onPress={() => {
                  okClick();
                }}
              >
                <Text style={{ color: Colors.white }}>{props.okText}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default ConfirmModal;
