import { Dimensions, StyleSheet } from 'react-native';
import Colors from './Shared/Colors';

const DynamicPageStyleSheet = StyleSheet.create({
  header: {
    paddingBottom: 20,
  },
  headerText: {
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: '700',
    lineHeight: 37,
    textAlign: 'left',
    color: Colors.white,
    paddingTop: 5,
    paddingLeft: 20,
    paddingBottom: 10,
  },
  formInfo: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    paddingHorizontal: 20,
  },
  closeFormIconContainer: {
    paddingRight: 10,
    justifyContent: 'center',
  },
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: -2,
    paddingLeft: 20,
    paddingRight: 20,
  },
  progressBarDot: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: Colors.darkerGreen,
    zIndex: 5,
  },
  progressBarLine: {
    borderBottomColor: Colors.darkerGreen,
    borderBottomWidth: 2,
    bottom: 4,
    flex: 1,
  },
  progressBarDotSelected: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: Colors.white,
    zIndex: 6,
  },
  progressBarDotWithErrors: {
    position: 'absolute',
    top: -5,
    left: -5,
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: Colors.brightRed,
    zIndex: 6,
  },
  progressBarErrorsText: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontWeight: '700',
  },
  progressBarLineSelected: {
    borderBottomColor: Colors.white,
    borderBottomWidth: 2,
    bottom: 4,
    flex: 1,
  },
  bodyContainer: {
    flex: 1,
    backgroundColor: Colors.darkGreen,
    paddingTop: 20,
  },
  body: {
    flex: 1,
    backgroundColor: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: '700',
    padding: 10,
  },
  controlsContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    marginBottom: 20,
  },
  confirmationContainer: {
    width: '100%',
    height: 360,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightTeal,
    borderRadius: 12,
    overflow: 'hidden',
  },
  confirmationText: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: '700',
  },
});

export { DynamicPageStyleSheet };
