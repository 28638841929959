import React, { useState, useEffect, useRef } from 'react';
import { ObjectId, Decimal128, UUID } from 'bson';
import { useToast } from 'react-native-toast-notifications';
import { ScrollView, View, TextInput, Text, Pressable } from 'react-native';
import Icon from '../Shared/Icon';
import moment from 'moment';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';
import { useSync } from '../../Providers/SyncProvider';
import { LabelValue, LatLng, MongoIdSqlId } from '../../Types/DtoTypes';
import { Project } from '../../Models/RealmModels/Projects';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { Formats } from '../../Constants/Formats';
import DynamicLocationMap from '../DynamicControls/DynamicLocationMap';
import DynamicDatePicker from '../DynamicControls/DynamicDatePicker';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import DynamicFacepile from '../DynamicControls/DynamicFacepile';
import { EditProjectSiteStyleSheet } from '../../Styles/EditProjectSiteStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { Navigation } from '../../Constants/Navigation';
import { usePermission } from '../../Providers/PermissionProvider';
import { PERMISSIONS } from '../../Constants/AppConstants';
import ConfirmModal from '../Shared/ConfirmModal';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import DynamicSelect from '../DynamicControls/DynamicSelect';

type EditProjectSiteProps = {
  navigation: any;
  projectSiteId: ObjectId | null;
  addProjectWithId?: string;
};

const EditProjectSite = (props: EditProjectSiteProps): React.ReactElement => {
  const {
    getProjectSites,
    upsertProjectSite,
    getOrgId,
    getPeople,
    cities,
    getProvStates,
  } = useSync();
  const [addingSite, setAddingSite] = useState(false);
  const [project, setProject] = useState<null | Project>(null);
  const [errorName, setErrorName] = useState('');
  const scrollRef = useRef<ScrollView>(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const { hasPermission } = usePermission();
  const toast = useToast();

  const [cityValue, setCityValue] = useState('');
  const [provName, setProvName] = useState('');

  const updateModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    let updatePeople = hasPermission(PERMISSIONS.PROJECT_UPDATE);

    setCanUpdate(updatePeople);
  }, []);

  useEffect(() => {
    //No project site id, adding new project site
    if (!props.projectSiteId) {
      setAddingSite(true);
      let newProject = getEmptyProject(props.addProjectWithId);
      setProject(newProject);
      return;
    }

    const loadProjectSite = async () => {
      let realmProject = (
        await getProjectSites({ _id: props.projectSiteId! }, 1)
      )[0];
      if (!realmProject) {
        //Log error project not found
        goBack();
        return;
      }

      // TODO: Remove when we get rid of all realmProject.workers values
      // {
      if (realmProject.workers && realmProject.workers.length > 0) {
        let workersArray: string[] = [];

        for (const w in realmProject.workers) {
          let worker = (await getPeople({ _id: new ObjectId(w) }, 1))[0];
          if (worker) {
            workersArray.push(
              JSON.stringify({
                mongoId: worker._id.toHexString(),
                SQLServerId: worker.SQLServerId,
              }),
            );
          }
        }

        realmProject.workers = [];
        realmProject.workersJSON = JSON.stringify(workersArray);
      }

      let projectManagerId = realmProject.projectManagerId;
      let projectManagerSQLServerId = realmProject.projectManagerSQLServerId;
      if (projectManagerId && !projectManagerSQLServerId) {
        let pm = (
          await getPeople({ _id: new ObjectId(projectManagerId) }, 1)
        )[0];
        if (pm) realmProject.projectManagerSQLServerId = pm.SQLServerId;
      }

      let safetyResourceId = realmProject.safetyResourceId;
      let safetyResourceSQLServerId = realmProject.safetyResourceSQLServerId;
      if (safetyResourceId && !safetyResourceSQLServerId) {
        let sr = (
          await getPeople({ _id: new ObjectId(safetyResourceId) }, 1)
        )[0];
        if (sr) realmProject.safetyResourceSQLServerId = sr.SQLServerId;
      }

      let siteSupervisorId = realmProject.siteSupervisorId;
      let siteSupervisorSQLServerId = realmProject.siteSupervisorSQLServerId;
      if (siteSupervisorId && !siteSupervisorSQLServerId) {
        let ss = (
          await getPeople({ _id: new ObjectId(siteSupervisorId) }, 1)
        )[0];
        if (ss) realmProject.siteSupervisorSQLServerId = ss.SQLServerId;
      }
      // }

      if (realmProject.citySQLServerId) {
        let cityObj = cities.find(
          x =>
            x.SQLServerId?.toLowerCase() ==
            realmProject.citySQLServerId?.toLowerCase(),
        );

        if (cityObj) {
          setCityValue(
            JSON.stringify({
              mongoId: cityObj._id.toHexString(),
              label: cityObj.cityName ?? '',
              SQLServerId: cityObj.SQLServerId ?? '',
            }),
          );

          let provObj = (await getProvStates()).find(
            x =>
              x.SQLServerId?.toLowerCase() ==
              cityObj!.provStateSQLServerId?.toLowerCase(),
          );
          if (provObj) setProvName(provObj.provState ?? '');
        }
      }

      setProject(realmProject);
    };

    loadProjectSite();
  }, [props.projectSiteId]);

  const getProjectLocationJSON = () => {
    if (
      project &&
      project!.location &&
      project!.location.longitude &&
      project!.location.latitude
    ) {
      let projectLocation: { latitude: number; longitude: number } = {
        latitude: Number(project!.location.latitude),
        longitude: Number(project!.location.longitude),
      };

      return JSON.stringify(projectLocation);
    }

    return '';
  };

  const getEmptyProject = (withId?: string) => {
    let orgid = getOrgId();

    let projectDto: Project = {
      _id: new ObjectId(withId),
      isActive: true,
      name: '',
      partition: orgid.toString(),
      isDeleted: false,
      dataHubVersion: Math.round(Date.now() / 1000),
      createDateTimeStamp: new Date(),
      SQLServerId: new UUID().toHexString().toUpperCase(),
    };

    return projectDto;
  };

  const validate = async () => {
    let nameErrorText = '';
    if (!project?.name.trim()) nameErrorText = 'Name is required';
    else {
      let projectSitesDb = await getProjectSites({ name: project!.name }, 1);

      if (
        projectSitesDb &&
        projectSitesDb[0] &&
        projectSitesDb[0].name === project!.name &&
        !projectSitesDb[0]._id.equals(project?._id!)
      ) {
        nameErrorText = 'Name already exists';
      }
    }

    setErrorName(nameErrorText);
    if (!nameErrorText) return true;

    //Scroll to top if valdate fails
    scrollRef!.current!.scrollTo({
      y: 0,
    });

    return false;
  };

  const saveProjectSite = () => {
    if (!canUpdate && !addingSite) {
      updateModalCommand.current.openModal();
      return;
    }

    const asyncCall = async () => {
      if (!(await validate())) return false;

      project!.dataHubVersion = Math.round(Date.now() / 1000);
      await upsertProjectSite(project!);
      goBack();
      toast.show('Project/Site saved successfully', { type: 'success' });
    };

    asyncCall();
  };

  const goBack = () => {
    props.navigation.pop();
  };

  const getMongoSqlId = (valueJson: string): MongoIdSqlId | null => {
    let mongoSqlId = null;

    if (valueJson) {
      try {
        mongoSqlId = JSON.parse(valueJson) as MongoIdSqlId;
      } catch (error) {
        console.log('Error: Bad object serialization');
      }
    }

    return mongoSqlId;
  };

  //Typescript complains about indexing props by string so checking it manually
  //Maybe there's an easier way to do this?
  const onChange = (propName: string, propValue: string | null) => {
    if (propName === 'siteSupervisorId') {
      let mongoSqlId = getMongoSqlId(propValue ?? '');
      if (mongoSqlId) {
        project!.siteSupervisorId = mongoSqlId.mongoId;
        project!.siteSupervisorSQLServerId = mongoSqlId.SQLServerId;
      } else {
        project!.siteSupervisorId = undefined;
        project!.siteSupervisorSQLServerId = undefined;
      }
    } else if (propName === 'projectManagerId') {
      let mongoSqlId = getMongoSqlId(propValue ?? '');
      if (mongoSqlId) {
        project!.projectManagerId = mongoSqlId.mongoId;
        project!.projectManagerSQLServerId = mongoSqlId.SQLServerId;
      } else {
        project!.projectManagerId = undefined;
        project!.projectManagerSQLServerId = undefined;
      }
    } else if (propName === 'safetyResourceId') {
      let mongoSqlId = getMongoSqlId(propValue ?? '');
      if (mongoSqlId) {
        project!.safetyResourceId = mongoSqlId.mongoId;
        project!.safetyResourceSQLServerId = mongoSqlId.SQLServerId;
      } else {
        project!.safetyResourceId = undefined;
        project!.safetyResourceSQLServerId = undefined;
      }
    } else if (propName === 'activeStartDate') {
      let momentDate = moment(propValue, Formats.BACKEND_DATE);
      if (momentDate.isValid())
        project!.activeStartDate = momentDate.startOf('day').toDate();
      else project!.activeStartDate = undefined;
    } else if (propName === 'activeEndDate') {
      let momentDate = moment(propValue, Formats.BACKEND_DATE);
      if (momentDate.isValid())
        project!.activeEndDate = momentDate.startOf('day').toDate();
      else project!.activeEndDate = undefined;

      let today = moment().startOf('day').toDate();
      if (
        project!.activeEndDate &&
        moment(project!.activeEndDate, Formats.BACKEND_DATE).toDate() < today
      )
        project!.isActive = false;
      else project!.isActive = true;
    } else if (propName === 'name') {
      project!.name = propValue!;
    } else if (propName === 'workersJSON') {
      project!.workersJSON = propValue!;
    } else if (propName === 'address') {
      project!.address = propValue!;
    } else if (propName === 'postalZip') {
      project!.postalZip = propValue!;
    }

    let newProject = { ...project } as Project;
    setProject(newProject);
  };

  const onCityChange = async (value: string) => {
    if (!project || project == null || value == null || value == '') return;

    let mongoIdSqlId = getMongoSqlId(value);
    if (mongoIdSqlId) {
      project.cityName = mongoIdSqlId.label;
      project.citySQLServerId = mongoIdSqlId.SQLServerId;

      let cityObj = cities.find(
        x =>
          x.SQLServerId?.toLowerCase() ==
          mongoIdSqlId!.SQLServerId?.toLowerCase(),
      );

      if (cityObj) {
        let provObj = (await getProvStates()).find(
          x =>
            x.SQLServerId?.toLowerCase() ==
            cityObj!.provStateSQLServerId?.toLowerCase(),
        );
        if (provObj) {
          project.provStateSQLServerId = provObj.SQLServerId;
          project.countrySQLServerId = provObj.countrySQLServerId;
          setProvName(provObj.provState ?? '');
        }
      }

      let newProject = { ...project } as Project;
      setProject(newProject);
      setCityValue(value);
    }
  };

  const onLocationChange = (value: string) => {
    if (!project || project == null || value == null || value == '') return;

    let newLocation = null;

    let latlng: LatLng = JSON.parse(value);
    if (latlng.latitude && latlng.longitude) {
      newLocation = {
        latitude: new Decimal128(latlng.latitude.toString()),
        longitude: new Decimal128(latlng.longitude.toString()),
      };
    }

    project.location = newLocation;
    let newProject = { ...project } as Project;
    setProject(newProject);
  };

  const renderLoading = (): React.ReactElement => {
    return (
      <View
        style={{
          position: 'absolute',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              color: Colors.white,
              fontSize: 16,
              fontWeight: '600',
            }}>
            Loading Site Info...
          </Text>
        </View>
      </View>
    );
  };

  return (
    <ScrollView ref={scrollRef}>
      <ConfirmModal
        title="No Access"
        message="You do not have access to update projects"
        okClick={() => {}}
        okText="Ok"
        setOpenModal={(func: () => void) => {
          updateModalCommand.current.setOpenModal(func);
        }}
      />
      <View>
        {!project ? (
          renderLoading()
        ) : (
          <>
            <View
              style={{
                backgroundColor: Colors.darkGreen,
                paddingTop: 20,
              }}>
              <View style={DynamicPageStyleSheet.header}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={DynamicPageStyleSheet.headerText}>
                    {addingSite ? 'Add a Project/Site' : 'Edit Project/Site'}
                  </Text>
                  <View
                    style={{ paddingRight: 10, justifyContent: 'flex-start' }}>
                    <Pressable
                      style={({ pressed }) => [
                        pressed && {
                          opacity: 0.4,
                        },
                      ]}
                      onPress={goBack}>
                      <Icon
                        icon={'close-circle'}
                        color={Colors.white}
                        size={24}
                      />
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
            <View style={EditProjectSiteStyleSheet.body}>
              <ScrollView keyboardShouldPersistTaps="handled">
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={CommonStyleSheet.controlLabel}>Name</Text>
                  <Text
                    style={[ControlsStyleSheet.required, { paddingTop: 10 }]}>
                    *
                  </Text>
                </View>
                <TextInput
                  style={[ControlsStyleSheet.input]}
                  onChangeText={value => {
                    onChange('name', value);
                  }}
                  value={project?.name}
                  editable={canUpdate || addingSite}
                />
                {errorName ? (
                  <Text style={ControlsStyleSheet.error}>{errorName}</Text>
                ) : null}
                <View style={{ flexDirection: 'row', gap: 20 }}>
                  <View style={{ flex: 1 }}>
                    <DynamicTextBox
                      label="Address"
                      value={project?.address ?? ''}
                      onChange={(controlId, controlTypeId, value) => {
                        onChange('address', value);
                      }}
                      disabled={!canUpdate && !addingSite}
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    <DynamicAutocomplete
                      config={{ optionSource: ListDataTypes.CITIES }}
                      label="City"
                      value={cityValue}
                      onChange={(controlId, controlTypeId, value) =>
                        onCityChange(value)
                      }
                      disabled={!canUpdate && !addingSite}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: 'row', gap: 20 }}>
                  <View style={{ flex: 1 }}>
                    <DynamicTextBox
                      label="Province"
                      value={provName}
                      disabled={true}
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    <DynamicTextBox
                      label="Postal Code"
                      value={project?.postalZip ?? ''}
                      onChange={(controlId, controlTypeId, value) => {
                        onChange('postalZip', value);
                      }}
                      disabled={!canUpdate && !addingSite}
                    />
                  </View>
                </View>
                <View>
                  <Text style={CommonStyleSheet.controlLabel}>Location</Text>
                </View>
                <DynamicLocationMap
                  config={{
                    initialRegion: {
                      latitude: 54.2217134,
                      longitude: -116.7579707,
                      latitudeDelta: 0,
                      longitudeDelta: 0,
                    },
                  }}
                  value={getProjectLocationJSON()}
                  onChange={(controlId, controlTypeId, value) => {
                    onLocationChange(value);
                  }}
                  disabled={!canUpdate && !addingSite}
                />
                <View>
                  <Text style={CommonStyleSheet.controlLabel}>
                    Active Dates
                  </Text>
                </View>
                <View
                  style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                  <View style={{ flexGrow: 1 }}>
                    <DynamicDatePicker
                      label="Start"
                      value={
                        project.activeStartDate
                          ? moment
                              .utc(project.activeStartDate)
                              .format(Formats.BACKEND_DATE)
                          : ''
                      }
                      required={false}
                      disabled={!canUpdate && !addingSite}
                      onChange={(controlId, controlTypeId, value) => {
                        onChange('activeStartDate', value);
                      }}
                    />
                  </View>
                  <View style={{ flexGrow: 1 }}>
                    <DynamicDatePicker
                      label="End"
                      value={
                        project.activeEndDate
                          ? moment
                              .utc(project.activeEndDate)
                              .format(Formats.BACKEND_DATE)
                          : ''
                      }
                      required={false}
                      disabled={!canUpdate && !addingSite}
                      onChange={(controlId, controlTypeId, value) => {
                        onChange('activeEndDate', value);
                      }}
                    />
                  </View>
                </View>
                <View>
                  <Text style={CommonStyleSheet.controlLabel}>People</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 20,
                  }}>
                  <View style={{ flex: 1, flexGrow: 1 }}>
                    <DynamicAutocomplete
                      label={'Site Supervisor'}
                      value={
                        project.siteSupervisorId
                          ? JSON.stringify({
                              mongoId: project.siteSupervisorId,
                              SQLServerId: project.siteSupervisorSQLServerId,
                            })
                          : ''
                      }
                      config={{ optionSource: ListDataTypes.PEOPLE }}
                      disabled={!canUpdate && !addingSite}
                      onChange={(controlId, controlTypeId, value) =>
                        onChange('siteSupervisorId', value?.toString())
                      }
                      required={false}
                    />
                  </View>
                  <View style={{ flex: 1, flexGrow: 1 }}>
                    <DynamicAutocomplete
                      label={'Project Manager'}
                      value={
                        project.projectManagerId
                          ? JSON.stringify({
                              mongoId: project.projectManagerId,
                              SQLServerId: project.projectManagerSQLServerId,
                            })
                          : ''
                      }
                      config={{ optionSource: ListDataTypes.PEOPLE }}
                      disabled={!canUpdate && !addingSite}
                      onChange={(controlId, controlTypeId, value) =>
                        onChange('projectManagerId', value?.toString())
                      }
                      required={false}
                    />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 20,
                  }}>
                  <View style={{ flex: 1, flexGrow: 1 }}>
                    <DynamicAutocomplete
                      label={'Safety Resource'}
                      value={
                        project.safetyResourceId
                          ? JSON.stringify({
                              mongoId: project.safetyResourceId,
                              SQLServerId: project.safetyResourceSQLServerId,
                            })
                          : ''
                      }
                      config={{ optionSource: ListDataTypes.PEOPLE }}
                      disabled={!canUpdate && !addingSite}
                      onChange={(controlId, controlTypeId, value) =>
                        onChange('safetyResourceId', value?.toString())
                      }
                      required={false}
                    />
                  </View>
                  <View style={{ flex: 1, flexGrow: 1 }}>
                    <DynamicFacepile
                      value={project?.workersJSON ?? ''}
                      disabled={!canUpdate && !addingSite}
                      label="Workers"
                      config={{
                        optionSource: ListDataTypes.PEOPLE,
                      }}
                      onChange={(controlId, controlTypeId, value, valid) => {
                        onChange('workersJSON', value);
                      }}
                    />
                  </View>
                </View>
                <View>
                  <Pressable
                    style={[
                      CommonStyleSheet.greenButton,
                      !canUpdate && !addingSite && { opacity: 0.4 },
                    ]}
                    android_ripple={{ color: Colors.whiteTransparent }}
                    onPress={() => {
                      saveProjectSite();
                    }}>
                    <Text style={CommonStyleSheet.greenButtonText}>
                      {addingSite ? 'Create Site' : 'Save Site'}
                    </Text>
                  </Pressable>
                </View>
              </ScrollView>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

export default EditProjectSite;
