import { Dimensions, StyleSheet } from "react-native";
import Colors from "./Shared/Colors";

const ModalStyleSheet = StyleSheet.create({
  modalBackgroundContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    flex: 1,
    backgroundColor: "#rgba(0,0,0,0.5)",
  },
  modalContainer: {
    backgroundColor: Colors.white,
    elevation: 5,
    shadowColor: "black",
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    borderRadius: 5,
  },
  modalActionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: 10,
  },
  modalAction: {
    backgroundColor: Colors.darkGreen,
    borderRadius: 5,
    paddingTop: 5,
    paddingLeft: 10,
    paddingBottom: 5,
    paddingRight: 10,
  },
});

export { ModalStyleSheet };
