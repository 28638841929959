import { ObjectId } from 'bson';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Realm from 'realm-web';
import { ListDataType, ListDataTypes } from '../Constants/ListDataTypes';
import { Project, ProjectSearch } from '../Models/RealmModels/Projects';
import { Person, PersonSearch } from '../Models/RealmModels/Person';
import { TemplateVersion } from '../Models/RealmModels/TemplateVersion';
import { OrgTemplate } from '../Models/RealmModels/OrgTemplate';
import {
  Submission,
  Submission_submissionStatus,
  Submission_templateType,
  Submission_templateVersion,
} from '../Models/RealmModels/Submission';
import { SubmissionStatus } from '../Models/RealmModels/SubmissionStatus';
import { Template, Template_type } from '../Models/RealmModels/Template';
import { TemplateVersionConversion } from '../Models/RealmModels/TemplateVersionConversion';
import { SubmissionLink } from '../Models/RealmModels/SubmissionLink';
import { SQLUser } from '../Models/RealmModels/SQLUser';
import { OIICS_BodyPart } from '../Models/RealmModels/OIICS_BodyPart';
import { OIICS_Nature } from '../Models/RealmModels/OIICS_Nature';
import { Mechanism } from '../Models/RealmModels/Mechanism';
import LocalStorageService from '../Services/LocalStorageService';
import { ILocalStorageService } from '../Services/Interfaces/ILocalStorageService';
import { REALM_FUNCTION_NAMES, STORAGE_KEYS } from '../Constants/AppConstants';
import { Crew } from '../Models/RealmModels/Crew';
import { Department } from '../Models/RealmModels/Department';
import { EquipmentType } from '../Models/RealmModels/EquipmentType';
import { HazardType } from '../Models/RealmModels/HazardType';
import { IncidentType } from '../Models/RealmModels/IncidentType';
import { Disposition } from '../Models/RealmModels/Disposition';
import { Document } from '../Models/RealmModels/Document';
import { DocumentType } from '../Models/RealmModels/DocumentType';
import { LocationType } from '../Models/RealmModels/LocationType';
import { Location } from '../Models/RealmModels/Location';
import { Job } from '../Models/RealmModels/Job';
import { Organisation } from '../Models/RealmModels/Organisation';
import { Permission } from '../Models/RealmModels/Permission';
import { Task } from '../Models/RealmModels/Task';
import { TaskType } from '../Models/RealmModels/TaskType';
import { Shift } from '../Models/RealmModels/Shift';
import { useCustomRealm } from './CustomRealmProvider';
import { IncidentClassification } from '../Models/RealmModels/IncidentClassification';
import { IncidentProbability } from '../Models/RealmModels/IncidentProbability';
import { SeverityLevel } from '../Models/RealmModels/SeverityLevel';
import { Equipment } from '../Models/RealmModels/Equipment';
import { Nature } from '../Models/RealmModels/Nature';
import { AnimalType } from '../Models/RealmModels/AnimalType';
import { EquipmentHeavyProfile } from '../Models/RealmModels/EquipmentHeavyProfile';
import { EquipmentOtherProfile } from '../Models/RealmModels/EquipmentOtherProfile';
import { EquipmentVehicleProfile } from '../Models/RealmModels/EquipmentVehicleProfile';
import {
  SubmissionTraining,
  SubmissionTraining_certificateType,
  SubmissionTraining_person,
  SubmissionTraining_submissionStatus,
  SubmissionTraining_templateType,
  SubmissionTraining_templateVersion,
  SubmissionTraining_trainingType,
} from '../Models/RealmModels/SubmissionTraining';
import { CertificationType } from '../Models/RealmModels/CertificationType';
import { TrainingType } from '../Models/RealmModels/TrainingType';
import { UserStatus } from '../Models/RealmModels/UserStatus';
import { SubmissionRelation } from '../Models/RealmModels/SubmissionRelation';
import { OrgRelation } from '../Models/RealmModels/OrgRelation';
import { OrgContractorNotificationRecipient } from '../Models/RealmModels/OrgContractorNotificationRecipient';
import { SubmissionBundle } from '../Models/RealmModels/SubmissionBundle';
import { OrgTemplateGroup } from '../Models/RealmModels/OrgTemplateGroup';
import { TemplateGroup } from '../Models/RealmModels/TemplateGroup';
import { GET_SYNC_MAX_DHV_BY_ORG_TYPE } from '../Types/RealmFunctionResultTypes';
import { StorageKeyType } from '../Types/StorageTypes';
import { ClassificationUnit } from '../Models/RealmModels/ClassificationUnit';
import { City } from '../Models/RealmModels/City';
import { ProvState } from '../Models/RealmModels/ProvState';
import { PeopleType } from '../Models/RealmModels/PeopleType';
import { Role } from '../Models/RealmModels/Role';
import { OrgStorage } from '../Models/RealmModels/OrgStorage';
import { ControlTypes } from '../Constants/ControlTypes';
import { DynamicAttachment } from '../Types/ControlTypes';
import { parse } from 'path';
import AttachmentService from '../Services/AttachmentService';
import { IAttachmentService } from '../Services/Interfaces/IAttachmentService';

type TypeWithId = {
  _id: ObjectId;
};

export type GenericListItem = {
  _id: ObjectId;
  name: string;
  masterValue?: string;
  masterLabel?: string;
  sortOrder: number;
  partition: string;
  isActive: boolean;
  isDeleted: boolean;
  dataHubVersion?: number;
  createDateTimeStamp: Date;
  SQLServerId: string;
};

type SyncProviderProps = {
  children: React.ReactNode;
};

type SyncContextValue = {
  getRealmApp: () => Realm.App<
    globalThis.Realm.DefaultFunctionsFactory &
      globalThis.Realm.BaseFunctionsFactory
  >;
  loadRealmData: () => Promise<void>;
  getCollectionSize: (collectionType: ListDataType) => Promise<number>;
  getListData: <T extends TypeWithId>(keyname: StorageKeyType) => T[];
  upsertListData: <T extends GenericListItem>(
    item: T,
    tableName: string,
    key: StorageKeyType,
    partition: string,
    isInsert: boolean,
  ) => void;
  getTemplateVersionConversions: () => Promise<TemplateVersionConversion[]>;
  getSubmissionLinks: () => Promise<SubmissionLink[]>;
  addSubmissionLink: (submissionLink: SubmissionLink) => void;
  removeSubmissionLinks: (submissionId: ObjectId, byParent: boolean) => void;
  getProjectSites: (
    search: ProjectSearch,
    count?: number,
  ) => Promise<Project[]>;
  getPeople: (search: PersonSearch, count?: number) => Promise<Person[]>;
  getSQLUsers: () => Promise<SQLUser[]>;
  upsertSQLUser: (user: SQLUser) => void;
  getUserStatus: () => Promise<UserStatus[]>;
  getTemplateVersions: () => Promise<TemplateVersion[]>;
  getFilteredTemplateVersions: () => Promise<TemplateVersion[]>;
  getSubmission: (submissionId: ObjectId) => Promise<Submission | undefined>;
  getSubmissions: () => Promise<Submission[]>;
  upsertSubmission: (submission: Submission) => void;
  getSubmissionTrainings: () => Promise<SubmissionTraining[]>;
  upsertSubmissionTraining: (submission: SubmissionTraining) => void;
  getSubmissionStatuses: () => Promise<SubmissionStatus[]>;
  upsertProjectSite: (projectSite: Project) => void;
  upsertPerson: (person: Person) => void;
  getTemplates: () => Promise<Template[]>;
  getFilteredTemplates: () => Promise<Template[]>;
  getAnimalTypes: () => Promise<AnimalType[]>;
  getCertificationTypes: () => Promise<CertificationType[]>;
  getClassificationUnits: () => Promise<ClassificationUnit[]>;
  getCrews: () => Promise<Crew[]>;
  getDepartments: () => Promise<Department[]>;
  getDispositions: () => Promise<Disposition[]>;
  getDocumentTypes: () => Promise<DocumentType[]>;
  getDocuments: () => Promise<Document[]>;
  upsertDocument: (doc: Document) => void;
  deleteDocument: (documentId: ObjectId) => void;
  getEquipmentTypes: () => Promise<EquipmentType[]>;
  getEquipments: () => Promise<Equipment[]>;
  getEquipmentsHeavy: () => EquipmentHeavyProfile[];
  getEquipmentsOther: () => EquipmentOtherProfile[];
  getEquipmentsVehicle: () => EquipmentVehicleProfile[];
  getHazardTypes: () => Promise<HazardType[]>;
  getIncidentTypes: () => Promise<IncidentType[]>;
  getIncidentClassifications: () => Promise<IncidentClassification[]>;
  getIncidentProbabilities: () => Promise<IncidentProbability[]>;
  getJobs: () => Job[];
  getLocations: () => Promise<Location[]>;
  getLocationTypes: () => Promise<LocationType[]>;
  getMechanisms: () => Promise<Mechanism[]>;
  getNatures: () => Promise<Nature[]>;
  getOIICSBodyParts: () => Promise<OIICS_BodyPart[]>;
  getOIICSNatures: () => Promise<OIICS_Nature[]>;
  getOrganisations: () => Organisation[];
  upsertOrganisations: (organisation: Organisation) => void;
  getOrgId: () => string;
  getOrgStorage: () => Promise<OrgStorage | undefined>;
  getOrgRecipients: () => Promise<OrgContractorNotificationRecipient[]>;
  getOrgRelations: () => Promise<OrgRelation[]>;
  getPeopleTypes: () => Promise<PeopleType[]>;
  getPermissions: () => Permission[];
  getProvStates: () => ProvState[];
  getRoles: () => Promise<Role[]>;
  getSubmissionBundles: () => Promise<SubmissionBundle[]>;
  upsertSubmissionBundle: (SubmissionBundle: SubmissionBundle) => void;
  getSubmissionRelations: () => Promise<SubmissionRelation[]>;
  upsertSubmissionRelation: (submissionRelation: SubmissionRelation) => void;
  getSeverityLevels: () => Promise<SeverityLevel[]>;
  getShifts: () => Promise<Shift[]>;
  getTasks: () => Promise<Task[]>;
  upsertTask: (task: Task) => void;
  upsertIncidentClassification: (
    incidentClassification: IncidentClassification,
  ) => void;
  upsertSeverityLevel: (severityLevel: SeverityLevel) => void;
  upsertIncidentProbability: (incidentProbability: IncidentProbability) => void;
  getTaskTypes: () => Promise<TaskType[]>;
  getTrainingTypes: () => Promise<TrainingType[]>;
  upsertHeavyEquipment: (equipment: EquipmentHeavyProfile) => void;
  upsertOtherEquipment: (equipment: EquipmentOtherProfile) => void;
  upsertVehicleEquipment: (equipment: EquipmentVehicleProfile) => void;
  deleteSubmission: (submissionId: ObjectId) => Promise<void>;
  deleteSubmissionRelation: (submissionRelationId: ObjectId) => Promise<void>;
  deleteSubmissionTraining: (submissionId: ObjectId) => Promise<void>;
  deleteSubmissionBundle: (id: ObjectId) => Promise<void>;
  deleteSubmissionAttachments: (submissionId: ObjectId) => Promise<void>;
  existSubmissionInBundle: (submissionId: ObjectId) => Promise<boolean>;
  clearStateData: () => void;
  submissions: Submission[];
  submissionTrainings: SubmissionTraining[];
  people: Person[];
  projects: Project[];
  cities: City[];
};

// create the fact context
const SyncContext = React.createContext<SyncContextValue>(
  {} as SyncContextValue,
);

const SyncProvider: React.FunctionComponent<SyncProviderProps> = ({
  children,
}): React.ReactElement => {
  useEffect(() => {
    let processSyncJob = async () => {
      let loggedIn = await getRealmApp().currentUser?.isLoggedIn;

      if (loggedIn)
        await Promise.all([
          loadPeople(),
          loadSubmissions(),
          loadSubmissionTrainings(),
          loadProjectSites(),
          loadDocuments(),
          reloadTable(ListDataTypes.CITIES),
        ]);
    };

    processSyncJob();
  }, []);

  const { getRealmApp } = useCustomRealm();

  //These tables use too much memory in localstorage, holding it in state instead
  //TODO: consider migrating to IndexedDB API for more web persistent storage?
  let isLoadingPeople = useRef<boolean>(false);
  let isLoadingSubmissions = useRef<boolean>(false);
  let isLoadingSubmissionTrainings = useRef<boolean>(false);
  let isLoadingProjectSites = useRef<boolean>(false);
  const [people, setPeople] = useState<Person[]>([]);
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [submissionTrainings, setSubmissionTrainings] = useState<
    SubmissionTraining[]
  >([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const { realmSignOut } = useCustomRealm();

  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const [tableMaxDHV, setTableMaxDHV] =
    useState<GET_SYNC_MAX_DHV_BY_ORG_TYPE>();

  const storageService: ILocalStorageService = new LocalStorageService();
  const SYNC_INTERVAL_MS = 600000; //600s

  useEffect(() => {
    const interval = setInterval(async () => {
      await processSyncIntervalJob();
    }, SYNC_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [tableMaxDHV]);

  const getTableMaxDHV = async () => {
    let orgid = getOrgId();
    const newTableMaxDhvs = (await getRealmApp().currentUser!.callFunction(
      REALM_FUNCTION_NAMES.GET_SYNC_MAX_DHV_BY_ORG,
      { partition: orgid },
    )) as GET_SYNC_MAX_DHV_BY_ORG_TYPE;

    return newTableMaxDhvs;
  };

  const processSyncIntervalJob = async () => {
    if (!tableMaxDHV) {
      let newTablsadeMaxDHV = await getTableMaxDHV();
      setTableMaxDHV(newTablsadeMaxDHV);
      return;
    }

    let newTableMaxDHV = await getTableMaxDHV();
    let tablesToUpdate: string[] = [];

    for (let i = 0; i < newTableMaxDHV.data?.length; i++) {
      let currentTableMaxDHV = tableMaxDHV.data.find(
        x => x.tableName === newTableMaxDHV.data[i].tableName,
      );

      if (!currentTableMaxDHV) continue;

      if (
        currentTableMaxDHV.maxDataHubVersion <
        newTableMaxDHV.data[i].maxDataHubVersion
      ) {
        tablesToUpdate.push(currentTableMaxDHV.tableName);
      }
    }

    for (let i = 0; i < tablesToUpdate.length; i++) {
      await reloadTable(tablesToUpdate[i]);
    }

    setTableMaxDHV(newTableMaxDHV);
  };

  const clearStateData = () => {
    setPeople([]);
    setSubmissions([]);
    setSubmissionTrainings([]);
    setProjects([]);
  };

  const reloadTable = async (tableName: string) => {
    let orgid = getOrgId();
    let filter = {
      partition: orgid,
    };

    switch (tableName) {
      case 'AnimalTypes':
        loadRealmTable<AnimalType>(
          ListDataTypes.ANIMALTYPES,
          STORAGE_KEYS.REALM_ANIMALTYPES,
          filter,
        );
        break;
      case 'CertificationTypes':
        loadRealmTable<CertificationType>(
          ListDataTypes.CERTIFICATIONTYPES,
          STORAGE_KEYS.REALM_CERTIFICATIONTYPES,
          filter,
        );
        break;
      case 'Crews':
        loadRealmTable<ClassificationUnit>(
          ListDataTypes.CLASSIFICATIONUNITS,
          STORAGE_KEYS.REALM_CLASSIFICATIONUNITS,
          filter,
        );
        break;
      case 'Crews':
        loadRealmTable<Crew>(
          ListDataTypes.CREWS,
          STORAGE_KEYS.REALM_CREWS,
          filter,
        );
        break;
      case 'Departments':
        loadRealmTable<Department>(
          ListDataTypes.DEPARTMENTS,
          STORAGE_KEYS.REALM_DEPARTMENTS,
          filter,
        );
        break;
      case 'Dispositions':
        loadRealmTable<Disposition>(
          ListDataTypes.DISPOSTIONS,
          STORAGE_KEYS.REALM_DISPOSITIONS,
          filter,
        );
        break;
      case 'DocumentTypes':
        loadRealmTable<DocumentType>(
          ListDataTypes.DOCUMENTTYPES,
          STORAGE_KEYS.REALM_DOCUMENTTYPES,
          filter,
        );
        break;
      case 'Documents':
        loadRealmTable<Document>(
          'Documents',
          STORAGE_KEYS.REALM_DOCUMENTS,
          filter,
        );
        break;
      case 'EquipmentTypes':
        loadRealmTable<EquipmentType>(
          ListDataTypes.EQUIPMENTTYPES,
          STORAGE_KEYS.REALM_EQUIPMENTTYPES,
          filter,
        );
        break;
      case 'Equipments':
        loadRealmTable<Equipment>(
          ListDataTypes.EQUIPMENTS,
          STORAGE_KEYS.REALM_EQUIPMENT,
          filter,
        );
        break;
      case 'EquipmentsHeavy':
        loadRealmTable<EquipmentHeavyProfile>(
          ListDataTypes.EQUIPMENTSHEAVY,
          STORAGE_KEYS.REALM_EQUIPMENTHEAVY,
          filter,
        );
        break;
      case 'EquipmentsOther':
        loadRealmTable<EquipmentOtherProfile>(
          ListDataTypes.EQUIPMENTOTHERPROFILES,
          STORAGE_KEYS.REALM_EQUIPMENTOTHER,
          filter,
        );
        break;
      case 'EquipmentsVehicle':
        loadRealmTable<EquipmentVehicleProfile>(
          ListDataTypes.EQUIPMENTSVEHICLE,
          STORAGE_KEYS.REALM_EQUIPMENTVEHICLE,
          filter,
        );
        break;
      case 'HazardTypes':
        loadRealmTable<HazardType>(
          ListDataTypes.HAZARDTYPES,
          STORAGE_KEYS.REALM_HAZARDTYPES,
          filter,
        );
        break;
      case 'IncidentProbabilities':
        loadRealmTable<IncidentProbability>(
          ListDataTypes.INCIDENTPROBABILITIES,
          STORAGE_KEYS.REALM_INCIDENTPROBABILITIES,
          filter,
        );
        break;
      case 'IncidentTypes':
        loadRealmTable<IncidentType>(
          ListDataTypes.INCIDENTTYPES,
          STORAGE_KEYS.REALM_INCIDENTTYPES,
          filter,
        );
        break;
      case 'IncidentClassifications':
        loadRealmTable<IncidentClassification>(
          ListDataTypes.INCIDENTCLASSIFICATIONS,
          STORAGE_KEYS.REALM_INCIDENTCLASSIFICATIONS,
          filter,
        );
        break;
      case 'Jobs':
        loadRealmTable<Job>(
          ListDataTypes.JOBS,
          STORAGE_KEYS.REALM_JOBS,
          filter,
        );
        break;
      case 'Locations':
        loadRealmTable<Location>(
          ListDataTypes.LOCATIONS,
          STORAGE_KEYS.REALM_LOCATIONS,
          filter,
        );
        break;
      case 'LocationTypes':
        loadRealmTable<LocationType>(
          ListDataTypes.LOCATIONTYPES,
          STORAGE_KEYS.REALM_LOCATIONTYPES,
          filter,
        );
        break;
      case 'Mechanisms':
        loadRealmTable<Mechanism>(
          ListDataTypes.MECHANISMS,
          STORAGE_KEYS.REALM_MECHANISMS,
          filter,
        );
        break;
      case 'Natures':
        loadRealmTable<Nature>(
          ListDataTypes.NATURES,
          STORAGE_KEYS.REALM_NATURES,
          filter,
        );
        break;
      case 'OIICSBodyParts':
        loadRealmTable<OIICS_BodyPart>(
          'OIICS_BodyParts',
          STORAGE_KEYS.REALM_OIICS_BODYPARTS,
          {},
        );
        break;
      case 'OIICSNatures':
        loadRealmTable<OIICS_Nature>(
          'OIICS_Natures',
          STORAGE_KEYS.REALM_OIICS_NATURES,
          {},
        );
        break;
      case 'Organisations':
        loadRealmTable<Organisation>(
          ListDataTypes.ORGANISATIONS,
          STORAGE_KEYS.REALM_ORGANISATIONS,
          {},
        );
        break;
      case 'OrgRecipients':
        loadRealmTable<OrgContractorNotificationRecipient>(
          'OrgContractorNotificationRecipients',
          STORAGE_KEYS.REALM_ORGRECIPIENTS,
          {},
        );
        break;
      case 'OrgRelations':
        loadRealmTable<OrgRelation>(
          'OrgRelations',
          STORAGE_KEYS.REALM_ORGRELATIONS,
          {},
        );
        break;
      case 'OrgTemplateGroups':
        loadRealmTable<OrgTemplateGroup>(
          'OrgTemplateGroups',
          STORAGE_KEYS.REALM_ORGTEMPLATEGROUPS,
          filter,
        );
        break;
      case 'People':
        loadPeople();
        break;
      case 'PeopleTypes':
        loadRealmTable<PeopleType>(
          'PeopleTypes',
          STORAGE_KEYS.REALM_PEOPLETYPES,
          filter,
        );
        break;
      case 'Permissions':
        loadRealmTable<Permission>(
          'Permissions',
          STORAGE_KEYS.REALM_PERMISSIONS,
          {},
        );
        break;
      case 'ProjectSites':
        loadProjectSites();
        break;
      case 'Roles':
        loadRealmTable<Role>(
          ListDataTypes.ROLES,
          STORAGE_KEYS.REALM_ROLES,
          filter,
        );
        break;
      case 'SeverityLevels':
        loadRealmTable<SeverityLevel>(
          ListDataTypes.SEVERITYLEVELS,
          STORAGE_KEYS.REALM_SEVERITYLEVELS,
          filter,
        );
        break;
      case 'SQLUsers':
        loadRealmTable<SQLUser>(
          ListDataTypes.SQLUser,
          STORAGE_KEYS.REALM_SQLUSERS,
          filter,
        );
        break;
      case 'Shifts':
        loadRealmTable<Shift>(
          ListDataTypes.SHIFTS,
          STORAGE_KEYS.REALM_SHIFTS,
          filter,
        );
        break;
      case 'Submissions':
        let maxSubmissionDhv = 0;
        if (submissions.length) {
          maxSubmissionDhv = Math.max(
            ...submissions
              .filter(x => !!x.dataHubVersion)
              .map(x => x.dataHubVersion!),
          );
        }

        await loadSubmissions(undefined, maxSubmissionDhv);
        break;
      case 'SubmissionBundles':
        loadRealmTable<SubmissionBundle>(
          'SubmissionBundles',
          STORAGE_KEYS.REALM_SUBMISSIONBUNDLES,
          {},
        );
        break;
      case 'SubmissionRelations':
        loadRealmTable<SubmissionRelation>(
          'SubmissionRelations',
          STORAGE_KEYS.REALM_SUBMISSIONRELATIONS,
          filter,
        );
        break;
      case 'SubmissionStatuses':
        loadRealmTable<SubmissionStatus>(
          'SubmissionStatuses',
          STORAGE_KEYS.REALM_SUBMISSIONSTATUSES,
          {},
        );
        break;
      case 'SubmissionTrainings':
        loadRealmTable<SubmissionTraining>(
          'SubmissionTrainings',
          STORAGE_KEYS.REALM_SUBMISSIONTRAININGS,
          filter,
        );
        break;
      case 'Tasks':
        loadRealmTable<Task>(
          ListDataTypes.TASKS,
          STORAGE_KEYS.REALM_TASKS,
          filter,
        );
        break;
      case 'TaskTypes':
        loadRealmTable<TaskType>(
          ListDataTypes.TASKTYPES,
          STORAGE_KEYS.REALM_TASKTYPES,
          filter,
        );
        break;
      case 'Templates':
        loadRealmTable<Template>('Templates', STORAGE_KEYS.REALM_TEMPLATES, {});
        break;
      case 'TemplateGroups':
        loadRealmTable<TemplateGroup>(
          'TemplateGroups',
          STORAGE_KEYS.REALM_TEMPLATEGROUPS,
          {},
        );
        break;
      case 'TemplateVersions':
        loadRealmTable<TemplateVersion>(
          'TemplateVersions',
          STORAGE_KEYS.REALM_TEMPLATEVERSIONS,
          {},
        );
        break;
      case 'TemplateVersionConversions':
        loadRealmTable<TemplateVersionConversion>(
          'TemplateVersionConversions',
          STORAGE_KEYS.REALM_TEMPLATEVERSIONCONVERSIONS,
          {},
        );
        break;
      case 'TrainingTypes':
        loadRealmTable<TrainingType>(
          ListDataTypes.TRAININGTYPES,
          STORAGE_KEYS.REALM_TRAININGTYPES,
          filter,
        );
        break;
      case 'UserStatus':
        loadRealmTable<UserStatus>(
          'UserStatuses',
          STORAGE_KEYS.REALM_USERSTATUS,
          {},
        );
        break;
      case 'Cities':
        let fetchedCities = await fetchRealmTable<City>(
          ListDataTypes.CITIES,
          STORAGE_KEYS.REALM_CITIES,
          {},
        );
        setCities(fetchedCities);
        break;
    }
  };

  const loadRealmData = async () => {
    let orgId = getOrgId();
    let filter = {
      partition: orgId,
    };

    await Promise.all([
      loadRealmTable<AnimalType>(
        ListDataTypes.ANIMALTYPES,
        STORAGE_KEYS.REALM_ANIMALTYPES,
        filter,
      ),
      loadRealmTable<CertificationType>(
        ListDataTypes.CERTIFICATIONTYPES,
        STORAGE_KEYS.REALM_CERTIFICATIONTYPES,
        filter,
      ),
      async () => {
        let fetchedCities = await fetchRealmTable<City>(
          ListDataTypes.CITIES,
          STORAGE_KEYS.REALM_CITIES,
          {},
        );
        setCities(fetchedCities);
      },
      loadRealmTable<ClassificationUnit>(
        ListDataTypes.CLASSIFICATIONUNITS,
        STORAGE_KEYS.REALM_CLASSIFICATIONUNITS,
        {},
      ),
      loadRealmTable<Crew>(
        ListDataTypes.CREWS,
        STORAGE_KEYS.REALM_CREWS,
        filter,
      ),
      loadRealmTable<Department>(
        ListDataTypes.DEPARTMENTS,
        STORAGE_KEYS.REALM_DEPARTMENTS,
        filter,
      ),
      loadRealmTable<Disposition>(
        ListDataTypes.DISPOSTIONS,
        STORAGE_KEYS.REALM_DISPOSITIONS,
        filter,
      ),
      loadRealmTable<DocumentType>(
        ListDataTypes.DOCUMENTTYPES,
        STORAGE_KEYS.REALM_DOCUMENTTYPES,
        filter,
      ),
      loadRealmTable<Document>(
        'Documents',
        STORAGE_KEYS.REALM_DOCUMENTS,
        filter,
      ),
      loadRealmTable<EquipmentType>(
        ListDataTypes.EQUIPMENTTYPES,
        STORAGE_KEYS.REALM_EQUIPMENTTYPES,
        filter,
      ),
      loadRealmTable<Equipment>(
        ListDataTypes.EQUIPMENTS,
        STORAGE_KEYS.REALM_EQUIPMENT,
        filter,
      ),
      loadRealmTable<EquipmentHeavyProfile>(
        ListDataTypes.EQUIPMENTSHEAVY,
        STORAGE_KEYS.REALM_EQUIPMENTHEAVY,
        filter,
      ),
      loadRealmTable<EquipmentOtherProfile>(
        ListDataTypes.EQUIPMENTSOTHER,
        STORAGE_KEYS.REALM_EQUIPMENTOTHER,
        filter,
      ),
      loadRealmTable<EquipmentVehicleProfile>(
        ListDataTypes.EQUIPMENTSVEHICLE,
        STORAGE_KEYS.REALM_EQUIPMENTVEHICLE,
        filter,
      ),
      loadRealmTable<HazardType>(
        ListDataTypes.HAZARDTYPES,
        STORAGE_KEYS.REALM_HAZARDTYPES,
        filter,
      ),
      loadRealmTable<IncidentProbability>(
        ListDataTypes.INCIDENTPROBABILITIES,
        STORAGE_KEYS.REALM_INCIDENTPROBABILITIES,
        filter,
      ),
      loadRealmTable<IncidentType>(
        ListDataTypes.INCIDENTTYPES,
        STORAGE_KEYS.REALM_INCIDENTTYPES,
        filter,
      ),
      loadRealmTable<IncidentClassification>(
        ListDataTypes.INCIDENTCLASSIFICATIONS,
        STORAGE_KEYS.REALM_INCIDENTCLASSIFICATIONS,
        filter,
      ),
      loadRealmTable<Job>(ListDataTypes.JOBS, STORAGE_KEYS.REALM_JOBS, filter),
      loadRealmTable<Location>(
        ListDataTypes.LOCATIONS,
        STORAGE_KEYS.REALM_LOCATIONS,
        filter,
      ),
      loadRealmTable<LocationType>(
        ListDataTypes.LOCATIONTYPES,
        STORAGE_KEYS.REALM_LOCATIONTYPES,
        filter,
      ),
      loadRealmTable<Mechanism>(
        ListDataTypes.MECHANISMS,
        STORAGE_KEYS.REALM_MECHANISMS,
        filter,
      ),
      loadRealmTable<Nature>(
        ListDataTypes.NATURES,
        STORAGE_KEYS.REALM_NATURES,
        filter,
      ),
      loadRealmTable<OIICS_BodyPart>(
        'OIICS_BodyParts',
        STORAGE_KEYS.REALM_OIICS_BODYPARTS,
        {},
      ),
      loadRealmTable<OIICS_Nature>(
        'OIICS_Natures',
        STORAGE_KEYS.REALM_OIICS_NATURES,
        {},
      ),
      loadRealmTable<Organisation>(
        ListDataTypes.ORGANISATIONS,
        STORAGE_KEYS.REALM_ORGANISATIONS,
        {},
      ),
      loadRealmTable<OrgContractorNotificationRecipient>(
        'OrgContractorNotificationRecipients',
        STORAGE_KEYS.REALM_ORGRECIPIENTS,
        {},
      ),
      loadRealmTable<OrgRelation>(
        'OrgRelations',
        STORAGE_KEYS.REALM_ORGRELATIONS,
        {},
      ),
      loadRealmTable<OrgTemplateGroup>(
        'OrgTemplateGroups',
        STORAGE_KEYS.REALM_ORGTEMPLATEGROUPS,
        {},
      ),
      loadPeople(),
      loadRealmTable<PeopleType>(
        'PeopleTypes',
        STORAGE_KEYS.REALM_PEOPLETYPES,
        filter,
      ),
      loadRealmTable<Permission>(
        'Permissions',
        STORAGE_KEYS.REALM_PERMISSIONS,
        {},
      ),
      loadRealmTable<ProvState>(
        ListDataTypes.PROVSTATES,
        STORAGE_KEYS.REALM_PROVSTATES,
        {},
      ),
      loadRealmTable<Role>('Roles', STORAGE_KEYS.REALM_ROLES, {}),
      loadRealmTable<SeverityLevel>(
        ListDataTypes.SEVERITYLEVELS,
        STORAGE_KEYS.REALM_SEVERITYLEVELS,
        filter,
      ),
      loadRealmTable<SQLUser>(
        ListDataTypes.SQLUser,
        STORAGE_KEYS.REALM_SQLUSERS,
        filter,
      ),
      loadRealmTable<Shift>(
        ListDataTypes.SHIFTS,
        STORAGE_KEYS.REALM_SHIFTS,
        filter,
      ),
      loadRealmTable<SubmissionBundle>(
        'SubmissionBundles',
        STORAGE_KEYS.REALM_SUBMISSIONBUNDLES,
        {},
      ),
      loadRealmTable<SubmissionRelation>(
        'SubmissionRelations',
        STORAGE_KEYS.REALM_SUBMISSIONRELATIONS,
        filter,
      ),
      loadRealmTable<SubmissionStatus>(
        'SubmissionStatuses',
        STORAGE_KEYS.REALM_SUBMISSIONSTATUSES,
        {},
      ),
      loadRealmTable<SubmissionTraining>(
        'SubmissionTrainings',
        STORAGE_KEYS.REALM_SUBMISSIONTRAININGS,
        filter,
      ),
      loadRealmTable<Task>(
        ListDataTypes.TASKS,
        STORAGE_KEYS.REALM_TASKS,
        filter,
      ),
      loadRealmTable<TaskType>(
        ListDataTypes.TASKTYPES,
        STORAGE_KEYS.REALM_TASKTYPES,
        filter,
      ),
      loadRealmTable<Template>('Templates', STORAGE_KEYS.REALM_TEMPLATES, {}),
      loadRealmTable<TemplateGroup>(
        'TemplateGroups',
        STORAGE_KEYS.REALM_TEMPLATEGROUPS,
        {},
      ),
      loadRealmTable<TemplateVersion>(
        'TemplateVersions',
        STORAGE_KEYS.REALM_TEMPLATEVERSIONS,
        {},
      ),
      loadRealmTable<TemplateVersionConversion>(
        'TemplateVersionConversions',
        STORAGE_KEYS.REALM_TEMPLATEVERSIONCONVERSIONS,
        {},
      ),
      loadRealmTable<TrainingType>(
        ListDataTypes.TRAININGTYPES,
        STORAGE_KEYS.REALM_TRAININGTYPES,
        filter,
      ),
      loadRealmTable<UserStatus>(
        'UserStatuses',
        STORAGE_KEYS.REALM_USERSTATUS,
        {},
      ),
      loadProjectSites(),
      loadSubmissions(),
      loadSubmissionTrainings(),
    ]);

    let newTableMaxDHV = await getTableMaxDHV();
    setTableMaxDHV(newTableMaxDHV);
  };

  const getOrgId = (): string => {
    if (!getRealmApp().currentUser) return '';

    const customData = getRealmApp().currentUser!.customData;
    let org = customData.organisation as any;
    let orgid = org.idstring as string;
    return orgid;
  };

  const getMongoClient = (): any => {
    let realmApp = getRealmApp();
    if (realmApp.currentUser)
      return realmApp.currentUser.mongoClient('mongodb-atlas');
    else return undefined;
  };

  const getCollectionSize = async (
    collectionType: ListDataType,
  ): Promise<number> => {
    const client = getMongoClient();
    if (!client) return 0;

    const collection = client.db('ehs-transaction').collection(collectionType);
    return collection.count();
  };

  const getProjectSites = async (search: ProjectSearch, count?: number) => {
    let projectObjs = [];
    projectObjs = projects.map((obj, i) => ({
      ...obj,
      _id: new ObjectId(obj._id),
    }));

    if (search._id) {
      projectObjs = projectObjs.filter(x => x._id.equals(search._id!));
    }

    if (search.name) {
      projectObjs = projectObjs.filter(x =>
        x.name.toLocaleLowerCase().includes(search.name!.toLocaleLowerCase()),
      );
    }

    if (count) projectObjs = projectObjs.slice(0, count);

    return projectObjs;
  };

  const loadProjectSites = async () => {
    const client = getMongoClient();
    if (!client) return [];

    if (isLoadingProjectSites.current) return [];
    isLoadingProjectSites.current = true;

    const projects = client
      .db('ehs-transaction')
      .collection(ListDataTypes.PROJECTS);

    let orgid = getOrgId();

    let realmProjects = (await projects.find({
      partition: orgid.toString(),
      isDeleted: false,
    })) as Project[];

    let jsonRealmProjects: any = realmProjects.map(x => {
      let jsonProject: any = { ...x };

      if (jsonProject.location) {
        if (jsonProject.location.latitude)
          jsonProject.location.latitude = x.location?.latitude?.toString();

        if (jsonProject.location.longitude)
          jsonProject.location.longitude = x.location?.longitude?.toString();
      }

      return jsonProject;
    });

    realmProjects.forEach((x: any) => {
      delete x.SQLDataHubVersion;
    });

    setProjects(realmProjects);
    isLoadingProjectSites.current = false;
    return realmProjects;
  };

  const getPeople = async (search: PersonSearch, count?: number) => {
    let peopleObjs = people;

    if (people.length === 0) peopleObjs = await loadPeople();

    peopleObjs = peopleObjs.filter(x => x.isDeleted == false);

    peopleObjs = peopleObjs.map((obj, i) => ({
      ...obj,
      _id: new ObjectId(obj._id),
    }));

    if (search._id) {
      peopleObjs = peopleObjs.filter(x => x._id.equals(search._id!));
    }

    if (search.email) {
      peopleObjs = peopleObjs.filter(x => x.email === search.email);
    }

    if (search.name) {
      peopleObjs = peopleObjs.filter(x =>
        (x.firstName + ' ' + x.lastName)
          .toLocaleLowerCase()
          .includes(search.name!.toLocaleLowerCase()),
      );
    }

    if (count) peopleObjs = peopleObjs.slice(0, count);

    return peopleObjs;
  };

  const loadPeople = async (_id?: ObjectId) => {
    const client = getMongoClient();
    if (!client) return [];

    const people = client
      .db('ehs-transaction')
      .collection(ListDataTypes.PEOPLE);
    const orgid = getOrgId();

    if (isLoadingPeople.current) return [];

    isLoadingPeople.current = true;

    let realmPeople: Person[] = [];
    if (_id) {
      let filteredPeople = (await people.find({
        _id: _id,
        partition: orgid.toString(),
        isDeleted: false,
      })) as Person[];
      if (filteredPeople && filteredPeople.length > 0) {
        let updatedPerson = filteredPeople[0];
        let peopleList = await getPeople({ name: '' });
        let personIndex = peopleList.findIndex(p => p._id.equals(_id));
        if (personIndex !== -1) peopleList.splice(personIndex, 1);
        peopleList.push(updatedPerson);
        realmPeople = [...peopleList];
      }
    } else
      realmPeople = (await people.find({
        partition: orgid.toString(),
        isDeleted: false,
      })) as Person[];

    realmPeople.forEach((x: any) => {
      delete x.SQLDataHubVersion;
    });

    setPeople(realmPeople);

    isLoadingPeople.current = false;
    return realmPeople;
  };

  const getSQLUsers = async () => {
    let sqlUsersJson = storageService.get(STORAGE_KEYS.REALM_SQLUSERS);

    if (!sqlUsersJson) return [];

    let sqlUsersObjs = JSON.parse(sqlUsersJson) as SQLUser[];
    sqlUsersObjs = sqlUsersObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return sqlUsersObjs.filter(u => !u.isDeleted);
  };

  const loadSQLUsers = async () => {
    const client = getMongoClient();
    if (!client) return [];

    const sqlUsers = client
      .db('ehs-transaction')
      .collection(ListDataTypes.SQLUser);

    const orgid = getOrgId();
    let realmSQLUsers = (await sqlUsers.find({
      partition: orgid.toString(),
    })) as SQLUser[];

    realmSQLUsers.forEach((x: any) => {
      delete x.SQLDataHubVersion;
    });

    storageService.set(
      STORAGE_KEYS.REALM_SQLUSERS,
      JSON.stringify(realmSQLUsers),
    );
  };

  const upsertSQLUser = async (user: SQLUser) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const sqlUsers = client
      .db('ehs-transaction')
      .collection(ListDataTypes.SQLUser);

    //Apparently need to re initalize the date to save
    user.createDateTimeStamp = new Date(user.createDateTimeStamp);

    const updateData = {
      $set: user,
    };

    const data = await sqlUsers.updateOne({ _id: user._id }, updateData, {
      upsert: true,
    });

    await loadSQLUsers();
  };

  const getUserStatus = async () => {
    let statusJson = storageService.get(STORAGE_KEYS.REALM_USERSTATUS);

    if (!statusJson) return [];

    let statusObjs = JSON.parse(statusJson) as UserStatus[];
    statusObjs = statusObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return statusObjs;
  };

  const getFilteredTemplateVersions = async () => {
    let templateVersionObjs = await getTemplateVersions();

    let templateIds = (await getFilteredTemplates()).map(x =>
      x._id.toHexString(),
    );

    let templateVersions = templateVersionObjs.filter(x =>
      templateIds.includes(x.templateId.toHexString()),
    );
    return templateVersions as TemplateVersion[];
  };

  const getTemplateVersions = async () => {
    let templateVersionsJson = storageService.get(
      STORAGE_KEYS.REALM_TEMPLATEVERSIONS,
    );

    if (!templateVersionsJson) return [];
    let templateVersionObjs = JSON.parse(
      templateVersionsJson,
    ) as TemplateVersion[];
    templateVersionObjs = templateVersionObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
      templateId: new ObjectId(o.templateId),
    }));

    return templateVersionObjs as TemplateVersion[];
  };

  const getFilteredTemplates = async () => {
    let templatesObjs = await getTemplates();

    let templateIds = (await getTemplateGroups())
      .map(x => x.templateIds)
      .reduce(function (prev, next) {
        return prev.concat(next);
      }, [])
      .map(x => x.toString());

    let templates = templatesObjs.filter(x =>
      templateIds.includes(x._id.toHexString()),
    );

    return templates as Template[];
  };

  const getTemplates = async () => {
    let templatesJson = storageService.get(STORAGE_KEYS.REALM_TEMPLATES);
    if (!templatesJson) return [];

    let templatesObjs = JSON.parse(templatesJson) as Template[];
    templatesObjs = templatesObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
      templateType: {
        _id: new ObjectId(o.templateType._id),
        name: o.templateType.name,
      } as Template_type,
    }));

    return templatesObjs as Template[];
  };

  const getTemplateGroups = async () => {
    let templateGroupsJson = storageService.get(
      STORAGE_KEYS.REALM_TEMPLATEGROUPS,
    );
    if (!templateGroupsJson) return [];

    let templateGroupsObjs = JSON.parse(templateGroupsJson) as TemplateGroup[];
    templateGroupsObjs = templateGroupsObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    let orgTemplateGroups = (await getOrgTemplateGroups())
      .map(x => x.templateGroupIds)
      .reduce(function (prev, next) {
        return prev.concat(next);
      }, [])
      .map(x => x.toString());

    let filteredTemplateGroups = templateGroupsObjs.filter(x =>
      orgTemplateGroups.includes(x._id.toHexString()),
    );

    return filteredTemplateGroups;
  };

  const getOrgTemplateGroups = async () => {
    let orgTemplateGroupJson = storageService.get(
      STORAGE_KEYS.REALM_ORGTEMPLATEGROUPS,
    );

    if (!orgTemplateGroupJson) return [];

    let orgTemplateGroupObjs = JSON.parse(
      orgTemplateGroupJson,
    ) as OrgTemplateGroup[];
    orgTemplateGroupObjs = orgTemplateGroupObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return orgTemplateGroupObjs;
  };

  const loadSubmissions = async (
    submissionId?: Realm.BSON.ObjectId,
    maxSubmissionDhv?: number,
  ) => {
    const client = getMongoClient();
    if (!client) return [];

    if (isLoadingSubmissions.current) return [];
    isLoadingSubmissions.current = true;

    const submissionsDb = client
      .db('ehs-transaction')
      .collection('Submissions');
    let orgid = getOrgId();

    let realmSubmissions: Submission[] = [];
    if (submissionId) {
      realmSubmissions = await getSubmissions();

      let realmSubmission = (await submissionsDb.find({
        _id: new ObjectId(submissionId),
      })) as Submission[];

      let oldRealmSubmissionIndex = realmSubmissions.findIndex(x =>
        x._id.equals(submissionId),
      );
      realmSubmissions[oldRealmSubmissionIndex] = realmSubmission[0];

      if (oldRealmSubmissionIndex !== -1)
        realmSubmissions[oldRealmSubmissionIndex] = realmSubmission[0];
      else realmSubmissions.push(realmSubmission[0]);
    } else if (maxSubmissionDhv) {
      realmSubmissions = await getSubmissions();

      let newRealmSubmissions = (await submissionsDb.find({
        dataHubVersion: { $gte: maxSubmissionDhv },
      })) as Submission[];

      for (let i = 0; i < newRealmSubmissions.length; i++) {
        let oldRealmSubmissionIndex = realmSubmissions.findIndex(x =>
          x._id.equals(newRealmSubmissions[i]._id),
        );

        if (oldRealmSubmissionIndex !== -1)
          realmSubmissions[oldRealmSubmissionIndex] = newRealmSubmissions[i];
        else realmSubmissions.push(newRealmSubmissions[i]);
      }

      //handle syncin draft deletion
      //get drafts from realm
      let newRealmDrafts = (await submissionsDb.find({
        'submissionStatus.name': 'Draft',
      })) as Submission[];
      let draftHash = new Map();
      for (let i = 0; i < newRealmDrafts.length; i++) {
        draftHash.set(
          newRealmDrafts[i]._id.toString(),
          newRealmDrafts[i]._id.toString(),
        );
      }

      //get current drafts
      let currentDrafts = realmSubmissions.filter(
        x => x.submissionStatus.name === 'Draft',
      );

      //if current draft doesnt exist in realm draft delete it from local
      for (let i = currentDrafts.length - 1; i >= 0; i--) {
        let id = currentDrafts[i]._id.toString();
        if (!draftHash.get(id)) {
          let deleteIndex = realmSubmissions.findIndex(
            x => x._id.toString() === id,
          );
          realmSubmissions.splice(deleteIndex, 1);
        }
      }
    } else {
      realmSubmissions = (await submissionsDb.find({
        partition: orgid.toString(),
      })) as Submission[];
    }

    setSubmissions(realmSubmissions);

    isLoadingSubmissions.current = false;
    return realmSubmissions;
  };

  const getSubmission = async (submissionId: ObjectId) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionsDb = client
      .db('ehs-transaction')
      .collection('Submissions');
    let orgid = getOrgId();

    let realmSubmissions = (await submissionsDb.find({
      _id: new ObjectId(submissionId),
    })) as Submission[];
    let realmSubmission = realmSubmissions[0];

    if (!realmSubmission) return undefined;

    realmSubmission = {
      ...realmSubmission,
      _id: new ObjectId(realmSubmission._id),
      createDateTimeStamp: new Date(realmSubmission.createDateTimeStamp),
      updateDateTimeStamp: new Date(realmSubmission.updateDateTimeStamp),
      templateVersion: {
        _id: new ObjectId(realmSubmission.templateVersion._id),
        name: realmSubmission.templateVersion.name,
      } as Submission_templateVersion,
      submissionStatus: {
        _id: new ObjectId(realmSubmission.submissionStatus._id),
        name: realmSubmission.submissionStatus.name,
      } as Submission_submissionStatus,
      templateType: {
        _id: new ObjectId(realmSubmission.templateType._id),
        name: realmSubmission.templateType.name,
      } as Submission_templateType,
      submissionStatusLock: realmSubmission.submissionStatusLock,
    };

    return realmSubmission;
  };

  const getSubmissions = async () => {
    let subObjs: Submission[] = [];
    if (submissions && submissions.length)
      subObjs = submissions.map(o => ({
        ...o,
        _id: new ObjectId(o._id),
        createDateTimeStamp: new Date(o.createDateTimeStamp),
        updateDateTimeStamp: new Date(o.updateDateTimeStamp),
        templateVersion: {
          _id: new ObjectId(o.templateVersion._id),
          name: o.templateVersion.name,
        } as Submission_templateVersion,
        submissionStatus: {
          _id: new ObjectId(o.submissionStatus._id),
          name: o.submissionStatus.name,
        } as Submission_submissionStatus,
        templateType: {
          _id: new ObjectId(o.templateType._id),
          name: o.templateType.name,
        } as Submission_templateType,
        submissionStatusLock: o.submissionStatusLock,
      }));
    return subObjs;
  };

  const upsertSubmission = async (submission: Submission) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissions = client.db('ehs-transaction').collection('Submissions');
    let orgid = getOrgId();

    //TODO: don't allow editing of _id, parition, createDate, createdBy etc

    submission.dataHubVersion = Math.floor(Date.now() / 1000);

    const updateData = {
      $set: submission,
    };

    const data = await submissions.updateOne(
      { _id: submission._id, partition: orgid.toString() },
      updateData,
      { upsert: true },
    );

    await loadSubmissions(submission._id);
  };

  const loadSubmissionBundles = async (submissionId?: Realm.BSON.ObjectId) => {
    const client = getMongoClient();
    if (!client) return [];

    const subs = client.db('ehs-transaction').collection('SubmissionBundles');

    let submissionBundles: SubmissionBundle[] = [];

    if (!submissionId) {
      submissionBundles = (await subs.find({})) as SubmissionBundle[];
    } else {
      submissionBundles = await getSubmissionBundles();

      let realmSubmissionBunds = (await subs.find({
        _id: submissionId,
      })) as SubmissionBundle[];

      let oldRealmSubBundleIndex = submissionBundles.findIndex(x =>
        x._id.equals(submissionId),
      );

      if (oldRealmSubBundleIndex !== -1) {
        submissionBundles[oldRealmSubBundleIndex] = realmSubmissionBunds[0];
      } else {
        submissionBundles.push(realmSubmissionBunds[0]);
      }
    }

    storageService.set(
      STORAGE_KEYS.REALM_SUBMISSIONBUNDLES,
      JSON.stringify(submissionBundles),
    );
  };

  const getSubmissionBundles = async () => {
    let subBundJson = storageService.get(STORAGE_KEYS.REALM_SUBMISSIONBUNDLES);

    if (!subBundJson) return [];

    let subBundObjs = JSON.parse(subBundJson) as SubmissionBundle[];
    subBundObjs = subBundObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
      auditSubmissionId: o.auditSubmissionId
        ? new ObjectId(o.auditSubmissionId)
        : undefined,
      submitDateTimeStamp:
        o.submitDateTimeStamp && new Date(o.submitDateTimeStamp),
      createDateTimeStamp:
        o.createDateTimeStamp && new Date(o.createDateTimeStamp),
    }));

    subBundObjs.forEach(x =>
      x.submissions?.forEach(x => {
        x.submissionId = new ObjectId(x.submissionId);
        x.updateDateTimeStamp =
          x.updateDateTimeStamp && new Date(x.updateDateTimeStamp);
        x.templateType =
          x.templateType &&
          ({
            _id: new ObjectId(x.templateType._id),
            name: x.templateType.name,
          } as Submission_templateType);
        x.templateVersion =
          x.templateVersion &&
          ({
            _id: new ObjectId(x.templateVersion._id),
            name: x.templateVersion.name,
          } as Submission_templateType);
      }),
    );

    return subBundObjs;
  };

  const upsertSubmissionBundle = async (submissionBundle: SubmissionBundle) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionBundles = client
      .db('ehs-transaction')
      .collection('SubmissionBundles');
    let orgid = getOrgId();

    const updateData = {
      $set: submissionBundle,
    };

    const data = await submissionBundles.updateOne(
      { _id: submissionBundle._id, partition: orgid.toString() },
      updateData,
      { upsert: true },
    );

    await loadSubmissionBundles(submissionBundle._id);
  };

  const loadSubmissionTrainings = async (
    submissionId?: Realm.BSON.ObjectId,
  ) => {
    const client = getMongoClient();
    if (!client) return [];

    if (isLoadingSubmissionTrainings.current) return [];
    isLoadingSubmissionTrainings.current = true;

    const subTrainsDb = client
      .db('ehs-transaction')
      .collection('SubmissionTrainings');
    let orgid = getOrgId();

    let realmSubTrainsDb: SubmissionTraining[] = [];

    if (!submissionId) {
      realmSubTrainsDb = (await subTrainsDb.find({
        partition: orgid.toString(),
      })) as SubmissionTraining[];
    } else {
      realmSubTrainsDb = submissionTrainings;

      let realmSubmissionTrain = (await subTrainsDb.find({
        _id: submissionId,
      })) as SubmissionTraining[];

      let oldRealmSubTrainIndex = realmSubTrainsDb.findIndex(x =>
        x._id.equals(submissionId),
      );

      if (oldRealmSubTrainIndex !== -1) {
        realmSubTrainsDb[oldRealmSubTrainIndex] = realmSubmissionTrain[0];
      } else {
        realmSubTrainsDb.push(realmSubmissionTrain[0]);
      }
    }

    storageService.set(
      STORAGE_KEYS.REALM_SUBMISSIONTRAININGS,
      JSON.stringify(realmSubTrainsDb),
    );

    setSubmissionTrainings(realmSubTrainsDb);

    isLoadingSubmissionTrainings.current = false;
  };

  const getSubmissionTrainings = async () => {
    let subTrainJson = storageService.get(
      STORAGE_KEYS.REALM_SUBMISSIONTRAININGS,
    );

    if (!subTrainJson) return [];

    let subTrainObjs = JSON.parse(subTrainJson) as SubmissionTraining[];
    subTrainObjs = subTrainObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
      createDateTimeStamp: new Date(o.createDateTimeStamp),
      updateDateTimeStamp: new Date(o.updateDateTimeStamp),
      person: {
        id: o.person && new ObjectId(o.person.id),
        firstName: o.person && o.person.firstName,
        lastName: o.person && o.person.lastName,
      } as SubmissionTraining_person,
      trainingType: {
        id: new ObjectId(o.trainingType.id),
        name: o.trainingType.name,
      } as SubmissionTraining_trainingType,
      certificateType: {
        id: new ObjectId(o.certificateType.id),
        name: o.certificateType.name,
      } as SubmissionTraining_certificateType,
      templateVersion: {
        _id: new ObjectId(o.templateVersion._id),
        name: o.templateVersion.name,
      } as SubmissionTraining_templateVersion,
      templateType: {
        _id: new ObjectId(o.templateType._id),
        name: o.templateType.name,
      } as SubmissionTraining_templateType,
      submissionStatus: {
        _id: new ObjectId(o.submissionStatus._id),
        name: o.submissionStatus.name,
      } as SubmissionTraining_submissionStatus,
    }));

    return subTrainObjs;
  };

  const upsertSubmissionTraining = async (submission: SubmissionTraining) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionTrainings = client
      .db('ehs-transaction')
      .collection('SubmissionTrainings');
    let orgid = getOrgId();

    //TODO: don't allow editing of _id, parition, createDate, createdBy etc

    submission.dataHubVersion = Math.floor(Date.now() / 1000);

    const updateData = {
      $set: submission,
    };

    const data = await submissionTrainings.updateOne(
      { _id: submission._id, partition: orgid.toString() },
      updateData,
      { upsert: true },
    );

    await loadSubmissionTrainings(submission._id);
  };

  const getSubmissionStatuses = async () => {
    let statusesJson = storageService.get(
      STORAGE_KEYS.REALM_SUBMISSIONSTATUSES,
    );

    if (!statusesJson) return [];

    let statusesObjs = JSON.parse(statusesJson) as SubmissionStatus[];
    statusesObjs = statusesObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return statusesObjs;
  };

  //Generic function getting list data, only use for objects where _id: ObjectId needs casting with no other special fields
  const getListData = <T extends TypeWithId>(keyname: StorageKeyType) => {
    let listDataJson = storageService.get(keyname);

    if (!listDataJson) return [];

    let listDataObjs = JSON.parse(listDataJson) as T[];
    listDataObjs = listDataObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return listDataObjs;
  };

  const upsertListData = async <T extends GenericListItem>(
    item: T,
    tableName: string,
    key: StorageKeyType,
    partition: string,
    isInsert: boolean,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const objDb = client.db('ehs-transaction').collection(tableName);

    //Apparently need to re initalize the date to save
    item.createDateTimeStamp = new Date(item.createDateTimeStamp);

    const updateData = isInsert
      ? {
          $set: item,
        }
      : {
          $set: {
            name: item.name,
            dataHubVersion: item.dataHubVersion,
            sortOrder: item.sortOrder,
            isActive: item.isActive,
          },
        };

    const data = await objDb.updateOne({ _id: item._id }, updateData, {
      upsert: true,
    });

    await loadRealmTable<T>(tableName, key, {
      partition: partition,
    });
  };

  const getOIICSBodyParts = async () => {
    return getListData<OIICS_BodyPart>(STORAGE_KEYS.REALM_OIICS_BODYPARTS);
  };

  const getOIICSNatures = async () => {
    return getListData<OIICS_Nature>(STORAGE_KEYS.REALM_OIICS_NATURES);
  };

  const getMechanisms = async () => {
    return getListData<Mechanism>(STORAGE_KEYS.REALM_MECHANISMS);
  };

  const getAnimalTypes = async () => {
    return getListData<AnimalType>(STORAGE_KEYS.REALM_ANIMALTYPES);
  };

  const getCertificationTypes = async () => {
    return getListData<CertificationType>(
      STORAGE_KEYS.REALM_CERTIFICATIONTYPES,
    );
  };

  const getCities = () => {
    return getListData<City>(STORAGE_KEYS.REALM_CITIES);
  };

  const getClassificationUnits = async () => {
    let cuJson = storageService.get(STORAGE_KEYS.REALM_CLASSIFICATIONUNITS);

    if (!cuJson) return [];

    let cuObjs = JSON.parse(cuJson) as ClassificationUnit[];
    cuObjs = cuObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return cuObjs;
  };

  const getCrews = async () => {
    return getListData<Crew>(STORAGE_KEYS.REALM_CREWS);
  };

  const getDepartments = async () => {
    return getListData<Department>(STORAGE_KEYS.REALM_DEPARTMENTS);
  };

  const getDispositions = async () => {
    return getListData<Disposition>(STORAGE_KEYS.REALM_DISPOSITIONS);
  };

  const getDocumentTypes = async () => {
    return getListData<DocumentType>(STORAGE_KEYS.REALM_DOCUMENTTYPES);
  };

  const getDocuments = async () => {
    return getListData<Document>(STORAGE_KEYS.REALM_DOCUMENTS);
  };

  const loadDocuments = async () => {
    const client = getMongoClient();
    if (!client) return [];

    const docs = client.db('ehs-transaction').collection('Documents');

    let orgid = getOrgId();
    let realmdocs = (await docs.find({
      partition: orgid.toString(),
    })) as Document[];
    storageService.set(STORAGE_KEYS.REALM_DOCUMENTS, JSON.stringify(realmdocs));
  };

  const upsertDocument = async (doc: Document) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const docDb = client.db('ehs-transaction').collection('Documents');

    //Apparently need to re initalize the date to save
    doc.createDateTimeStamp = new Date(doc.createDateTimeStamp);

    const updateData = {
      $set: doc,
    };

    const data = await docDb.updateOne({ _id: doc._id }, updateData, {
      upsert: true,
    });

    await loadDocuments();
  };

  const deleteDocument = async (documentId: Realm.BSON.ObjectId) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const documents = client.db('ehs-transaction').collection('Documents');

    await documents.deleteOne({ _id: documentId });

    await loadDocuments();
  };

  const getEquipmentTypes = async () => {
    return await getListData<EquipmentType>(STORAGE_KEYS.REALM_EQUIPMENTTYPES);
  };

  const getEquipments = async () => {
    return await getListData<Equipment>(STORAGE_KEYS.REALM_EQUIPMENT);
  };

  const getEquipmentsHeavy = () => {
    return getListData<EquipmentHeavyProfile>(
      STORAGE_KEYS.REALM_EQUIPMENTHEAVY,
    );
  };

  const loadEquipmentsHeavy = async () => {
    const client = getMongoClient();
    if (!client) return [];

    const equipments = client
      .db('ehs-transaction')
      .collection('EquipmentHeavyProfiles');

    let orgid = getOrgId();
    let realmEquipments = (await equipments.find({
      partition: orgid.toString(),
    })) as EquipmentHeavyProfile[];
    storageService.set(
      STORAGE_KEYS.REALM_EQUIPMENTHEAVY,
      JSON.stringify(realmEquipments),
    );
  };

  const getEquipmentsOther = () => {
    return getListData<EquipmentOtherProfile>(
      STORAGE_KEYS.REALM_EQUIPMENTOTHER,
    );
  };

  const loadEquipmentsOther = async () => {
    const client = getMongoClient();
    if (!client) return [];

    const equipments = client
      .db('ehs-transaction')
      .collection('EquipmentOtherProfiles');

    let orgid = getOrgId();
    let realmEquipments = (await equipments.find({
      partition: orgid.toString(),
    })) as Equipment[];
    storageService.set(
      STORAGE_KEYS.REALM_EQUIPMENTOTHER,
      JSON.stringify(realmEquipments),
    );
  };

  const getEquipmentsVehicle = () => {
    return getListData<EquipmentVehicleProfile>(
      STORAGE_KEYS.REALM_EQUIPMENTVEHICLE,
    );
  };

  const loadEquipmentsVehicle = async () => {
    const client = getMongoClient();
    if (!client) return [];

    const equipments = client
      .db('ehs-transaction')
      .collection('EquipmentVehicleProfiles');

    let orgid = getOrgId();
    let realmEquipments = (await equipments.find({
      partition: orgid.toString(),
    })) as Equipment[];
    storageService.set(
      STORAGE_KEYS.REALM_EQUIPMENTVEHICLE,
      JSON.stringify(realmEquipments),
    );
  };

  const getHazardTypes = async () => {
    return getListData<HazardType>(STORAGE_KEYS.REALM_HAZARDTYPES);
  };

  const getIncidentTypes = async () => {
    return getListData<IncidentType>(STORAGE_KEYS.REALM_INCIDENTTYPES);
  };

  const getIncidentClassifications = async () => {
    return getListData<IncidentClassification>(
      STORAGE_KEYS.REALM_INCIDENTCLASSIFICATIONS,
    );
  };

  const getJobs = () => {
    return getListData<Job>(STORAGE_KEYS.REALM_JOBS);
  };

  const getLocations = async () => {
    return getListData<Location>(STORAGE_KEYS.REALM_LOCATIONS);
  };

  const getShifts = async () => {
    return getListData<Shift>(STORAGE_KEYS.REALM_SHIFTS);
  };

  const getNatures = async () => {
    return getListData<Nature>(STORAGE_KEYS.REALM_NATURES);
  };

  const getLocationTypes = async () => {
    return getListData<LocationType>(STORAGE_KEYS.REALM_LOCATIONTYPES);
  };

  const getOrganisations = () => {
    let organisationsJson = storageService.get(
      STORAGE_KEYS.REALM_ORGANISATIONS,
    );

    if (!organisationsJson) return [];

    let organisationsObjs = JSON.parse(organisationsJson) as Organisation[];
    organisationsObjs = organisationsObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return organisationsObjs;
  };

  const upsertOrganisations = async (organisation: Organisation) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const organisations = client
      .db('ehs-transaction')
      .collection(ListDataTypes.ORGANISATIONS);

    //Apparently need to re initalize the date to save
    organisation.createDateTimeStamp = new Date(
      organisation.createDateTimeStamp,
    );

    const updateData = {
      $set: organisation,
    };

    const data = await organisations.updateOne(
      { _id: organisation._id },
      updateData,
      {
        upsert: true,
      },
    );

    loadRealmTable<Organisation>(
      ListDataTypes.ORGANISATIONS,
      STORAGE_KEYS.REALM_ORGANISATIONS,
      {},
    );
  };

  const getOrgStorage = async () => {
    const client = getMongoClient();
    if (!client) return;

    const realmTable = client.db('ehs-transaction').collection('OrgStorage');

    let orgid = getOrgId();
    let realmItems = (await realmTable.find({
      partition: orgid,
    })) as OrgStorage[];

    if (realmItems.length > 0) return realmItems[0];
    else return undefined;
  };

  const getOrgRecipients = async () => {
    let orgRecipientsJson = storageService.get(
      STORAGE_KEYS.REALM_ORGRECIPIENTS,
    );

    if (!orgRecipientsJson) return [];

    let orgRecipientsObjs = JSON.parse(
      orgRecipientsJson,
    ) as OrgContractorNotificationRecipient[];
    orgRecipientsObjs = orgRecipientsObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return orgRecipientsObjs;
  };

  const getOrgRelations = async () => {
    let orgRelationsJson = storageService.get(STORAGE_KEYS.REALM_ORGRELATIONS);

    if (!orgRelationsJson) return [];

    let orgRelationObjs = JSON.parse(orgRelationsJson) as OrgRelation[];
    orgRelationObjs = orgRelationObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return orgRelationObjs;
  };

  const getPeopleTypes = async () => {
    return getListData<PeopleType>(STORAGE_KEYS.REALM_PEOPLETYPES);
  };

  const getPermissions = () => {
    let permissionJson = storageService.get(STORAGE_KEYS.REALM_PERMISSIONS);

    if (!permissionJson) return [];

    let permissionObjs = JSON.parse(permissionJson) as Permission[];
    permissionObjs = permissionObjs.map(o => ({
      ...o,
      _id: new ObjectId(o._id),
    }));

    return permissionObjs;
  };

  const getProvStates = () => {
    return getListData<ProvState>(STORAGE_KEYS.REALM_PROVSTATES);
  };

  const getRoles = async () => {
    return getListData<Role>(STORAGE_KEYS.REALM_ROLES);
  };

  const getTasks = async () => {
    return getListData<Task>(STORAGE_KEYS.REALM_TASKS);
  };

  const loadRealmTable = async <Type,>(
    tableName: string,
    key: StorageKeyType,
    filter: any,
  ): Promise<void> => {
    const client = getMongoClient();
    if (!client) return;

    const realmTable = client.db('ehs-transaction').collection(tableName);

    let realmItems = (await realmTable.find(filter)) as Type[];
    realmItems.forEach((x: any) => {
      delete x.SQLDataHubVersion;
    });

    storageService.set(key, JSON.stringify(realmItems));
  };

  const fetchRealmTable = async <Type,>(
    tableName: string,
    key: StorageKeyType,
    filter: any,
  ): Promise<Type[]> => {
    const client = getMongoClient();
    if (!client) return [];

    const realmTable = client.db('ehs-transaction').collection(tableName);

    let realmItems = (await realmTable.find(filter)) as Type[];
    return realmItems;
  };

  const getTaskTypes = async () => {
    return getListData<TaskType>(STORAGE_KEYS.REALM_TASKTYPES);
  };

  const getTrainingTypes = async () => {
    return getListData<TrainingType>(STORAGE_KEYS.REALM_TRAININGTYPES);
  };

  const upsertTask = async (task: Task) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const objDb = client.db('ehs-transaction').collection(ListDataTypes.TASKS);

    //Apparently need to re initalize the date to save
    task.createDateTimeStamp = new Date(task.createDateTimeStamp);

    const updateData = {
      $set: task,
    };

    const data = await objDb.updateOne({ _id: task._id }, updateData, {
      upsert: true,
    });

    await loadRealmTable<Task>(ListDataTypes.TASKS, STORAGE_KEYS.REALM_TASKS, {
      partition: task.partition,
    });
  };

  const upsertIncidentClassification = async (
    incidentClassification: IncidentClassification,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const objDb = client
      .db('ehs-transaction')
      .collection(ListDataTypes.INCIDENTCLASSIFICATIONS);

    //Apparently need to re initalize the date to save
    incidentClassification.createDateTimeStamp = new Date(
      incidentClassification.createDateTimeStamp,
    );

    const updateData = {
      $set: incidentClassification,
    };

    const data = await objDb.updateOne(
      { _id: incidentClassification._id },
      updateData,
      {
        upsert: true,
      },
    );

    await loadRealmTable<IncidentClassification>(
      ListDataTypes.INCIDENTCLASSIFICATIONS,
      STORAGE_KEYS.REALM_INCIDENTCLASSIFICATIONS,
      {
        partition: incidentClassification.partition,
      },
    );
  };

  const upsertSeverityLevel = async (severityLevel: SeverityLevel) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const objDb = client
      .db('ehs-transaction')
      .collection(ListDataTypes.SEVERITYLEVELS);

    //Apparently need to re initalize the date to save
    severityLevel.createDateTimeStamp = new Date(
      severityLevel.createDateTimeStamp,
    );

    const updateData = {
      $set: severityLevel,
    };

    const data = await objDb.updateOne({ _id: severityLevel._id }, updateData, {
      upsert: true,
    });

    await loadRealmTable<SeverityLevel>(
      ListDataTypes.SEVERITYLEVELS,
      STORAGE_KEYS.REALM_SEVERITYLEVELS,
      {
        partition: severityLevel.partition,
      },
    );
  };

  const upsertIncidentProbability = async (
    incidentProbability: IncidentProbability,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const objDb = client
      .db('ehs-transaction')
      .collection(ListDataTypes.INCIDENTPROBABILITIES);

    //Apparently need to re initalize the date to save
    incidentProbability.createDateTimeStamp = new Date(
      incidentProbability.createDateTimeStamp,
    );

    const updateData = {
      $set: incidentProbability,
    };

    const data = await objDb.updateOne(
      { _id: incidentProbability._id },
      updateData,
      {
        upsert: true,
      },
    );

    await loadRealmTable<IncidentProbability>(
      ListDataTypes.INCIDENTPROBABILITIES,
      STORAGE_KEYS.REALM_INCIDENTPROBABILITIES,
      {
        partition: incidentProbability.partition,
      },
    );
  };

  const getIncidentProbabilities = async () => {
    return getListData<IncidentProbability>(
      STORAGE_KEYS.REALM_INCIDENTPROBABILITIES,
    );
  };

  const getSeverityLevels = async () => {
    return getListData<SeverityLevel>(STORAGE_KEYS.REALM_SEVERITYLEVELS);
  };

  const deleteSubmissionRelation = async (
    submissionRelationId: Realm.BSON.ObjectId,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionRelations = client
      .db('ehs-transaction')
      .collection('SubmissionRelations');

    await submissionRelations.deleteOne({ _id: submissionRelationId });
  };

  const upsertSubmissionRelation = async (
    submissionRelation: SubmissionRelation,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionRelationDb = client
      .db('ehs-transaction')
      .collection('SubmissionRelations');

    //Apparently need to re initalize the date to save
    submissionRelation.createDateTimeStamp = new Date(
      submissionRelation.createDateTimeStamp,
    );

    const updateData = {
      $set: submissionRelation,
    };

    const data = await submissionRelationDb.updateOne(
      { _id: submissionRelation._id },
      updateData,
      {
        upsert: true,
      },
    );

    await loadSubmissionRelations();
  };

  const getSubmissionRelations = async () => {
    let relJson = storageService.get(STORAGE_KEYS.REALM_SUBMISSIONRELATIONS);

    if (!relJson) return [];

    let relJsonObjs = JSON.parse(relJson) as SubmissionRelation[];
    relJsonObjs = relJsonObjs.map(o => ({
      ...o,
      relationId: new ObjectId(o.relationId),
      submissionId: new ObjectId(o.submissionId),
      _id: new ObjectId(o._id),
    }));

    return relJsonObjs;
  };

  const loadSubmissionRelations = async () => {
    const client = getMongoClient();
    if (!client) return [];

    const rels = client.db('ehs-transaction').collection('SubmissionRelations');

    let orgid = getOrgId();
    let realmRels = (await rels.find({
      partition: orgid.toString(),
    })) as SubmissionRelation[];

    realmRels.forEach((x: any) => {
      delete x.SQLDataHubVersion;
    });

    storageService.set(
      STORAGE_KEYS.REALM_SUBMISSIONRELATIONS,
      JSON.stringify(realmRels),
    );
  };

  const getTemplateVersionConversions = async () => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const conversions = client
      .db('ehs-transaction')
      .collection('TemplateVersionConversions');

    let orgid = getOrgId();
    return (await conversions.find({})) as TemplateVersionConversion[];
  };

  const getSubmissionLinks = async () => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionLinks = client
      .db('ehs-transaction')
      .collection('SubmissionLinks');

    let orgid = getOrgId();
    return (await submissionLinks.find({
      partition: orgid.toString(),
    })) as SubmissionLink[];
  };

  const addSubmissionLink = async (submissionLink: SubmissionLink) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionLinks = client
      .db('ehs-transaction')
      .collection('SubmissionLinks');

    const data = await submissionLinks.insertOne(submissionLink);
  };

  const removeSubmissionLinks = async (
    submissionId: ObjectId,
    byParent: boolean,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionLinks = client
      .db('ehs-transaction')
      .collection('SubmissionLinks');

    if (byParent)
      await submissionLinks.deleteMany({ parentSubmissionId: submissionId });
    else await submissionLinks.deleteMany({ childSubmissionId: submissionId });
  };

  const deleteSubmissionAttachments = async (submissionId: ObjectId) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');

    let submissionToDelete = submissions.find(x => x._id.equals(submissionId));
    if (!submissionToDelete) return;

    let attachmentAnswers = submissionToDelete.answers.filter(
      x => x.controlTypeId == ControlTypes.PHOTOS_AND_ATTACHMENTS,
    );

    if (!attachmentAnswers || attachmentAnswers.length < 1) return;

    for (let i = 0; i < attachmentAnswers.length; i++) {
      if (!attachmentAnswers[i].answer) continue;

      let parsedAttachments = JSON.parse(
        attachmentAnswers[i].answer!,
      ) as DynamicAttachment[];

      for (let j = 0; j < parsedAttachments.length; j++) {
        await attachmentService.deleteBlobAttachment(
          parsedAttachments[j].blobUri!,
        );

        if (parsedAttachments[j].blobThumbnailUri) {
          await attachmentService.deleteBlobAttachment(
            parsedAttachments[j].blobThumbnailUri!,
          );
        }
      }
    }
  };

  const existSubmissionInBundle = async (
    submissionId: ObjectId,
  ): Promise<boolean> => {
    return (await getSubmissionBundles()).some(x=>x.submissions && x.submissions.some(y=>y.submissionId && y.submissionId.equals(submissionId)));
  };

  const upsertHeavyEquipment = async (equipment: EquipmentHeavyProfile) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const equipments = client
      .db('ehs-transaction')
      .collection(ListDataTypes.EQUIPMENTSHEAVY);

    //Apparently need to re initalize the date to save
    equipment.createDateTimeStamp = new Date(equipment.createDateTimeStamp);

    const updateData = {
      $set: equipment,
    };

    const data = await equipments.updateOne(
      { _id: equipment._id },
      updateData,
      {
        upsert: true,
      },
    );

    await loadEquipmentsHeavy();
  };

  const upsertOtherEquipment = async (equipment: EquipmentOtherProfile) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const equipments = client
      .db('ehs-transaction')
      .collection(ListDataTypes.EQUIPMENTSOTHER);

    //Apparently need to re initalize the date to save
    equipment.createDateTimeStamp = new Date(equipment.createDateTimeStamp);

    const updateData = {
      $set: equipment,
    };

    const data = await equipments.updateOne(
      { _id: equipment._id },
      updateData,
      {
        upsert: true,
      },
    );

    await loadEquipmentsOther();
  };

  const upsertVehicleEquipment = async (equipment: EquipmentVehicleProfile) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const equipments = client
      .db('ehs-transaction')
      .collection(ListDataTypes.EQUIPMENTSVEHICLE);

    //Apparently need to re initalize the date to save
    equipment.createDateTimeStamp = new Date(equipment.createDateTimeStamp);

    const updateData = {
      $set: equipment,
    };

    const data = await equipments.updateOne(
      { _id: equipment._id },
      updateData,
      {
        upsert: true,
      },
    );

    await loadEquipmentsVehicle();
  };

  const upsertProjectSite = async (projectSite: Project) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const projectSites = client
      .db('ehs-transaction')
      .collection(ListDataTypes.PROJECTS);

    //Apparently need to re initalize the date to save
    projectSite.createDateTimeStamp = new Date(projectSite.createDateTimeStamp);

    const updateData = {
      $set: projectSite,
    };

    const data = await projectSites.updateOne(
      { _id: projectSite._id },
      updateData,
      { upsert: true },
    );

    await loadProjectSites();
  };

  const upsertPerson = async (person: Person) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const people = client
      .db('ehs-transaction')
      .collection(ListDataTypes.PEOPLE);

    //Apparently need to re initalize the date to save
    person.createDateTimeStamp = new Date(person.createDateTimeStamp);

    const updateData = {
      $set: person,
    };

    const data = await people.updateOne({ _id: person._id }, updateData, {
      upsert: true,
    });

    await loadPeople(person._id);
  };

  const deleteSubmission = async (submissionId: Realm.BSON.ObjectId) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const realmSubmissions = client
      .db('ehs-transaction')
      .collection('Submissions');

    await realmSubmissions.deleteOne({ _id: submissionId });

    let newSubmissions = [...submissions];
    newSubmissions = newSubmissions.filter(x => !x._id.equals(submissionId));
    setSubmissions(newSubmissions);
  };

  const deleteSubmissionTraining = async (
    submissionId: Realm.BSON.ObjectId,
  ) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const realmSubmissionTrainings = client
      .db('ehs-transaction')
      .collection('SubmissionTrainings');

    await realmSubmissionTrainings.deleteOne({ _id: submissionId });

    let newSubmissionTrainings = [...submissionTrainings];
    newSubmissionTrainings = newSubmissionTrainings.filter(
      x => !x._id.equals(submissionId),
    );
    setSubmissionTrainings(newSubmissionTrainings);
  };

  const deleteSubmissionBundle = async (id: Realm.BSON.ObjectId) => {
    const client: any = getRealmApp().currentUser!.mongoClient('mongodb-atlas');
    const submissionBundles = client
      .db('ehs-transaction')
      .collection('SubmissionBundles');

    await submissionBundles.deleteOne({ _id: id });

    await loadSubmissionBundles();
  };

  const value = {
    getRealmApp: getRealmApp,
    loadRealmData: loadRealmData,
    getListData: getListData,
    upsertListData: upsertListData,
    getProjectSites: getProjectSites,
    upsertProjectSite: upsertProjectSite,
    getPeople: getPeople,
    upsertPerson: upsertPerson,
    getSQLUsers: getSQLUsers,
    upsertSQLUser: upsertSQLUser,
    getUserStatus: getUserStatus,
    getCollectionSize: getCollectionSize,
    getOrgId: getOrgId,
    getOrgStorage: getOrgStorage,
    getOrgRecipients: getOrgRecipients,
    getOrgRelations: getOrgRelations,
    getTemplateVersionConversions: getTemplateVersionConversions,
    getSubmissionLinks: getSubmissionLinks,
    addSubmissionLink: addSubmissionLink,
    removeSubmissionLinks: removeSubmissionLinks,
    getTemplateVersions: getTemplateVersions,
    getFilteredTemplateVersions: getFilteredTemplateVersions,
    getSubmissionStatuses: getSubmissionStatuses,
    getSubmission: getSubmission,
    getSubmissions: getSubmissions,
    upsertSubmission: upsertSubmission,
    getSubmissionBundles: getSubmissionBundles,
    upsertSubmissionBundle: upsertSubmissionBundle,
    getSubmissionTrainings: getSubmissionTrainings,
    upsertSubmissionTraining: upsertSubmissionTraining,
    getTemplates: getTemplates,
    getFilteredTemplates: getFilteredTemplates,
    getOIICSBodyParts: getOIICSBodyParts,
    getOIICSNatures: getOIICSNatures,
    getMechanisms: getMechanisms,
    deleteSubmission: deleteSubmission,
    deleteSubmissionTraining: deleteSubmissionTraining,
    deleteSubmissionBundle: deleteSubmissionBundle,
    deleteSubmissionAttachments: deleteSubmissionAttachments,
    existSubmissionInBundle: existSubmissionInBundle,
    getAnimalTypes: getAnimalTypes,
    getCertificationTypes: getCertificationTypes,
    getClassificationUnits: getClassificationUnits,
    getCrews: getCrews,
    getDepartments: getDepartments,
    getDispositions: getDispositions,
    getDocumentTypes: getDocumentTypes,
    getDocuments: getDocuments,
    upsertDocument: upsertDocument,
    deleteDocument: deleteDocument,
    getEquipmentTypes: getEquipmentTypes,
    getEquipments: getEquipments,
    getEquipmentsHeavy: getEquipmentsHeavy,
    getEquipmentsOther: getEquipmentsOther,
    getEquipmentsVehicle: getEquipmentsVehicle,
    getHazardTypes: getHazardTypes,
    getIncidentTypes: getIncidentTypes,
    getIncidentClassifications: getIncidentClassifications,
    getIncidentProbabilities: getIncidentProbabilities,
    getJobs: getJobs,
    getLocations: getLocations,
    getLocationTypes: getLocationTypes,
    getNatures: getNatures,
    getOrganisations: getOrganisations,
    upsertOrganisations: upsertOrganisations,
    getPermissions: getPermissions,
    getPeopleTypes: getPeopleTypes,
    getProvStates: getProvStates,
    getSeverityLevels: getSeverityLevels,
    deleteSubmissionRelation: deleteSubmissionRelation,
    getSubmissionRelations: getSubmissionRelations,
    upsertSubmissionRelation: upsertSubmissionRelation,
    getRoles: getRoles,
    getShifts: getShifts,
    getTasks: getTasks,
    getTaskTypes: getTaskTypes,
    getTrainingTypes: getTrainingTypes,
    upsertTask: upsertTask,
    upsertIncidentClassification: upsertIncidentClassification,
    upsertSeverityLevel: upsertSeverityLevel,
    upsertIncidentProbability: upsertIncidentProbability,
    upsertHeavyEquipment: upsertHeavyEquipment,
    upsertOtherEquipment: upsertOtherEquipment,
    upsertVehicleEquipment: upsertVehicleEquipment,
    clearStateData: clearStateData,
    submissions: submissions,
    submissionTrainings: submissionTrainings,
    people: people,
    projects: projects,
    cities: cities,
  };

  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>;
};

const useSync = () => {
  const syncContext = useContext(SyncContext);

  if (syncContext === null)
    throw new Error('useSync() called outside of a SyncProvider?');

  return syncContext;
};

export { SyncProvider, SyncContext, useSync };
