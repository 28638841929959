import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  ImageBackground,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import Icon from '../Shared/Icon';
import { DynamicAttachment } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Images } from '../../Constants/Images';
import AttachmentService from '../../Services/AttachmentService';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import axios, { AxiosError } from 'axios';

type UriSasPair = {
  uri: string;
  sasUri: string;
};

declare type DynamicCompanyDocumentProps = {
  documentId: string;
  config?: {
    required?: boolean;
    maxFileSizeMB?: number;
  };
  value?: string;
  visible?: boolean;
  disabled?: boolean;
  showError?: boolean;
  onChange?: (
    controlId: number | undefined,
    controlTypeId: number | undefined,
    value: string,
    isValid: boolean,
  ) => void;
};

const DynamicCompanyDocument = (
  props: DynamicCompanyDocumentProps,
): React.ReactElement => {
  const { realmSignOut } = useCustomRealm();
  const [maxSize, setMaxSize] = useState(0);
  const [document, setDocument] = useState<DynamicAttachment | undefined>(
    undefined,
  );
  const [error, setError] = useState('');
  const [sasUris, setSasUris] = useState<UriSasPair[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const inputRef = React.createRef<HTMLInputElement>();

  const imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  const allowedTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];

  useEffect(() => {
    if (props.config?.maxFileSizeMB) setMaxSize(props.config?.maxFileSizeMB);
    else if (process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB)
      setMaxSize(parseInt(process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB));

    const getAttachmentUris = async () => {
      if (props.value) {
        let parsedDocument = JSON.parse(props.value) as DynamicAttachment;

        let newSasUris: UriSasPair[] = [];
        if (parsedDocument.blobUri) {
          try {
            let fullUri = await attachmentService.getSasUri(
              parsedDocument.blobUri!,
            );

            newSasUris.push({
              uri: parsedDocument.blobUri!,
              sasUri: fullUri,
            });
          } catch (err) {
            //failed getting sasuri
          }
        }

        setSasUris(newSasUris);
        setDocument(parsedDocument);
      }
    };

    getAttachmentUris();
  }, []);

  useEffect(() => {
    let newValue = '';
    if (document) newValue = JSON.stringify(document);

    if (props.onChange) props.onChange(undefined, undefined, newValue, valid());
  }, [document]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  async function showFilePicker(): Promise<void> {
    setError('');
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }

  async function selectFiles(files: FileList | null): Promise<void> {
    if (files && files[0]) {
      setIsLoading(true);

      try {
        let maxByteSize = maxSize * 1024 * 1024;
        if (files[0].size >= maxByteSize) {
          setError('File size must be less than ' + maxSize + 'MB');
          return;
        }

        let fileType = files[0].type;
        let fileName = files[0].name.replaceAll(' ', '_');

        let uris: string[] = [];
        try {
          let fullImageUri = await attachmentService.pushAttachmentChunks(
            props.documentId,
            'company-document',
            fileName,
            files[0],
          );
          uris.push(fullImageUri);

          if (files[0].type.includes('image/')) {
            let thumbnailFile = await attachmentService.createThumbnail(
              files[0],
            );

            let thumbnailUri = await attachmentService.pushAttachmentChunks(
              props.documentId,
              'company-document',
              thumbnailFile.name,
              thumbnailFile,
            );

            uris.push(thumbnailUri);
          }
        } catch (err: any | AxiosError) {
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 413) {
              setError('File exceeds size limits.');
              return;
            }
          }

          setError('Please try re-uploading your files');
          return;
        }

        if (
          (files[0].type === 'image/heic' || files[0].type === 'image/heif') &&
          uris[0].match(/.+\.png$/)
        ) {
          //Rename type/extension if heic/heif file was converted to png
          fileType = 'image/png';
          fileName = fileName.split('.')[0] + '.png';
        }

        let newDoc: DynamicAttachment = {
          refId: props.documentId,
          refType: 'company-document',
          controlId: undefined,
          uri: '',
          name: fileName,
          type: fileType,
          size: files[0].size,
          blobUri: uris[0],
          blobThumbnailUri: uris[1],
        };

        let blobUri = await attachmentService.getSasUri(uris[0]!);

        let newSasUris: UriSasPair[] = [];
        newSasUris.push({ uri: uris[0], sasUri: blobUri });
        setSasUris(newSasUris);

        setDocument(newDoc);
      } catch (error) {
        /* TODO: Log errors. */
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  function removeDocument(): void {
    setDocument(undefined);
  }

  async function downloadDocument(): Promise<void> {
    if (document && document.blobUri && document.name)
      await attachmentService.downloadAttachment(
        document.blobUri,
        document.name,
      );
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && !document) {
      isValid = false;
      error = 'You need to add a document';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderPicture = (): React.ReactElement => {
    if (!document) return <></>;

    let sasUri = sasUris.find(x => x.uri == document.blobUri);
    let uri = '';

    if (sasUri) uri = sasUri.sasUri;

    return (
      <ImageBackground
        source={{ uri: uri }}
        style={[
          ControlsStyleSheet.attachmentsItem,
          { flexBasis: '100%', minHeight: 200 },
        ]}>
        <View style={ControlsStyleSheet.attachmentsActions}>
          {!props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => removeDocument()}>
              <Icon icon={'trash'} color={Colors.green} size={20} />
            </Pressable>
          )}
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsAction,
              { marginLeft: 12 },
              pressed && {
                backgroundColor: Colors.lightTeal,
              },
            ]}
            onPress={() => downloadDocument()}>
            <Icon icon={'download'} color={Colors.green} size={20} />
          </Pressable>
        </View>
      </ImageBackground>
    );
  };

  const renderAttachment = (): React.ReactElement => {
    if (!document) return <></>;

    let fileName = document.name ?? '';
    let extension = '';
    if (fileName && fileName.split('.').length > 0) {
      let namePart = fileName.split('.')[0];
      extension = fileName.split('.')[1];
      if (namePart.length > 24) namePart = namePart.substring(0, 26) + '..';
      fileName = namePart + '.' + fileName.split('.')[1];
    }

    return (
      <View
        style={[
          ControlsStyleSheet.attachmentsItem,
          { flexBasis: '100%', justifyContent: 'center' },
        ]}>
        {extension === 'pdf' ? (
          <Image style={{ width: 32, height: 32 }} source={Images.PDF} />
        ) : (
          <Icon icon={'file'} color={Colors.green} size={20} />
        )}
        <Text style={ControlsStyleSheet.attachmentsText}>{fileName}</Text>
        <View style={ControlsStyleSheet.attachmentsActions}>
          {!props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => removeDocument()}>
              <Icon icon={'trash'} color={Colors.green} size={20} />
            </Pressable>
          )}
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsAction,
              { marginLeft: 12 },
              pressed && {
                backgroundColor: Colors.lightTeal,
              },
            ]}
            onPress={() => downloadDocument()}>
            <Icon icon={'download'} color={Colors.green} size={20} />
          </Pressable>
        </View>
      </View>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View style={ControlsStyleSheet.attachmentsContainer}>
        {!props.disabled && !document && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsItem,
              { flexBasis: '100%', justifyContent: 'center', minHeight: 100 },
              pressed && {
                opacity: 0.6,
              },
            ]}
            onPress={showFilePicker}>
            {isLoading ? (
              <>
                <ActivityIndicator color={Colors.green} size={24} />
                <Text style={ControlsStyleSheet.attachmentsText}>
                  Uploading
                </Text>
              </>
            ) : (
              <>
                <Icon icon={'picture'} color={Colors.green} size={24} />
                <Text style={ControlsStyleSheet.attachmentsText}>
                  Add an attachment
                </Text>
              </>
            )}
          </Pressable>
        )}
        {document && imageTypes.includes(document.type ?? '') ? (
          <>{renderPicture()}</>
        ) : (
          <>{renderAttachment()}</>
        )}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept={allowedTypes.join(',')}
        onChange={event => selectFiles(event.target.files)}
      />
    </View>
  );
};

export default DynamicCompanyDocument;
