import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId } from 'bson';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import Icon from '../Shared/Icon';
import { DynamicLinkedDropDownsProps } from '../../Types/ControlTypes';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { ControlTypes } from '../../Constants/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import Colors from '../../Styles/Shared/Colors';

export declare type LinkedDropDownsItem = {
  _id: ObjectId;
  SQLServerId: string;
  name: string;
  parentid?: ObjectId;
  nature?: string;
  mechanism?: string;
};

const DynamicLinkedDropDowns = (
  props: DynamicLinkedDropDownsProps,
): React.ReactElement => {
  const [source, setSource] = useState<LinkedDropDownsItem[]>([]);
  const [value, setValue] = useState(props.value ?? '');
  const [selection, setSelection] = useState(
    getSelectionFromProps(props.value),
  );
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [error, setError] = useState('');

  const [level1Value, setLevel1Value] = useState('');
  const [level1Items, setLevel1Items] = useState(
    new Array<{ name: string; value: string }>(),
  );

  const level2Ref = React.createRef<HTMLSelectElement>();
  const [level2Focused, setLevel2Focused] = useState<boolean | null>(null);
  const [level2Value, setLevel2Value] = useState('');
  const [level2Items, setLevel2Items] = useState(
    new Array<{ label: string; value: string }>(),
  );

  const level3Ref = React.createRef<HTMLSelectElement>();
  const [level3Focused, setLevel3Focused] = useState<boolean | null>(null);
  const [level3Value, setLevel3Value] = useState('');
  const [level3Items, setLevel3Items] = useState(
    new Array<{ label: string; value: string }>(),
  );

  const level4Ref = React.createRef<HTMLSelectElement>();
  const [level4Focused, setLevel4Focused] = useState<boolean | null>(null);
  const [level4Value, setLevel4Value] = useState('');
  const [level4Items, setLevel4Items] = useState(
    new Array<{ label: string; value: string }>(),
  );

  const { getOIICSBodyParts } = useSync();

  useEffect(() => {
    getSourceFromProps(props.config?.source);
  }, []);

  useEffect(() => {
    let items = source
      .filter(p => p.parentid === undefined)
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
      .map(p => ({
        name: p.name.replace(' ', '\n'),
        value: JSON.stringify({
          mongoId: p._id.toHexString(),
          SQLServerId: p.SQLServerId,
        }),
      }));

    setLevel1Items(items);
  }, [source]);

  useEffect(() => {
    let items = new Array<{ label: string; value: string }>();
    if (level1Value) {
      let valObj = JSON.parse(level1Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      items = source
        .filter(p => String(p.parentid) === valObj.mongoId)
        .map(p => ({
          label: p.name,
          value: JSON.stringify({
            mongoId: p._id.toHexString(),
            SQLServerId: p.SQLServerId,
          }),
        }));
    }

    setLevel2Items(items);
    setLevel2Value('');
  }, [level1Value]);

  useEffect(() => {
    let items = new Array<{ label: string; value: string }>();
    if (level2Value) {
      let valObj = JSON.parse(level2Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      items = source
        .filter(p => String(p.parentid) === valObj.mongoId)
        .map(p => ({
          label: p.name,
          value: JSON.stringify({
            mongoId: p._id.toHexString(),
            SQLServerId: p.SQLServerId,
          }),
        }));
    }

    console.log(level2Value);

    setLevel3Items(items);
    setLevel3Value('');
  }, [level2Value]);

  useEffect(() => {
    let items = new Array<{ label: string; value: string }>();
    if (level3Value) {
      let valObj = JSON.parse(level3Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      items = source
        .filter(p => String(p.parentid) === valObj.mongoId)
        .map(p => ({
          label: p.name,
          value: JSON.stringify({
            mongoId: p._id.toHexString(),
            SQLServerId: p.SQLServerId,
          }),
        }));
    }

    setLevel4Items(items);
    setLevel4Value('');
  }, [level3Value]);

  useEffect(() => {
    if (
      props.value !== null &&
      props.value !== undefined &&
      props.value !== value
    ) {
      setValue(props.value);

      let newSelection = getSelectionFromProps(props.value);
      setSelection(newSelection);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    let newValue = JSON.stringify(selection);
    if (newValue !== value) setValue(newValue);
  }, [selection]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function getSelectionFromProps(
    propsValue: string | undefined,
  ): Array<LinkedDropDownsItem> {
    let newSelection = new Array<LinkedDropDownsItem>();

    if (propsValue) {
      try {
        let newSelectionArray = JSON.parse(
          propsValue,
        ) as Array<LinkedDropDownsItem>;
        newSelection = newSelectionArray;
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad Linked Drop Down serialization');
      }
    }

    return newSelection;
  }

  async function getSourceFromProps(
    propsValue: string | undefined,
  ): Promise<void> {
    let newSource: LinkedDropDownsItem[] = [];

    if (propsValue) {
      try {
        if (props.config?.source === ListDataTypes.BODYPARTS) {
          let bodyParts = await getOIICSBodyParts();
          if (bodyParts)
            newSource = bodyParts.map(p => ({
              _id: p._id,
              SQLServerId: p.SQLServerId,
              name: p.name,
              parentid: p.parentid,
            }));
        }
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad Attachment serialization');
      }
    }

    setSource(newSource);
  }

  function showModal(): void {
    if (props.disabled) return;

    Keyboard.dismiss();

    setLevel1Value('');
    setIsShowingModal(true);
  }

  function hideModal(): void {
    setIsShowingModal(false);
  }

  function isAddEnabled(): boolean {
    let item: LinkedDropDownsItem | undefined = undefined;
    if (level4Value) {
      let valObj = JSON.parse(level4Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    } else if (level3Value && level4Items.length === 0) {
      let valObj = JSON.parse(level3Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    } else if (level2Value && level3Items.length === 0) {
      let valObj = JSON.parse(level2Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    } else if (level1Value && level2Items.length === 0) {
      let valObj = JSON.parse(level1Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    }

    if (item && !selection.some(i => i._id === item!._id)) return true;

    return false;
  }

  function add(): void {
    let item: LinkedDropDownsItem | undefined = undefined;
    if (level4Value) {
      let valObj = JSON.parse(level4Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    } else if (level3Value) {
      let valObj = JSON.parse(level3Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    } else if (level2Value) {
      let valObj = JSON.parse(level2Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    } else if (level1Value) {
      let valObj = JSON.parse(level1Value) as {
        mongoId: string;
        SQLServerId: string;
      };

      item = source.find(p => p._id.toHexString() === valObj.mongoId);
    }

    if (item) {
      let newSelection = [...selection];
      newSelection.push(item);
      setSelection(newSelection);
      setIsShowingModal(false);
    }
  }

  function remove(item: LinkedDropDownsItem): void {
    let newSelection = [...selection];

    let index = newSelection.indexOf(item);
    if (index !== -1) {
      newSelection.splice(index, 1);
      setSelection(newSelection);
    }
  }

  function changeLevel2Value(): void {
    setLevel2Focused(false);

    let newValue = '';
    if (level2Ref.current) {
      let selectOptions = level2Ref.current.options;
      for (let i = 0; i < selectOptions.length; i++) {
        if (selectOptions[i].selected && selectOptions[i].value !== '') {
          newValue = selectOptions[i].value;
          break;
        }
      }
    }

    setLevel2Value(newValue);
  }

  function changeLevel3Value(): void {
    setLevel3Focused(false);

    let newValue = '';
    if (level3Ref.current) {
      let selectOptions = level3Ref.current.options;
      for (let i = 0; i < selectOptions.length; i++) {
        if (selectOptions[i].selected && selectOptions[i].value !== '') {
          newValue = selectOptions[i].value;
          break;
        }
      }
    }

    setLevel3Value(newValue);
  }

  function changeLevel4Value(): void {
    setLevel4Focused(false);

    let newValue = '';
    if (level4Ref.current) {
      let selectOptions = level4Ref.current.options;
      for (let i = 0; i < selectOptions.length; i++) {
        if (selectOptions[i].selected && selectOptions[i].value !== '') {
          newValue = selectOptions[i].value;
          break;
        }
      }
    }

    setLevel4Value(newValue);
  }

  function setItemNature(item: LinkedDropDownsItem, nature: string): void {
    let newSelection = [...selection];
    let index = newSelection.indexOf(item);
    if (index !== -1 && newSelection[index].nature !== nature) {
      newSelection[index].nature = nature;
      setSelection(newSelection);
    }
  }

  function setItemMechanism(
    item: LinkedDropDownsItem,
    mechanism: string,
  ): void {
    let newSelection = [...selection];
    let index = newSelection.indexOf(item);
    if (index !== -1 && newSelection[index].mechanism !== mechanism) {
      newSelection[index].mechanism = mechanism;
      setSelection(newSelection);
    }
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderSelectedItems = (): React.ReactElement => {
    return (
      <View style={{ marginVertical: 12 }}>
        {selection.map((item, index) => {
          return (
            <View
              key={index}
              style={[
                ReportsStyleSheet.listItemContainer,
                {
                  flexWrap: 'wrap',
                  borderWidth: 1,
                  borderColor: Colors.darkGreen,
                  borderRadius: 12,
                  padding: 12,
                  marginBottom: 12,
                },
              ]}>
              <Icon icon={'injury'} color={Colors.darkestGreen} size={24} />
              <Text
                style={[
                  ReportsStyleSheet.listItemName,
                  { paddingHorizontal: 12, paddingTop: 4 },
                ]}>
                {item.name}
              </Text>
              {!props.disabled && (
                <Pressable
                  style={({ pressed }) => [
                    {
                      width: 28,
                      height: 28,
                      borderRadius: 28,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginHorizontal: 12,
                    },
                    pressed && {
                      backgroundColor: Colors.darkGreenTransparent,
                    },
                  ]}
                  disabled={props.disabled}
                  onPress={() => remove(item)}>
                  <Icon icon={'trash'} color={Colors.darkestGreen} size={20} />
                </Pressable>
              )}
              <View style={{ flexBasis: '100%' }}>
                <DynamicAutocomplete
                  label={'Nature'}
                  value={item.nature}
                  config={{ optionSource: ListDataTypes.NATURES }}
                  disabled={false}
                  onChange={(controlId, controlTypeId, value) =>
                    setItemNature(item, value)
                  }
                  required={false}
                />
              </View>
              <View style={{ flexBasis: '100%' }}>
                <DynamicAutocomplete
                  label="Mechanism"
                  config={{ optionSource: ListDataTypes.MECHANISMS }}
                  value={item.mechanism}
                  onChange={(controlId, controlTypeId, value) => {
                    setItemMechanism(item, value);
                  }}
                  required={false}
                />
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const renderModal = (): React.ReactElement => {
    let disabled = !isAddEnabled();

    return (
      <Modal
        visible={isShowingModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={hideModal}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView>
              <View style={{ alignSelf: 'center', marginTop: 12 }}>
                <DynamicSegmentedControl
                  config={{
                    segments: level1Items,
                  }}
                  value={level1Value}
                  onChange={(controlId, controlTypeId, value) => {
                    setLevel1Value(value);
                  }}
                />
              </View>
              {level1Value && level2Items.length > 0 && (
                <View style={{ marginTop: 24 }}>
                  <select
                    ref={level2Ref}
                    style={{
                      color: Colors.darkGreen,
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: '700',
                      letterSpacing: 0.7,
                      backgroundColor:
                        error === ''
                          ? Colors.darkGreenTransparent
                          : Colors.redTransparent,
                      borderColor: level2Focused
                        ? error === ''
                          ? Colors.darkGreen
                          : Colors.red
                        : error === ''
                        ? Colors.darkGreenTransparent
                        : Colors.redTransparent,
                      borderRadius: 6,
                      borderWidth: 1,
                      padding: 12,
                      paddingTop: 8,
                      outlineStyle: 'none',
                    }}
                    onFocus={() => setLevel2Focused(true)}
                    onBlur={() => setLevel2Focused(false)}
                    onChange={() => changeLevel2Value()}
                    disabled={props.disabled}>
                    <option value="" style={ControlsStyleSheet.selectText}>
                      Select an option
                    </option>
                    {level2Items.map(option => {
                      return (
                        <option
                          value={option.value}
                          style={ControlsStyleSheet.selectText}
                          selected={option.value === level2Value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </View>
              )}
              {level2Value && level3Items.length > 0 && (
                <View style={{ marginTop: 24 }}>
                  <select
                    ref={level3Ref}
                    style={{
                      color: Colors.darkGreen,
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: '700',
                      letterSpacing: 0.7,
                      backgroundColor:
                        error === ''
                          ? Colors.darkGreenTransparent
                          : Colors.redTransparent,
                      borderColor: level3Focused
                        ? error === ''
                          ? Colors.darkGreen
                          : Colors.red
                        : error === ''
                        ? Colors.darkGreenTransparent
                        : Colors.redTransparent,
                      borderRadius: 6,
                      borderWidth: 1,
                      padding: 12,
                      paddingTop: 8,
                      outlineStyle: 'none',
                    }}
                    onFocus={() => setLevel3Focused(true)}
                    onBlur={() => setLevel3Focused(false)}
                    onChange={() => changeLevel3Value()}
                    disabled={props.disabled}>
                    <option value="" style={ControlsStyleSheet.selectText}>
                      Select an option
                    </option>
                    {level3Items.map(option => {
                      return (
                        <option
                          value={option.value}
                          style={ControlsStyleSheet.selectText}
                          selected={option.value === level3Value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </View>
              )}
              {level3Value && level4Items.length > 0 && (
                <View style={{ marginTop: 24 }}>
                  <select
                    ref={level4Ref}
                    style={{
                      color: Colors.darkGreen,
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: '700',
                      letterSpacing: 0.7,
                      backgroundColor:
                        error === ''
                          ? Colors.darkGreenTransparent
                          : Colors.redTransparent,
                      borderColor: level4Focused
                        ? error === ''
                          ? Colors.darkGreen
                          : Colors.red
                        : error === ''
                        ? Colors.darkGreenTransparent
                        : Colors.redTransparent,
                      borderRadius: 6,
                      borderWidth: 1,
                      padding: 12,
                      paddingTop: 8,
                      outlineStyle: 'none',
                    }}
                    onFocus={() => setLevel4Focused(true)}
                    onBlur={() => setLevel4Focused(false)}
                    onChange={() => changeLevel4Value()}
                    disabled={props.disabled}>
                    <option value="" style={ControlsStyleSheet.selectText}>
                      Select an option
                    </option>
                    {level4Items.map(option => {
                      return (
                        <option
                          value={option.value}
                          style={ControlsStyleSheet.selectText}
                          selected={option.value === level4Value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </View>
              )}
            </ScrollView>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                disabled && { backgroundColor: Colors.gray },
                { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              disabled={disabled}
              onPress={add}>
              <Text style={CommonStyleSheet.greenButtonText}>Add</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 24,
        }}>
        <Text style={ControlsStyleSheet.labelLarge}>{props.label}</Text>
        <Pressable
          style={({ pressed }) => [
            ControlsStyleSheet.linkRecordAddButton,
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          disabled={props.disabled}
          onPress={showModal}>
          <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
        </Pressable>
      </View>
      {renderSelectedItems()}
      {renderModal()}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicLinkedDropDowns;
