import { DEFAULT_SOURCE_KEY, PERMISSIONS } from '../Constants/AppConstants';
import { usePermission } from '../Providers/PermissionProvider';
import { useSync } from '../Providers/SyncProvider';

/**
 * Hook to return default value for dynamic form
 */

type DefaultInfo = {
  getDefaultValue(key: string, submissionId?: string): string | undefined;
};
const useDynamicDefault = (): DefaultInfo => {
  const {
    getRealmApp,
    getOrgId,
    getOrganisations,
    people,
    submissionTrainings,
  } = useSync();
  const orgId = getOrgId();
  let currentUser = getRealmApp().currentUser;
  const { hasPermission } = usePermission();

  const getDefaultValue = (key: string, submissionId?: string) => {
    let org = getOrganisations().find(x => x.SQLServerId === orgId);

    switch (key) {
      case DEFAULT_SOURCE_KEY.COMPANYNAME: {
        return org?.name;
      }
      case DEFAULT_SOURCE_KEY.TRADENAME: {
        return org?.tradeName;
      }
      case DEFAULT_SOURCE_KEY.COMPANYOWNER: {
        return org?.owners;
      }
      case DEFAULT_SOURCE_KEY.MAILADDRESS: {
        return org?.mailingLocationJSON;
      }
      case DEFAULT_SOURCE_KEY.STREETADDRESS: {
        return org?.headOfficeLocationJSON;
      }
      case DEFAULT_SOURCE_KEY.PRIMARYCONTACT: {
        return org?.primaryContactJSON;
      }
      case DEFAULT_SOURCE_KEY.WSBCNUMBER: {
        return org?.WSBCNumber;
      }
      case DEFAULT_SOURCE_KEY.CORNUMBER: {
        return org?.CORNumber;
      }
      case DEFAULT_SOURCE_KEY.SAFENUMBER: {
        return org?.SAFENumber;
      }
      case DEFAULT_SOURCE_KEY.MAINACTIVITIES: {
        return org?.mainActivities;
      }
      case DEFAULT_SOURCE_KEY.WSBCUNITS: {
        return org?.WSBCUnitsJSON;
      }
      case DEFAULT_SOURCE_KEY.AUDITCONTACT: {
        return org?.auditContactJSON;
      }
      case DEFAULT_SOURCE_KEY.SAFETYCONTACT: {
        return org?.safetyContactJSON;
      }
      case DEFAULT_SOURCE_KEY.TRAINEE: {
        let currentUserJSON = '';
        if (currentUser && currentUser.profile.email) {
          let hasCreateTrainingPermission = hasPermission(
            PERMISSIONS.PEOPLE_CREATE_TRAINING,
          );
          if (!hasCreateTrainingPermission) {
            let currentPerson = people.find(
              x => x.email === currentUser?.profile?.email,
            );

            if (currentPerson)
              currentUserJSON = JSON.stringify({
                mongoId: currentPerson._id,
                label: currentPerson.firstName + ' ' + currentPerson.lastName,
                SQLServerId: currentPerson.SQLServerId,
              });
          } else if (submissionId) {
            // if hasCreateTrainingPermission and "SubmissionTraining.person" was filled
            // grab the value
            let sub = submissionTrainings.find(x => x._id.equals(submissionId));

            if (sub && sub.person && sub.person.id) {
              let currentPerson = people.find(x =>
                x._id.equals(sub!.person!.id!.toHexString()),
              );

              if (currentPerson)
                currentUserJSON = JSON.stringify({
                  mongoId: currentPerson._id,
                  label: currentPerson.firstName + ' ' + currentPerson.lastName,
                  SQLServerId: currentPerson.SQLServerId,
                });
            }
          }
        }

        return currentUserJSON;
      }
      default:
        return '';
    }
  };

  return { getDefaultValue: getDefaultValue };
};

export default useDynamicDefault;
