import React, { useEffect, useState, useRef } from 'react';
import { View } from 'react-native';
import { ObjectId } from 'bson';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import DynamicPage from './DynamicPage';
import { useSync } from '../../Providers/SyncProvider';
import {
  TemplateVersion,
  TemplateVersion_conditions,
  TemplateVersion_pages,
  TemplateVersion_pages_controls,
} from '../../Models/RealmModels/TemplateVersion';
import PageControlFooter from './PageControlFooter';
import FormHeader from './FormHeader';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { SubmissionAnswerDTO } from '../../Types/DtoTypes';
import {
  SubmissionTraining,
  SubmissionTraining_answer,
} from '../../Models/RealmModels/SubmissionTraining';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { RiskRatingEmptyValue } from '../DynamicControls/DynamicRiskRating';
import { METADATA_KEYS, TRAINING_TYPES } from '../../Constants/AppConstants';
import IConditionsHelperService from '../../Services/Interfaces/IConditionsHelperService';
import ConditionsHelperService from '../../Services/ConditionsHelperService';
import moment from 'moment';
import { Formats } from '../../Constants/Formats';
import Colors from '../../Styles/Shared/Colors';

type TrainingFormProps = {
  navigation: any;
  submissionId: ObjectId | null;
};

const TrainingForm = (props: TrainingFormProps): React.ReactElement => {
  const conditionsHelper: IConditionsHelperService =
    new ConditionsHelperService();

  //Leave dynamic form if props aren't populated
  if (!props.submissionId) {
    console.error('Invalid dynamic form props');
    props.navigation.pop();
  }

  const [trainingType, setTrainingType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setPage] = useState(0);
  const [form, setForm] = useState<TemplateVersion | null>(null);
  const [submission, setSubmission] = useState<SubmissionTraining | null>(null);
  const [answers, setAnswers] = useState<SubmissionAnswerDTO[]>([]);
  const {
    getTemplates,
    getTemplateVersions,
    getSubmissionTrainings,
    getSubmissionStatuses,
    upsertSubmissionTraining,
    deleteSubmissionTraining,
    getPeople,
    getTrainingTypes,
    getCertificationTypes,
  } = useSync();

  const [validations, setValidations] = useState<
    { controlId: number; Label: string; page: number; isValid: boolean }[]
  >([]);
  const [showErrors, setShowErrors] = useState<
    { page: number; show: boolean }[]
  >([]);

  const [icon, setIcon] = useState('');
  const mounted = useRef(false);
  const isUpdating = useRef(false);
  const needsUpdating = useRef(false);
  const metadata = useRef('');
  const [controlHash, setControlHash] = useState<
    Map<number, TemplateVersion_pages_controls>
  >(new Map<number, TemplateVersion_pages_controls>());

  useEffect(() => {
    const getForm = async () => {
      let submission = (await getSubmissionTrainings()).find(x =>
        x._id.equals(props.submissionId!),
      );

      let form = (await getTemplateVersions()).find(x =>
        x._id.equals(submission?.templateVersion!._id!),
      )!;
      setForm(form);
      setSubmission(submission!);

      let template = (await getTemplates()).find(x =>
        x._id.equals(form?.templateId),
      )!;
      if (template && template.iconSvg)
        setIcon(
          template.iconSvg
            // First: For dark mode icons (Eg. Site Inspections in home) replace white with darkestGreen
            .replaceAll(Colors.white, Colors.darkestGreen)
            //Second: For all other icons (Eg. Site Inspections in operations) replace green/currentColor with white
            .replaceAll(Colors.green, Colors.white)
            .replaceAll('currentColor', Colors.white),
        );

      if (!mounted.current) {
        metadata.current = submission!.metadataJSON;
        mounted.current = true;
      }

      //Store answers so its not querying realm every time
      let answers: SubmissionAnswerDTO[] = submission!.answers.map(x => ({
        controlId: x.controlId,
        controlTypeId: x.controlTypeId,
        answer: x.answer,
      }));
      setAnswers(answers);
    };

    getForm();

    const getAttachmentFunc = async () => {
      //await getAttachments(submission?.SQLServerId!); TODO:later
    };
    getAttachmentFunc();

    // Reset page in case user jumps from one report to another
    setPage(0);
  }, [props.submissionId]);

  useEffect(() => {
    let newControlHash = new Map();
    for (let i = 0; i < form?.pages[currentPage].controls!.length!; i++) {
      newControlHash.set(
        form?.pages[currentPage].controls![i].controlId!,
        form?.pages[currentPage].controls![i]!,
      );
    }

    setControlHash(newControlHash);
  }, [form, currentPage]);

  const goToPage = (pageNumber: number) => {
    validatePages(0, pageNumber);

    if (
      pageNumber != currentPage &&
      pageNumber >= 0 &&
      pageNumber < form?.pages.length!
    )
      setPage(pageNumber);
  };

  const getPreviousPage = () => {
    if (currentPage > 0) setPage(currentPage - 1);
  };

  const getNextPage = () => {
    validatePage(currentPage);

    if (currentPage != form?.pages.length! - 1) setPage(currentPage + 1);
  };

  const validatePage = (index: number): boolean => {
    let isPageValid = true;

    let validationsArray = [...validations];

    let page = form?.pages[index];
    if (page && page.controls)
      page.controls.forEach(c => {
        if (c.config) {
          let vControl = validateControl(c, page!, index);
          let val = validationsArray.find(v => v.controlId === c.controlId);
          if (!val) {
            validationsArray.push(vControl);
          } else val.isValid = vControl.isValid;

          if (!vControl.isValid) isPageValid = false;
        }
      });
    setValidations(validationsArray);

    let showErrorsArray = [...showErrors];
    let showPageErrors = showErrorsArray.find(s => s.page === index);
    if (!showPageErrors)
      showErrorsArray.push({
        page: index,
        show: true,
      });
    setShowErrors(showErrorsArray);

    return isPageValid;
  };

  const validatePages = (from: number, to: number): boolean => {
    let isPageValid = true;

    let validationsArray = [...validations];

    for (let i = from; i < to; i++) {
      let page = form?.pages[i];

      if (page && page.controls)
        page.controls.forEach(c => {
          if (c.config) {
            let vControl = validateControl(c, page!, i);
            let val = validationsArray.find(v => v.controlId === c.controlId);
            if (!val) {
              validationsArray.push(vControl);
            } else val.isValid = vControl.isValid;

            if (!vControl.isValid) isPageValid = false;
          }
        });
    }
    setValidations(validationsArray);

    let showErrorsArray = [...showErrors];
    for (let i = from; i < to; i++) {
      let showPageErrors = showErrorsArray.find(s => s.page === i);
      if (!showPageErrors)
        showErrorsArray.push({
          page: i,
          show: true,
        });
    }
    setShowErrors(showErrorsArray);

    return isPageValid;
  };

  const validateControl = (
    control: TemplateVersion_pages_controls,
    page: TemplateVersion_pages,
    pageNumber: number,
  ): { controlId: number; Label: string; page: number; isValid: boolean } => {
    let controlValidation = {
      controlId: control.controlId!,
      Label: control.label ?? control.controlTypeName ?? '',
      page: pageNumber,
      isValid: true,
    };

    if (control.config) {
      let config = JSON.parse(control.config);
      let answer = submission!.answers.find(
        a => a.controlId == control.controlId,
      );
      let isValid = true;

      if (
        conditionsHelper.isControlVisible(
          control,
          conditions,
          answers,
          controlHash,
        )
      ) {
        if (
          config['required'] &&
          config['required'] === true &&
          !(
            answer &&
            answer.answer &&
            answer.answer !== '[]' &&
            answer.answer !== 'null' &&
            answer.answer !== RiskRatingEmptyValue
          )
        )
          isValid = false;

        if (
          config['validationRegExp'] &&
          answer &&
          answer.answer &&
          answer.answer !== '[]' &&
          answer.answer !== 'null' &&
          answer.answer !== RiskRatingEmptyValue
        ) {
          var regExp = new RegExp(config['validationRegExp'], 'g');
          if (!regExp.test(answer.answer)) isValid = false;
        }

        if (config['minDate'] && answer && answer.answer) {
          let val = moment(answer.answer, Formats.BACKEND_DATE).toDate();
          let min =
            config['minDate'] === 'today'
              ? moment().startOf('day').toDate()
              : moment(config['minDate'], 'YYYY-MM-DD').toDate();

          if (val < min) isValid = false;
        }

        if (config['maxDate'] && answer && answer.answer) {
          let val = moment(answer.answer, Formats.BACKEND_DATE).toDate();
          let max =
            config['maxDate'] === 'today'
              ? moment().startOf('day').toDate()
              : moment(config['maxDate'], 'YYYY-MM-DD').toDate();

          if (val > max) isValid = false;
        }
      }

      controlValidation = {
        controlId: control.controlId!,
        Label: control.label ?? control.controlTypeName ?? '',
        page: pageNumber,
        isValid: isValid,
      };
    }

    return controlValidation;
  };

  const updateMetaData = async (key: string, value: string) => {
    if (submitted) return;

    let meta = null;

    if (metadata.current) meta = JSON.parse(metadata.current);

    if (meta === null) meta = {};

    if (key === METADATA_KEYS.TRAINING_TYPE) setTrainingType(value);
    else if (key === METADATA_KEYS.WORKERID) {
      let mongoId = '';
      try {
        let valObj = JSON.parse(value) as {
          mongoId: string;
          SQLServerId: string;
        };

        mongoId = valObj.mongoId;
      } catch (error) {}

      if (mongoId) {
        let person = await getPeople({ _id: new ObjectId(mongoId) }, 1);
        if (person.length > 0) {
          submission!.person = {
            id: person[0]._id,
            firstName: person[0].firstName,
            lastName: person[0].lastName,
            SQLServerPersonId: person[0].SQLServerId,
          };
        }
      }
    } else if (key === METADATA_KEYS.TRAINING_TYPE_ID) {
      let mongoId = '';
      try {
        let valObj = JSON.parse(value) as {
          mongoId: string;
          SQLServerId: string;
        };

        mongoId = valObj.mongoId;
      } catch (error) {}

      if (trainingType === TRAINING_TYPES.CERTIFICATE) {
        let cType = (await getCertificationTypes()).filter(c =>
          c._id.equals(mongoId),
        );
        if (cType.length > 0) {
          submission!.certificateType = {
            id: cType[0]._id,
            name: cType[0].name,
          };
          submission!.trainingType = {};
        }
      } else {
        let tType = (await getTrainingTypes()).filter(t =>
          t._id.equals(mongoId),
        );
        if (tType.length > 0) {
          submission!.trainingType = {
            id: tType[0]._id,
            name: tType[0].name,
          };
          submission!.certificateType = {};
        }
      }
    } else {
      meta[key] = value;

      metadata.current = JSON.stringify(meta);
      submission!.metadataJSON = metadata.current;
    }

    await upsertSubmissionTraining(submission!);
  };

  const onChange = async (
    controlId: number | undefined,
    controlTypeId: number | undefined,
    value: string,
    isValid: boolean,
  ) => {
    if (!controlId || !controlTypeId) return;

    const answer = submission!.answers.find(x => x.controlId == controlId);
    if ((!answer && !value) || (answer && answer.answer === value)) return;

    if (!answer) {
      submission!.answers.push({
        controlId: controlId,
        controlTypeId: controlTypeId,
        answer: value,
      } as SubmissionTraining_answer);
    } else {
      if (answer.answer === value) return;

      answer.answer = value;
    }

    submission!.dataHubVersion = Math.round(Date.now() / 1000);
    submission!.metadataJSON = metadata.current;

    //Handle issue with multiple updates at once
    if (isUpdating.current == true) {
      needsUpdating.current = true;
    }

    if (isUpdating.current == false) {
      isUpdating.current = true;
      await upsertSubmissionTraining(submission!);
      isUpdating.current = false;

      if (needsUpdating.current == true) {
        await upsertSubmissionTraining(submission!);
        needsUpdating.current = false;
      }
    }

    const localAnswer = answers.find(x => x.controlId == controlId);
    if (localAnswer) {
      localAnswer.answer = value;
    } else {
      const newAnswer: SubmissionAnswerDTO = {
        controlId: controlId,
        answer: value,
      };
      answers.push(newAnswer);
    }
    setAnswers(JSON.parse(JSON.stringify(answers)));

    if (showErrors[currentPage] && showErrors[currentPage].show)
      validatePage(currentPage);
  };

  const onClose = async () => {
    let isFormEmpty = !answers.some(
      a =>
        a.answer &&
        a.answer !== '[]' &&
        a.answer !== 'null' && //Empty values are JSON stringified as 'null'
        a.answer !== RiskRatingEmptyValue,
    );

    if (isFormEmpty) await deleteSubmissionTraining(props.submissionId!);

    props.navigation.pop();
  };

  const submitForm = async () => {
    const submittedStatus = (await getSubmissionStatuses()).find(
      x => x.name == SubmissionStatuses.SUBMITTED,
    );
    if (!submittedStatus) {
      console.error('Missing submitted status');
      props.navigation.pop();
    }

    submission!.submissionStatus._id = submittedStatus?._id;
    submission!.submissionStatus.name = submittedStatus?.name;
    submission!.metadataJSON = metadata.current;
    await upsertSubmissionTraining(submission!);

    props.navigation.pop();
  };

  //If form is not loaded yet show nothing
  if (!form) {
    console.log('formnotloaded');
    return <LoadingSpinner message="Loading" visible={true} />;
  }

  let conditions: TemplateVersion_conditions[] = form.conditions ?? [];
  let page = form.pages[currentPage];
  const submitted =
    submission?.submissionStatus &&
    submission?.submissionStatus.name!.toUpperCase() ===
      SubmissionStatuses.SUBMITTED.toUpperCase();

  if (!page.controls || page.controls.length == 0) {
    console.error('Page missing controls');
    props.navigation.pop();
  }

  return (
    <View style={DynamicPageStyleSheet.bodyContainer}>
      <FormHeader
        submissionId={submission?._id?.toHexString() ?? ''}
        submissionStatus={submission?.submissionStatus.name}
        submissionType="submissionTraining"
        formName={form.displayName ?? form.name}
        formIcon={icon}
        pageCount={form.pages.length}
        currentPage={currentPage}
        pagePressed={goToPage}
        closePressed={onClose}
        validations={validations}
        showErrors={showErrors}
        showMoreActions={true}
      />
      <LoadingSpinner
        message="Loading"
        visible={isLoading || !mounted.current}
      />
      {mounted.current && (
        <DynamicPage
          navigation={props.navigation}
          submissionId={submission?._id!}
          submissionSQLServerId={submission?.SQLServerId!}
          onChange={onChange}
          updateMetaData={updateMetaData}
          submitForm={submitForm}
          validateAllPages={() => validatePages(0, form.pages.length)}
          answers={answers}
          page={page}
          pageNumber={currentPage}
          conditions={conditions}
          disabled={submitted!}
          setIsLoading={(loading: boolean) => {
            setIsLoading(loading);
          }}
          validations={validations}
          showErrors={showErrors[currentPage] && showErrors[currentPage].show}
        />
      )}
      <PageControlFooter
        backOnPress={getPreviousPage}
        nextOnPress={getNextPage}
        currentPage={currentPage}
        maxPages={form.pages.length}
      />
    </View>
  );
};

export default TrainingForm;
