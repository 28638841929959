import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';

type ProgressBarProps = {
  currentPage: number;
  pageCount: number;
  pagePressed: (pageNumber: number) => void;
  validations: {
    controlId: number;
    Label: string;
    page: number;
    isValid: boolean;
  }[];
  showErrors: { page: number; show: boolean }[];
};

const ProgressBar = (props: ProgressBarProps): React.ReactElement => {
  const renderDot = (index: number): React.ReactElement => {
    let dotStyle = DynamicPageStyleSheet.progressBarDot;

    let hasErrors = props.validations.some(v => v.page === index && !v.isValid);
    let showPageErrors = props.showErrors.some(s => s.page === index && s.show);

    if (index <= props.currentPage) {
      dotStyle = DynamicPageStyleSheet.progressBarDotSelected;
    }

    return (
      <Pressable
        key={'dot' + index.toString()}
        onPress={() => {
          props.pagePressed(index);
        }}
        hitSlop={30}
        style={dotStyle}>
        {showPageErrors && hasErrors && (
          <View style={DynamicPageStyleSheet.progressBarDotWithErrors}>
            <Text style={DynamicPageStyleSheet.progressBarErrorsText}>!</Text>
          </View>
        )}
      </Pressable>
    );
  };

  const renderLine = (index: number): React.ReactElement => {
    let lineStyle = DynamicPageStyleSheet.progressBarLine;
    if (index < props.currentPage)
      lineStyle = DynamicPageStyleSheet.progressBarLineSelected;
    return <View key={'line' + index.toString()} style={lineStyle} />;
  };

  let pageMarkers: React.ReactElement[] = [];

  for (let i = 0; i < props.pageCount; i++) {
    pageMarkers.push(renderDot(i));
    if (i + 1 < props.pageCount) pageMarkers.push(renderLine(i));
  }

  return (
    <View style={DynamicPageStyleSheet.progressBarContainer}>
      {pageMarkers}
    </View>
  );
};

export default ProgressBar;
