import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Image,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import * as Realm from 'realm-web';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import SearchBoxComponent from '../Shared/SearchBox';
import {
  DynamicFacepileItem,
  DynamicFacepileProps,
} from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Icon from '../Shared/Icon';
import Colors from '../../Styles/Shared/Colors';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { useSync } from '../../Providers/SyncProvider';
import useDynamicData from '../../Hooks/useDynamicData';
import { MongoIdSqlId } from '../../Types/DtoTypes';

const DynamicFacepile = (props: DynamicFacepileProps): React.ReactElement => {
  const defaultItemsToShow = 10;

  const { getMultiselectionData } = useDynamicData();

  const [options, setOptions] = useState(
    props.config?.options ?? new Array<DynamicFacepileItem>(),
  );
  const [selection, setSelection] = useState(new Array<string>());
  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);
  const [value, setValue] = useState(getValueFromProps(props.value));
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (props.config?.optionSource)
      getOptionsFromSource().then(result => setOptions(result));
  }, [props.config?.optionSource]);

  useEffect(() => {
    if (props.onChange && props.value != value)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (isFocused === false) valid();
    else if (isFocused) Keyboard.dismiss();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function getValueFromProps(propsValue: string | undefined): string {
    let newValueArray = new Array<string>();

    if (propsValue) {
      try {
        let propsValueArray = JSON.parse(propsValue) as Array<string>;

        propsValueArray.forEach(val => {
          newValueArray.push(val);
        });

        newValueArray = [...newValueArray];
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad Facepile serialization');
      }
    }

    return JSON.stringify(newValueArray);
  }

  async function getOptionsFromSource(): Promise<DynamicFacepileItem[]> {
    let newOptions = new Array<DynamicFacepileItem>();

    newOptions = await getMultiselectionData(
      props.config?.optionSource!,
      props.value,
    );

    return newOptions;
  }

  function showModal(): void {
    if (props.disabled) return;
    Keyboard.dismiss();

    let selectedValues: string[] = [];
    if (value) {
      let valueArray = JSON.parse(value) as Array<string>;
      valueArray.forEach(val => {
        let valObj = JSON.parse(val) as MongoIdSqlId;
        let option = options.find(
          o =>
            o.value &&
            (JSON.parse(o.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
        );
        if (option) selectedValues.push(option.value);
      });
    }
    setSelection(selectedValues);

    setSearch('');
    setItemsToShow(defaultItemsToShow);
    setIsFocused(true);
  }

  function hideModal(): void {
    setIsFocused(false);
  }

  function changeSearch(newSearch: string): void {
    setSearch(newSearch);
    setItemsToShow(defaultItemsToShow);
  }

  function select(item: DynamicFacepileItem): void {
    Keyboard.dismiss();

    let newSelection = [...selection];

    let index = newSelection.indexOf(item.value);
    if (index === -1) newSelection.push(item.value);
    else newSelection.splice(index, 1);

    setSelection(newSelection);
  }

  function save(): void {
    setValue(JSON.stringify(selection));
    setIsFocused(false);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '[]') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);

    return isValid;
  }

  function sortOptions(options: DynamicFacepileItem[]): DynamicFacepileItem[] {
    return options.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : a.label.toLowerCase() < b.label.toLowerCase()
        ? -1
        : 0,
    );
  }

  const renderFacepile = (): React.ReactElement => {
    let selectedValues: string[] = [];

    let valueArray = JSON.parse(value) as Array<string>;
    valueArray.forEach(val => {
      let valObj = JSON.parse(val) as MongoIdSqlId;
      let option = options.find(
        o =>
          o.value &&
          (JSON.parse(o.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
      );
      if (option) selectedValues.push(option.value);
      else selectedValues.push(val);
    });

    return (
      <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <View style={ControlsStyleSheet.facepileContainer}>
          {selectedValues.map((v, i) => {
            return renderFace(v, i);
          })}
        </View>
      </View>
    );
  };

  const renderFace = (val: string, index?: number): React.ReactElement => {
    const facesToRender = 7;

    if (index && index > facesToRender)
      return <React.Fragment key={index}></React.Fragment>;
    if (index === facesToRender) {
      let valueArray = JSON.parse(value) as Array<string>;
      return (
        <View
          key={index}
          style={[
            ControlsStyleSheet.facepile,
            {
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginLeft: 16,
              marginRight: 0,
            },
          ]}>
          <Text style={ControlsStyleSheet.facepileText}>
            +{valueArray.length - facesToRender}
          </Text>
        </View>
      );
    }

    let text = '';
    let avatar = '';
    let name = '';

    let valObj = JSON.parse(val) as MongoIdSqlId;
    let item = options.find(item => item.value === val);
    if (item) {
      name = item.label;
      if (item.avatar) avatar = item.avatar;
    } else name = valObj.label ?? '';

    if (name) {
      let match = name.match(/\b(\w)/g);
      let acronym = match?.join('');
      if (acronym) text = acronym.substring(0, 2);
      else text = name;
    } else return <React.Fragment key={index}></React.Fragment>;

    return (
      <View key={index}>
        {avatar ? (
          <View style={ControlsStyleSheet.facepile}>
            <Image
              style={{ width: 32, height: 32, resizeMode: 'contain' }}
              source={{ uri: avatar }}
            />
          </View>
        ) : (
          <View style={ControlsStyleSheet.facepile}>
            <Text style={ControlsStyleSheet.facepileText}>{text}</Text>
          </View>
        )}
      </View>
    );
  };

  const renderModal = (): React.ReactElement => {
    let availableOptions = 0;
    let visibleOptions = new Array<DynamicFacepileItem>();

    if (search) {
      let filteredOptions = options.filter(o =>
        o.label.toLowerCase().includes(search.toLowerCase()),
      );

      availableOptions = filteredOptions.length;
      visibleOptions = filteredOptions.slice(0, itemsToShow);
    } else {
      availableOptions = options.length;

      selection.forEach(val => {
        let item = options.find(item => item.value === val);
        if (item) visibleOptions.push(item);
      });

      visibleOptions = sortOptions(visibleOptions);

      //Complete the list with the top # elements
      for (
        let i = 0;
        i < options.length && visibleOptions.length < itemsToShow;
        i++
      ) {
        if (!visibleOptions.some(o => o.value === options[i].value))
          visibleOptions.push(options[i]);
      }

      availableOptions = options.length;
    }

    return (
      <Modal
        visible={isFocused ?? false}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label ?? ''}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => hideModal()}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <SearchBoxComponent
              onChangeSearch={newSearch => changeSearch(newSearch)}
            />
            {visibleOptions.length === 0 && (
              <Text
                style={[
                  ControlsStyleSheet.checkBoxText,
                  { alignSelf: 'center', marginTop: 12 },
                ]}>
                No Results Found
              </Text>
            )}
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 20 }}>
              {visibleOptions.map(option => {
                return renderItem(option);
              })}
              {visibleOptions.length < availableOptions && (
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.smallGreenButton,
                    { alignSelf: 'center', marginVertical: 12 },
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  onPress={() =>
                    setItemsToShow(itemsToShow + defaultItemsToShow)
                  }>
                  <Text style={CommonStyleSheet.smallGreenButtonText}>
                    Show More
                  </Text>
                </Pressable>
              )}
            </ScrollView>
            <Text
              style={[
                ControlsStyleSheet.checkBoxText,
                { alignSelf: 'center', marginBottom: 12 },
              ]}>
              Showing {visibleOptions.length} Of {availableOptions} Records
            </Text>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={save}>
              <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const renderItem = (item: DynamicFacepileItem): React.ReactElement => {
    let checked = selection.includes(item.value);
    return (
      <Pressable
        key={item.value}
        style={({ pressed }) => [
          ControlsStyleSheet.groupSelectorOptionContainer,
          { marginTop: 0, padding: 8 },
          pressed && {
            backgroundColor: Colors.lightTealPressed,
          },
        ]}
        onPress={() => select(item)}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            gap: 24,
            paddingRight: 4,
          }}>
          {renderFace(item.value)}
          <Text
            style={[ControlsStyleSheet.checkBoxText, { flex: 1 }]}
            numberOfLines={1}>
            {item.label}
          </Text>
        </View>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            checked && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon icon={'checked'} color={Colors.white} size={16} />
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <View>
        <TextInput
          showSoftInputOnFocus={false}
          style={[
            ControlsStyleSheet.input,
            isFocused && ControlsStyleSheet.inputFocused,
            error !== '' && ControlsStyleSheet.inputError,
            isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
          ]}
          placeholder={props.config?.placeholder}
          placeholderTextColor={ControlsStyleSheet.placeholder.color}
          editable={!props.disabled}
        />
        {renderFacepile()}
        {!props.disabled && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.groupSelector,
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
                borderRadius: 4,
              },
            ]}
            onPress={showModal}
            disabled={props.disabled}>
            <View style={ControlsStyleSheet.groupSelectorButton}>
              <Text style={ControlsStyleSheet.groupSelectorButtonText}>
                {value && value !== '[]' ? 'Change' : 'Select'}
              </Text>
            </View>
          </Pressable>
        )}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      {renderModal()}
    </View>
  );
};

export default DynamicFacepile;
