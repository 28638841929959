import React, { useState, useEffect, useRef } from 'react';
import { View, Text } from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import DynamicSelect from '../DynamicControls/DynamicSelect';
import { DynamicRiskRatingProps } from '../../Types/ControlTypes';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';

declare type RiskRatingValueType = {
  actual?: string;
  potential?: string;
  probability?: string;
};

declare type SeverityLevelDTO = {
  id: string;
  name: string;
  sortOrder: string;
};

declare type ProbabilityDTO = {
  id: string;
  name: string;
  sortOrder: string;
};

const RiskRatingEmptyValue = '{"actual":"","potential":"","probability":""}';

const DynamicRiskRating = (
  props: DynamicRiskRatingProps,
): React.ReactElement => {
  const [value, setValue] = useState(
    props.value ? props.value : RiskRatingEmptyValue,
  );
  const [error, setError] = useState('');

  const [actual, setActual] = useState<string | undefined>(undefined);
  const [potential, setPotential] = useState<string | undefined>(undefined);
  const [probability, setProbability] = useState<string | undefined>(undefined);

  const isMounted = useRef(false);

  const { getSeverityLevels, getIncidentProbabilities } = useSync();
  const [severityLevels, setSeverityLevels] = useState<SeverityLevelDTO[]>([]);
  const [probabilities, setProbabilities] = useState<ProbabilityDTO[]>([]);

  useEffect(() => {
    fetchLevels();

    let actualVal = '';
    let potentialVal = '';
    let probabilityVal = '';

    if (
      props.value !== null &&
      props.value !== undefined &&
      props.value !== ''
    ) {
      try {
        let propsValue = JSON.parse(props.value) as RiskRatingValueType;
        if (
          propsValue.actual &&
          propsValue.potential &&
          propsValue.probability
        ) {
          actualVal = propsValue.actual;
          potentialVal = propsValue.potential;
          probabilityVal = propsValue.probability;
        }
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad Risk Rating serialization');
      }
    }

    setActual(actualVal);
    setPotential(potentialVal);
    setProbability(probabilityVal);
  }, []);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  const fetchLevels = async () => {
    let severityLevels = await getSeverityLevels();
    severityLevels = severityLevels.filter(x => x.isActive && !x.isDeleted);

    let severityLevelsArray = severityLevels
      .sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0,
      )
      .reverse()
      .map(
        s =>
          ({
            id: s._id.toHexString(),
            name: s.name,
            sortOrder: s.sortOrder.toString(),
          } as SeverityLevelDTO),
      );

    let probabilities = await getIncidentProbabilities();
    probabilities = probabilities.filter(x => x.isActive && !x.isDeleted);

    let probabilitiesArray = probabilities
      .sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0,
      )
      .map(
        s =>
          ({
            id: s._id.toHexString(),
            name: s.name,
            sortOrder: s.sortOrder.toString(),
          } as ProbabilityDTO),
      );

    setSeverityLevels(severityLevelsArray);
    setProbabilities(probabilitiesArray);
  };

  function onChange(name: string, changedValue: string): void {
    if (isMounted.current) {
      let newValue = RiskRatingEmptyValue;

      try {
        let valueObject = JSON.parse(value) as RiskRatingValueType;

        switch (name) {
          case 'Actual':
            if (valueObject.actual !== changedValue) {
              valueObject.actual = changedValue;
              setActual(changedValue);
            }
            break;
          case 'Potential':
            if (valueObject.potential !== changedValue) {
              valueObject.potential = changedValue;
              setPotential(changedValue);
            }
            break;
          case 'Probability':
            if (valueObject.probability !== changedValue) {
              valueObject.probability = changedValue;
              setProbability(changedValue);
            }
            break;
        }

        newValue = JSON.stringify(valueObject);
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad Risk Rating serialization');
      }

      setValue(newValue);
    }
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  function getBackgroundColor(itemValue: number): string {
    if (severityLevels.length == 0 || probabilities.length == 0) return '';

    let backgroundColor = '';
    let green = hexToRgb(Colors.green);
    let orange = hexToRgb(Colors.orange);
    let red = hexToRgb(Colors.brightRed);

    let maxSeverity = parseInt(severityLevels[0].sortOrder);
    let maxProbability = parseInt(
      probabilities[probabilities.length - 1].sortOrder,
    );
    let maxValue = maxSeverity * maxProbability;
    let middleValue = (maxSeverity / 2) * (maxProbability / 2);

    if (itemValue <= middleValue) {
      let weight = itemValue / middleValue;
      backgroundColor =
        '#rgba(' +
        Math.round(orange.r * weight + green.r * (1 - weight)) +
        ', ' +
        Math.round(orange.g * weight + green.g * (1 - weight)) +
        ', ' +
        Math.round(orange.b * weight + green.b * (1 - weight)) +
        ', 1)';
    } else {
      let weight = (itemValue - middleValue) / (maxValue - middleValue);
      backgroundColor =
        '#rgba(' +
        Math.round(red.r * weight + orange.r * (1 - weight)) +
        ', ' +
        Math.round(red.g * weight + orange.g * (1 - weight)) +
        ', ' +
        Math.round(red.b * weight + orange.b * (1 - weight)) +
        ', 1)';
    }

    return backgroundColor;
  }

  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: 0, g: 0, b: 0 };
  }

  const renderLegend = (): React.ReactElement => {
    // Don't render the legend if it's an external recipient view
    if (props.externalView) return <></>;

    if (severityLevels.length == 0 || probabilities.length == 0) return <></>;

    let maxSeverity = parseInt(severityLevels[0].sortOrder);
    let maxProbability = parseInt(
      probabilities[probabilities.length - 1].sortOrder,
    );
    let maxValue = maxSeverity * maxProbability;

    return (
      <View style={{ marginBottom: 6 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              ControlsStyleSheet.riskRatingItem,
              { backgroundColor: getBackgroundColor(maxValue) },
            ]}></Text>
          <Text style={ControlsStyleSheet.riskRatingRow}>
            Catastrophic - Stop
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              ControlsStyleSheet.riskRatingItem,
              { backgroundColor: getBackgroundColor(maxValue * 0.75) },
            ]}></Text>
          <Text style={ControlsStyleSheet.riskRatingRow}>
            Unacceptable - Urgent Action
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              ControlsStyleSheet.riskRatingItem,
              { backgroundColor: getBackgroundColor(maxValue * 0.5) },
            ]}></Text>
          <Text style={ControlsStyleSheet.riskRatingRow}>
            Undesirable - Action
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              ControlsStyleSheet.riskRatingItem,
              { backgroundColor: getBackgroundColor(maxValue * 0.25) },
            ]}></Text>
          <Text style={ControlsStyleSheet.riskRatingRow}>
            Acceptable - Monitor
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              ControlsStyleSheet.riskRatingItem,
              { backgroundColor: getBackgroundColor(maxValue * 0) },
            ]}></Text>
          <Text style={ControlsStyleSheet.riskRatingRow}>
            Desirable - No Action
          </Text>
        </View>
      </View>
    );
  };

  const renderMatrix = (): React.ReactElement => {
    // Don't render the matrix if it's an external recipient view
    if (props.externalView) return <></>;

    let potentialValue = 0;
    let probabilityValue = 0;

    try {
      if (potential !== undefined && probability !== undefined) {
        let potentialObject = JSON.parse(potential) as MongoIdSqlId;
        let selectedPotential = severityLevels.find(
          s => s.id == potentialObject.mongoId,
        );
        if (selectedPotential)
          potentialValue = parseInt(selectedPotential.sortOrder);

        let probabilityObject = JSON.parse(probability) as MongoIdSqlId;
        let selectedProbability = probabilities.find(
          p => p.id == probabilityObject.mongoId,
        );
        if (selectedProbability)
          probabilityValue = parseInt(selectedProbability.sortOrder);
      }
    } catch (error) {
      console.log('Error: Bad Risk Rating serialization');
    }

    return (
      <View style={ControlsStyleSheet.riskRatingContainer}>
        <Text
          style={{
            position: 'absolute',
            top: 46,
            right:
              probabilities.length * 30 +
              Math.max(...severityLevels.map(x => x.name.length)) * 5,
            transform: [{ rotate: '-90deg' }],
            color: Colors.darkGreen,
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: '700',
            textTransform: 'uppercase',
          }}>
          Severity
        </Text>
        {severityLevels &&
          severityLevels.map((sev, i) => {
            return (
              <React.Fragment key={i}>
                <Text style={ControlsStyleSheet.riskRatingRow}>{sev.name}</Text>
                {probabilities.map((prob, j) => {
                  let sevValue = parseInt(sev.sortOrder);
                  let probValue = parseInt(prob.sortOrder);
                  let itemValue = sevValue * probValue;
                  return (
                    <Text
                      key={j}
                      style={[
                        ControlsStyleSheet.riskRatingItem,
                        { backgroundColor: getBackgroundColor(itemValue) },
                        potentialValue === severityLevels.length - i &&
                          probabilityValue === j + 1 && {
                            borderColor: 'black',
                            fontSize: 16,
                            paddingTop: 0,
                          },
                      ]}>
                      {itemValue}
                    </Text>
                  );
                })}
                <View style={{ flexBasis: '100%' }}></View>
              </React.Fragment>
            );
          })}
        {probabilities.map((prob, i) => {
          let probLabel =
            prob.name.length > 13
              ? prob.name.substring(0, 10) + '...'
              : prob.name;

          return (
            <Text
              key={i}
              style={[
                ControlsStyleSheet.riskRatingRowVertical,
                { top: severityLevels.length * 30 + probLabel.length * 3 },
                {
                  right:
                    (probabilities.length - i) * 30 -
                    probLabel.length * 3.2 -
                    12,
                },
              ]}>
              {probLabel}
            </Text>
          );
        })}
        <View
          style={{
            flexBasis: '100%',
            height: Math.max(...probabilities.map(x => x.name.length)) * 7,
          }}></View>
        <View style={{ alignItems: 'center' }}>
          <Text
            style={{
              color: Colors.darkGreen,
              fontFamily: 'Poppins',
              fontSize: 24,
              fontWeight: '700',
              textTransform: 'uppercase',
              paddingHorizontal: 8,
            }}>
            Likelihood
          </Text>
          <Text
            style={[
              ControlsStyleSheet.label,
              { marginTop: 0, marginBottom: 0 },
            ]}>
            Exposure
          </Text>
          <Text
            style={[
              ControlsStyleSheet.label,
              { marginTop: 0, marginBottom: 0 },
            ]}>
            Increases
          </Text>
          <Text
            style={[
              ControlsStyleSheet.label,
              { marginTop: 0, marginBottom: 0 },
            ]}>
            Likelihood
          </Text>
        </View>
      </View>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={[ControlsStyleSheet.label, { fontSize: 14 }]}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      {actual !== undefined && (
        <View>
          <DynamicSelect
            label="Actual Severity Index"
            config={{ optionSource: ListDataTypes.SEVERITYLEVELS }}
            value={actual}
            disabled={props.disabled}
            onChange={(controlId, controlTypeId, value) => {
              onChange('Actual', value);
            }}
          />
        </View>
      )}
      {potential !== undefined && (
        <View>
          <DynamicSelect
            label="Potential Severity Index"
            config={{ optionSource: ListDataTypes.SEVERITYLEVELS }}
            value={potential}
            disabled={props.disabled}
            onChange={(controlId, controlTypeId, value) => {
              onChange('Potential', value);
            }}
          />
        </View>
      )}
      {probability !== undefined && (
        <View
          onLayout={() => {
            isMounted.current = true;
          }}>
          <DynamicSelect
            label="Probability Index of Occurrence"
            config={{ optionSource: ListDataTypes.INCIDENTPROBABILITIES }}
            value={probability}
            disabled={props.disabled}
            onChange={(controlId, controlTypeId, value) => {
              onChange('Probability', value);
            }}
          />
        </View>
      )}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 24 }}>
        <View style={{ flex: 1, minWidth: 300, alignItems: 'flex-end' }}>
          {renderMatrix()}
        </View>
        <View style={{ flex: 1, minWidth: 300, alignItems: 'flex-start' }}>
          {renderLegend()}
        </View>
      </View>
    </View>
  );
};

export default DynamicRiskRating;
export { RiskRatingEmptyValue };
