import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text, Image, Pressable } from 'react-native';
import * as Realm from 'realm-web';
import { useSync } from '../../Providers/SyncProvider';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import AttachmentService from '../../Services/AttachmentService';
import Profile from '../Views/Profile';
import Dashboard from '../Views/Dashboard';
import Reports from '../Views/Reports';
import People from '../Views/People';
import Equipment from '../Views/Equipment';
import Operations from '../Views/Operations';
import Icon from '../Shared/Icon';
import { COMMON_TEMPLATES } from '../../Constants/AppConstants';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';
import { Navigation, Widgets } from '../../Constants/Navigation';
import ProjectSites from '../Views/ProjectSites';
import EditProjectSite from '../Views/EditProjectSite';
import {
  NavigationContainer,
  getStateFromPath,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { navigationRef, navigate, currentRoute } from './RootNavigation';
import EditPerson from '../Views/EditPerson';
import PeopleList from '../Views/PeopleList';
import TrainingBreakdown from '../Views/TrainingBreakdown';
import DocumentLibrary from '../Views/DocumentLibrary';
import ListManagement from '../Views/ListManagement';
import EditListManagement from '../Views/EditListManagement';
import DynamicForm from '../DynamicForm/DynamicForm';
import BundleForm from '../DynamicForm/BundleForm';
import CompletionForm from '../DynamicForm/CompletionForm';
import TrainingForm from '../DynamicForm/TrainingForm';
import EquipmentLog from '../Views/EquipmentLog';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import Login from '../Views/Login';
import EditEquipmentOther from '../Views/EditOtherEquipment';
import EditEquipmentVehicle from '../Views/EditVehicleEquipment';
import EditEquipmentHeavy from '../Views/EditHeavyEquipment';
import SubmissionBundles from '../Views/SubmissionBundles';
import EditSubmissionBundle from '../Views/EditSubmissionBundle';
import CompanyProfile from '../Views/CompanyProfile';
import { Person } from '../../Models/RealmModels/Person';
import PublicProfile from '../Views/PublicProfile';
import { DynamicAttachment } from '../../Types/ControlTypes';

type MainContainerProps = {
  navigation: any;
};

const MainContainer = (props: MainContainerProps): React.ReactElement => {
  const [active, setActive] = useState(Widgets.HOME);
  const { clearStateData } = useSync();
  const [profilePicture, setProfilePicture] = useState('');
  const [profileIconText, setProfileIconText] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState('');
  const { isLoggedIn, realmSignOut, loggedIn, setLoggedIn } = useCustomRealm();

  const { getPeople, getRealmApp } = useSync();
  const currentUser = getRealmApp().currentUser;

  const [path, setPath] = useState('');

  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  useEffect(() => {
    setLoaded(true);
    setLoggedIn(isLoggedIn());
  }, []);

  useEffect(() => {
    const getPerson = async () => {
      let person: Person | undefined = undefined;
      const currentUser = getRealmApp().currentUser;
      if (currentUser && currentUser.profile.personId)
        person = (await getPeople({})).find(
          x =>
            x.SQLServerId.toLowerCase() ===
            String(currentUser!.profile.personId).toLowerCase(),
        );

      if (person) {
        setName(person.firstName + ' ' + person.lastName);

        let match = (person.firstName + ' ' + person.lastName).match(/\b(\w)/g);
        let acronym = match?.join('');
        if (acronym) setProfileIconText(acronym.substring(0, 2));
        if (person.photo) getProfilePicture(person.photo);
      }
    };

    getPerson();
  }, [currentUser]);

  const getProfilePicture = async (photo: string) => {
    try {
      let photoAttachment = JSON.parse(photo) as DynamicAttachment;
      if (photoAttachment.blobUri) {
        let sasUri = await attachmentService.getSasUri(photoAttachment.blobUri);
        setProfilePicture(sasUri);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // NavigationContainer should do this work automatically, couldn't find the issue
    // Intentionally "linking.config.screens" was left empty, if it's filled navigation doesn't work
    // "getStateFromPath" doesn't work either
    // TODO: Make NavigationContainer work properly
    let decodedPath = decodeURI(path.substring(1));

    let search = '';
    let urlParts = decodedPath.split('?');
    if (urlParts.length === 2) search = urlParts[1];
    let searchParams = new URLSearchParams(search);

    switch (decodedPath) {
      case Navigation.HOME:
      case Navigation.PROFILE:
      case Navigation.REPORTS:
      case Navigation.PEOPLE:
      case Navigation.TRAININGBREAKDOWN:
      case Navigation.EQUIPMENT:
      case Navigation.EQUIPMENTLOG:
      case Navigation.OPERATIONS:
      case Navigation.PROJECTSITES:
      case Navigation.PEOPLELIST:
      case Navigation.COMPANYPROFILE:
      case Navigation.DOCUMENTLIBRARY:
      case Navigation.LISTMANAGEMENT:
      case Navigation.SUBMISSIONBUNDLES:
      case Navigation.PUBLICPROFILE:
        navigate(decodedPath, {});
        break;
      default:
        if (decodedPath.startsWith(Navigation.VEHICLEPROFILE))
          navigate(Navigation.VEHICLEPROFILE, {
            equipmentId: searchParams.get('equipmentId'),
          });
        else if (decodedPath.startsWith(Navigation.HEAVYPROFILE))
          navigate(Navigation.HEAVYPROFILE, {
            equipmentId: searchParams.get('equipmentId'),
          });
        else if (decodedPath.startsWith(Navigation.OTHERPROFILE))
          navigate(Navigation.OTHERPROFILE, {
            equipmentId: searchParams.get('equipmentId'),
          });
        else if (decodedPath.startsWith(Navigation.EDITPROJECTSITE))
          navigate(Navigation.EDITPROJECTSITE, {
            projectSiteId: searchParams.get('projectSiteId'),
          });
        else if (decodedPath.startsWith(Navigation.EDITPERSON))
          navigate(Navigation.EDITPERSON, {
            personId: searchParams.get('personId'),
          });
        else if (decodedPath.startsWith(Navigation.DYNAMICFORM))
          navigate(Navigation.DYNAMICFORM, {
            submissionId: searchParams.get('submissionId'),
            submissionStatus: searchParams.get('submissionStatus'),
            showConfirmation: searchParams.get('showConfirmation'),
          });
        else if (decodedPath.startsWith(Navigation.TRAININGFORM))
          navigate(Navigation.TRAININGFORM, {
            submissionId: searchParams.get('submissionId'),
          });
        else if (decodedPath.startsWith(Navigation.COMPLETIONFORM))
          navigate(Navigation.COMPLETIONFORM, {
            submissionId: searchParams.get('submissionId'),
            title: searchParams.get('submissionId')
              ? COMMON_TEMPLATES.COMPLETE_TASK
              : COMMON_TEMPLATES.TASK,
          });
        else if (decodedPath.startsWith(Navigation.EDITBUNDLE))
          navigate(Navigation.EDITBUNDLE, {
            bundleId: searchParams.get('bundleId'),
          });
        else if (decodedPath.startsWith(Navigation.BUNDLEFORM))
          navigate(Navigation.BUNDLEFORM, {
            bundleId: searchParams.get('bundleId'),
            submissionId: searchParams.get('submissionId'),
            headerTitle: searchParams.get('headerTitle'),
          });
        else if (decodedPath.startsWith(Navigation.EDITLISTMANAGEMENT))
          navigate(Navigation.EDITLISTMANAGEMENT, {
            listName: searchParams.get('listName'),
            listKey: searchParams.get('listKey'),
            listLabel: searchParams.get('listLabel'),
            listType: searchParams.get('listType'),
          });
        break;
    }
  }, [path]);

  const setActiveWidget = () => {
    switch (currentRoute()) {
      case Navigation.HOME:
      case Navigation.REPORTS:
      case Navigation.PEOPLE:
      case Navigation.EQUIPMENT:
      case Navigation.OPERATIONS:
      case Navigation.PROJECTSITES:
      case Navigation.PEOPLELIST:
      case Navigation.PROFILE:
      case Navigation.COMPANYPROFILE:
      case Navigation.DOCUMENTLIBRARY:
      case Navigation.LISTMANAGEMENT:
      case Navigation.SUBMISSIONBUNDLES:
        setActive(currentRoute());
        break;
      //Workaround to be able to change the document title before it goes out of the navigation
      case Navigation.LOGIN:
        setActive('');
        realmSignOut();
        break;
      default:
        break;
    }
  };

  const Stack = createStackNavigator();

  if (!loaded) return <></>;

  if (!loggedIn) return <Login></Login>;

  return (
    <View style={CommonStyleSheet.mainContainer}>
      <View style={CommonStyleSheet.widgetContainer}>
        <ScrollView>
          <Pressable
            style={({ pressed }) => [
              { borderRadius: 12, padding: 12 },
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() =>
              navigate(Navigation.PROFILE, { personId: undefined })
            }>
            {profilePicture ? (
              <View style={CommonStyleSheet.profileIcon}>
                <Image
                  style={{ width: 70, height: 70, resizeMode: 'contain' }}
                  source={{ uri: profilePicture }}
                />
              </View>
            ) : (
              <View style={CommonStyleSheet.profileIcon}>
                <Text style={CommonStyleSheet.profileIconText}>
                  {profileIconText}
                </Text>
              </View>
            )}

            <Text
              style={[
                CommonStyleSheet.widgetText,
                { textAlign: 'center' },
                active === Navigation.PROFILE && CommonStyleSheet.widgetActive,
              ]}>
              {name}
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.HOME, {})}>
            <Icon
              icon={'nav-home'}
              color={
                active === '' || active === Navigation.HOME
                  ? Colors.teal
                  : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                (active === '' || active === Navigation.HOME) &&
                  CommonStyleSheet.widgetActive,
              ]}>
              Home
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.REPORTS, {})}>
            <Icon
              icon={'nav-reports'}
              color={active === Navigation.REPORTS ? Colors.teal : Colors.white}
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.REPORTS && CommonStyleSheet.widgetActive,
              ]}>
              Reports
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.PEOPLE, {})}>
            <Icon
              icon={'nav-people'}
              color={active === Navigation.PEOPLE ? Colors.teal : Colors.white}
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.PEOPLE && CommonStyleSheet.widgetActive,
              ]}>
              People
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.EQUIPMENT, {})}>
            <Icon
              icon={'nav-equipment'}
              color={
                active === Navigation.EQUIPMENT ? Colors.teal : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active == Navigation.EQUIPMENT && CommonStyleSheet.widgetActive,
              ]}>
              Equipment
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.OPERATIONS, {})}>
            <Icon
              icon={'nav-operations'}
              color={
                active === Navigation.OPERATIONS ? Colors.teal : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.OPERATIONS &&
                  CommonStyleSheet.widgetActive,
              ]}>
              Operations
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.PROJECTSITES, {})}>
            <Icon
              icon={'projects-sites'}
              color={
                active === Navigation.PROJECTSITES ? Colors.teal : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.PROJECTSITES &&
                  CommonStyleSheet.widgetActive,
              ]}>
              Projects/Sites
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.PEOPLELIST, {})}>
            <Icon
              icon={'people-list'}
              color={
                active === Navigation.PEOPLELIST ? Colors.teal : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.PEOPLELIST &&
                  CommonStyleSheet.widgetActive,
              ]}>
              People List
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.COMPANYPROFILE, {})}>
            <Icon
              icon={'company'}
              color={
                active === Navigation.COMPANYPROFILE
                  ? Colors.teal
                  : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.COMPANYPROFILE &&
                  CommonStyleSheet.widgetActive,
              ]}>
              Company Profile
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.DOCUMENTLIBRARY, {})}>
            <Icon
              icon={'library'}
              color={
                active === Navigation.DOCUMENTLIBRARY
                  ? Colors.teal
                  : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.DOCUMENTLIBRARY &&
                  CommonStyleSheet.widgetActive,
              ]}>
              Document Library
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.LISTMANAGEMENT, {})}>
            <Icon
              icon={'list'}
              color={
                active === Navigation.LISTMANAGEMENT
                  ? Colors.teal
                  : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.LISTMANAGEMENT &&
                  CommonStyleSheet.widgetActive,
              ]}>
              List Management
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => navigate(Navigation.SUBMISSIONBUNDLES, {})}>
            <Icon
              icon={'folder'}
              color={
                active === Navigation.SUBMISSIONBUNDLES
                  ? Colors.teal
                  : Colors.white
              }
              size={24}
            />
            <Text
              style={[
                CommonStyleSheet.widgetText,
                active === Navigation.SUBMISSIONBUNDLES &&
                  CommonStyleSheet.widgetActive,
              ]}>
              Submissions
            </Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.widget,
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => {
              navigate(Navigation.LOGIN, {});
              clearStateData();
            }}>
            <Icon icon={'logout'} color={Colors.white} size={24} />
            <Text style={CommonStyleSheet.widgetText}>Logout</Text>
          </Pressable>
        </ScrollView>
        <Text style={CommonStyleSheet.widgetText}>
          App Version {process.env.REACT_APP_VERSION_NAME}
        </Text>
      </View>
      <View style={CommonStyleSheet.bodyContainer}>
        <NavigationContainer
          ref={navigationRef}
          onStateChange={state => setActiveWidget()}
          linking={{
            prefixes: [],
            config: { screens: {} },
            getStateFromPath: (path, options) => {
              // "setTimeout" because Firefox is throwing:
              // Cannot update a component (`MainContainer`) while rendering a different component
              setTimeout(() => {
                setPath(path);
              }, 100);

              return getStateFromPath(path, options);
            },
          }}>
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
            }}>
            <Stack.Screen name={Navigation.HOME} component={Dashboard} />
            <Stack.Screen name={Navigation.PROFILE}>
              {(props: any) => (
                <Profile
                  navigation={props.navigation}
                  personId={props.route.params.personId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.REPORTS} component={Reports} />
            <Stack.Screen name={Navigation.PEOPLE} component={People} />
            <Stack.Screen name={Navigation.EQUIPMENT} component={Equipment} />
            <Stack.Screen name={Navigation.OPERATIONS} component={Operations} />
            <Stack.Screen
              name={Navigation.PROJECTSITES}
              component={ProjectSites}
            />
            <Stack.Screen name={Navigation.EDITPROJECTSITE}>
              {(props: any) => (
                <EditProjectSite
                  navigation={props.navigation}
                  projectSiteId={props.route.params.projectSiteId}
                  addProjectWithId={props.route.params.addProjectWithId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.PEOPLELIST}>
              {(props: any) => <PeopleList navigation={props.navigation} />}
            </Stack.Screen>
            <Stack.Screen name={Navigation.TRAININGBREAKDOWN}>
              {(props: any) => (
                <TrainingBreakdown navigation={props.navigation} />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.EDITPERSON}>
              {(props: any) => (
                <EditPerson
                  navigation={props.navigation}
                  personId={props.route.params.personId}
                  addPersonWithId={props.route.params.addPersonWithId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.HEAVYPROFILE}>
              {(props: any) => (
                <EditEquipmentHeavy
                  navigation={props.navigation}
                  equipmentId={props.route.params.equipmentId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.OTHERPROFILE}>
              {(props: any) => (
                <EditEquipmentOther
                  navigation={props.navigation}
                  equipmentId={props.route.params.equipmentId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.VEHICLEPROFILE}>
              {(props: any) => (
                <EditEquipmentVehicle
                  navigation={props.navigation}
                  equipmentId={props.route.params.equipmentId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.DYNAMICFORM}
              options={{ headerShown: false }}>
              {(props: any) => (
                <DynamicForm
                  navigation={props.navigation}
                  submissionId={props.route.params.submissionId}
                  showConfirmation={props.route.params.showConfirmation}
                  headerTitle={props.route.params.headerTitle}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.COMPLETIONFORM}
              options={{ headerShown: false }}>
              {(props: any) => (
                <CompletionForm
                  navigation={props.navigation}
                  submissionId={props.route.params.submissionId}
                  title={props.route.params.title}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.TRAININGFORM}
              options={{ headerShown: false }}>
              {(props: any) => (
                <TrainingForm
                  navigation={props.navigation}
                  submissionId={props.route.params.submissionId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.EQUIPMENTLOG}
              component={EquipmentLog}
            />
            <Stack.Screen
              name={Navigation.COMPANYPROFILE}
              component={CompanyProfile}
            />
            <Stack.Screen
              name={Navigation.DOCUMENTLIBRARY}
              component={DocumentLibrary}
            />
            <Stack.Screen
              name={Navigation.LISTMANAGEMENT}
              component={ListManagement}
            />
            <Stack.Screen name={Navigation.EDITLISTMANAGEMENT}>
              {(props: any) => (
                <EditListManagement
                  navigation={props.navigation}
                  listName={props.route.params.listName}
                  listKey={props.route.params.listKey}
                  listLabel={props.route.params.listLabel}
                  listType={props.route.params.listType}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.SUBMISSIONBUNDLES}
              component={SubmissionBundles}
            />
            <Stack.Screen name={Navigation.EDITBUNDLE}>
              {(props: any) => (
                <EditSubmissionBundle
                  navigation={props.navigation}
                  bundleId={props.route.params.bundleId}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.BUNDLEFORM}
              options={{ headerShown: false }}>
              {(props: any) => (
                <BundleForm
                  navigation={props.navigation}
                  bundleId={props.route.params.bundleId}
                  submissionId={props.route.params.submissionId}
                  submissionType={props.route.params.submissionType}
                  headerTitle={props.route.params.headerTitle}
                  externalView={props.route.params.externalView}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name={Navigation.PUBLICPROFILE}
              options={{ headerShown: false }}>
              {(props: any) => (
                <PublicProfile
                  navigation={props.navigation}
                  profile={props.route.params.profile}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name={Navigation.LOGIN}>
              {(props: any) => <></>}
            </Stack.Screen>
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    </View>
  );
};

export default MainContainer;
