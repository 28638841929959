import React, { useState, useEffect } from 'react';
import { ObjectId } from 'bson';
import {
  ScrollView,
  View,
  ImageBackground,
  TextInput,
  Text,
  Pressable,
  Image,
} from 'react-native';
import DynamicSelect from '../DynamicControls/DynamicSelect';
import Icon from '../Shared/Icon';
import { Icons } from '../../Constants/Icons';
import CustomPressable from '../Shared/CustomPressable';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { useSync } from '../../Providers/SyncProvider';
import SearchBox from '../Shared/SearchBox';
import { LabelValue } from '../../Types/DtoTypes';
import { Project } from '../../Models/RealmModels/Projects';
import { ProjectSitesStyleSheet } from '../../Styles/ProjectSiteStyles';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { Navigation } from '../../Constants/Navigation';
import { Person } from '../../Models/RealmModels/Person';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { PERSON_STATUSES } from '../../Constants/AppConstants';

type PeopleListProps = { navigation: any };

export type PersonRecord = {
  id: ObjectId;
  sqlServerId: string;
  firstName: string;
  lastName: string;
  info: string;
  isActive: boolean;
  userStatus?: string;
};

const PeopleList = (props: PeopleListProps): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState<PersonRecord[] | undefined>(undefined);

  const [filteredStatus, setFilteredStatus] = useState([
    PERSON_STATUSES.ACTIVE,
    PERSON_STATUSES.ACCESS,
    PERSON_STATUSES.PENDING,
    PERSON_STATUSES.DISABLED,
  ]);
  const [filteredOrderBy, setFilteredOrderBy] = useState('First Name');

  const DISPLAY_INCREMENT = 5;
  const [recordsToShow, setRecordsToShow] = useState(DISPLAY_INCREMENT);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const [myKey, setMyKey] = useState('');
  const [navKey, setNavKey] = useState('');

  const { getPeople, getSQLUsers, people } = useSync();

  useEffect(() => {
    fetchPeople();
  }, [people]);

  useEffect(() => {
    setMyKey(currentKey());
    setNavKey(currentKey());

    if (navigationRef.current) {
      const unsubscribe = navigationRef.current.addListener('state', () => {
        setNavKey(currentKey());
        fetchPeople();
      });

      return unsubscribe;
    }
  }, []);

  useEffect(() => {
    setRecordsToShow(DISPLAY_INCREMENT);
    setExpandedItems([]);

    fetchPeople();
  }, [search]);

  const fetchPeople = async () => {
    let filteredRecords = await getPeople({ name: search });
    filteredRecords = filteredRecords.filter(p => !p.isDeleted);

    // Only grab users of current filtered person rows
    let sqlServerIds = filteredRecords.map(v => v.SQLServerId);
    let filteredUsers = (await getSQLUsers())
      .sort(x => x.dataHubVersion)
      .reverse()
      .filter(u => !u.isDeleted && sqlServerIds.includes(u.SQLServerPersonId))
      .map(u => ({
        sqlServerPersonId: u.SQLServerPersonId,
        statusObj: u.statusObj,
      }));

    let mappedRecords: PersonRecord[] = [];
    filteredRecords.forEach(p => {
      let personInfo = 'Email: ' + (p.email ? p.email : '(not provided)');
      if (p.phone) personInfo += ', Phone: ' + p.phone;

      let status = '';
      let user = filteredUsers.find(u => u.sqlServerPersonId === p.SQLServerId);
      if (user && user.statusObj) status = user.statusObj.name ?? '';

      mappedRecords.push({
        id: p._id,
        sqlServerId: p.SQLServerId,
        firstName: p.firstName,
        lastName: p.lastName,
        info: personInfo,
        isActive: p.isActive,
        userStatus: status,
      });
    });

    setRecords(mappedRecords);
  };

  const goToPerson = (personId: ObjectId | null) => {
    props.navigation.push(Navigation.EDITPERSON, {
      personId: personId,
    });
  };

  function sortItems(itemsToSort: PersonRecord[]): PersonRecord[] {
    if (filteredOrderBy === 'First Name')
      return itemsToSort.sort((a, b) =>
        ((a.firstName + ' ' + a.lastName)?.toLowerCase() ?? '') >
        ((b.firstName + ' ' + b.lastName).toLowerCase() ?? '')
          ? 1
          : ((a.firstName + ' ' + a.lastName)?.toLowerCase() ?? '') <
            ((b.firstName + ' ' + b.lastName)?.toLowerCase() ?? '')
          ? -1
          : 0,
      );
    else
      return itemsToSort.sort((a, b) =>
        ((a.lastName + ' ' + a.firstName)?.toLowerCase() ?? '') >
        ((b.lastName + ' ' + b.firstName).toLowerCase() ?? '')
          ? 1
          : ((a.lastName + ' ' + a.firstName)?.toLowerCase() ?? '') <
            ((b.lastName + ' ' + b.firstName)?.toLowerCase() ?? '')
          ? -1
          : 0,
      );
  }

  function expand(index: number): void {
    let newExpandedItems = [...expandedItems];

    newExpandedItems.push(index);

    setExpandedItems(newExpandedItems);
  }

  function collapse(index: number): void {
    let newExpandedItems = [...expandedItems];

    let arrayIndex = newExpandedItems.indexOf(index);
    if (arrayIndex !== -1) {
      newExpandedItems.splice(arrayIndex, 1);
      setExpandedItems(newExpandedItems);
    }
  }

  function toggleFilteredStatus(status: string): void {
    let newFilteredStatus = [...filteredStatus];

    let index = newFilteredStatus.indexOf(status);
    if (index !== -1) newFilteredStatus.splice(index, 1);
    else newFilteredStatus.push(status);

    setRecordsToShow(DISPLAY_INCREMENT);
    setExpandedItems([]);

    setFilteredStatus(newFilteredStatus);
  }

  function changeFilteredOrderBy(value: string): void {
    setRecordsToShow(DISPLAY_INCREMENT);
    setExpandedItems([]);

    setFilteredOrderBy(value);
  }

  const renderPeopleList = () => {
    if (!records) return <></>;

    let filteredRecords = records.filter(
      p =>
        (p.firstName + ' ' + p.lastName)
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        p.info.toLowerCase().includes(search.toLowerCase()),
    );

    filteredRecords = filteredRecords.filter(
      p =>
        // Apply ACTIVE filter
        (filteredStatus.includes(PERSON_STATUSES.ACTIVE) &&
          p.isActive &&
          !p.userStatus) ||
        // Apply DEACTIVED filter
        (filteredStatus.includes(PERSON_STATUSES.DEACTIVED) &&
          !p.isActive &&
          !p.userStatus) ||
        // Apply ACCESS filter
        (filteredStatus.includes(PERSON_STATUSES.ACCESS) &&
          p.userStatus === PERSON_STATUSES.ACCESS) ||
        // Apply PENDING filter
        (filteredStatus.includes(PERSON_STATUSES.PENDING) &&
          p.userStatus === PERSON_STATUSES.PENDING) ||
        // Apply DISABLED filter
        (filteredStatus.includes(PERSON_STATUSES.DISABLED) &&
          p.userStatus === PERSON_STATUSES.DISABLED),
    );

    let visibleRecords = sortItems(filteredRecords).slice(0, recordsToShow);
    if (visibleRecords.length === 0)
      return (
        <Text
          style={[
            ProjectSitesStyleSheet.siteItemText,
            { textAlign: 'center' },
          ]}>
          No Results Found
        </Text>
      );

    return (
      <View style={{ gap: 16, padding: 16 }}>
        {visibleRecords.map((person, i) => {
          return renderRecord(person, i);
        })}
        {filteredRecords.length > visibleRecords.length && (
          <Pressable
            key={visibleRecords.length}
            style={({ pressed }) => [
              {
                flexDirection: 'column',
                backgroundColor: Colors.darkGreen,
                borderRadius: 12,
                padding: 12,
                alignItems: 'center',
              },
              pressed && {
                backgroundColor: Colors.green,
              },
            ]}
            onPress={() => setRecordsToShow(recordsToShow + DISPLAY_INCREMENT)}>
            <Text
              style={[
                ProjectSitesStyleSheet.siteItemText,
                { flex: 1, color: Colors.white },
              ]}>
              Show More
            </Text>
          </Pressable>
        )}
      </View>
    );
  };

  const renderRecord = (
    person: PersonRecord,
    index: number,
  ): React.ReactElement => {
    return (
      <View
        key={index}
        style={{
          flexDirection: 'column',
          backgroundColor: Colors.darkGreen,
          borderRadius: 12,
          padding: 12,
        }}>
        <View style={{ flexDirection: 'row' }}>
          {renderUserStatus(person)}
          <Text
            style={[
              ProjectSitesStyleSheet.siteItemText,
              { flex: 1, color: Colors.white },
            ]}
            numberOfLines={1}>
            {person.firstName + ' ' + person.lastName}
          </Text>
          {!expandedItems.includes(index) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                pressed && {
                  backgroundColor: Colors.whiteTransparent,
                },
              ]}
              onPress={() => expand(index)}>
              <Icon icon={'expand'} color={Colors.white} size={20} />
            </Pressable>
          )}
          {expandedItems.includes(index) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                pressed && {
                  backgroundColor: Colors.whiteTransparent,
                },
              ]}
              onPress={() => collapse(index)}>
              <Icon icon={'collapse'} color={Colors.white} size={20} />
            </Pressable>
          )}
        </View>
        {expandedItems.includes(index) ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderTopWidth: 1,
              borderTopColor: Colors.white,
              marginTop: 6,
              paddingTop: 6,
            }}>
            <Text
              style={[
                ProjectSitesStyleSheet.siteItemText,
                {
                  fontWeight: 'normal',
                  flex: 1,
                  color: Colors.white,
                },
              ]}
              numberOfLines={1}>
              {person.info}
            </Text>
            <Pressable
              style={({ pressed }) => [
                {
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                pressed && {
                  backgroundColor: Colors.whiteTransparent,
                },
              ]}
              onPress={() => goToPerson(person.id)}>
              <Icon icon={'pencil'} color={Colors.white} size={20} />
            </Pressable>
          </View>
        ) : null}
      </View>
    );
  };

  const renderUserStatus = (person: PersonRecord): React.ReactElement => {
    let icon = '';
    if (person.userStatus) {
      if (person.userStatus === PERSON_STATUSES.ACCESS)
        icon = Icons.USER_ACCESS;
      else if (person.userStatus === PERSON_STATUSES.PENDING)
        icon = Icons.USER_PENDIND;
      else if (person.userStatus === PERSON_STATUSES.DISABLED)
        icon = Icons.USER_DISABLED;
    } else {
      if (person.isActive) icon = Icons.PERSON_ACTIVE;
      else icon = Icons.PERSON_DEACTIVED;
    }

    return (
      <View style={{ marginRight: 12 }}>
        {icon ? (
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              icon.replaceAll('currentColor', Colors.teal),
            )}`}
            style={{ color: Colors.teal }}
          />
        ) : null}
      </View>
    );
  };

  const renderFilters = (): React.ReactElement => {
    return (
      <View
        style={{
          backgroundColor: Colors.lightgray,
          borderRadius: 12,
          marginVertical: 12,
          marginRight: 12,
          padding: 24,
        }}>
        <Text style={[CommonStyleSheet.controlLabel, { alignSelf: 'center' }]}>
          Filter
        </Text>
        <View>
          <Text style={CommonStyleSheet.controlLabel}>Status</Text>
          <View style={CommonStyleSheet.filterButtonContainer}>
            <View style={CommonStyleSheet.filterButtonItem}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.filterButton,
                  filteredStatus.includes(PERSON_STATUSES.ACTIVE) && {
                    backgroundColor: Colors.teal,
                  },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => toggleFilteredStatus(PERSON_STATUSES.ACTIVE)}>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    Icons.PERSON_ACTIVE.replaceAll(
                      'currentColor',
                      Colors.darkestGreen,
                    ),
                  )}`}
                />
              </Pressable>
              <Text style={CommonStyleSheet.filterButtonText}>
                {PERSON_STATUSES.ACTIVE}
              </Text>
            </View>
            <View style={CommonStyleSheet.filterButtonItem}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.filterButton,
                  filteredStatus.includes(PERSON_STATUSES.ACCESS) && {
                    backgroundColor: Colors.teal,
                  },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => toggleFilteredStatus(PERSON_STATUSES.ACCESS)}>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    Icons.USER_ACCESS.replaceAll(
                      'currentColor',
                      Colors.darkestGreen,
                    ),
                  )}`}
                />
              </Pressable>
              <Text style={CommonStyleSheet.filterButtonText}>
                {PERSON_STATUSES.ACCESS}
              </Text>
            </View>
            <View style={CommonStyleSheet.filterButtonItem}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.filterButton,
                  filteredStatus.includes(PERSON_STATUSES.PENDING) && {
                    backgroundColor: Colors.teal,
                  },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => toggleFilteredStatus(PERSON_STATUSES.PENDING)}>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    Icons.USER_PENDIND.replaceAll(
                      'currentColor',
                      Colors.darkestGreen,
                    ),
                  )}`}
                />
              </Pressable>
              <Text style={CommonStyleSheet.filterButtonText}>
                {PERSON_STATUSES.PENDING}
              </Text>
            </View>
            <View style={CommonStyleSheet.filterButtonItem}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.filterButton,
                  filteredStatus.includes(PERSON_STATUSES.DISABLED) && {
                    backgroundColor: Colors.teal,
                  },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => toggleFilteredStatus(PERSON_STATUSES.DISABLED)}>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    Icons.USER_DISABLED.replaceAll(
                      'currentColor',
                      Colors.darkestGreen,
                    ),
                  )}`}
                />
              </Pressable>
              <Text style={CommonStyleSheet.filterButtonText}>
                {PERSON_STATUSES.DISABLED}
              </Text>
            </View>
            <View style={CommonStyleSheet.filterButtonItem}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.filterButton,
                  filteredStatus.includes(PERSON_STATUSES.DEACTIVED) && {
                    backgroundColor: Colors.teal,
                  },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => toggleFilteredStatus(PERSON_STATUSES.DEACTIVED)}>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    Icons.PERSON_DEACTIVED.replaceAll(
                      'currentColor',
                      Colors.darkestGreen,
                    ),
                  )}`}
                />
              </Pressable>
              <Text style={CommonStyleSheet.filterButtonText}>
                {PERSON_STATUSES.DEACTIVED}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={CommonStyleSheet.controlLabel}>Order By</Text>
          <DynamicSelect
            config={{
              options: [
                { label: 'First Name', value: 'First Name' },
                { label: 'Last Name', value: 'Last Name' },
              ],
            }}
            value={filteredOrderBy ? filteredOrderBy : ''}
            onChange={(controlId, controlTypeId, value) =>
              changeFilteredOrderBy(value)
            }
          />
        </View>
      </View>
    );
  };

  return (
    <ScrollView>
      <View>
        <ImageBackground
          source={Images.PEOPLE}
          resizeMode="cover"
          style={{ height: 260 }}>
          <Text style={ReportsStyleSheet.headerTitle}>People List</Text>
        </ImageBackground>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          <View style={{ marginTop: -58, marginLeft: 'auto', marginRight: 28 }}>
            <CustomPressable
              style={({ pressed }) => [
                {
                  backgroundColor: Colors.green,
                  width: 58,
                  height: 58,
                  borderRadius: 58,
                },
                pressed && {
                  backgroundColor: Colors.teal,
                },
              ]}
              onPress={() => goToPerson(null)}
              tooltip="Add New">
              <View style={{ marginTop: -1, marginLeft: -1 }}>
                <Icon icon={'add'} color={Colors.white} size={60} />
              </View>
            </CustomPressable>
          </View>
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          {renderPeopleList()}
        </View>
        <View style={{ flex: 1 }}>
          {renderFilters()}
          <View style={CommonStyleSheet.bcsfFooter}>
            <Image
              source={Images.BCSF}
              style={CommonStyleSheet.bcsfFooterImage}
            />
            <Text style={CommonStyleSheet.bcsfFooterText}>
              BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
            </Text>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default PeopleList;
