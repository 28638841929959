import React, { useState, useEffect } from 'react';
import { View, Text, TextInput } from 'react-native';
import { DynamicTextAreaProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';

const DynamicTextArea = (props: DynamicTextAreaProps): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '');
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (isFocused === false) {
      let valid = validate();

      if (props.value === value) return;
      if (
        props.config?.hasMetaData &&
        props.config?.metaDataKey &&
        props.updateMetaData
      )
        props.updateMetaData(props.config.metaDataKey, value);

      if (props.onChange)
        props.onChange(props.controlId, props.controlTypeId, value, valid);
    }
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) validate();
  }, [props.showError]);

  function focus(): void {
    setIsFocused(true);

    if (props.onFocus) props.onFocus(position);
  }

  function validate(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);

    return isValid;
  }

  return (
    <View
      style={{ display: props.visible === false ? 'none' : 'flex' }}
      onLayout={event => setPosition(event.nativeEvent.layout.y)}>
      {props.label ? (
        <Text style={ControlsStyleSheet.label}>
          {props.label}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
      ) : null}
      <TextInput
        multiline={true}
        numberOfLines={4}
        style={[
          ControlsStyleSheet.input,
          ControlsStyleSheet.inputArea,
          isFocused && ControlsStyleSheet.inputFocused,
          error !== '' && ControlsStyleSheet.inputError,
          isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
          {
            color: props.config?.color ?? ControlsStyleSheet.input.color,
            fontSize:
              props.config?.fontSize ?? ControlsStyleSheet.input.fontSize,
            fontWeight:
              props.config?.fontWeight ?? ControlsStyleSheet.input.fontWeight,
          },
        ]}
        placeholder={props.config?.placeholder}
        placeholderTextColor={ControlsStyleSheet.placeholder.color}
        maxLength={props.config?.maxLength}
        value={value}
        onChangeText={newText => setValue(newText)}
        onFocus={() => focus()}
        onBlur={() => setIsFocused(false)}
        editable={!props.disabled}
      />
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicTextArea;
