import { StyleSheet } from 'react-native';
import Colors from './Shared/Colors';

const ReportsStyleSheet = StyleSheet.create({
  headerTitle: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 72,
    fontWeight: '700',
    marginHorizontal: 20,
    marginTop: 'auto',
  },
  fabContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 28,
  },
  fabItem: {
    backgroundColor: Colors.green,
    width: 76,
    height: 76,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 76,
    marginHorizontal: 4,
    marginBottom: 8,
  },
  fabIcon: {
    display: 'flex',
    color: Colors.white,
  },
  draftAction: {
    marginLeft: 10,
    marginRight: 5,
    padding: 7,
    backgroundColor: Colors.darkGreen,
    borderRadius: 20,
  },
  list: {
    paddingHorizontal: 20,
    paddingTop: 34,
    paddingBottom: 20,
  },
  listItemIcon: {
    display: 'flex',
    color: Colors.darkestGreen,
    marginHorizontal: 12,
  },
  listTitle: {
    color: Colors.darkestGreen,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '700',
    letterSpacing: 3.06,
    textTransform: 'uppercase',
    alignSelf: 'center',
    marginHorizontal: 20,
    marginBottom: 6,
  },
  listSubtitle: {
    color: Colors.darkestGreen,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 3.06,
    textTransform: 'uppercase',
    alignSelf: 'center',
    marginHorizontal: 20,
    marginTop: 12,
    marginBottom: 6,
  },
  listItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 14,
  },
  listItemName: {
    flex: 1,
    alignSelf: 'stretch',
    color: Colors.darkestGreen,
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: '700',
  },
  listItemDateContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6,
    padding: 6,
    gap: 5,
  },
  listItemDate: {
    color: Colors.darkestGreen,
    fontFamily: 'Lato',
    fontSize: 10,
    fontWeight: '900',
  },
  listItemTime: {
    color: Colors.darkestGreen,
    fontFamily: 'Lato',
    fontSize: 10,
    fontWeight: '400',
  },
});

export { ReportsStyleSheet };
