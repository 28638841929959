import React from 'react';
import { View, Text, Pressable, Modal } from 'react-native';
import Icon from '../Shared/Icon';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';

type DismissModalProps = {
  isShowingModal: boolean;
  title: string;
  text: string;
  onDismiss: () => void;
};

const DismissModal = (props: DismissModalProps): React.ReactElement => {
  function dismiss(): void {
    if (props.onDismiss) props.onDismiss();
  }

  return (
    <Modal
      visible={props.isShowingModal}
      transparent={true}
      statusBarTranslucent={true}
      animationType="fade">
      <Pressable
        style={[
          ControlsStyleSheet.modalBackground,
          {
            //@ts-ignore
            cursor: 'auto',
          },
        ]}
        onPress={dismiss}>
        <View
          style={{
            backgroundColor: Colors.white,
            width: 320,
            alignSelf: 'center',
            borderRadius: 20,
            marginTop: 110,
            marginRight: 10,
            paddingHorizontal: 20,
          }}>
          <View style={ControlsStyleSheet.groupSelectorModalBar}>
            <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
              {props.title}
            </Text>
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.groupSelectorModalClose,
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                  borderRadius: 24,
                },
              ]}
              onPress={dismiss}>
              <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
            </Pressable>
          </View>
          <View style={{ marginBottom: 40 }}>
            <Text
              style={[
                ControlsStyleSheet.attachmentsText,
                { fontSize: 14, textAlign: 'left', textTransform: 'none' },
              ]}>
              {props.text}
            </Text>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

export default DismissModal;
