import React, { useState, useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  Image,
  ImageBackground,
  Text,
  Pressable,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId, UUID } from 'bson';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionTraining } from '../../Models/RealmModels/SubmissionTraining';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import {
  TemplateCategoryTypes,
  TemplateTypes,
} from '../../Constants/TemplateCategoryTypes';
import { Navigation } from '../../Constants/Navigation';
import RecordList, { Record } from '../Shared/RecordList';
import Icon from '../Shared/Icon';
import CustomPressable from '../Shared/CustomPressable';
import { DynamicScreenButton } from '../../Types/ControlTypes';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { useIsFocused } from '@react-navigation/native';
import {
  CATEGORY,
  METADATA_KEYS,
  PERMISSIONS,
} from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import ConfirmModal from '../Shared/ConfirmModal';
import { useRelation } from '../../Providers/SubmissionRelationProvider';
import { FilterTypes } from '../../Constants/General';

export declare type PeopleProps = {
  navigation: any;
};

const People = (props: PeopleProps): React.ReactElement => {
  const [reports, setReports] = useState<Submission[]>([]);
  const [screenButtons, setScreenButtons] = useState<DynamicScreenButton[]>([]);
  const isFocused = useIsFocused();

  const { hasPermission } = usePermission();
  const { getRelatedSubmissions } = useRelation();
  const [canCreate, setCanCreate] = useState<boolean>();

  const {
    getPeople,
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissions,
    getSubmissionStatuses,
    getRealmApp,
    upsertSubmission,
    upsertSubmissionTraining,
    submissions,
  } = useSync();

  const unselectCommand = useRef({
    unselect: () => {},
    setUnselectCommand(func: () => void) {
      this.unselect = func;
    },
  });

  const createModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    let createReport = hasPermission(PERMISSIONS.REPORT_PEOPLE_CREATE);
    setCanCreate(createReport);

    const fetchScreenButtons = async () => {
      let filteredTemplates = (await getFilteredTemplates())
        .filter(
          t =>
            t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.PEOPLE,
        )
        .sort((a, b) => a.row - b.row);

      let newScreenButtons: DynamicScreenButton[] = [];
      for (let i = 0; i < filteredTemplates.length; i++) {
        let templateId: ObjectId = filteredTemplates[i]._id;
        let versions = (await getFilteredTemplateVersions())
          .filter(x => x.templateId.equals(templateId))
          .sort(x => x.version)
          .reverse();

        if (versions && versions.length > 0) {
          newScreenButtons.push({
            templateName: filteredTemplates[i].name,
            label: filteredTemplates[i].name,
            icon: filteredTemplates[i].iconSvg ?? '',
            version: versions[0].version,
          });
        }
      }

      setScreenButtons(newScreenButtons);
    };

    fetchScreenButtons();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [submissions]);

  useEffect(() => {
    if (isFocused) {
      fetchReports();
    }
  }, [isFocused]);

  const fetchReports = async () => {
    let viewReport = hasPermission(PERMISSIONS.REPORT_PEOPLE_VIEW);
    let peopleTemplates = (await getFilteredTemplates())
      .filter(
        t =>
          t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.PEOPLE &&
          t.templateType.name !== TemplateTypes.TRAINING_BREAKDOWN,
      )
      .map(t => t.name.toLowerCase().trim());

    let subs = (await getSubmissions()).filter(
      s =>
        peopleTemplates.includes(
          s.templateVersion.name?.toLowerCase().trim() ?? '',
        ) &&
        (s.submissionStatus.name === SubmissionStatuses.DRAFT ||
          s.submissionStatus.name === SubmissionStatuses.SUBMITTED),
    );

    if (!viewReport) {
      //Special permissions for PM/Supervisor
      let user = getRealmApp().currentUser;
      if (user) {
        let userEmail = user.customData['email'];
        let personLoggedIn = (await getPeople({})).find(
          x => x.email == userEmail,
        );

        if (personLoggedIn) {
          let submissionIds = await getRelatedSubmissions(personLoggedIn._id);
          subs = subs.filter(
            x =>
              submissionIds.includes(x._id.toHexString()) ||
              x.createdBy == user?.customData['email'],
          );
        }
      }
    }

    setReports(subs);
  };

  async function openDynamicForm(
    templateName: string,
    showConfirmation?: boolean,
  ): Promise<void> {
    if (!canCreate) {
      createModalCommand.current.openModal();
      return;
    }

    let template = (await getFilteredTemplates()).find(
      t => t.name === templateName,
    );

    if (template) {
      let templateId = template._id;
      let versions = (await getFilteredTemplateVersions())
        .filter(x => x.templateId.equals(templateId))
        .sort(x => x.version)
        .reverse()
        .map(x => ({ _id: x._id, name: x.name }));

      let newDraftId: ObjectId = new ObjectId();
      const draftStatus: SubmissionStatus = (
        await getSubmissionStatuses()
      ).find(x => x.name == SubmissionStatuses.DRAFT)!;

      let currentUser = getRealmApp().currentUser;
      let org = '';
      let currentEmail = '';
      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }
        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let newSubmission: Submission = {
        _id: newDraftId,
        partition: org,
        createdBy: currentEmail as string,
        updatedBy: currentEmail as string,
        templateVersion: {
          _id: versions[0]._id,
          name: versions[0].name,
        },
        templateType: {
          _id: template!.templateType._id,
          name: template!.templateType.name,
        },
        submissionStatus: {
          _id: draftStatus._id,
          name: draftStatus.name,
        },
        SQLServerId: new UUID().toHexString(),
        answers: [],
        metadataJSON: '',
        createDateTimeStamp: new Date(),
        updateDateTimeStamp: new Date(),
      };

      if (template.templateType.name === TemplateTypes.TRAINING_BREAKDOWN) {
        let newSubmissionTraining: SubmissionTraining = {
          ...newSubmission,
          person: {},
          trainingType: {},
          certificateType: {},
        };

        await upsertSubmissionTraining(newSubmissionTraining);

        if (newDraftId == null) throw Error('Error: create draft failed!');

        props.navigation.navigate(Navigation.TRAININGFORM, {
          submissionId: newDraftId,
        });
      } else {
        await upsertSubmission(newSubmission);

        if (newDraftId == null) throw Error('Error: create draft failed!');

        props.navigation.navigate(Navigation.DYNAMICFORM, {
          submissionId: newDraftId,
          showConfirmation: showConfirmation,
          submissionStatus: SubmissionStatuses.DRAFT,
        });
      }
    }
  }

  const goToAddPerson = (personId: ObjectId | null) => {
    props.navigation.push(Navigation.EDITPERSON, {
      personId: personId,
    });
  };

  const goToTrainingBreakdown = () => {
    props.navigation.push(Navigation.TRAININGBREAKDOWN);
  };

  async function recordPressed(recordId: string): Promise<void> {
    props.navigation.push(Navigation.DYNAMICFORM, {
      submissionId: recordId,
      showConfirmation: false,
    });
  }

  const renderScreenButton = (
    button: DynamicScreenButton,
    index: number,
  ): React.ReactElement => {
    return (
      <React.Fragment key={index}>
        <CustomPressable
          style={({ pressed }) => [
            CommonStyleSheet.screenButton,
            { overflow: 'visible' },
            !canCreate && { opacity: 0.4 },
            pressed && { opacity: 0.6 },
          ]}
          onPress={() => openDynamicForm(button.templateName, false)}>
          {button.icon !== '' && (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(button.icon)}`}
              color={Colors.green}
              style={{ color: Colors.green }}
            />
          )}
          <Text style={CommonStyleSheet.screenButtonText}>{button.label}</Text>
        </CustomPressable>
        {index % 2 === 1 && <View style={{ flexBasis: '100%' }}></View>}
      </React.Fragment>
    );
  };

  return (
    <ScrollView>
      <Pressable
        style={{
          //@ts-ignore
          cursor: 'auto',
        }}
        onPress={() => {
          unselectCommand.current.unselect();
        }}>
        <>
          <View>
            <ImageBackground
              source={Images.PEOPLE}
              resizeMode="cover"
              style={{ height: 260 }}>
              <Text style={ReportsStyleSheet.headerTitle}>People</Text>
            </ImageBackground>

            <ConfirmModal
              title="No Access"
              message="You do not have access to create reports"
              okClick={() => {}}
              okText="Ok"
              setOpenModal={(func: () => void) => {
                createModalCommand.current.setOpenModal(func);
              }}
            />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 2, padding: 28 }}>
              <RecordList
                records={reports}
                showDrafts={true}
                hasMetaData={true}
                category={CATEGORY.PEOPLE}
                metaDataKey_title={METADATA_KEYS.WORKERID}
                showFilters={true}
                filters={[
                  { label: 'Date Range', type: FilterTypes.DATE_RANGE },
                  { label: 'Type', type: FilterTypes.REPORT_TYPE },
                  { label: 'Person', type: FilterTypes.PERSON },
                ]}
                navigation={props.navigation}
                onRecordPressed={recordId => recordPressed(recordId)}
                setUnselectCommand={(func: () => void) => {
                  unselectCommand.current.setUnselectCommand(func);
                }}
              />
            </View>
            <View style={{ flex: 1 }}>
              <View style={CommonStyleSheet.screenButtonsContainer}>
                {screenButtons.map((screenButton, index) => {
                  return renderScreenButton(screenButton, index);
                })}
                {screenButtons.length > 0 && (
                  <React.Fragment key={screenButtons.length}>
                    <Pressable
                      style={({ pressed }) => [
                        CommonStyleSheet.screenButton,
                        pressed && { opacity: 0.6 },
                      ]}
                      onPress={() => goToTrainingBreakdown()}>
                      <Icon
                        icon={'training-breakdown'}
                        color={Colors.green}
                        size={24}
                      />
                      <Text style={CommonStyleSheet.screenButtonText}>
                        Training Breakdown
                      </Text>
                    </Pressable>
                    {screenButtons.length % 2 === 1 && (
                      <View style={{ flexBasis: '100%' }}></View>
                    )}
                    <Pressable
                      style={({ pressed }) => [
                        CommonStyleSheet.screenButton,
                        pressed && { opacity: 0.6 },
                      ]}
                      onPress={() => goToAddPerson(null)}>
                      <Icon icon={'user-plus'} color={Colors.green} size={24} />
                      <Text style={CommonStyleSheet.screenButtonText}>
                        Add a person
                      </Text>
                    </Pressable>
                  </React.Fragment>
                )}
              </View>
              <View style={CommonStyleSheet.bcsfFooter}>
                <Image
                  source={Images.BCSF}
                  style={CommonStyleSheet.bcsfFooterImage}
                />
                <Text style={CommonStyleSheet.bcsfFooterText}>
                  BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
                </Text>
              </View>
            </View>
          </View>
        </>
      </Pressable>
    </ScrollView>
  );
};

export default People;
