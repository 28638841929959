import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  ActivityIndicator,
  Modal,
} from 'react-native';
import * as Realm from 'realm-web';
import moment from 'moment';
import { useSync } from '../../Providers/SyncProvider';
import SearchBoxComponent from '../Shared/SearchBox';
import Icon from '../Shared/Icon';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import {
  SubmissionBundle,
  SubmissionBundle_submissions,
} from '../../Models/RealmModels/SubmissionBundle';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';

type AddReportToBundleProps = {
  submissionId: string;
  submissionType?: 'submission' | 'submissionTraining';
  formName: string;
  visible: boolean;
  onClose?: () => void;
};

type Record = {
  id: string;
  name: string;
  datetime: Date;
};

const AddReportToBundle = (
  props: AddReportToBundleProps,
): React.ReactElement => {
  const [visible, setVisible] = useState(props.visible);
  const [records, setRecords] = useState<Record[]>([]);

  const [search, setSearch] = useState('');
  const [parentBundles, setParentBundles] = useState<string[]>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const [addingNewBundle, setAddingNewBundle] = useState(false);
  const [newBundleName, setNewBundleName] = useState('');
  const [showNewBundleErrors, setShowNewBundleErrors] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const defaultItemsToShow = 10;
  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);

  const {
    getSubmissions,
    getSubmissionTrainings,
    getSubmissionBundles,
    upsertSubmissionBundle,
    getOrgId,
    getRealmApp,
  } = useSync();

  useEffect(() => {
    if (props.visible !== visible) setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    if (visible) {
      setItemsToShow(defaultItemsToShow);
      setAddingNewBundle(false);
      setIsSaving(false);

      fetchRecords();
    }
  }, [visible]);

  async function fetchRecords(): Promise<void> {
    let orgId = getOrgId();

    let filteredRecords = (await getSubmissionBundles()).filter(
      s => s.partition === orgId && s.status === SubmissionStatuses.DRAFT,
    );

    let mappedRecords: Record[] = [];
    let newParents: string[] = [];
    filteredRecords.forEach(b => {
      mappedRecords.push({
        id: b._id!.toHexString(),
        name: b.name ?? '',
        datetime: b.createDateTimeStamp ?? new Date(),
      });

      if (b.submissions)
        b.submissions.forEach(s => {
          if (
            s.submissionId &&
            s.submissionId.toHexString() == props.submissionId &&
            b._id &&
            !newParents.includes(b._id.toHexString())
          )
            newParents.push(b._id.toHexString());
        });
    });

    mappedRecords.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
    setRecords(mappedRecords);
    setParentBundles(newParents);
    setSelection(newParents);
  }

  function close(): void {
    if (props.onClose) props.onClose();
  }

  function changeSearch(newSearch: string): void {
    setSearch(newSearch);
    setItemsToShow(defaultItemsToShow);
  }

  function showNewBundle(): void {
    setAddingNewBundle(true);
    setNewBundleName('');
    setShowNewBundleErrors(false);
  }

  async function createBundle(): Promise<void> {
    if (!newBundleName) {
      setShowNewBundleErrors(true);
      return;
    }

    let user = getRealmApp().currentUser;
    if (!user) return;

    let userEmail = user.customData['email'] as string;
    let orgId = getOrgId();

    let submissions: SubmissionBundle_submissions[] = [];
    let bundleSubmission = await getBundleSubmission();
    if (bundleSubmission) submissions.push(bundleSubmission);

    let newSubmissionId = new Realm.BSON.ObjectId();
    let newSubmission: SubmissionBundle = {
      _id: newSubmissionId,
      partition: orgId,
      status: SubmissionStatuses.DRAFT,
      name: newBundleName,
      submissions: submissions,
      createdBy: userEmail,
      createDateTimeStamp: new Date(),
    };
    await upsertSubmissionBundle(newSubmission);

    setAddingNewBundle(false);
    setNewBundleName('');
    setShowNewBundleErrors(false);

    fetchRecords();
  }

  function select(record: Record): void {
    let newSelection = [...selection];

    let index = newSelection.indexOf(record.id);
    if (index === -1) newSelection.push(record.id);
    else newSelection.splice(index, 1);

    setSelection(newSelection);
  }

  async function save(): Promise<void> {
    // Adding submission to bundles
    let toAdd = selection.filter(s => !parentBundles.includes(s));
    for (let i = 0; i < toAdd.length; i++) {
      await addTo(toAdd[i]);
    }

    // Removing submission from bundles
    let toRemove = parentBundles.filter(s => !selection.includes(s));
    for (let i = 0; i < toRemove.length; i++) {
      await removeFrom(toRemove[i]);
    }

    close();
  }

  async function addTo(bundleId: string): Promise<void> {
    let bundle = (await getSubmissionBundles()).find(b =>
      b._id.equals(bundleId),
    );
    let bundleSubmission = await getBundleSubmission();
    if (bundleSubmission && bundle) {
      // Here we are mapping all bundle submissions in an array, otherwise it's going to fail without an error message
      let bundleSubmissions: SubmissionBundle_submissions[] = [];
      if (bundle.submissions && bundle.submissions.length > 0) {
        bundle.submissions.forEach(s => {
          let submissionAnswers = s.answers.map(a => ({
            answer: a.answer,
            controlId: a.controlId,
            controlTypeId: a.controlTypeId,
          }));

          bundleSubmissions.push({
            submissionId: s.submissionId,
            answers: submissionAnswers,
            metadataJSON: s.metadataJSON,
            templateType: {
              _id: s.templateType?._id,
              name: s.templateType?.name,
            },
            templateVersion: {
              _id: s.templateVersion?._id,
              name: s.templateVersion?.name,
            },
            updatedBy: s.updatedBy,
            updateDateTimeStamp: s.updateDateTimeStamp,
          } as SubmissionBundle_submissions);
        });
      }

      bundleSubmissions.push(bundleSubmission);
      bundle.submissions = bundleSubmissions;
      await upsertSubmissionBundle(bundle);
    }
  }

  async function removeFrom(bundleId: string): Promise<void> {
    let bundle = (await getSubmissionBundles()).find(b =>
      b._id.equals(bundleId),
    );
    if (bundle) {
      // Here we are mapping all bundle submissions in an array, otherwise it's going to fail without an error message
      let bundleSubmissions: SubmissionBundle_submissions[] = [];
      if (bundle.submissions && bundle.submissions.length > 0) {
        bundle.submissions.forEach(s => {
          if (!s.submissionId?.equals(props.submissionId)) {
            let submissionAnswers = s.answers.map(a => ({
              answer: a.answer,
              controlId: a.controlId,
              controlTypeId: a.controlTypeId,
            }));

            bundleSubmissions.push({
              submissionId: s.submissionId,
              answers: submissionAnswers,
              metadataJSON: s.metadataJSON,
              templateType: {
                _id: s.templateType?._id,
                name: s.templateType?.name,
              },
              templateVersion: {
                _id: s.templateVersion?._id,
                name: s.templateVersion?.name,
              },
              updatedBy: s.updatedBy,
              updateDateTimeStamp: s.updateDateTimeStamp,
            } as SubmissionBundle_submissions);
          }
        });
      }

      bundle.submissions = bundleSubmissions;
      await upsertSubmissionBundle(bundle);
    }
  }

  async function getBundleSubmission(): Promise<
    SubmissionBundle_submissions | undefined
  > {
    let user = getRealmApp().currentUser;
    if (!user) return;

    let userEmail = user.customData['email'] as string;
    let bundleSubmission: SubmissionBundle_submissions | undefined = undefined;

    if (props.submissionType === 'submission') {
      let submission = (await getSubmissions()).find(s =>
        s._id.equals(props.submissionId),
      );
      if (submission) {
        let answers = submission.answers.map(a => ({
          answer: a.answer,
          controlId: a.controlId,
          controlTypeId: a.controlTypeId,
        }));

        bundleSubmission = {
          submissionId: submission._id,
          submissionSQLServerId: submission.SQLServerId,
          answers: answers,
          metadataJSON: submission.metadataJSON,
          templateType: {
            _id: submission.templateType._id,
            name: submission.templateType.name,
          },
          templateVersion: {
            _id: submission.templateVersion._id,
            name: submission.templateVersion.name,
          },
          updatedBy: userEmail,
          updateDateTimeStamp: new Date(),
        } as SubmissionBundle_submissions;
      }
    } else {
      let training = (await getSubmissionTrainings()).find(s =>
        s._id.equals(props.submissionId),
      );
      if (training) {
        let answers = training.answers.map(a => ({
          answer: a.answer,
          controlId: a.controlId,
          controlTypeId: a.controlTypeId,
        }));

        bundleSubmission = {
          submissionId: training._id,
          answers: answers,
          metadataJSON: training.metadataJSON,
          templateType: {
            _id: training.templateType._id,
            name: training.templateType.name,
          },
          templateVersion: {
            _id: training.templateVersion._id,
            name: training.templateVersion.name,
          },
          updatedBy: userEmail,
          updateDateTimeStamp: new Date(),
        } as SubmissionBundle_submissions;
      }
    }

    return bundleSubmission;
  }

  const renderModal = (): React.ReactElement => {
    let availableOptions = 0;
    let visibleOptions: Record[] = [];

    if (search) {
      let filteredRecords = records.filter(r =>
        r.name.toLowerCase().includes(search.toLowerCase()),
      );

      availableOptions = filteredRecords.length;
      visibleOptions = filteredRecords.slice(0, itemsToShow);
    } else {
      selection.forEach(val => {
        let item = records.find(item => item.id === val);
        if (item) visibleOptions.push(item);
      });

      //Complete the list with the top # elements
      for (
        let i = 0;
        i < records.length && visibleOptions.length < itemsToShow;
        i++
      ) {
        if (!visibleOptions.some(o => o.id === records[i].id))
          visibleOptions.push(records[i]);
      }

      availableOptions = records.length;
    }

    return (
      <Modal
        visible={visible}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Add Report To Bundle
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => close()}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            {addingNewBundle ? (
              <>
                <View>
                  <Text
                    style={[
                      ControlsStyleSheet.labelLarge,
                      {
                        alignSelf: 'center',
                        textAlign: 'center',
                        marginTop: 36,
                      },
                    ]}>
                    Create New Bundle And Include This Report
                  </Text>
                  <Text style={ControlsStyleSheet.label}>
                    Bundle Name
                    <Text style={ControlsStyleSheet.required}>*</Text>
                  </Text>
                  <TextInput
                    style={ControlsStyleSheet.input}
                    maxLength={50}
                    onChangeText={newText => setNewBundleName(newText)}
                  />
                  {showNewBundleErrors && (
                    <Text style={ControlsStyleSheet.error}>
                      Bundle Name is required
                    </Text>
                  )}
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <Pressable
                      style={({ pressed }) => [
                        CommonStyleSheet.smallGreenButton,
                        { alignSelf: 'center', marginVertical: 12 },
                        pressed && {
                          opacity: 0.8,
                        },
                      ]}
                      onPress={() => setAddingNewBundle(false)}>
                      <Text style={CommonStyleSheet.greenButtonText}>
                        Cancel
                      </Text>
                    </Pressable>
                    <Pressable
                      style={({ pressed }) => [
                        CommonStyleSheet.smallGreenButton,
                        { alignSelf: 'center', marginVertical: 12 },
                        pressed && {
                          opacity: 0.8,
                        },
                      ]}
                      onPress={() => createBundle()}>
                      <Text style={CommonStyleSheet.greenButtonText}>
                        Create
                      </Text>
                    </Pressable>
                  </View>
                </View>
              </>
            ) : (
              <>
                <SearchBoxComponent
                  onChangeSearch={newSearch => changeSearch(newSearch)}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 24,
                  }}>
                  <Text style={ControlsStyleSheet.labelLarge}>
                    Create New Bundle
                  </Text>
                  <Pressable
                    style={({ pressed }) => [
                      ControlsStyleSheet.linkRecordAddButton,
                      pressed && {
                        backgroundColor: Colors.darkGreenTransparent,
                      },
                    ]}
                    onPress={() => showNewBundle()}>
                    <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
                  </Pressable>
                </View>
                <ScrollView style={{ marginVertical: 20 }}>
                  {visibleOptions.length === 0 && (
                    <Text
                      style={[
                        ControlsStyleSheet.checkBoxText,
                        { alignSelf: 'center', marginTop: 12 },
                      ]}>
                      No Results Found
                    </Text>
                  )}
                  {visibleOptions.map((record, index) => {
                    return renderRecord(record, index);
                  })}
                  {visibleOptions.length < availableOptions && (
                    <Pressable
                      style={({ pressed }) => [
                        CommonStyleSheet.smallGreenButton,
                        { alignSelf: 'center', marginVertical: 12 },
                        pressed && {
                          opacity: 0.8,
                        },
                      ]}
                      onPress={() =>
                        setItemsToShow(itemsToShow + defaultItemsToShow)
                      }>
                      <Text style={CommonStyleSheet.smallGreenButtonText}>
                        Show More
                      </Text>
                    </Pressable>
                  )}
                </ScrollView>
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.greenButton,
                    { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  disabled={isSaving}
                  onPress={() => {
                    setIsSaving(true);
                    save();
                  }}>
                  {isSaving ? (
                    <ActivityIndicator color="white" size={24} />
                  ) : (
                    <Text style={CommonStyleSheet.greenButtonText}>Ok</Text>
                  )}
                </Pressable>
              </>
            )}
          </View>
        </View>
      </Modal>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    let checked = selection.some(s => s === record.id);

    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => select(record)}>
        <Text style={ReportsStyleSheet.listItemName} numberOfLines={2}>
          {record.name}
        </Text>
        <View style={ReportsStyleSheet.listItemDateContent}>
          <Text style={ReportsStyleSheet.listItemDate}>
            {moment(record.datetime).format('MMM DD')}
          </Text>
          <Text style={ReportsStyleSheet.listItemTime}>
            {moment(record.datetime).format('HH:ss')}
          </Text>
        </View>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            { marginHorizontal: 12 },
            checked && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon icon={'checked'} color={Colors.white} size={16} />
        </View>
      </Pressable>
    );
  };

  return renderModal();
};

export default AddReportToBundle;
