import React, { useState, useEffect } from 'react';
import { View, Text, TextStyle, Pressable, ViewStyle } from 'react-native';
import { DynamicCheckboxGroupProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import Icon from '../Shared/Icon';
import useDynamicData from '../../Hooks/useDynamicData';
import { LabelValue } from '../../Types/DtoTypes';

const DynamicCheckBoxGroup = (
  props: DynamicCheckboxGroupProps,
): React.ReactElement => {
  const [value, setValue] = useState<string[]>(getValueFromProps(props.value));
  const [error, setError] = useState('');
  const [options, setOptions] = useState<LabelValue<string>[]>([]);
  const { getData } = useDynamicData();

  useEffect(() => {
    const initOptions = async () => {
      let newOptions = await getItemsFromSource();
      setOptions(newOptions);
    };

    initOptions();
  }, []);

  useEffect(() => {
    if (props.value && getValueFromProps(props.value) != value) {
      setValue(getValueFromProps(props.value));
    }
  }, [props.value]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(
        props.controlId,
        props.controlTypeId,
        JSON.stringify(value),
        valid(),
      );

    if (
      props.updateMetaData &&
      props.config?.hasMetaData &&
      props.config?.metaDataKey
    )
      props.updateMetaData(props.config.metaDataKey, JSON.stringify(value));
  }, [value]);

  function getValueFromProps(propsValue: string | undefined): string[] {
    let newValue = [];

    if (propsValue) newValue = JSON.parse(propsValue);

    return newValue;
  }

  async function getItemsFromSource(): Promise<LabelValue<string>[]> {
    let newOptions: LabelValue<string>[] = [];

    if (props.config?.items) newOptions = props.config?.items;
    else if (props.config?.optionSource) {
      newOptions = await getData(
        props.config?.optionSource!,
        props.value,
        false,
        '',
      );
    }

    return newOptions;
  }

  const valid = () => {
    let isValid = true;
    let error = '';

    if (props.config?.required && value.length === 0) {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError) setError(error);
    return isValid;
  };

  const onChange = (itemValue: string) => {
    let newValue = [...value];
    if (newValue.includes(itemValue))
      newValue.splice(newValue.indexOf(itemValue), 1);
    else newValue.push(itemValue);

    setValue(newValue);
  };

  const renderCheckboxes = (): React.ReactElement => {
    return (
      <View>
        {options.map((item, index) => {
          let val = item.value!;
          return (
            <Pressable
              key={item.value}
              style={({ pressed }) => [
                ControlsStyleSheet.checkBoxContainer,
                pressed && { opacity: 0.8 },
                props.disabled && { opacity: 0.6 },
              ]}
              disabled={props.disabled ?? false}
              onPress={() => onChange(val)}>
              <View
                style={[
                  ControlsStyleSheet.checkBox,
                  value.includes(val) && {
                    backgroundColor: Colors.teal,
                    borderColor: Colors.teal,
                  },
                ]}>
                <Icon
                  icon={'checked'}
                  color={
                    value.includes(val) ? Colors.white : Colors.whiteTransparent
                  }
                  size={16}
                />
              </View>
              <Text
                style={[
                  ControlsStyleSheet.checkBoxText,
                  { marginRight: 10, paddingRight: 10 },
                ]}>
                {item.label}
              </Text>
            </Pressable>
          );
        })}
      </View>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <View
        style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
        {renderCheckboxes()}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicCheckBoxGroup;
