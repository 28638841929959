import React, { useState, useEffect } from 'react';
import { View, Text, TextStyle, Pressable, ViewStyle } from 'react-native';
import {
  DynamicRadioProps,
  DynamicSegmentProps,
} from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import Icon from '../Shared/Icon';
import { LabelValue } from '../../Types/DtoTypes';
import useDynamicData from '../../Hooks/useDynamicData';

const DynamicRadioControl = (props: DynamicRadioProps): React.ReactElement => {
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState<LabelValue<string>[]>([]);
  const { getData } = useDynamicData();

  useEffect(() => {
    const initOptions = async () => {
      let newOptions = await getItemsFromSource();
      setOptions(newOptions);
    };

    initOptions();
  }, []);

  useEffect(() => {
    if (props.value != value) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (value && value != props.value) {
      if (props.onChange)
        props.onChange(props.controlId, props.controlTypeId, value, valid());

      if (
        props.updateMetaData &&
        props.config?.hasMetaData &&
        props.config?.metaDataKey
      )
        props.updateMetaData(props.config.metaDataKey, value);
    }
  }, [value]);

  async function getItemsFromSource(): Promise<LabelValue<string>[]> {
    let newOptions: LabelValue<string>[] = [];

    if (props.config?.radios) newOptions = props.config?.radios;
    else if (props.config?.optionSource) {
      newOptions = await getData(
        props.config?.optionSource!,
        props.value,
        false,
        '',
      );
    }

    return newOptions;
  }

  const valid = () => {
    return !(props.required && value === '');
  };

  const renderRadios = (): React.ReactElement => {
    return (
      <View>
        {options.map((radio, index) => {
          let val = radio.value ?? radio.label;
          return (
            <Pressable
              key={radio.value}
              style={({ pressed }) => [
                ControlsStyleSheet.checkBoxContainer,
                { marginRight: 10, paddingRight: 10 },
                pressed && { opacity: 0.8 },
                props.disabled && { opacity: 0.6 },
              ]}
              disabled={props.disabled ?? false}
              onPress={() => setValue(val)}>
              <View
                style={[
                  ControlsStyleSheet.checkBox,
                  value === val && {
                    backgroundColor: Colors.teal,
                    borderColor: Colors.teal,
                  },
                ]}>
                <Icon
                  icon={'checked'}
                  color={val === value ? Colors.white : Colors.whiteTransparent}
                  size={16}
                />
              </View>
              <Text
                style={[
                  ControlsStyleSheet.checkBoxText,
                  { marginRight: 10, paddingRight: 10 },
                ]}>
                {radio.label}
              </Text>
            </Pressable>
          );
        })}
      </View>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
        {renderRadios()}
      </View>
    </View>
  );
};

export default DynamicRadioControl;
