export class STORAGE_KEYS {
  public static readonly TOKEN_KEY = 'TOKEN';
  public static readonly REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
  public static readonly USER_ROLES = 'USER_ROLES';
  public static readonly TOKEN_EXPIRATION_KEY = 'TOKEN_EXPIRATION';
  // path to local medias [{incidentId:string, [{ mediaId: string, localMediaPath:string }]}]
  public static readonly LOCAL_MEDIAS_KEY = 'LOCAL_MEDIAS';
  public static readonly ACTIVE_SITE_KEY = 'ACTIVE_SITE';
  public static readonly ATTACHMENTS_SAS_URIS = 'ATTACHMENTS_SAS_URIS';

  //Store realm data in local storage on login so you don't have to query it for every control/page load
  public static readonly REALM_PEOPLE = 'REALM_PEOPLE';
  public static readonly REALM_PROJECTSITES = 'REALM_PROJECTSITES';
  public static readonly REALM_SQLUSERS = 'REALM_SQLUSERS';
  public static readonly REALM_USERSTATUS = 'REALM_USERSTATUS';
  public static readonly REALM_ANIMALTYPES = 'REALM_ANIMALTYPES';
  public static readonly REALM_CERTIFICATIONTYPES = 'REALM_CERTIFICATIONTYPES';
  public static readonly REALM_CITIES = 'REALM_CITIES';
  public static readonly REALM_CLASSIFICATIONUNITS =
    'REALM_CLASSIFICATIONUNITS';
  public static readonly REALM_CREWS = 'REALM_CREWS';
  public static readonly REALM_DEPARTMENTS = 'REALM_DEPARTMENTS';
  public static readonly REALM_DISPOSITIONS = 'REALM_DISPOSITIONS';
  public static readonly REALM_DOCUMENTS = 'REALM_DOCUMENTS';
  public static readonly REALM_DOCUMENTTYPES = 'REALM_DOCUMENTTYPES';
  public static readonly REALM_EQUIPMENT = 'REALM_EQUIPMENTS';
  public static readonly REALM_EQUIPMENTHEAVY = 'REALM_EQUIPMENTSHEAVY';
  public static readonly REALM_EQUIPMENTOTHER = 'REALM_EQUIPMENTSOTHER';
  public static readonly REALM_EQUIPMENTVEHICLE = 'REALM_EQUIPMENTSVEHICLE';
  public static readonly REALM_EQUIPMENTTYPES = 'REALM_EQUIPMENTTYPES';
  public static readonly REALM_HAZARDTYPES = 'REALM_HAZARDTYPES';
  public static readonly REALM_INCIDENTTYPES = 'REALM_INCIDENTTYPES';
  public static readonly REALM_INCIDENTCLASSIFICATIONS =
    'REALM_INCIDENTCLASSIFICATIONS';
  public static readonly REALM_JOBS = 'REALM_JOBS';
  public static readonly REALM_LOCATIONS = 'REALM_LOCATIONS';
  public static readonly REALM_LOCATIONTYPES = 'REALM_LOCATIONTYPES';
  public static readonly REALM_MECHANISMS = 'REALM_MECHANISMS';
  public static readonly REALM_NATURES = 'REALM_NATURES';
  public static readonly REALM_ORGANISATIONS = 'REALM_ORGANISATIONS';
  public static readonly REALM_ORGRECIPIENTS = 'REALM_ORGRECIPIENTS';
  public static readonly REALM_ORGRELATIONS = 'REALM_ORGRELATIONS';
  public static readonly REALM_ORGTEMPLATEGROUPS = 'REALM_ORGTEMPLATEGROUPS';
  public static readonly REALM_PEOPLETYPES = 'REALM_PEOPLETYPES';
  public static readonly REALM_PERMISSIONS = 'REALM_PERMISSIONS';
  public static readonly REALM_PROVSTATES = 'REALM_PROVSTATES';
  public static readonly REALM_ROLES = 'REALM_ROLES';
  public static readonly REALM_SHIFTS = 'REALM_SHIFTS';
  public static readonly REALM_SUBMISSIONS = 'REALM_SUBMISSIONS';
  public static readonly REALM_SUBMISSIONBUNDLES = 'REALM_SUBMISSIONBUNDLES';
  public static readonly REALM_SUBMISSIONTRAININGS =
    'REALM_SUBMISSIONTRAININGS';
  public static readonly REALM_TASKS = 'REALM_TASKS';
  public static readonly REALM_TASKTYPES = 'REALM_TASKTYPES';
  public static readonly REALM_OIICS_BODYPARTS = 'REALM_OIICS_BODYPARTS';
  public static readonly REALM_OIICS_NATURES = 'REALM_OIICS_NATURES';
  public static readonly REALM_SUBMISSIONRELATIONS =
    'REALM_SUBMISSIONRELATIONS';
  public static readonly REALM_SUBMISSIONSTATUSES = 'REALM_SUBMISSIONSTATUSES';
  public static readonly REALM_TRAININGTYPES = 'REALM_TRAININGTYPES';
  public static readonly REALM_INCIDENTPROBABILITIES =
    'REALM_INCIDENTPROBABILITIES';
  public static readonly REALM_SEVERITYLEVELS = 'REALM_SEVERITYLEVELS';
  public static readonly REALM_TEMPLATES = 'REALM_TEMPLATES';
  public static readonly REALM_TEMPLATEVERSIONS = 'REALM_TEMPLATEVERSIONS';
  public static readonly REALM_TEMPLATEVERSIONCONVERSIONS =
    'REALM_TEMPLATEVERSIONCONVERSIONS';
  public static readonly REALM_TEMPLATEGROUPS = 'REALM_TEMPLATEGROUPS';
}

export class METADATA_KEYS {
  public static readonly PROJECT_NAME = 'projectName';
  public static readonly TITLE = 'Title';
  public static readonly DUEDATE = 'DueDate';
  public static readonly DUETIME = 'DueTime';
  public static readonly EQUIPMENTID = 'EquipmentId';
  public static readonly WORKERID = 'WorkerId';
  public static readonly DISMISS_REASON = 'DismissReason';
  public static readonly EXPIRY_DATE = 'ExpiryDate';
  public static readonly TRAINING_TYPE = 'TrainingType';
  public static readonly TRAINING_TYPE_ID = 'TrainingTypeId';
  public static readonly PROJECT = 'Project';
  public static readonly POTENTIAL_SEVERITY = 'PotentialSeverity';
  public static readonly INCIDENTDATE = 'IncidentDate';
  public static readonly INCIDENTTIME = 'IncidentTime';
  public static readonly PERSON_INVOLVED = 'PersonInvolved';
  public static readonly ASSIGNED_TO = 'AssignedTo';
}

export class COMMON_TEMPLATES {
  public static readonly TASK = 'Create a Task';
  public static readonly COMPLETE_TASK = 'Task';
  public static readonly TRAINING = 'Add A Training Record / Certification';
}

export class TRAINING_TYPES {
  public static readonly TRAINING = 'training';
  public static readonly CERTIFICATE = 'certificate';
}

//Should match mongo permissions table
export class PERMISSIONS {
  //Supervisor Permission
  //Supervisor can manage reports of people they supervise
  public static readonly SUPERVISOR = 'SUPERVISOR';

  //Project Manager Permission
  //Project Manager can manage reports on the projects they manage
  //aka Safety manager/ Safety Admin
  public static readonly PROJECT_MANAGER = 'PROJECT_MANAGER';

  //Company Profile
  public static readonly COMPANY_PROFILE_UPDATE = 'COMPANY_PROFILE_UPDATE';

  //Document View
  public static readonly DOCUMENT_VIEW = 'DOCUMENT_VIEW'; //Can view all documents
  public static readonly DOCUMENT_EDIT = 'DOCUMENT_EDIT'; //Can delete/edit all documents

  //Permissions for Submission Bundles
  public static readonly BUNDLE_EXTERNAL_SUBMISSION =
    'BUNDLE_EXTERNAL_SUBMISSION';
  public static readonly BUNDLE_VIEW = 'BUNDLE_VIEW';

  //Task Permissions
  public static readonly TASK_VIEW = 'TASK_VIEW';

  //Permissions for report tab
  public static readonly REPORT_CREATE = 'REPORT_CREATE';
  public static readonly REPORT_UPDATE = 'REPORT_UPDATE'; //Can still update reports you create without this permission
  public static readonly REPORT_VIEW = 'REPORT_VIEW'; //Can still view reports you create without this permission
  public static readonly REPORT_ELEVATE = 'REPORT_ELEVATE';
  public static readonly REPORT_DELETE = 'REPORT_DELETE'; //Can still delete draft reports you create without this permission

  //Permissions for people tab
  public static readonly REPORT_PEOPLE_CREATE = 'REPORT_PEOPLE_CREATE';
  public static readonly REPORT_PEOPLE_UPDATE = 'REPORT_PEOPLE_UPDATE';
  public static readonly REPORT_PEOPLE_VIEW = 'REPORT_PEOPLE_VIEW';
  public static readonly REPORT_PEOPLE_DELETE = 'REPORT_PEOPLE_DELETE';

  //Permissions for operation tab
  public static readonly REPORT_OPERATION_CREATE = 'REPORT_OPERATION_CREATE';
  public static readonly REPORT_OPERATION_UPDATE = 'REPORT_OPERATION_UPDATE';
  public static readonly REPORT_OPERATION_VIEW = 'REPORT_OPERATION_VIEW';
  public static readonly REPORT_OPERATION_DELETE = 'REPORT_OPERATION_DELETE';

  //Permissions for equipment tab
  public static readonly REPORT_EQUIPMENT_CREATE = 'REPORT_EQUIPMENT_CREATE';
  public static readonly REPORT_EQUIPMENT_UPDATE = 'REPORT_EQUIPMENT_UPDATE';
  public static readonly REPORT_EQUIPMENT_VIEW = 'REPORT_EQUIPMENT_VIEW';
  public static readonly REPORT_EQUIPMENT_DELETE = 'REPORT_EQUIPMENT_DELETE';

  //Permissions for people list
  public static readonly PEOPLE_CREATE = 'PEOPLE_CREATE';
  public static readonly PEOPLE_UPDATE = 'PEOPLE_UPDATE';
  public static readonly PEOPLE_ACCESS = 'PEOPLE_ACCESS';
  public static readonly PEOPLE_CREATE_TRAINING = 'PEOPLE_CREATE_TRAINING';

  //Permissions for project list
  public static readonly PROJECT_CREATE = 'PROJECT_CREATE';
  public static readonly PROJECT_UPDATE = 'PROJECT_UPDATE';

  //Permissions for list management
  public static readonly LISTMANAGEMENT_UPSERT = 'LISTMANAGEMENT_UPSERT';

  //Permissions for lock and unlock
  public static readonly SUBMISSION_LOCK = 'SUBMISSION_LOCK';
  public static readonly SUBMISSION_UNLOCK = 'SUBMISSION_UNLOCK';
}

export class CATEGORY {
  public static readonly HOME = 'HOME';
  public static readonly REPORTS = 'REPORTS';
  public static readonly PEOPLE = 'PEOPLE';
  public static readonly OPERATIONS = 'OPERATIONS';
  public static readonly EQUIPMENT = 'EQUIPMENT';
}

export class PERSON_STATUSES {
  public static readonly ACTIVE = 'Active';
  public static readonly ACCESS = 'Access';
  public static readonly PENDING = 'Pending';
  public static readonly DISABLED = 'Disabled';
  public static readonly DEACTIVED = 'Deactivated';
}

export class FORM_TYPE {
  public static readonly SUBMISSION = 'Submission';
  public static readonly SUBMISSIONTRAINING = 'SubmissionTraining';
}

export class RELATION_TYPE {
  public static readonly PERSON_INVOLVED = 'PersonInvolved';
  public static readonly USER_INVOLVED = 'UserInvolved';
  public static readonly PROJECT = 'Project';
}

export class REALM_FUNCTION_NAMES {
  public static readonly GET_SYNC_MAX_DHV_BY_ORG = 'getSyncMaxDHVByOrg';
}

//Constant for defaulting values on dynamic forms
export class DEFAULT_SOURCE_KEY {
  //Default company data
  public static readonly COMPANYNAME = 'CompanyName';
  public static readonly TRADENAME = 'CompanyTradeName';
  public static readonly COMPANYOWNER = 'CompanyOwner';
  public static readonly MAILADDRESS = 'MailAddress';
  public static readonly STREETADDRESS = 'StreetAddress';
  public static readonly PRIMARYCONTACT = 'PrimaryContact';
  public static readonly WSBCNUMBER = 'WSBCNumber';
  public static readonly CORNUMBER = 'CORNumber';
  public static readonly SAFENUMBER = 'SAFENumber';
  public static readonly MAINACTIVITIES = 'MainActivities';
  public static readonly WSBCUNITS = 'WSBCUnits';
  public static readonly SAFETYCONTACT = 'SafetyContact';
  public static readonly AUDITCONTACT = 'AuditContact';

  //Used for training submissions
  //Set to current user if they're basic
  public static readonly TRAINEE = 'Trainee';
}

export const TRAINING_STATUSES = [
  'Valid',
  'Expiring Soon',
  'Expired',
  'Never Expires',
];

export class DOCUMENT_LIBRARY {
  public static readonly DEFAULT_STORAGE_SPACE = 500;
}

export class LOCK_STATUS {
  public static readonly LOCK = 'Lock';
  public static readonly UNLOCK = 'Unlock';
  public static readonly SUPERLOCK = 'Superlock';
}
