import { StyleSheet } from 'react-native';
import Colors from './Shared/Colors';

const EditPersonStyles = StyleSheet.create({
  headerText: {
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: '700',
    lineHeight: 37,
    textAlign: 'left',
    color: Colors.darkGreen,
    paddingTop: 20,
    paddingBottom: 10,
  },
  closeFormIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 10,
    paddingTop: 10,
    justifyContent: 'flex-end',
  },
  body: {
    padding: 20,
  },
});

export { EditPersonStyles };
