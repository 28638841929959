import { ILocalStorageService } from './Interfaces/ILocalStorageService';
import { StorageKeyType } from '../Types/StorageTypes';
import { STORAGE_KEYS } from '../Constants/AppConstants';

//Wrapper around the browser localStorage, just in case we use something else in the future it'll be easy to update
class LocalStorageService implements ILocalStorageService {
  constructor() {}

  get(key: StorageKeyType): string | null {
    return localStorage.getItem(key);
  }

  set(key: StorageKeyType, value: string): void {
    localStorage.setItem(key, value);
  }

  clearData(): void {
    localStorage.clear();
  }
}

export default LocalStorageService;
