import { StyleSheet, Dimensions } from "react-native";
import Colors from "./Shared/Colors";

const LoginStyleSheet = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: Colors.darkestGreen,
    paddingVertical:40,
  },
  form: {
    width: 440,
    backgroundColor: Colors.yellow,
    flexDirection: "column",
    borderRadius: 20,
    padding: 20,
  },
  logo: {
    height: 60,
    resizeMode: "contain",
    marginTop: 60,
    marginBottom: 40,
  },
  label: {
    color: Colors.darkGreen,
    fontFamily: "Poppins",
    fontSize: 11,
    fontWeight: "700",
    letterSpacing: 1.87,
    textTransform: "uppercase",
    marginTop: 20,
  },
  input: {
    color: Colors.darkGreen,
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: "400",
    lineHeight: 14,
    letterSpacing: 0.07,
    backgroundColor: Colors.darkGreenTransparent,
    borderRadius: 6,
    padding: 12,
    paddingTop: 8,
    marginTop: 6,
    outlineStyle: "none",
  },
  button: {
    width: "100%",
    height: 48,
    alignSelf: "center",
    marginTop: 24,
  },
  googleButton: {
    marginTop: 12,
    marginBottom: 12,
  },
  googleButtonIcon: {
    width: 24,
    height: 24,
  },
  googleButtonText: {
    color: "#878787",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "700",
  },
  showPasswordButton: {
    position: "absolute",
    top: 8,
    right: 6,
    borderRadius: 40,
    padding: 8,
  },
  linkButton: {
    borderRadius: 34,
    paddingHorizontal: 10,
    paddingVertical: 8,
    marginTop: 6,
  },
  forgotPassword: {
    color: Colors.green,
    fontFamily: "Lato",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: 11,
    letterSpacing: 0.5,
  },
  register: {
    color: Colors.green,
    fontFamily: "Lato",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 14,
    letterSpacing: 0.7,
  },
  resetPassword: {
    color: Colors.darkGreen,
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "700",
    marginTop: 20,
  },
  resetPasswordSuccess: {
    backgroundColor: Colors.teal,
    color: Colors.white,
    borderRadius: 8,
    padding: 12,
  },
  footerText: {
    color: Colors.darkestGreen,
    alignSelf: "center",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: "700",
    marginTop: 40,
  },
});

export { LoginStyleSheet };
