import React from 'react';
import { ScrollView, View, Text, Pressable, Image } from 'react-native';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ProfileStyleSheet } from '../../Styles/ProfileStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Icons } from '../../Constants/Icons';
import Colors from '../../Styles/Shared/Colors';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import moment from 'moment';
import { ProfileDTO, ProfileTrainingRecordDTO } from '../../Types/DtoTypes';
import useQRCode from '../../Hooks/useQRCode';
import Icon from '../Shared/Icon';

type PublicProfileProps = {
  navigation: any;
  profile: ProfileDTO;
};

const PublicProfile = (props: PublicProfileProps): React.ReactElement => {
  const { renderQRCode } = useQRCode();

  function goBack(): void {
    props.navigation.pop();
  }

  const renderExpiryDate = (
    record: ProfileTrainingRecordDTO,
  ): React.ReactElement => {
    let date = record.expiryDate
      ? moment(record.expiryDate).format('MMM DD YYYY')
      : '';

    let backgroundColor = '';
    let color = '';

    switch (record.status) {
      case 'Valid':
      case 'Never Expires':
        backgroundColor = Colors.lightgray;
        color = Colors.darkGreen;
        break;
      case 'Expiring Soon':
        backgroundColor = Colors.yellow;
        color = Colors.darkGreen;
        break;
      case 'Expired':
        backgroundColor = Colors.red;
        color = Colors.white;
        break;
      default:
        break;
    }

    return (
      <View
        style={[
          ReportsStyleSheet.listItemDateContent,
          { backgroundColor: backgroundColor, marginRight: 12 },
        ]}>
        <Text style={[ReportsStyleSheet.listItemDate, { color: color }]}>
          {date ? date : 'Never Expires'}
        </Text>
      </View>
    );
  };

  const renderRecord = (
    record: ProfileTrainingRecordDTO,
    index: number,
  ): React.ReactElement => {
    console.log(JSON.stringify(record));
    return (
      <View key={index} style={[ReportsStyleSheet.listItemContainer]}>
        <Icon
          icon={
            record.type.source === 'training' ? 'training' : 'certification'
          }
          color={Colors.darkestGreen}
          size={24}
        />
        <Text style={ReportsStyleSheet.listItemName} numberOfLines={1}>
          {record.type.name}
        </Text>
        {renderExpiryDate(record)}
      </View>
    );
  };

  return (
    <ScrollView>
      <View style={ProfileStyleSheet.header}>
        <View style={ProfileStyleSheet.headerBar}>
          <Pressable
            hitSlop={40}
            style={({ pressed }) => [
              {
                width: 42,
                height: 42,
                borderRadius: 42,
                padding: 5,
              },
              pressed && {
                backgroundColor: Colors.teal,
              },
            ]}
            onPress={() => goBack()}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                Icons.CHEVRON_LEFT.replaceAll('currentColor', Colors.white),
              )}`}
            />
          </Pressable>
        </View>
        <View style={ProfileStyleSheet.profileContainer}>
          <View style={ProfileStyleSheet.pictureBar}>
            <View style={CommonStyleSheet.profileIcon}>
              <Image
                resizeMode="contain"
                style={{ width: '100%', height: '100%' }}
                source={{ uri: props.profile.photoUri }}
              />
            </View>
            {/* TODO: Add "QR Code" */}
            <View>{renderQRCode(props.profile.ehsId)}</View>
          </View>
          <Text style={ProfileStyleSheet.profileName}>
            {props.profile.fullName}
          </Text>
          <Text style={ProfileStyleSheet.profilePhone}>
            EHS ID: {props.profile.ehsId}
          </Text>
          <Text style={ProfileStyleSheet.profilePhone}>
            Company: {props.profile.companyName}
          </Text>
          {props.profile.jobName && (
            <Text style={ProfileStyleSheet.profilePhone}>
              Job: {props.profile.jobName}
            </Text>
          )}
        </View>
      </View>
      <View style={{ marginHorizontal: 12 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginVertical: 24,
            paddingHorizontal: 8,
          }}>
          <Text style={ControlsStyleSheet.labelLarge}>
            Training And Certificates
          </Text>
        </View>
        <View style={{ marginBottom: 24 }}>
          {props.profile.trainingList.map((training, index) => {
            return renderRecord(training, index);
          })}
        </View>
      </View>
    </ScrollView>
  );
};

export default PublicProfile;
