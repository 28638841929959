import React from 'react';
import { View, Text } from 'react-native';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { LabelProps } from '../../Types/ControlTypes';

const DynamicLabel = (props: LabelProps): React.ReactElement => {
  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text
        style={{
          color: props.config?.color ?? ControlsStyleSheet.label.color,
          fontFamily:
            props.config?.fontFamily ?? ControlsStyleSheet.label.fontFamily,
          fontSize: props.config?.fontSize ?? ControlsStyleSheet.label.fontSize,
          fontWeight:
            props.config?.fontWeight ?? ControlsStyleSheet.label.fontWeight,
          paddingTop:
            props.config?.paddingTop ?? ControlsStyleSheet.label.marginTop,
          paddingBottom:
            props.config?.paddingBottom ??
            ControlsStyleSheet.label.paddingBottom,
          letterSpacing:
            props.config?.letterSpacing ??
            ControlsStyleSheet.label.letterSpacing,
        }}>
        {props.value ?? ''}
      </Text>
    </View>
  );
};

export default DynamicLabel;
