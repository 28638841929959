import React, { useState, useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  FlatList,
  Text,
  Pressable,
  NativeScrollEvent,
} from 'react-native';
import { ObjectId, UUID } from 'bson';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { Navigation } from '../../Constants/Navigation';
import Icon from '../Shared/Icon';
import { DynamicAutocompleteProps } from '../../Types/ControlTypes';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { LabelValue, MongoIdSqlId } from '../../Types/DtoTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { TextInput } from 'react-native';
import { METADATA_KEYS, STORAGE_KEYS } from '../../Constants/AppConstants';
import useDynamicData from '../../Hooks/useDynamicData';
import { useSync } from '../../Providers/SyncProvider';
import { useRelation } from '../../Providers/SubmissionRelationProvider';
import { ILocalStorageService } from '../../Services/Interfaces/ILocalStorageService';
import LocalStorageService from '../../Services/LocalStorageService';

const DynamicAutocomplete = (
  props: DynamicAutocompleteProps,
): React.ReactElement => {
  const defaultItemsToShow = 5;

  const { getData } = useDynamicData();
  const { updateSubmissionRelation } = useRelation();
  const { getPeople, getProjectSites } = useSync();

  const [value, setValue] = useState(props.value ?? '');
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');
  const [position, setPosition] = useState(0);
  const [autofill, setAutofill] = useState(props.autofill);

  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);
  const [showAddButton, setShowAddButton] = useState(false);
  const isLoading = useRef(false);
  const lastOffsetY = useRef(0);

  const [source, setSource] = useState<LabelValue<string>[]>([]);
  const [items, setItems] = useState<LabelValue<string>[]>([]);

  const searchRef = React.createRef<TextInput>();

  const [myKey, setMyKey] = useState('');
  const [navKey, setNavKey] = useState('');

  const storageService: ILocalStorageService = new LocalStorageService();

  useEffect(() => {
    const getActiveSite = async () => {
      // If source is "projects", no props.autofill exists and no value has been selected,
      //set the default project/sites as value
      if (
        props.config?.optionSource! &&
        props.config.optionSource == ListDataTypes.PROJECTS &&
        !props.autofill &&
        !value
      ) {
        let jsonActiveSite = storageService.get(STORAGE_KEYS.ACTIVE_SITE_KEY);
        if (jsonActiveSite) {
          let activeSite = JSON.parse(jsonActiveSite);
          let project = (
            await getProjectSites({ _id: new ObjectId(activeSite.value) }, 1)
          )[0];

          let newObjValue = {
            mongoId: project._id.toHexString(),
            label: project.name,
            SQLServerId: project.SQLServerId,
          };

          setValue(JSON.stringify(newObjValue));
          setSearch(newObjValue.label);
        }
      }
    };

    getActiveSite();
  }, []);

  useEffect(() => {
    if (props.navigation) {
      setMyKey(currentKey());
      setNavKey(currentKey());

      if (navigationRef.current) {
        const unsubscribe = navigationRef.current.addListener('state', () => {
          setNavKey(currentKey());
        });

        return unsubscribe;
      }
    }
  }, []);

  useEffect(() => {
    if (myKey === navKey && value) {
      getItemsFromSource().then(result => {
        try {
          // When we come back from profile screens, try to find the new item added
          // if it doesn't exist, clear the value
          let valObj = JSON.parse(value) as MongoIdSqlId;

          let selectedItem = result.find(
            r =>
              r.value &&
              (JSON.parse(r.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
          );

          if (selectedItem) {
            setValue(selectedItem.value ?? '');
            setSearch(selectedItem?.label ?? '');
            setIsFocused(false);
          } else setValue('');
        } catch (error) {}
      });
    }
  }, [myKey, navKey]);

  useEffect(() => {
    if (props.config?.optionSource)
      getItemsFromSource().then(result => setSource(result));

    if (
      props.navigation &&
      (props.config?.optionSource === ListDataTypes.PEOPLE ||
        props.config?.optionSource == ListDataTypes.PROJECTS)
    )
      setShowAddButton(true);
  }, [props.config?.optionSource]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());

    if (props.updateMetaData) {
      if (
        props.config?.hasMetaData &&
        props.config?.metaDataKey &&
        (props.config!.optionSource == ListDataTypes.EQUIPMENTVEHICLEPROFILES ||
          props.config!.optionSource == ListDataTypes.EQUIPMENTHEAVYPROFILES ||
          props.config!.optionSource == ListDataTypes.EQUIPMENTOTHERPROFILES)
      ) {
        if (value) {
          let mongoId = '';

          try {
            let valObj = JSON.parse(value) as MongoIdSqlId;

            mongoId = valObj.mongoId ?? '';
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }

          props.updateMetaData(props.config.metaDataKey, mongoId);
        } else props.updateMetaData(props.config.metaDataKey, '');
      } else if (props.config?.hasMetaData && props.config?.metaDataKey)
        props.updateMetaData(props.config.metaDataKey, value);

      //If it doesn't have a specific metaDataKey but its source is PROJECTS
      //Update metadata with key "projectName" by default
      if (props.config!.optionSource == ListDataTypes.PROJECTS) {
        if (value && source.length > 0) {
          let projectName = '';

          try {
            let valObj = JSON.parse(value) as MongoIdSqlId;

            projectName =
              source.find(
                x =>
                  x.value &&
                  (JSON.parse(x.value) as MongoIdSqlId).mongoId ===
                    valObj!.mongoId,
              )?.label ?? '';
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }

          props.updateMetaData(METADATA_KEYS.PROJECT_NAME, projectName);
        }
      }
    }

    //Update relation record
    if (value && value !== props.value && props.config!.relationType) {
      let valObj = JSON.parse(value) as MongoIdSqlId;

      updateSubmissionRelation(
        props.submissionId!,
        new ObjectId(valObj.mongoId),
        props.config!.relationType,
      );
    }
  }, [value]);

  useEffect(() => {
    if (!isFocused) return;

    let newItems: LabelValue<string>[] = [];

    newItems = source.filter(s =>
      s.label?.toLowerCase().includes(search.toLowerCase()),
    );
    newItems = sortItems(newItems).slice(0, itemsToShow);

    if (newItems.length === 0)
      newItems.push({ label: 'No Results Found', value: '' });

    isLoading.current = false;
    setItems(newItems);
  }, [search, isFocused, itemsToShow]);

  useEffect(() => {
    if (value && source) {
      let selectedItem = getItemByValue(value);
      if (selectedItem) setSearch(selectedItem.label ?? '');
      else {
        let valObj = JSON.parse(value) as MongoIdSqlId;
        setSearch(valObj.label ?? '');
      }
    }
  }, [source]);

  useEffect(() => {
    if (isFocused === false) valid();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  useEffect(() => {
    if (props.focusedControlId !== props.controlId && isFocused)
      setIsFocused(false);
  }, [props.focusedControlId]);

  useEffect(() => {
    const getAutofillValue = async () => {
      if (autofill && autofill.value) {
        if (
          autofill.condition === 'Supervisor' &&
          props.config?.optionSource === ListDataTypes.PEOPLE
        ) {
          try {
            let valObj = JSON.parse(autofill.value) as MongoIdSqlId;

            let person = (
              await getPeople({ _id: new ObjectId(valObj.mongoId) }, 1)
            )[0];

            let supervisor = (
              await getPeople({ _id: new ObjectId(person.supervisorId) }, 1)
            )[0];

            let newObjValue = {
              mongoId: supervisor._id.toHexString(),
              label: supervisor.firstName + ' ' + supervisor.lastName,
              SQLServerId: supervisor.SQLServerId,
            };

            setValue(JSON.stringify(newObjValue));
            setSearch(newObjValue.label);
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }
        } else if (
          autofill.condition === 'ProjectManager' &&
          props.config?.optionSource === ListDataTypes.PEOPLE
        ) {
          try {
            let valObj = JSON.parse(autofill.value) as MongoIdSqlId;

            let project = (
              await getProjectSites({ _id: new ObjectId(valObj.mongoId) }, 1)
            )[0];

            let projectManager = (
              await getPeople(
                { _id: new ObjectId(project.projectManagerId!) },
                1,
              )
            )[0];

            let newObjValue = {
              mongoId: projectManager._id.toHexString(),
              label: projectManager.firstName + ' ' + projectManager.lastName,
              SQLServerId: projectManager.SQLServerId,
            };

            setValue(JSON.stringify(newObjValue));
            setSearch(newObjValue.label);
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }
        } else if (
          autofill.condition === 'FirstAidAttendant' &&
          props.config?.optionSource === ListDataTypes.PEOPLE
        ) {
          try {
            let valObj = JSON.parse(autofill.value) as MongoIdSqlId;

            let project = (
              await getProjectSites({ _id: new ObjectId(valObj.mongoId) }, 1)
            )[0];

            let safetyResource = (
              await getPeople(
                { _id: new ObjectId(project.safetyResourceId!) },
                1,
              )
            )[0];

            let newObjValue = {
              mongoId: safetyResource._id.toHexString(),
              label: safetyResource.firstName + ' ' + safetyResource.lastName,
              SQLServerId: safetyResource.SQLServerId,
            };

            setValue(JSON.stringify(newObjValue));
            setSearch(newObjValue.label);
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }
        }
      }
    };

    if (!value) getAutofillValue();
  }, [autofill]);

  useEffect(() => {
    if (
      props.autofill &&
      JSON.stringify(props.autofill) !== JSON.stringify(autofill)
    )
      setAutofill(props.autofill);
  }, [props.autofill]);

  async function getItemsFromSource(): Promise<LabelValue<string>[]> {
    let newOptions: LabelValue<string>[] = [];

    newOptions = await getData(
      props.config?.optionSource!,
      props.value,
      false,
      props.config?.incidentType,
    );

    return newOptions;
  }

  function sortItems(itemsToSort: LabelValue<string>[]): LabelValue<string>[] {
    return itemsToSort.sort((a, b) =>
      (a.label?.toLowerCase() ?? '') > (b.label?.toLowerCase() ?? '')
        ? 1
        : (a.label?.toLowerCase() ?? '') < (b.label?.toLowerCase() ?? '')
        ? -1
        : 0,
    );
  }

  function getItemByValue(itemValue: string): LabelValue<string> | undefined {
    let selectedItem: LabelValue<string> | undefined = undefined;

    let valObj = JSON.parse(itemValue) as MongoIdSqlId;
    if (itemValue)
      selectedItem = source.find(
        i =>
          i.value &&
          (JSON.parse(i.value) as MongoIdSqlId).mongoId === valObj.mongoId,
      );

    return selectedItem;
  }

  function focus(): void {
    if (props.disabled) return;

    if (value === '') isLoading.current = true;

    setItemsToShow(defaultItemsToShow);
    setIsFocused(true);

    if (props.onFocus) props.onFocus(position);
  }

  function change(): void {
    setIsFocused(true);

    if (searchRef.current) searchRef.current.focus();
  }

  function clear(): void {
    setValue('');
    setSearch('');

    if (searchRef.current) searchRef.current.focus();
  }

  function addNew(): void {
    if (props.config?.optionSource === ListDataTypes.PEOPLE) {
      let newPersonId = new ObjectId();
      props.navigation.push(Navigation.EDITPERSON, {
        addPersonWithId: newPersonId.toHexString(),
      });

      let val = JSON.stringify({
        mongoId: newPersonId.toHexString(),
        label: '',
        SQLServerId: '',
      });
      setValue(val);
    } else if (props.config?.optionSource == ListDataTypes.PROJECTS) {
      let newProjectId = new ObjectId();
      props.navigation.push(Navigation.EDITPROJECTSITE, {
        addProjectWithId: newProjectId.toHexString(),
      });

      let val = JSON.stringify({
        mongoId: newProjectId.toHexString(),
        label: '',
        SQLServerId: '',
      });
      setValue(val);
    }
  }

  function select(item: LabelValue<string>): void {
    let selectedItem: LabelValue<string> | undefined = undefined;
    if (item.value) {
      let valObj = JSON.parse(item.value) as MongoIdSqlId;
      selectedItem = source.find(
        r =>
          r.value &&
          (JSON.parse(r.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
      );
    }

    setValue(selectedItem?.value ?? '');
    setSearch(selectedItem?.label ?? '');

    setIsFocused(false);
  }

  function onScrollEnd(event: NativeScrollEvent): void {
    let contentHeight = event.contentSize.height;
    let offsetY = event.contentOffset.y;
    let layoutHeight = event.layoutMeasurement.height;

    let isScrollingToBottom =
      !isLoading.current &&
      lastOffsetY.current !== offsetY &&
      offsetY + layoutHeight > contentHeight - 1;

    if (isScrollingToBottom) {
      lastOffsetY.current = offsetY;
      isLoading.current = true;
      setItemsToShow(itemsToShow + defaultItemsToShow);
    }
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if ((props.showError || isFocused !== null) && !props.disabled)
      setError(error);

    return isValid;
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: LabelValue<string>;
    index: number;
  }): React.ReactElement => {
    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => select(item)}
        disabled={item.value === ''}>
        <Text
          style={[
            ControlsStyleSheet.autocompleteListItem,
            index + 1 === items.length && { borderBottomWidth: 0 },
          ]}>
          {item.label}
        </Text>
      </Pressable>
    );
  };

  return (
    <View onLayout={event => setPosition(event.nativeEvent.layout.y)}>
      {props.label ? (
        <Text style={ControlsStyleSheet.label}>
          {props.label}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
      ) : null}
      <View onPointerDown={event => event.stopPropagation()}>
        <View>
          <View style={{ position: 'absolute', left: 10, top: 8 }}>
            <Icon icon={'input-search'} color={Colors.darkGreen} size={24} />
          </View>
          <TextInput
            ref={searchRef}
            showSoftInputOnFocus={false}
            style={[
              ControlsStyleSheet.input,
              { paddingHorizontal: 40 },
              error !== '' && ControlsStyleSheet.inputError,
            ]}
            placeholder={props.config?.placeholder}
            placeholderTextColor={ControlsStyleSheet.placeholder.color}
            value={search}
            onChangeText={newText => setSearch(newText)}
            onFocus={focus}
            editable={!props.disabled}
          />
          {isFocused && showAddButton && (
            <View
              style={[
                ControlsStyleSheet.autocompleteButtonContainer,
                { right: 40 },
              ]}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.autocompleteButton,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                  },
                ]}
                onPress={() => addNew()}>
                <Text style={ControlsStyleSheet.autocompleteButtonText}>
                  Add New
                </Text>
              </Pressable>
            </View>
          )}
          {!props.disabled && (
            <Pressable
              style={({ pressed }) => [
                { position: 'absolute', right: 12, top: 11 },
                { display: search !== '' ? 'flex' : 'none' },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                  borderRadius: 14,
                },
              ]}
              onPress={clear}>
              <Icon icon={'input-clear'} color={Colors.darkGreen} size={18} />
            </Pressable>
          )}
          <Pressable
            style={({ pressed }) => [
              { position: 'absolute', right: 12, top: 11 },
              { display: isFocused && search === '' ? 'flex' : 'none' },
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
                borderRadius: 14,
              },
            ]}
            onPress={() => setIsFocused(false)}>
            <Icon icon={'arrow-up'} color={Colors.darkGreen} size={18} />
          </Pressable>
          {!props.disabled && !isFocused && value !== '' && search !== '' && (
            <View style={ControlsStyleSheet.autocompleteButtonContainer}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.autocompleteButton,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                  },
                ]}
                onPress={() => change()}
                disabled={props.disabled}>
                <Text style={ControlsStyleSheet.autocompleteButtonText}>
                  Change
                </Text>
              </Pressable>
            </View>
          )}
        </View>
        {isFocused && (
          <View style={ControlsStyleSheet.autocompleteList}>
            <ScrollView onScroll={event => onScrollEnd(event.nativeEvent)}>
              <FlatList data={items} renderItem={renderItem} />
              <View>
                <Text
                  style={[
                    ControlsStyleSheet.autocompleteListItem,
                    { color: Colors.gray, borderBottomWidth: 0 },
                  ]}>
                  {isLoading.current ? 'Loading items...' : ''}
                </Text>
              </View>
            </ScrollView>
          </View>
        )}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicAutocomplete;
