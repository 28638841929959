const Colors = {
  white: '#FCFEFE',
  whiteTransparent: '#FCFEFE0D',
  green: '#00645E',
  greenTransparent: '#00645E0D',
  darkGreen: '#38635E',
  darkGreenTransparent: '#2037330D',
  darkerGreen: '#283733',
  darkestGreen: '#203733',
  teal: '#43B0AA',
  lightTeal: '#E3F0EF',
  lightTealPressed: '#C6DEDC',
  yellow: '#E2C482',
  red: '#BF6A58',
  brightRed: '#D92424',
  orange: '#E6AB35',
  redTransparent: '#BF6A580D',
  lightgray: 'lightgray',
  gray: 'gray',
};

export default Colors;
