import React, { useState, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';
import { DynamicCheckBoxProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Icon from '../Shared/Icon';
import Colors from '../../Styles/Shared/Colors';

const DynamicCheckBox = (props: DynamicCheckBoxProps): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => {
    if (props.onChange && props.value !== value)
      props.onChange(props.controlId, props.controlTypeId, value, true);
  }, [value]);

  function onChange(): void {
    let val = !(value === 'true');
    setValue(String(val));
  }

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Pressable
        style={({ pressed }) => [
          ControlsStyleSheet.checkBoxContainer,
          pressed && { opacity: 0.8 },
          props.disabled && { opacity: 0.6 },
        ]}
        disabled={props.disabled ?? false}
        onPress={() => onChange()}>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            value === 'true' && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon
            icon={'checked'}
            color={value === 'true' ? Colors.white : Colors.whiteTransparent}
            size={16}
          />
        </View>
        <Text
          style={[
            ControlsStyleSheet.checkBoxText,
            { color: value === 'true' ? Colors.green : Colors.darkGreen },
          ]}>
          {props.label}
        </Text>
      </Pressable>
    </View>
  );
};

export default DynamicCheckBox;
