import { StyleSheet } from 'react-native';
import Colors from './Shared/Colors';

const HomeStyleSheet = StyleSheet.create({
  headerTitle: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 72,
    fontWeight: '700',
    marginHorizontal: 20,
    marginTop: 'auto',
  },
  offlineContainer: {
    width: '90%',
    backgroundColor: Colors.yellow,
    borderRadius: 24,
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: -22,
    paddingHorizontal: 40,
  },
  offlineContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    paddingVertical: 8,
  },
  offlineIcon: {
    display: 'flex',
    color: Colors.darkGreen,
  },
  offlineText: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: '700',
  },
  tasksList: {
    flex: 2,
    padding: 28,
  },
  tasksTitle: {
    color: Colors.darkestGreen,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 2.04,
    textTransform: 'uppercase',
    alignSelf: 'center',
    marginBottom: 20,
  },
  taskContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.darkestGreen,
  },
  taskNameContainer: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  taskName: {
    color: Colors.darkestGreen,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
  },
  taskDateContent: {
    alignItems: 'center',
    borderRadius: 6,
    paddingHorizontal: 6,
    paddingVertical: 4,
    marginHorizontal: 14,
  },
  taskDate: {
    fontFamily: 'Lato',
    fontSize: 10,
    fontWeight: '900',
  },
  taskTime: {
    fontFamily: 'Lato',
    fontSize: 10,
    fontWeight: '400',
  },
});

export { HomeStyleSheet };
