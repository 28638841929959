import { StyleSheet } from 'react-native';
import Colors from './Shared/Colors';

const QuickAddListSheet = StyleSheet.create({
  quickAddButton: {
    zIndex: 100,
    width: 84,
    height: 84,
    position: 'absolute',
    bottom: 40,
    right: 40,
  },
  quickAddListContainer: {
    width: 600,
    height: '100%',
    backgroundColor: Colors.yellow,
    alignSelf: 'flex-end',
  },
  quickAddListHeading: {
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: '700',
    color: Colors.darkGreen,
    alignSelf: 'center',
    marginVertical: 40,
  },
  quickAddListItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    backgroundColor: Colors.darkGreen,
    borderRadius: 8,
    padding: 20,
    marginHorizontal: 20,
    marginVertical: 12,
  },
  quickAddListText: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: 18,
  },
});

export {QuickAddListSheet};
