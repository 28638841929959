import { StyleSheet } from "react-native";
import Colors from "./Shared/Colors";

const ProjectSitesStyleSheet = StyleSheet.create({
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 10,
    paddingLeft: 5,
    alignSelf: "center",
  },
  searchStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.teal,
    borderWidth: 2,
    padding: 15,
    color: Colors.darkGreen,
    margin: 20,
    borderRadius: 12,
  },
  siteList: {
    padding: 20,
    display: "flex",
    gap: 10,
  },
  siteItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    gap: 10,
  },
  siteItemPress: {
    flexDirection: "row",
    flex: 1,
    flexGrow: 1,
    justifyContent: "space-between",
    padding: 16,
    borderRadius: 12,
    alignItems: "center",
  },
  siteItemText: {
    color: Colors.darkGreen,
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: "700",
    letterSpacing: 2.06,
  },
  header: {
    fontSize: 28,
    fontFamily: "Poppins",
    fontWeight: "700",
    color: Colors.darkGreen,
    padding: 20,
  },
});

export { ProjectSitesStyleSheet };
