import React, { useState, useEffect } from 'react';
import { ObjectId } from 'bson';
import { View, Text, Pressable, Modal, ScrollView } from 'react-native';
import { usePermission } from '../../Providers/PermissionProvider';
import { LOCK_STATUS, PERMISSIONS } from '../../Constants/AppConstants';
import Colors from '../../Styles/Shared/Colors';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import ProgressBar from './ProgressBar';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { Images } from '../../Constants/Images';
import Icon from '../Shared/Icon';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import AddReportToBundle from '../Shared/AddReportToBundle';
import { TemplateTypes } from '../../Constants/TemplateCategoryTypes';
import { useSync } from '../../Providers/SyncProvider';
import DynamicPDF from './DynamicPDF';

type FormHeaderProps = {
  submissionId: string;
  submissionType?: 'submission' | 'submissionTraining';
  submissionStatus?: string;
  formName: string;
  formIcon?: string;
  pageCount: number;
  currentPage: number;
  creator?: string;
  creatorLabel?: string;
  updatedBy?: string;
  updatedLabel?: string;
  onStatusLockChange?: (newSubmissionStatusLock: string) => void; // Optional callback
  pagePressed: (pageNumber: number) => void;
  closePressed: () => void;
  validations: {
    controlId: number;
    Label: string;
    page: number;
    isValid: boolean;
  }[];
  showErrors: { page: number; show: boolean }[];
  showMoreActions: boolean;
  taskStatus?: string;
};

const FormHeader = (props: FormHeaderProps): React.ReactElement => {
  const [isShowingActionsModal, setIsShowingActionsModal] = useState(false);
  const {
    submissions,
    submissionTrainings,
    getSubmissions,
    upsertSubmission,
    existSubmissionInBundle,
  } = useSync();
  const [addToBundlePermissions, setAddToBundlePermissions] = useState(false);
  const [lockPermissions, setLockPermissions] = useState(false);
  const [unLockPermissions, setUnlockPermissions] = useState(false);
  const [isAddingToBundle, setIsAddingToBundle] = useState(false);
  const [isShowingPDFModal, setIsShowingPDFModal] = useState(false);
  const [submission, setSubmission] = useState<Submission | null>(null);
  const [submissionStatusLock, setSubmissionStatusLock] = useState('');
  const [isInBundle, setIsInBundle] = useState(false);
  const { hasPermission } = usePermission();

  useEffect(() => {
    //Get Permissions
    let viewBundle = hasPermission(PERMISSIONS.BUNDLE_VIEW);
    let lockSubmissionPermission = hasPermission(PERMISSIONS.SUBMISSION_LOCK);
    let unLockSubmissionPermission = hasPermission(
      PERMISSIONS.SUBMISSION_UNLOCK,
    );
    setAddToBundlePermissions(viewBundle);
    setLockPermissions(lockSubmissionPermission);
    setUnlockPermissions(unLockSubmissionPermission);

    //GetSubmission
    const getSubm = async () => {
      let sub = (await getSubmissions()).find(x =>
        x._id.equals(props.submissionId),
      );
      if(sub) {
        setSubmission(sub);
        setSubmissionStatusLock(sub.submissionStatusLock ?? '');
        setIsInBundle(await existSubmissionInBundle(sub._id));
      }
    };

    getSubm();
  }, []);

  const addToBundle = () => {
    setIsShowingActionsModal(false);
    setIsAddingToBundle(true);
  };

  const renderAddToBundleAction = () => {
    let addToBundleDisabled = false;
    let isDraft = false;

    if (!addToBundlePermissions) addToBundleDisabled = true;
    else if (
      props.submissionStatus?.toLowerCase() !==
        SubmissionStatuses.SUBMITTED.toLowerCase() &&
      props.submissionStatus?.toLowerCase() !==
        SubmissionStatuses.COMPLETE.toLowerCase()
    ) {
      addToBundleDisabled = true;
      isDraft = true;
    }

    if (props.submissionType == 'submission') {
      let formSubmission = submissions.find(x =>
        x._id.equals(props.submissionId),
      );

      //Don't show add report action button for audit submission
      if (
        !formSubmission ||
        formSubmission.templateType.name == TemplateTypes.AUDIT
      )
        return <></>;
    } else if (props.submissionType == 'submissionTraining') {
      let formSubmission = submissionTrainings.find(x =>
        x._id.equals(props.submissionId),
      );

      //Don't show add report action button for audit submission
      if (
        !formSubmission ||
        formSubmission.templateType.name == TemplateTypes.AUDIT
      )
        return <></>;
    }

    return (
      <View style={{ marginVertical: 0, paddingBottom: 0 }}>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 12, width: '100%' },
            (pressed || addToBundleDisabled) && {
              opacity: 0.8,
            },
          ]}
          onPress={() => addToBundle()}
          disabled={addToBundleDisabled}>
          <Text style={CommonStyleSheet.greenButtonText}>
            Add Report To Bundle
          </Text>
        </Pressable>
        {!addToBundlePermissions && (
          <Text style={ControlsStyleSheet.error}>
            You do not have permissions to add reports to bundles
          </Text>
        )}
        {isDraft && (
          <Text style={ControlsStyleSheet.error}>
            You can only add submitted reports to a bundle
          </Text>
        )}
      </View>
    );
  };

  const closeAddToBundle = async () => {
    setIsAddingToBundle(false);
    saveSubmissionStatusLock();
  };

  const saveSubmissionStatusLock = async () => {
    let realmSubmission = (await getSubmissions()).find(x => x._id.equals(props.submissionId));
    if (realmSubmission) {
      let newSubmissionStatusLock = '';
      if(await existSubmissionInBundle(realmSubmission!._id))
        newSubmissionStatusLock = LOCK_STATUS.SUPERLOCK;
      else{
        if (submissionStatusLock == LOCK_STATUS.LOCK) newSubmissionStatusLock = LOCK_STATUS.UNLOCK;
        else newSubmissionStatusLock = LOCK_STATUS.LOCK;
      }
      
      realmSubmission!.submissionStatusLock = newSubmissionStatusLock;
      await upsertSubmission(realmSubmission);

      setSubmission(realmSubmission);
      setSubmissionStatusLock(newSubmissionStatusLock);
      setIsShowingActionsModal(false);
      if (props.onStatusLockChange) props.onStatusLockChange(newSubmissionStatusLock);
    }
  };

  const renderLockUnlockAction = () => {
    let isDraft = true;
    if (props.submissionType != 'submission') {
      return <></>;
    }

    //Check if it is task to check the status
    if (props.formName == 'Task') {
      isDraft =
        props.taskStatus?.toLowerCase() ==
        SubmissionStatuses.COMPLETE.toLowerCase()
          ? false
          : true;
    } else {
      isDraft =
        props.submissionStatus?.toLowerCase() !==
        SubmissionStatuses.SUBMITTED.toLowerCase()
          ? true
          : false;
    }
    if (isDraft || submissionStatusLock == LOCK_STATUS.SUPERLOCK) return <></>;

    let lockUnlockButtonText = '';
    let isButtonDisabled = false;
    if (submissionStatusLock == LOCK_STATUS.LOCK || submissionStatusLock == LOCK_STATUS.SUPERLOCK) {
      lockUnlockButtonText = LOCK_STATUS.UNLOCK;
      isButtonDisabled = !unLockPermissions;
    }
    else if (submissionStatusLock == LOCK_STATUS.UNLOCK) {
      lockUnlockButtonText = LOCK_STATUS.LOCK;
      isButtonDisabled = !lockPermissions;
    }

    return (
      <View style={{ marginVertical: 0, paddingBottom: 0 }}>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 12, width: '100%' },
            (pressed || isButtonDisabled) && {
              opacity: 0.6,
            },
          ]}
          onPress={() => saveSubmissionStatusLock()}
          disabled={isButtonDisabled}>
          <Text style={CommonStyleSheet.greenButtonText}>
            {lockUnlockButtonText}
          </Text>
        </Pressable>
        {isButtonDisabled && (
          <Text style={ControlsStyleSheet.error}>
            You do not have permissions to lock or unlock reports
          </Text>
        )}
      </View>
    );
  };

  const renderPrintAction = () => {
    return (
      <View>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 12, width: '100%' },
            pressed && {
              opacity: 0.8,
            },
          ]}
          onPress={() => {
            setIsShowingPDFModal(true);
          }}>
          <Text style={CommonStyleSheet.greenButtonText}>Print</Text>
        </Pressable>
      </View>
    );
  };

  const renderPDFModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingPDFModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Download Documents
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsShowingPDFModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 24 }}>
              <View style={ControlsStyleSheet.attachmentsContainer}>
                {isShowingPDFModal && (
                  <DynamicPDF
                    submissionId={props.submissionId}
                    type={props.submissionType ?? 'submission'}></DynamicPDF>
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  const renderActionsModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingActionsModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <Pressable
          style={[
            ControlsStyleSheet.modalBackground,
            {
              //@ts-ignore
              cursor: 'auto',
            },
          ]}
          onPress={() => setIsShowingActionsModal(false)}>
          <View
            style={{
              backgroundColor: Colors.white,
              alignSelf: 'flex-end',
              borderRadius: 20,
              marginTop: 110,
              marginRight: 10,
              paddingHorizontal: 20,
              paddingBottom: 15,
            }}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                More Actions
              </Text>
            </View>
            {renderPrintAction()}
            {renderAddToBundleAction()}
            {renderLockUnlockAction()}
          </View>
        </Pressable>
      </Modal>
    );
  };

  return (
    <View style={DynamicPageStyleSheet.header}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
            gap: 8,
            paddingLeft: 20,
          }}>
          {props.formIcon && (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                props.formIcon,
              )}`}
              style={{
                width: 28,
                height: 28,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
          )}
          <Text style={[DynamicPageStyleSheet.headerText, { padding: 0 }]}>
            {props.formName}
          </Text>
        </View>
        <View style={DynamicPageStyleSheet.closeFormIconContainer}>
          <Pressable
            onPress={props.closePressed}
            style={({ pressed }) => [
              pressed && {
                opacity: 0.4,
              },
            ]}>
            <Icon icon={'close-circle'} color={Colors.white} size={24} />
          </Pressable>
          {props.showMoreActions && (
            <Pressable
              style={({ pressed }) => [
                { marginTop: 24, marginBottom: 30 },
                pressed && {
                  opacity: 0.4,
                },
              ]}
              onPress={() => setIsShowingActionsModal(true)}>
              <Icon icon={'more'} color={Colors.white} size={20} />
            </Pressable>
          )}
        </View>
      </View>
      {submission && submission.submissionStatusLock ? (
        <Text style={[DynamicPageStyleSheet.formInfo, { marginBottom: 4 }]}>
          Status Lock: {submission.submissionStatusLock}
        </Text>
      ) : null}
      {props.creator && props.creatorLabel ? (
        <Text style={DynamicPageStyleSheet.formInfo}>
          {props.creatorLabel + ' ' + props.creator}
        </Text>
      ) : null}
      {props.updatedBy && props.updatedLabel ? (
        <Text style={[DynamicPageStyleSheet.formInfo, { marginTop: 4 }]}>
          {props.updatedLabel + ' ' + props.updatedBy}
        </Text>
      ) : null}
      {props.pageCount > 1 && (
        <ProgressBar
          pageCount={props.pageCount}
          currentPage={props.currentPage}
          pagePressed={props.pagePressed}
          validations={props.validations}
          showErrors={props.showErrors}
        />
      )}
      {renderActionsModal()}
      {props.showMoreActions && (
        <AddReportToBundle
          submissionId={props.submissionId}
          submissionType={props.submissionType}
          formName={props.formName}
          visible={isAddingToBundle}
          onClose={() => closeAddToBundle()}
        />
      )}

      {renderPDFModal()}
    </View>
  );
};

export default FormHeader;
