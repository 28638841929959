import React, { useState, useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  FlatList,
  Text,
  Pressable,
  NativeScrollEvent,
} from 'react-native';
import { ObjectId, UUID } from 'bson';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { Navigation } from '../../Constants/Navigation';
import Icon from '../Shared/Icon';
import {
  DynamicAutocompleteExtendedProps,
  DynamicAutocompleteProps,
} from '../../Types/ControlTypes';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { LabelValue, MongoIdSqlId } from '../../Types/DtoTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { TextInput } from 'react-native';
import { METADATA_KEYS, STORAGE_KEYS } from '../../Constants/AppConstants';
import useDynamicData from '../../Hooks/useDynamicData';
import { useSync } from '../../Providers/SyncProvider';
import { useRelation } from '../../Providers/SubmissionRelationProvider';
import { ILocalStorageService } from '../../Services/Interfaces/ILocalStorageService';
import LocalStorageService from '../../Services/LocalStorageService';
import DynamicAutocomplete from './DynamicAutocomplete';
import { Project } from '../../Models/RealmModels/Projects';
import { Person } from '../../Models/RealmModels/Person';
import DynamicTextBox from './DynamicTextBox';
import useRandom from '../../Hooks/useRandom';

const DynamicAutocompleteExtended = (
  props: DynamicAutocompleteExtendedProps,
): React.ReactElement => {
  const [position, setPosition] = useState(0);
  const [value, setValue] = useState(props.value ?? '');
  const { projects, people, getProvStates, cities, getJobs } = useSync();
  const { randomId } = useRandom();

  const onChange = (
    controlId: number | undefined,
    controlTypeId: number | undefined,
    value: string,
    isValid: boolean,
  ) => {
    if (props.onChange)
      props.onChange(controlId, controlTypeId, value, isValid);

    setValue(value);
  };

  const renderAutoComplete = () => {
    return (
      <DynamicAutocomplete
        submissionId={props.submissionId}
        key={props.controlId}
        controlId={props.controlId!}
        controlTypeId={props.controlTypeId}
        config={props.config}
        navigation={props.navigation}
        label={props.config?.autocompleteLabel}
        value={props.value}
        visible={props.visible}
        disabled={props.disabled}
        onChange={onChange}
        onFocus={target => props.onFocus && props.onFocus(target + position)}
        updateMetaData={props.updateMetaData}
        showError={props.showError}
        autofill={props.autofill}
      />
    );
  };

  //REMINDER: If updating this don't forget to update DynamicPDFSubmission
  const renderTextInfo = () => {
    let textInfo = [];
    type ProjectField = keyof Project;
    type PersonField = keyof Person;

    function isValidProjectField(
      value: string,
    ): asserts value is ProjectField {}

    function isValidPeopleField(value: string): asserts value is PersonField {}

    if (!value) return <></>;

    let valObj = JSON.parse(value) as MongoIdSqlId;

    if (props.config?.optionSource === ListDataTypes.PROJECTS) {
      let project = projects.find(x => x._id.toHexString() === valObj.mongoId);
      if (props.config.dataFields?.length && project) {
        for (let i = 0; i < props.config.dataFields?.length; i++) {
          let fieldName = props.config.dataFields[i];
          isValidProjectField(fieldName.fieldName);
          let fieldValue = project![fieldName.fieldName];
          if (fieldName.fieldName === 'provStateSQLServerId') {
            let provStateName = '';

            if (fieldValue) {
              let selectedProvState = getProvStates().find(
                x => x.SQLServerId == fieldValue,
              );
              if (selectedProvState)
                provStateName = selectedProvState.provState!;
            }

            textInfo.push(
              <DynamicTextBox
                key={randomId()}
                config={{ borderWidth: 0 }}
                label={fieldName.label}
                value={provStateName}
                visible={true}
                disabled={true}
              />,
            );
          } else if (fieldName.fieldName === 'citySQLServerId') {
            let cityName = '';

            if (fieldValue) {
              let selectedCity = cities.find(x => x.SQLServerId == fieldValue);
              if (selectedCity) cityName = selectedCity.cityName!;

              textInfo.push(
                <DynamicTextBox
                  key={randomId()}
                  config={{ borderWidth: 0 }}
                  label={fieldName.label}
                  value={cityName}
                  visible={true}
                  disabled={true}
                />,
              );
            }
          } else if (
            fieldName.fieldName === 'cityName' &&
            typeof fieldValue === 'string'
          ) {
            if (!project.citySQLServerId)
              textInfo.push(
                <DynamicTextBox
                  key={randomId()}
                  config={{ borderWidth: 0 }}
                  label={fieldName.label}
                  value={fieldValue}
                  visible={true}
                  disabled={true}
                />,
              );
          } else if (typeof fieldValue === 'string') {
            textInfo.push(
              <DynamicTextBox
                key={randomId()}
                config={{ borderWidth: 0 }}
                label={fieldName.label}
                value={fieldValue}
                visible={true}
                disabled={true}
              />,
            );
          } else {
            textInfo.push(
              <DynamicTextBox
                key={randomId()}
                config={{ borderWidth: 0 }}
                label={fieldName.label}
                value={''}
                visible={true}
                disabled={true}
              />,
            );
          }
        }
      }
    } else if (
      props.config?.optionSource === ListDataTypes.PEOPLE &&
      props.value
    ) {
      let person = people.find(
        x => x._id.toHexString() === valObj.mongoId?.toString(),
      );

      if (props.config.dataFields?.length && person) {
        for (let i = 0; i < props.config.dataFields?.length; i++) {
          let fieldName = props.config.dataFields[i];
          isValidPeopleField(fieldName.fieldName);
          let fieldValue = person![fieldName.fieldName];
          if (fieldName.fieldName === 'jobSQLServerId') {
            let jobName = '';

            if (fieldValue) {
              let selectedJob = getJobs().find(
                x => x.SQLServerId == fieldValue,
              );
              if (selectedJob) jobName = selectedJob.name!;
            }
            textInfo.push(
              <DynamicTextBox
                key={randomId()}
                config={{ borderWidth: 0 }}
                label={fieldName.label}
                value={jobName}
                visible={true}
                disabled={true}
              />,
            );
          } else if (typeof fieldValue === 'string')
            textInfo.push(
              <DynamicTextBox
                key={randomId()}
                config={{ borderWidth: 0 }}
                label={fieldName.label}
                value={fieldValue}
                visible={true}
                disabled={true}
              />,
            );
          else
            textInfo.push(
              <DynamicTextBox
                key={randomId()}
                config={{ borderWidth: 0 }}
                label={fieldName.label}
                value={''}
                visible={true}
                disabled={true}
              />,
            );
        }
      }
    }
    return textInfo;
  };

  return (
    <View
      onLayout={event => {
        setPosition(event.nativeEvent.layout.y);
      }}>
      {props.label ? (
        <Text style={ControlsStyleSheet.label}>
          {props.label}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
      ) : null}
      <View
        style={{
          paddingHorizontal: 10,
          paddingTop: 10,
          elevation: 5,
          backgroundColor: 'white',
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: 0.2,
          shadowRadius: 10,
          shadowColor: 'black',
        }}>
        {renderAutoComplete()}
        {renderTextInfo()}
      </View>
    </View>
  );
};

export default DynamicAutocompleteExtended;
