/**
 * Hook to get random number
 */

type RandomInfo = {
  randomId(): string;
};
const useRandom = (): RandomInfo => {
  const randomId = () => {
    //36 to hex
    return (Math.random() + 1).toString(36).substring(7);
  };

  return { randomId: randomId };
};

export default useRandom;
