import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, Image, Pressable, Modal } from 'react-native';
import { ObjectId, UUID } from 'bson';
import { useSync } from '../../Providers/SyncProvider';
import { Template } from '../../Models/RealmModels/Template';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { Navigation } from '../../Constants/Navigation';
import { QuickAddListSheet } from '../../Styles/QuickAddListSheet';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Images } from '../../Constants/Images';
import { TemplateVersion } from '../../Models/RealmModels/TemplateVersion';

type QuickAddListProps = {
  navigation: any;
};

const QuickAddList = (props: QuickAddListProps): React.ReactElement => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [versions, setVersions] = useState<TemplateVersion[]>([]);
  const [showModal, setShowModal] = useState(false);

  const {
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissionStatuses,
    getRealmApp,
    upsertSubmission,
  } = useSync();

  useEffect(() => {
    const fetchTemplates = async () => {
      let filteredTemplates = (await getFilteredTemplates()).filter(
        t =>
          t.templateCategory.templateCategoryName ==
          TemplateCategoryTypes.QUICKADD,
      );

      let filteredTemplatesIds = filteredTemplates.map(t =>
        t._id.toHexString(),
      );

      let filteredVersions: TemplateVersion[] = [];
      let allVersions = (await getFilteredTemplateVersions()).filter(v =>
        filteredTemplatesIds.includes(v.templateId.toHexString()),
      );
      filteredTemplates.forEach(t => {
        let templateVersions = allVersions
          .filter(v => v.templateId.equals(t._id))
          .sort(x => x.version)
          .reverse();
        filteredVersions.push(templateVersions[0]);
      });

      setTemplates(filteredTemplates);
      setVersions(filteredVersions);
    };

    fetchTemplates();
  }, []);

  async function openQuickAdd(templateId: string): Promise<void> {
    let template = (await getFilteredTemplates()).find(
      t => t._id.toHexString() === templateId,
    );

    if (template) {
      let version = versions.find(v => v.templateId.equals(templateId));

      if (!version) return;

      let newDraftId: ObjectId = new ObjectId();
      const draftStatus: SubmissionStatus = (
        await getSubmissionStatuses()
      ).find(x => x.name == SubmissionStatuses.DRAFT)!;

      let currentUser = getRealmApp().currentUser;
      let org = '';
      let currentEmail = '';
      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }
        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let newSubmission: Submission = {
        _id: newDraftId,
        partition: org,
        createdBy: currentEmail as string,
        updatedBy: currentEmail as string,
        templateVersion: {
          _id: version._id,
          name: version.name,
        },
        templateType: {
          _id: template!.templateType._id,
          name: template!.templateType.name,
        },
        submissionStatus: {
          _id: draftStatus._id,
          name: draftStatus.name,
        },
        SQLServerId: new UUID().toHexString(),
        answers: [],
        metadataJSON: '',
        createDateTimeStamp: new Date(),
        updateDateTimeStamp: new Date(),
      };

      await upsertSubmission(newSubmission);

      if (newDraftId == null) throw Error('Error: create draft failed!');

      setShowModal(false);

      props.navigation.navigate(Navigation.DYNAMICFORM, {
        submissionId: newDraftId,
      });
    }
  }

  const renderTemplates = (): React.ReactElement => {
    return (
      <React.Fragment>
        {templates.map((template, index) => {
          let version = versions.find(
            v => v && v.templateId && v.templateId.equals(template._id),
          );
          if (!version) return null;
          return (
            <Pressable
              key={index}
              onPress={() => {
                openQuickAdd(template._id.toHexString());
              }}
              style={({ pressed }) => [
                pressed && {
                  opacity: 0.8,
                },
                QuickAddListSheet.quickAddListItem,
              ]}>
              {template.iconSvg !== '' && (
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    template.iconSvg!.replaceAll('currentColor', Colors.white),
                  )}`}
                />
              )}
              <Text style={QuickAddListSheet.quickAddListText}>
                {version.displayName ?? version.name}
              </Text>
            </Pressable>
          );
        })}
      </React.Fragment>
    );
  };

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={QuickAddListSheet.quickAddListContainer}>
            <ScrollView style={{ padding: 20 }}>
              <Text style={QuickAddListSheet.quickAddListHeading}>
                Launch Quick Report
              </Text>
              {renderTemplates()}
            </ScrollView>
          </View>
          <Pressable
            style={({ pressed }) => [
              QuickAddListSheet.quickAddButton,
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() => setShowModal(false)}>
            <Image
              resizeMode="contain"
              style={{ width: '100%', height: '100%' }}
              source={Images.CLOSE}
            />
          </Pressable>
        </View>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {showModal ? null : (
        <Pressable
          style={({ pressed }) => [
            QuickAddListSheet.quickAddButton,
            pressed && {
              opacity: 0.8,
            },
          ]}
          onPress={() => setShowModal(true)}>
          <Image
            resizeMode="contain"
            style={{ width: '100%', height: '100%' }}
            source={Images.FAB}
          />
        </Pressable>
      )}
      {renderModal()}
    </React.Fragment>
  );
};

export default QuickAddList;
