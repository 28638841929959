export class Widgets {
  public static readonly HOME = 'home';
  public static readonly REPORTS = 'reports';
  public static readonly PEOPLE = 'people';
  public static readonly EQUIPMENT = 'equipment';
  public static readonly OPERATIONS = 'operations';
  public static readonly PROJECTSITES = 'projectsites';
  public static readonly EDITPROJECTSITE = 'editprojectsites';
  public static readonly MENU = 'menu';
}

export class Navigation {
  public static readonly MAIN = 'main';
  public static readonly HOME = 'Home';
  public static readonly PROFILE = 'Profile';
  public static readonly DYNAMICFORM = 'Form';
  public static readonly COMPLETIONFORM = 'Task Status';
  public static readonly REPORTS = 'Reports';
  public static readonly PEOPLE = 'People';
  public static readonly EQUIPMENT = 'Equipment';
  public static readonly OPERATIONS = 'Operations';
  public static readonly PROJECTSITES = 'Project - Sites';
  public static readonly EDITPROJECTSITE = 'Project - Site';
  public static readonly PEOPLELIST = 'People List';
  public static readonly TRAININGBREAKDOWN = 'Training Breakdown';
  public static readonly TRAININGFORM = 'Training Form';
  public static readonly EDITPERSON = 'Person';
  public static readonly LOGIN = 'BCFSC FIRS';
  public static readonly EQUIPMENTLOG = 'Equipment log';
  public static readonly HEAVYPROFILE = 'Heavy Equipment';
  public static readonly VEHICLEPROFILE = 'Vehicle';
  public static readonly OTHERPROFILE = 'Other Equipment';
  public static readonly COMPANYPROFILE = 'Company Profile';
  public static readonly DOCUMENTLIBRARY = 'Document Library';
  public static readonly SUBMISSIONBUNDLES = 'Submission Bundles';
  public static readonly EDITBUNDLE = 'Submission Bundle';
  public static readonly BUNDLEFORM = 'Bundle Form';
  public static readonly LISTMANAGEMENT = 'List Management';
  public static readonly EDITLISTMANAGEMENT = 'Edit List';
  public static readonly PUBLICPROFILE = 'publicProfile';
}
