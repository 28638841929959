import React, { useState, useEffect } from 'react';
import { ScrollView, View, Image, Text, Pressable, Modal } from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId, UUID } from 'bson';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';
import { Navigation } from '../../Constants/Navigation';
import SearchBox from '../Shared/SearchBox';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import moment from 'moment';
import Icon from '../Shared/Icon';
import CustomPressable from '../Shared/CustomPressable';
import { DynamicScreenButton } from '../../Types/ControlTypes';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { QuickAddListSheet } from '../../Styles/QuickAddListSheet';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { useIsFocused } from '@react-navigation/native';
import { Icons } from '../../Constants/Icons';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';
import { GeneralDynamicConfig } from '../../Constants/General';

export declare type EquipmentLogProps = {
  navigation: any;
};

type Record = {
  id: ObjectId;
  name: string;
  number: string;
  icon: string;
  status: string;
};

const EquipmentLog = (props: EquipmentLogProps): React.ReactElement => {
  const [vehiclesEquipment, setVehiclesEquipment] = useState<Record[]>([]);
  const [heavyEquipment, setHeavyEquipment] = useState<Record[]>([]);
  const [otherEquipment, setOtherEquipment] = useState<Record[]>([]);
  const [search, setSearch] = useState('');
  const [screenButtons, setScreenButtons] = useState<DynamicScreenButton[]>([]);
  const isFocused = useIsFocused();

  const DISPLAY_INCREMENT = 5;
  const [vehiclesToShow, setVehiclesToShow] = useState(DISPLAY_INCREMENT);
  const [heavyToShow, setHeavyToShow] = useState(DISPLAY_INCREMENT);
  const [otherToShow, setOtherToShow] = useState(DISPLAY_INCREMENT);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  const VEHICLETITLE = 'Vehicles';
  const HEAVYTITLE = 'Heavy Equipment';
  const OTHERTITLE = 'Other Equipment';

  const {
    getOrgId,
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissions,
    getEquipmentsVehicle,
    getEquipmentsHeavy,
    getEquipmentsOther,
    getSubmissionStatuses,
    getRealmApp,
    upsertSubmission,
  } = useSync();

  useEffect(() => {
    const fetchScreenButtons = async () => {
      let filteredTemplates = (await getFilteredTemplates())
        .filter(
          t =>
            t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.EQUIPMENT,
        )
        .sort((a, b) => a.row - b.row);

      let newScreenButtons: DynamicScreenButton[] = [];

      for (let i = 0; i < filteredTemplates.length; i++) {
        let templateId: ObjectId = filteredTemplates[i]._id;
        let versions = (await getFilteredTemplateVersions())
          .filter(x => x.templateId.equals(templateId))
          .sort(x => x.version)
          .reverse();

        if (versions && versions.length > 0) {
          newScreenButtons.push({
            templateName: filteredTemplates[i].name,
            label: filteredTemplates[i].name,
            icon: filteredTemplates[i].iconSvg ?? '',
            version: versions[0].version,
          });
        }
      }

      setScreenButtons(newScreenButtons);
    };

    fetchScreenButtons();
    fetchRecords();
  }, []);

  useEffect(() => {
    if (isFocused) {
      fetchRecords();
    }
  }, [isFocused, showInactive]);

  const fetchRecords = async () => {
    let orgId = getOrgId();

    const vehiclesRecords = (await getEquipmentsVehicle())
      .filter(
        v => v.partition === orgId && (v.status == 'Active' || showInactive),
      )
      .map(v => ({
        id: v._id,
        name: v.name,
        number: v.number,
        icon: Icons.VEHICLE,
        status: v.status,
      }));

    vehiclesRecords.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0,
    );

    setVehiclesEquipment(vehiclesRecords);

    const heavyRecords = (await getEquipmentsHeavy())
      .filter(
        v => v.partition === orgId && (v.status == 'Active' || showInactive),
      )
      .map(v => ({
        id: v._id,
        name: v.name,
        number: v.number,
        icon: Icons.NAV_EQUIPMENT,
        status: v.status,
      }));

    heavyRecords.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0,
    );

    setHeavyEquipment(heavyRecords);

    const otherRecords = (await getEquipmentsOther())
      .filter(
        v => v.partition === orgId && (v.status == 'Active' || showInactive),
      )
      .map(v => ({
        id: v._id,
        name: v.name,
        number: v.number,
        icon: Icons.TABLET,
        status: v.status,
      }));

    otherRecords.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0,
    );

    setOtherEquipment(otherRecords);
  };

  async function openDynamicForm(
    templateName: string,
    showConfirmation?: boolean,
  ): Promise<void> {
    let template = (await getFilteredTemplates()).find(t => t.name === templateName);

    if (template) {
      let templateId = template._id;
      let versions = (await getFilteredTemplateVersions())
        .filter(x => x.templateId.equals(templateId))
        .sort(x => x.version)
        .reverse()
        .map(x => ({ _id: x._id, name: x.name }));

      let newDraftId: ObjectId = new ObjectId();
      const draftStatus: SubmissionStatus = (
        await getSubmissionStatuses()
      ).find(x => x.name == SubmissionStatuses.DRAFT)!;

      let currentUser = getRealmApp().currentUser;
      let org = '';
      let currentEmail = '';
      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }
        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let newSubmission: Submission = {
        _id: newDraftId,
        partition: org,
        createdBy: currentEmail as string,
        updatedBy: currentEmail as string,
        templateVersion: {
          _id: versions[0]._id,
          name: versions[0].name,
        },
        templateType: {
          _id: template!.templateType._id,
          name: template!.templateType.name,
        },
        submissionStatus: {
          _id: draftStatus._id,
          name: draftStatus.name,
        },
        SQLServerId: new UUID().toHexString(),
        answers: [],
        metadataJSON: '',
        createDateTimeStamp: new Date(),
        updateDateTimeStamp: new Date(),
      };

      await upsertSubmission(newSubmission);

      if (newDraftId == null) throw Error('Error: create draft failed!');

      props.navigation.navigate(Navigation.DYNAMICFORM, {
        submissionId: newDraftId,
        showConfirmation: showConfirmation,
        submissionStatus: SubmissionStatuses.DRAFT,
      });
    }
  }

  async function recordPressed(
    type: string,
    recordId?: ObjectId,
  ): Promise<void> {
    setShowAddModal(false);

    let navType = '';
    if (type === HEAVYTITLE) {
      navType = Navigation.HEAVYPROFILE;
    } else if (type === VEHICLETITLE) {
      navType = Navigation.VEHICLEPROFILE;
    } else if (type === OTHERTITLE) {
      navType = Navigation.OTHERPROFILE;
    }

    props.navigation.push(navType, {
      equipmentId: recordId?.toHexString(),
    });
  }

  const goBack = () => {
    props.navigation.pop();
  };

  function showMore(type: string): void {
    if (type === HEAVYTITLE) setHeavyToShow(heavyToShow + DISPLAY_INCREMENT);
    else if (type === VEHICLETITLE)
      setVehiclesToShow(vehiclesToShow + DISPLAY_INCREMENT);
    else if (type === OTHERTITLE)
      setOtherToShow(otherToShow + DISPLAY_INCREMENT);
  }

  const renderRecord = (
    record: Record,
    type: string,
    index: number,
  ): React.ReactElement => {
    let status = '';
    let statusObj = GeneralDynamicConfig.SEGMENT_STATUS.segments.find(
      x => x.value == record.status,
    );
    if (statusObj && statusObj.value != 'Active')
      status = ' - ' + statusObj.name;

    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            { flex: 1 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => recordPressed(type, record.id)}>
          <View style={{ marginHorizontal: 12 }}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                record.icon.replaceAll('currentColor', Colors.darkestGreen),
              )}`}
            />
          </View>
          <Text style={ReportsStyleSheet.listItemName} numberOfLines={2}>
            {record.name} - {record.number}
            {status}
          </Text>
          <View style={{ marginHorizontal: 12 }}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                Icons.LIST_ITEM_NAV.replaceAll(
                  'currentColor',
                  Colors.darkestGreen,
                ),
              )}`}
            />
          </View>
        </Pressable>
      </View>
    );
  };

  const renderCategory = (
    records: Record[],
    title: string,
    recordsToShow: number,
  ): React.ReactElement => {
    let filteredRecords: Record[] = search
      ? records.filter(r =>
          (r.name + ' - ' + r.number)
            .toLowerCase()
            .includes(search.toLowerCase()),
        )
      : records;

    let showMoreVisible = filteredRecords.length > recordsToShow;
    filteredRecords = filteredRecords.slice(0, recordsToShow);

    if (filteredRecords.length === 0) return <></>;

    return (
      <>
        <Text style={[CommonStyleSheet.controlLabel, { marginTop: 24 }]}>
          {title}
        </Text>
        {filteredRecords.map((record, i) => {
          return renderRecord(record, title, i);
        })}
        {showMoreVisible && (
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.smallGreenButton,
              { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() => showMore(title)}>
            <Text style={CommonStyleSheet.smallGreenButtonText}>Show More</Text>
          </Pressable>
        )}
      </>
    );
  };

  const renderRecords = (): React.ReactElement => {
    return (
      <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
        {renderCategory(vehiclesEquipment, VEHICLETITLE, vehiclesToShow)}
        {renderCategory(heavyEquipment, HEAVYTITLE, heavyToShow)}
        {renderCategory(otherEquipment, OTHERTITLE, otherToShow)}
      </View>
    );
  };

  const renderScreenButton = (
    button: DynamicScreenButton,
    index: number,
  ): React.ReactElement => {
    return (
      <React.Fragment key={index}>
        <CustomPressable
          style={({ pressed }) => [
            CommonStyleSheet.screenButton,
            { overflow: 'visible' },
            pressed && { opacity: 0.6 },
          ]}
          onPress={() => openDynamicForm(button.templateName, false)}>
          {button.icon !== '' && (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(button.icon)}`}
              color={Colors.green}
              style={{ color: Colors.green }}
            />
          )}
          <Text style={CommonStyleSheet.screenButtonText}>{button.label}</Text>
        </CustomPressable>
        {index % 2 === 1 && <View style={{ flexBasis: '100%' }}></View>}
      </React.Fragment>
    );
  };

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showAddModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={QuickAddListSheet.quickAddListContainer}>
            <ScrollView style={{ padding: 20 }}>
              <Text style={QuickAddListSheet.quickAddListHeading}>
                Equipment Type
              </Text>
              <React.Fragment>
                <Pressable
                  onPress={() => {
                    recordPressed(VEHICLETITLE);
                  }}
                  style={({ pressed }) => [
                    pressed && {
                      opacity: 0.8,
                    },
                    QuickAddListSheet.quickAddListItem,
                  ]}>
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      Icons.VEHICLE.replaceAll('currentColor', Colors.white),
                    )}`}
                  />
                  <Text style={QuickAddListSheet.quickAddListText}>
                    {VEHICLETITLE}
                  </Text>
                </Pressable>
                <Pressable
                  onPress={() => {
                    recordPressed(HEAVYTITLE);
                  }}
                  style={({ pressed }) => [
                    pressed && {
                      opacity: 0.8,
                    },
                    QuickAddListSheet.quickAddListItem,
                  ]}>
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      Icons.NAV_EQUIPMENT.replaceAll(
                        'currentColor',
                        Colors.white,
                      ),
                    )}`}
                  />
                  <Text style={QuickAddListSheet.quickAddListText}>
                    {HEAVYTITLE}
                  </Text>
                </Pressable>
                <Pressable
                  onPress={() => {
                    recordPressed(OTHERTITLE);
                  }}
                  style={({ pressed }) => [
                    pressed && {
                      opacity: 0.8,
                    },
                    QuickAddListSheet.quickAddListItem,
                  ]}>
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      Icons.TABLET.replaceAll('currentColor', Colors.white),
                    )}`}
                  />
                  <Text style={QuickAddListSheet.quickAddListText}>
                    {OTHERTITLE}
                  </Text>
                </Pressable>
              </React.Fragment>
            </ScrollView>
          </View>
          <Pressable
            style={({ pressed }) => [
              QuickAddListSheet.quickAddButton,
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() => setShowAddModal(false)}>
            <Image
              resizeMode="contain"
              style={{ width: '100%', height: '100%' }}
              source={Images.CLOSE}
            />
          </Pressable>
        </View>
      </Modal>
    );
  };

  return (
    <ScrollView>
      <View style={{ height: 260, backgroundColor: Colors.darkestGreen }}>
        <Pressable
          hitSlop={40}
          style={({ pressed }) => [
            {
              width: 42,
              height: 42,
              borderRadius: 42,
              padding: 5,
            },
            pressed && {
              backgroundColor: Colors.teal,
            },
          ]}
          onPress={() => goBack()}>
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              Icons.CHEVRON_LEFT.replaceAll('currentColor', Colors.white),
            )}`}
          />
        </Pressable>
        <Text style={ReportsStyleSheet.headerTitle}>Equipment Log</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          <View style={{ marginTop: -58, marginLeft: 'auto', marginRight: 28 }}>
            <CustomPressable
              style={({ pressed }) => [
                {
                  backgroundColor: Colors.green,
                  width: 58,
                  height: 58,
                  borderRadius: 58,
                },
                pressed && {
                  backgroundColor: Colors.teal,
                },
              ]}
              onPress={() => setShowAddModal(true)}
              tooltip="Add New">
              <View style={{ marginTop: -1, marginLeft: -1 }}>
                <Icon icon={'add'} color={Colors.white} size={60} />
              </View>
            </CustomPressable>
          </View>
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          <View
            style={{
              alignSelf: 'flex-end',
              marginHorizontal: 20,
              marginTop: 6,
            }}>
            <DynamicCheckBox
              label="Show Inactive"
              value={showInactive ? 'true' : 'false'}
              onChange={(controlId, controlTypeId, value) =>
                setShowInactive(value === 'true')
              }
            />
          </View>
          {renderRecords()}
        </View>
        <View style={{ flex: 1 }}>
          <View style={CommonStyleSheet.screenButtonsContainer}>
            {screenButtons.map((screenButton, index) => {
              return renderScreenButton(screenButton, index);
            })}
          </View>
          {screenButtons.length > 0 && (
            <View style={CommonStyleSheet.screenMessageContainer}>
              <Text style={CommonStyleSheet.screenMessageText}>
                Don't forget to add new vehicles, and update vehicles that may
                not be in service.
              </Text>
            </View>
          )}
        </View>
      </View>
      {renderModal()}
    </ScrollView>
  );
};

export default EquipmentLog;
