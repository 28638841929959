import React, { useState, useEffect, useRef } from 'react';
import { ObjectId, UUID } from 'bson';
import {
  ScrollView,
  View,
  Image,
  ImageBackground,
  Text,
  Pressable,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';
import { useSync } from '../../Providers/SyncProvider';
import SearchBox from '../Shared/SearchBox';
import Icon from '../Shared/Icon';
import ConfirmModal from '../Shared/ConfirmModal';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { ProjectSitesStyleSheet } from '../../Styles/ProjectSiteStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { Navigation } from '../../Constants/Navigation';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { SubmissionBundle } from '../../Models/RealmModels/SubmissionBundle';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { PERMISSIONS } from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import { Formats } from '../../Constants/Formats';

type SubmissionBundlesProps = { navigation: any };

export type SubmissionBundleRecord = {
  id: ObjectId;
  name: string;
  status: string;
  user: string;
  org: string;
  internalRecipients: string;
  externalOrg: string;
  externalRecipients: string;
  datetime: Date;
  category: 'Submitted By My Org' | 'Submitted To My Org';
};

const SubmissionBundles = (
  props: SubmissionBundlesProps,
): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState<SubmissionBundleRecord[]>([]);

  const DISPLAY_INCREMENT = 5;
  const [recordsToShow, setRecordsToShow] = useState([
    { category: 'Submitted By My Org', recordsToShow: DISPLAY_INCREMENT },
    { category: 'Submitted To My Org', recordsToShow: DISPLAY_INCREMENT },
  ]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [toDelete, setToDelete] = useState<ObjectId | undefined>(undefined);
  const { hasPermission } = usePermission();
  const [canViewAll, setCanViewAll] = useState<boolean>(false);

  const isFocused = useIsFocused();
  const {
    getSubmissionBundles,
    upsertSubmissionBundle,
    deleteSubmissionBundle,
    getOrganisations,
    getOrgId,
    getRealmApp,
  } = useSync();

  const deleteModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    let viewBundle = hasPermission(PERMISSIONS.BUNDLE_VIEW);
    setCanViewAll(viewBundle);
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [canViewAll]);

  useEffect(() => {
    if (isFocused) {
      fetchRecords();
    }
  }, [isFocused]);

  const fetchRecords = async () => {
    let user = getRealmApp().currentUser;
    if (!user) return;

    let userEmail = user.customData['email'] as string;
    let orgId = getOrgId();

    let filteredRecords = (await getSubmissionBundles()).filter(
      s =>
        (s.partition === orgId || s.externalOrgId === orgId) &&
        (s.partition === orgId || s.externalOrgId === orgId) &&
        (canViewAll ||
          s.createdBy === userEmail ||
          (s.internalRecipients &&
            s.internalRecipients?.indexOf(userEmail) !== -1) ||
          (s.externalRecipients &&
            s.externalRecipients?.indexOf(userEmail) !== -1)),
    );

    let orgs = filteredRecords.map(s => s.externalOrgId);
    orgs = orgs.concat(filteredRecords.map(s => s.partition));

    let objOrgs = (await getOrganisations()).filter(o =>
      orgs.includes(o.SQLServerId),
    );

    let mappedRecords: SubmissionBundleRecord[] = [];
    filteredRecords.forEach(s => {
      let org = objOrgs.find(e => e.SQLServerId === s.partition);
      let externalOrg = objOrgs.find(e => e.SQLServerId === s.externalOrgId);

      mappedRecords.push({
        id: s._id,
        name: s.name ?? '',
        status: s.status ?? '',
        user: s.createdBy ?? '',
        org: org?.name ?? '',
        internalRecipients: s.internalRecipients
          ? s.internalRecipients.join(', ')
          : '',
        externalOrg: externalOrg?.name ?? '',
        externalRecipients: s.externalRecipients
          ? s.externalRecipients.join(', ')
          : '',
        datetime: s.submitDateTimeStamp ?? s.createDateTimeStamp!,
        category:
          s.partition === orgId ? 'Submitted By My Org' : 'Submitted To My Org',
      });
    });

    mappedRecords.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
    setRecords(mappedRecords);

    setRecordsToShow([
      {
        category: 'Submitted By My Org - Draft',
        recordsToShow: DISPLAY_INCREMENT,
      },
      {
        category: 'Submitted By My Org - Submitted',
        recordsToShow: DISPLAY_INCREMENT,
      },
      {
        category: 'Submitted To My Org - Submitted',
        recordsToShow: DISPLAY_INCREMENT,
      },
    ]);
    setExpandedItems([]);
  };

  const goToSubmissionBundle = async (id: ObjectId | null) => {
    let bundleId = '';
    if (id) bundleId = id.toHexString();
    else {
      let currentUser = getRealmApp().currentUser;

      let org = '';
      let currentEmail = '';
      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }
        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let newSubmissionId: ObjectId = new ObjectId();
      let newSubmission: SubmissionBundle = {
        _id: newSubmissionId,
        partition: org,
        status: SubmissionStatuses.DRAFT,
        submissions: [],
        createdBy: currentEmail as string,
        createDateTimeStamp: new Date(),
        SQLServerId: new UUID().toHexString(),
      };
      await upsertSubmissionBundle(newSubmission);

      bundleId = newSubmission._id.toHexString();
    }

    props.navigation.push(Navigation.EDITBUNDLE, {
      bundleId: bundleId,
    });
  };

  const confirmDelete = (id: ObjectId) => {
    setToDelete(id);
    deleteModalCommand.current.openModal();
  };

  const deleteBundle = async () => {
    if (toDelete) {
      await deleteSubmissionBundle(new ObjectId(toDelete));

      let newRecords = [...records.filter(x => x.id != toDelete)];
      setRecords(newRecords);
    }
  };

  function expand(id: string): void {
    let newExpandedItems = [...expandedItems];

    newExpandedItems.push(id);

    setExpandedItems(newExpandedItems);
  }

  function collapse(id: string): void {
    let newExpandedItems = [...expandedItems];

    let arrayIndex = newExpandedItems.indexOf(id);
    if (arrayIndex !== -1) {
      newExpandedItems.splice(arrayIndex, 1);
      setExpandedItems(newExpandedItems);
    }
  }

  const incrementRecordsToShow = (category: string) => {
    let newRecordsToShow = [...recordsToShow];

    let recordsToShowByCategory = newRecordsToShow.filter(
      s => s.category === category,
    );
    if (recordsToShowByCategory.length > 0)
      recordsToShowByCategory[0].recordsToShow += DISPLAY_INCREMENT;

    setRecordsToShow(newRecordsToShow);
  };

  const renderCategory = (category: string) => {
    let filteredRecords = records.filter(
      s =>
        s.category === category &&
        s.name.toLowerCase().includes(search.toLowerCase()),
    );

    if (filteredRecords.length === 0) return <></>;

    return (
      <View style={{ gap: 16, padding: 16 }}>
        <Text style={CommonStyleSheet.controlLabel}>{category}</Text>
        {category === 'Submitted By My Org' &&
          renderRecordsByStatus(category, SubmissionStatuses.DRAFT)}
        {renderRecordsByStatus(category, SubmissionStatuses.SUBMITTED)}
      </View>
    );
  };

  const renderRecordsByStatus = (category: string, status: string) => {
    let filteredRecords = records.filter(
      s =>
        s.category === category &&
        s.status === status &&
        s.name.toLowerCase().includes(search.toLowerCase()),
    );

    let itemsToShow = DISPLAY_INCREMENT;
    let recordsToShowByCategory = recordsToShow.filter(
      s => s.category === category + ' - ' + status,
    );
    if (recordsToShowByCategory.length > 0)
      itemsToShow = recordsToShowByCategory[0].recordsToShow;

    let visibleRecords = filteredRecords.slice(0, itemsToShow);
    if (visibleRecords.length === 0) return <></>;

    return (
      <>
        <Text style={ReportsStyleSheet.listTitle}>{status}</Text>
        {visibleRecords.map((record, i) => {
          return renderRecord(record, i);
        })}
        {filteredRecords.length > visibleRecords.length && (
          <Pressable
            key={visibleRecords.length}
            style={({ pressed }) => [
              {
                flexDirection: 'column',
                backgroundColor: Colors.darkGreen,
                borderRadius: 12,
                padding: 12,
                alignItems: 'center',
              },
              pressed && {
                backgroundColor: Colors.green,
              },
            ]}
            onPress={() => incrementRecordsToShow(category + ' - ' + status)}>
            <Text
              style={[
                ProjectSitesStyleSheet.siteItemText,
                { flex: 1, color: Colors.white },
              ]}>
              Show More
            </Text>
          </Pressable>
        )}
      </>
    );
  };

  const renderRecord = (
    record: SubmissionBundleRecord,
    index: number,
  ): React.ReactElement => {
    let date = moment(record.datetime).format(Formats.FRONTEND_DATE);
    let time = moment(record.datetime).format(Formats.FRONTEND_TIME_ZONE);

    return (
      <View
        key={index}
        style={{
          flexDirection: 'column',
          backgroundColor: Colors.darkGreen,
          borderRadius: 12,
          padding: 12,
        }}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginRight: 12 }}>
            <Icon
              icon={'folder'}
              color={
                record.status === SubmissionStatuses.SUBMITTED
                  ? Colors.teal
                  : Colors.white
              }
              size={24}
            />
          </View>
          <Text
            style={[
              ProjectSitesStyleSheet.siteItemText,
              { flex: 1, color: Colors.white },
            ]}
            numberOfLines={1}>
            {record.name}
          </Text>
          {!expandedItems.includes(record.id.toHexString()) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                pressed && {
                  backgroundColor: Colors.whiteTransparent,
                },
              ]}
              onPress={() => expand(record.id.toHexString())}>
              <Icon icon={'expand'} color={Colors.white} size={20} />
            </Pressable>
          )}
          {expandedItems.includes(record.id.toHexString()) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                pressed && {
                  backgroundColor: Colors.whiteTransparent,
                },
              ]}
              onPress={() => collapse(record.id.toHexString())}>
              <Icon icon={'collapse'} color={Colors.white} size={20} />
            </Pressable>
          )}
        </View>
        {expandedItems.includes(record.id.toHexString()) ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderTopWidth: 1,
              borderTopColor: Colors.white,
              marginTop: 6,
              paddingTop: 6,
            }}>
            {record.category === 'Submitted By My Org' ? (
              <>
                <View style={{ flex: 1 }}>
                  {record.internalRecipients ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      Internal Recipient(s): {record.internalRecipients}
                    </Text>
                  ) : null}
                  {record.externalOrg ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      External Org: {record.externalOrg}
                    </Text>
                  ) : null}
                  {record.externalRecipients ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      External Recipient(s): {record.externalRecipients}
                    </Text>
                  ) : null}
                  {record.status === SubmissionStatuses.SUBMITTED ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      Submitted Date: {date} {time}
                    </Text>
                  ) : null}
                </View>
                {record.status === SubmissionStatuses.DRAFT ? (
                  <Pressable
                    style={({ pressed }) => [
                      {
                        width: 24,
                        height: 24,
                        borderRadius: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 2,
                        marginRight: 12,
                      },
                      pressed && {
                        backgroundColor: Colors.whiteTransparent,
                      },
                    ]}
                    onPress={() => confirmDelete(record.id)}>
                    <Icon icon={'trash'} color={Colors.white} size={20} />
                  </Pressable>
                ) : null}
                <Pressable
                  style={({ pressed }) => [
                    {
                      width: 24,
                      height: 24,
                      borderRadius: 24,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 2,
                    },
                    pressed && {
                      backgroundColor: Colors.whiteTransparent,
                    },
                  ]}
                  onPress={() => goToSubmissionBundle(record.id)}>
                  <Icon
                    icon={
                      record.status === SubmissionStatuses.SUBMITTED
                        ? 'eye'
                        : 'pencil'
                    }
                    color={Colors.white}
                    size={20}
                  />
                </Pressable>
              </>
            ) : (
              <>
                <View style={{ flex: 1 }}>
                  {record.externalOrg ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      Submitted From: {record.org}
                    </Text>
                  ) : null}
                  {record.externalRecipients ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      Submitted By: {record.user}
                    </Text>
                  ) : null}
                  {record.status === SubmissionStatuses.SUBMITTED ? (
                    <Text
                      style={[
                        ProjectSitesStyleSheet.siteItemText,
                        {
                          fontSize: 14,
                          fontWeight: 'normal',
                          color: Colors.white,
                          marginVertical: 6,
                        },
                      ]}
                      numberOfLines={1}>
                      Submitted Date: {date} {time}
                    </Text>
                  ) : null}
                </View>
                <Pressable
                  style={({ pressed }) => [
                    {
                      width: 24,
                      height: 24,
                      borderRadius: 24,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 2,
                    },
                    pressed && {
                      backgroundColor: Colors.whiteTransparent,
                    },
                  ]}
                  onPress={() => goToSubmissionBundle(record.id)}>
                  <Icon icon={'eye'} color={Colors.white} size={20} />
                </Pressable>
              </>
            )}
          </View>
        ) : null}
      </View>
    );
  };

  return (
    <ScrollView>
      <View>
        <ImageBackground
          source={Images.PEOPLE}
          resizeMode="cover"
          style={{ height: 260 }}>
          <Text style={ReportsStyleSheet.headerTitle}>Submissions</Text>
        </ImageBackground>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          {renderCategory('Submitted By My Org')}
          {renderCategory('Submitted To My Org')}
        </View>
        <View style={{ flex: 1 }}>
          <View style={CommonStyleSheet.screenButtonsContainer}>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.screenButton,
                pressed && { opacity: 0.6 },
              ]}
              onPress={() => goToSubmissionBundle(null)}>
              <Icon icon={'folder'} color={Colors.green} size={24} />
              <Text style={CommonStyleSheet.screenButtonText}>
                Create A Submission
              </Text>
            </Pressable>
          </View>
          <View style={CommonStyleSheet.bcsfFooter}>
            <Image
              source={Images.BCSF}
              style={CommonStyleSheet.bcsfFooterImage}
            />
            <Text style={CommonStyleSheet.bcsfFooterText}>
              BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
            </Text>
          </View>
        </View>
      </View>
      <ConfirmModal
        title="Delete this record"
        message="Are you sure you want to delete this record?"
        cancelClick={() => {}}
        cancelText="No"
        okClick={() => deleteBundle()}
        okText="Delete"
        setOpenModal={(func: () => void) =>
          deleteModalCommand.current.setOpenModal(func)
        }
      />
    </ScrollView>
  );
};

export default SubmissionBundles;
