import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TextStyle } from 'react-native';
import { DynamicReportDescriptionProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';

const DynamicReportDescription = (
  props: DynamicReportDescriptionProps,
): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '');
  const [goodCharThreshold] = useState(props.config?.goodCharThreshold ?? 400);
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  // "UserHadInteraction": State used to know when the user goes out of the control,
  // the "Poor Description" should only appear after the first lost focus
  const [userHadInteraction, setUserHadInteraction] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (isFocused === false) valid();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function focus(): void {
    setIsFocused(true);

    if (props.onFocus) props.onFocus(position);
  }

  function blur(): void {
    if (!userHadInteraction) setUserHadInteraction(true);

    setIsFocused(false);
  }

  function getDescStyle(): TextStyle | null {
    //Good description should appear immediatly after goodCharThreshold is reached
    if (value.length >= goodCharThreshold)
      return ControlsStyleSheet.reportDescriptionGood;

    //Poor description should appear only after the first lost focus or because a parent validation
    if (userHadInteraction || props.showError)
      return ControlsStyleSheet.reportDescriptionPoor;

    return null;
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);

    return isValid;
  }

  return (
    <View
      style={{ display: props.visible === false ? 'none' : 'flex' }}
      onLayout={event => setPosition(event.nativeEvent.layout.y)}>
      {props.label ? (
        <Text style={ControlsStyleSheet.label}>
          {props.label}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
      ) : null}
      <TextInput
        multiline={true}
        style={[
          ControlsStyleSheet.input,
          ControlsStyleSheet.reportDescription,
          isFocused && ControlsStyleSheet.inputFocused,
          getDescStyle(),
        ]}
        placeholder={props.config?.placeholder}
        placeholderTextColor={ControlsStyleSheet.placeholder.color}
        value={value}
        onChangeText={newText => setValue(newText)}
        onFocus={() => focus()}
        onBlur={() => blur()}
        editable={!props.disabled}
      />
      {getDescStyle() !== null ? (
        <View
          style={[
            ControlsStyleSheet.reportDescriptionFrame,
            {
              backgroundColor:
                value.length < goodCharThreshold ? Colors.red : Colors.green,
            },
          ]}>
          <Text style={{ color: Colors.white }}>
            {value.length < goodCharThreshold
              ? 'Poor Description'
              : 'Good Description'}
          </Text>
        </View>
      ) : null}
      <Text style={[ControlsStyleSheet.error, { alignSelf: 'center' }]}>
        {error}
      </Text>
    </View>
  );
};

export default DynamicReportDescription;
