import { createNavigationContainerRef } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef<any>();

export function navigate(name: string, params: any) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export function currentRoute() {
  if (navigationRef.isReady()) return navigationRef!.getCurrentRoute()!.name;

  return '';
}

export function currentKey() {
  if (navigationRef.isReady()) return navigationRef!.getCurrentRoute()!.key;

  return '';
}
