import { StyleSheet } from 'react-native';
import Colors from './Shared/Colors';

const ProfileStyleSheet = StyleSheet.create({
  header: {
    backgroundColor: Colors.darkGreen,
    paddingTop: 20,
  },
  headerBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  headerBarIcon: {
    display: 'flex',
    color: Colors.white,
    padding: 14,
  },
  headerBarText: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  profileContainer: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  pictureBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
  },
  profileName: {
    color: Colors.white,
    fontFamily: 'Poppins',
    fontSize: 28,
    fontWeight: '700',
  },
  profileEmail: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'lowercase',
  },
  profilePhone: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: '700',
  },
  profileOrganisation: {
    color: Colors.white,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '400',
    textTransform: 'capitalize',
  },
});

export { ProfileStyleSheet };
