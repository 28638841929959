import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import FormHeader from './FormHeader';
import DynamicPage from './DynamicPage';
import PageControlFooter from './PageControlFooter';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { ObjectId } from 'bson';
import {
  TemplateVersion,
  TemplateVersion_conditions,
} from '../../Models/RealmModels/TemplateVersion';
import { SubmissionBundle_submissions } from '../../Models/RealmModels/SubmissionBundle';
import { SubmissionAnswerDTO } from '../../Types/DtoTypes';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import Colors from '../../Styles/Shared/Colors';

type BundleFormProps = {
  navigation: any;
  bundleId: ObjectId | null;
  submissionId: ObjectId | null;
  submissionType?: 'submission' | 'submissionTraining';
  headerTitle?: string;
  externalView?: boolean;
};

const BundleForm = (props: BundleFormProps): React.ReactElement => {
  //Leave dynamic form if props aren't populated
  if (!props.bundleId || !props.submissionId) {
    console.error('Invalid dynamic form props');
    props.navigation.pop();
  }

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setPage] = useState(0);
  const [form, setForm] = useState<TemplateVersion | null>(null);
  const [submission, setSubmission] =
    useState<SubmissionBundle_submissions | null>(null);
  const [answers, setAnswers] = useState<SubmissionAnswerDTO[]>([]);
  const { getTemplates, getSubmissionBundles, getTemplateVersions } = useSync();

  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState('');

  useEffect(() => {
    const getForm = async () => {
      let bundle = (await getSubmissionBundles()).find(b =>
        b._id.equals(props.bundleId!),
      );

      if (bundle && bundle.submissions) {
        let sub = bundle.submissions.find(
          s => s.submissionId && s.submissionId.equals(props.submissionId!),
        );
        if (sub) {
          let formVersion = (await getTemplateVersions()).find(f =>
            f._id.equals(sub?.templateVersion!._id!),
          )!;

          if (formVersion) {
            let template = (await getTemplates()).find(x =>
              x._id.equals(formVersion.templateId),
            )!;
            if (template && template.iconSvg)
              setIcon(
                template.iconSvg
                  // First: For dark mode icons (Eg. Site Inspections in home) replace white with darkestGreen
                  .replaceAll(Colors.white, Colors.darkestGreen)
                  //Second: For all other icons (Eg. Site Inspections in operations) replace green/currentColor with white
                  .replaceAll(Colors.green, Colors.white)
                  .replaceAll('currentColor', Colors.white),
              );

            //Store answers so its not querying realm every time
            let answers: SubmissionAnswerDTO[] = sub.answers.map(a => ({
              controlId: a.controlId,
              answer: a.answer,
            }));

            setForm(formVersion);
            setSubmission(sub);
            setAnswers(answers);
            setTitle(sub.templateVersion?.name ?? '');
          }
        }
      }
    };

    getForm();
  }, [props.submissionId]);

  const goToPage = (pageNumber: number) => {
    if (
      pageNumber != currentPage &&
      pageNumber >= 0 &&
      pageNumber < form?.pages.length!
    )
      setPage(pageNumber);
  };

  const getPreviousPage = () => {
    if (currentPage > 0) setPage(currentPage - 1);
  };

  const getNextPage = () => {
    if (currentPage != form?.pages.length! - 1) setPage(currentPage + 1);
  };

  const onClose = async () => {
    props.navigation.pop();
  };

  // If form is not loaded yet show a Loading Spinner
  if (!form || !submission) {
    console.log('formnotloaded');
    return <LoadingSpinner message="Loading" visible={true} />;
  }

  let conditions: TemplateVersion_conditions[] = form.conditions ?? [];
  let page = form.pages[currentPage];
  if (!page.controls || page.controls.length == 0) {
    console.error('Page missing controls');
    props.navigation.pop();
  }

  return (
    <View style={DynamicPageStyleSheet.bodyContainer}>
      <FormHeader
        submissionId={submission?.submissionId?.toHexString() ?? ''}
        submissionType={props.submissionType}
        submissionStatus={
          props.submissionType === 'submission'
            ? SubmissionStatuses.SUBMITTED
            : SubmissionStatuses.COMPLETE
        }
        formName={props.headerTitle ?? form.displayName ?? form.name}
        formIcon={icon}
        pageCount={form.pages.length}
        currentPage={currentPage}
        pagePressed={goToPage}
        closePressed={onClose}
        validations={[]}
        showErrors={form.pages.map((p, i) => ({ page: i, show: false }))}
        showMoreActions={props.externalView ? !props.externalView : true}
      />
      <LoadingSpinner message="Loading" visible={isLoading} />
      <DynamicPage
        navigation={props.navigation}
        submissionId={submission!.submissionId!}
        submissionSQLServerId={submission!.submissionSQLServerId ?? ''}
        submitForm={() => {}}
        answers={answers}
        page={page}
        pageNumber={currentPage}
        conditions={conditions}
        disabled={true}
        setIsLoading={(loading: boolean) => {
          setIsLoading(loading);
        }}
        validations={[]}
        showErrors={false}
        externalView={props.externalView}
      />
      <PageControlFooter
        backOnPress={getPreviousPage}
        nextOnPress={getNextPage}
        currentPage={currentPage}
        maxPages={form.pages.length}
      />
    </View>
  );
};

export default BundleForm;
