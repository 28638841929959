import React, { useState, useEffect } from 'react';
import { ObjectId } from 'bson';
import {
  ScrollView,
  View,
  ImageBackground,
  TextInput,
  Text,
  Pressable,
  Image,
} from 'react-native';
import moment from 'moment';
import Icon from '../Shared/Icon';
import CustomPressable from '../Shared/CustomPressable';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { useSync } from '../../Providers/SyncProvider';
import { LabelValue } from '../../Types/DtoTypes';
import { Project } from '../../Models/RealmModels/Projects';
import { ProjectSitesStyleSheet } from '../../Styles/ProjectSiteStyles';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { Navigation } from '../../Constants/Navigation';
import LocalStorageService from '../../Services/LocalStorageService';
import { ILocalStorageService } from '../../Services/Interfaces/ILocalStorageService';
import { act } from 'react-dom/test-utils';
import { STORAGE_KEYS } from '../../Constants/AppConstants';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { Formats } from '../../Constants/Formats';
import SearchBox from '../Shared/SearchBox';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';

type ProjectSitesProps = { navigation: any };

const ProjectSites = (props: ProjectSitesProps): React.ReactElement => {
  const storageService: ILocalStorageService = new LocalStorageService();

  const [search, setSearch] = useState('');
  const [selectedSite, setSelectedSite] = useState<null | LabelValue<ObjectId>>(
    null,
  );

  const [activeSite, setActiveSite] = useState<null | LabelValue<ObjectId>>(
    null,
  );

  const [projectCount, setProjectCount] = useState(0);
  const [projectDisplayCount, setProjectDisplayCount] = useState(5);
  const [projectSites, setProjectSites] = useState<Project[]>([]);
  const { getCollectionSize, getProjectSites, projects } = useSync();
  const [showInactive, setShowInactive] = useState(false);

  const DISPLAY_INCREMENT = 5;

  useEffect(() => {
    const getSizeAsync = async () => {
      let count = await getCollectionSize(ListDataTypes.PROJECTS);
      setProjectCount(count);
    };

    getSizeAsync();

    let activeSiteJSON = storageService.get(STORAGE_KEYS.ACTIVE_SITE_KEY);
    if (activeSiteJSON) {
      let parsedSite = JSON.parse(activeSiteJSON);
      let newActiveSite: LabelValue<ObjectId> = {
        label: parsedSite.label,
        value: new ObjectId(parsedSite.value),
      };
      setActiveSite(newActiveSite);
    }
  }, []);

  useEffect(() => {
    if (navigationRef.current) {
      const unsubscribe = navigationRef.current.addListener('state', () => {
        fetchProjects();
      });

      return unsubscribe;
    }
  }, []);

  useEffect(() => {
    if (activeSite)
      storageService.set(
        STORAGE_KEYS.ACTIVE_SITE_KEY,
        JSON.stringify(activeSite),
      );
  }, [activeSite]);

  useEffect(() => {
    fetchProjects();
  }, [projectDisplayCount, search, activeSite, projects, showInactive]);

  const fetchProjects = async () => {
    let newProjects = await getProjectSites({ name: search });

    let today = moment().startOf('day').toDate();
    newProjects = newProjects.filter(
      x =>
        !x.isDeleted &&
        (showInactive ||
          (x.isActive &&
            (!x.activeEndDate ||
              moment(x.activeEndDate, Formats.BACKEND_DATE).toDate() >=
                today))),
    );

    setProjectCount(newProjects.length);

    newProjects = newProjects.sort((a: Project, b: Project) => {
      return sortSites(a, b);
    });

    // Remove the extra item
    if (newProjects.length > projectDisplayCount)
      newProjects = newProjects.slice(0, projectDisplayCount);

    setProjectSites(newProjects);
  };

  const goToSite = (projectSiteId: ObjectId | null) => {
    props.navigation.push(Navigation.EDITPROJECTSITE, {
      projectSiteId: projectSiteId,
    });
  };

  //sort alphabetically with the active site on top
  const sortSites = (a: Project, b: Project) => {
    if (a.name === activeSite?.label) return -1;

    if (b.name === activeSite?.label) return 1;

    if (a.name!.toLocaleUpperCase() > b.name!.toLocaleUpperCase()) return 1;

    if (a.name!.toLocaleUpperCase() < b.name!.toLocaleUpperCase()) return -1;

    return 0;
  };

  const renderSiteItemActions = (site: LabelValue<ObjectId>) => {
    const actions = (
      <View style={ProjectSitesStyleSheet.actionContainer}>
        {site.value?.equals(activeSite?.value!) ? (
          <CustomPressable
            onPress={() => {
              setActiveSite(null);
            }}
            style={({ pressed }) => [
              pressed && {
                opacity: 0.6,
              },
              {
                padding: 0,
                marginRight: 5,
              },
            ]}
            tooltip="Remove as Default">
            <Icon icon={'close'} color={Colors.teal} size={20} />
          </CustomPressable>
        ) : (
          <CustomPressable
            onPress={() => {
              setActiveSite(site);
            }}
            style={({ pressed }) => [
              pressed && {
                opacity: 0.6,
              },
              {
                padding: 0,
                marginRight: 5,
              },
            ]}
            tooltip="Set as Default">
            <Icon icon={'input-check'} color={Colors.teal} size={20} />
          </CustomPressable>
        )}
        <CustomPressable
          onPress={() => {
            goToSite(site.value!);
          }}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            {
              padding: 0,
              marginRight: 5,
            },
          ]}
          tooltip="View / Edit">
          <Icon icon={'eye'} color={Colors.teal} size={20} />
        </CustomPressable>
      </View>
    );

    return actions;
  };

  const renderProjectSites = () => {
    let today = moment().startOf('day').toDate();
    let projectOptions: LabelValue<ObjectId>[] = projectSites.map<
      LabelValue<ObjectId>
    >(x => ({
      label:
        x.name +
        (!x.isActive ||
        (x.activeEndDate &&
          moment(x.activeEndDate, Formats.BACKEND_DATE).toDate() < today)
          ? ' - Inactive '
          : ''),
      value: x._id,
    }));

    if (!projectOptions || projectOptions.length == 0) {
      return <Text style={{ textAlign: 'center' }}>No Results Found</Text>;
    }

    let projectItems: React.ReactElement[] = [];
    for (let i = 0; i < projectOptions.length && i < projectDisplayCount; i++) {
      let project = projectOptions[i];
      let selected = false;
      let active = false;

      let backgroundColor = Colors.whiteTransparent;
      let color = Colors.teal;

      if (project.label == selectedSite?.label) selected = true;

      if (activeSite && project.label == activeSite?.label) active = true;

      projectItems.push(
        <View
          key={'siteItem' + project.value}
          style={ProjectSitesStyleSheet.siteItem}>
          <Pressable
            onPress={() => {
              setSelectedSite(project);
            }}
            style={({ pressed }) => [
              ProjectSitesStyleSheet.siteItemPress,
              { borderColor: Colors.teal, borderWidth: 2 },
              pressed && {
                backgroundColor: Colors.lightTealPressed,
              },
              active && {
                backgroundColor: Colors.teal,
              },
              active &&
                pressed && {
                  opacity: 0.6,
                },
            ]}>
            <Text
              style={[
                ProjectSitesStyleSheet.siteItemText,
                {
                  color: active ? Colors.white : Colors.teal,
                },
              ]}
              numberOfLines={1}>
              {project.label}
            </Text>
            {activeSite && activeSite?.value!.equals(project.value!) && (
              <View
                style={{
                  backgroundColor: Colors.white,
                  borderRadius: 15,
                  padding: 2,
                }}>
                <Icon icon={'input-check'} color={Colors.teal} size={15} />
              </View>
            )}
          </Pressable>
          {selected && renderSiteItemActions(project)}
        </View>,
      );
    }

    if (projectCount > projectDisplayCount) {
      projectItems.push(
        <Pressable
          key="ShowMore"
          onPress={() =>
            setProjectDisplayCount(projectDisplayCount + DISPLAY_INCREMENT)
          }
          style={({ pressed }) => [
            {
              borderRadius: 12,
              padding: 16,
              backgroundColor: Colors.darkGreen,
            },
            pressed && {
              opacity: 0.8,
            },
          ]}>
          <Text
            style={[
              ProjectSitesStyleSheet.siteItemText,
              { color: Colors.white, textAlign: 'center' },
            ]}>
            Show More
          </Text>
        </Pressable>,
      );
    }
    return projectItems;
  };

  return (
    <ScrollView>
      <Pressable
        style={{
          //@ts-ignore
          cursor: 'auto',
        }}
        onPress={() => {
          setSelectedSite(null);
        }}>
        <>
          <View>
            <ImageBackground
              source={Images.PEOPLE}
              resizeMode="cover"
              style={{ height: 260 }}>
              <Text style={ReportsStyleSheet.headerTitle}>Projects/Sites</Text>
            </ImageBackground>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 2, padding: 28 }}>
              <View
                style={{ marginTop: -58, marginLeft: 'auto', marginRight: 28 }}>
                <CustomPressable
                  style={({ pressed }) => [
                    {
                      backgroundColor: Colors.green,
                      width: 58,
                      height: 58,
                      borderRadius: 58,
                    },
                    pressed && {
                      backgroundColor: Colors.teal,
                    },
                  ]}
                  onPress={() => goToSite(null)}
                  tooltip="Add New">
                  <View style={{ marginTop: -1, marginLeft: -1 }}>
                    <Icon icon={'add'} color={Colors.white} size={60} />
                  </View>
                </CustomPressable>
              </View>
              <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
              <View
                style={{
                  alignSelf: 'flex-end',
                  marginHorizontal: 20,
                  marginTop: 6,
                }}>
                <DynamicCheckBox
                  label="Show Inactive"
                  value={showInactive ? 'true' : 'false'}
                  onChange={(controlId, controlTypeId, value) =>
                    setShowInactive(value === 'true')
                  }
                />
              </View>
              <View style={ProjectSitesStyleSheet.siteList}>
                {renderProjectSites()}
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={CommonStyleSheet.bcsfFooter}>
                <Image
                  source={Images.BCSF}
                  style={CommonStyleSheet.bcsfFooterImage}
                />
                <Text style={CommonStyleSheet.bcsfFooterText}>
                  BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
                </Text>
              </View>
            </View>
          </View>
        </>
      </Pressable>
    </ScrollView>
  );
};

export default ProjectSites;
