import React, { useContext, useEffect, useState } from 'react';
import { useSync } from './SyncProvider';
import { PERMISSIONS, STORAGE_KEYS } from '../Constants/AppConstants';
import LocalStorageService from '../Services/LocalStorageService';
import { ILocalStorageService } from '../Services/Interfaces/ILocalStorageService';

type PermissionProviderProps = {
  children: React.ReactNode;
};

type PermissionContextValue = {
  hasPermission: (permission: string) => boolean;
};

const PermissionContext = React.createContext<PermissionContextValue>(
  {} as PermissionContextValue,
);

//Mongo Permissions table has list of permissions with an array of roles that has access to that permission
const PermissionProvider: React.FunctionComponent<PermissionProviderProps> = ({
  children,
}): React.ReactElement => {
  const { getPermissions } = useSync();

  const storageService: ILocalStorageService = new LocalStorageService();

  const getRolesFromJwt = (jwt: string): string[] => {
    try {
      let split = jwt.split('.');
      let decodedPayload = JSON.parse(atob(split[1]));
      return decodedPayload.roles as string[];
    } catch {
      //TODO:fail parsing jwt
    }

    return [];
  };

  const hasPermission = (permission: string): boolean => {
    let jwt = storageService.get(STORAGE_KEYS.TOKEN_KEY);
    if (!jwt) return false;

    let jwtRoles = getRolesFromJwt(jwt);
    let permissionRoles = getPermissions().find(
      x => x.name == permission,
    )?.roles;
    if (!permissionRoles) return false;

    for (let i = 0; i < permissionRoles?.length; i++) {
      if (jwtRoles.includes(permissionRoles[i])) return true;
    }

    return false;
  };

  const value = {
    hasPermission: hasPermission,
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

const usePermission = () => {
  const permissionContext = useContext(PermissionContext);

  if (permissionContext === null)
    throw new Error('usePermission() called outside of a PermissionProvider?');

  return permissionContext;
};

export { PermissionProvider, PermissionContext, usePermission };
