import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  ImageBackground,
  Pressable,
  Modal,
} from 'react-native';
import Icon from '../Shared/Icon';
import {
  DynamicAttachment,
  DynamicProfilePictureProps,
} from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Images } from '../../Constants/Images';
import AttachmentService from '../../Services/AttachmentService';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import { useSync } from '../../Providers/SyncProvider';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import axios, { AxiosError } from 'axios';

type UriSasPair = {
  uri: string;
  sasUri: string;
};

const DynamicProfilePicture = (
  props: DynamicProfilePictureProps,
): React.ReactElement => {
  const { realmSignOut } = useCustomRealm();
  const [maxSize, setMaxSize] = useState(0);
  const [picture, setPicture] = useState<DynamicAttachment | undefined>(
    undefined,
  );
  const [error, setError] = useState('');
  const [preview, setPreview] = useState('');
  const [sasUris, setSasUris] = useState<UriSasPair[]>([]);

  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const inputRef = React.createRef<HTMLInputElement>();

  const imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  useEffect(() => {
    if (props.config?.maxFileSizeMB) setMaxSize(props.config?.maxFileSizeMB);
    else if (process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB)
      setMaxSize(parseInt(process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB));

    const getAttachmentUris = async () => {
      if (props.value) {
        let parsedPicture = JSON.parse(props.value!);
        let newSasUris: UriSasPair[] = [];

        if (parsedPicture.blobThumbnailUri) {
          try {
            let thumbnailUri = await attachmentService.getSasUri(
              parsedPicture.blobThumbnailUri!,
            );
            let fullUri = await attachmentService.getSasUri(
              parsedPicture.blobUri!,
            );

            newSasUris.push({
              uri: parsedPicture.blobThumbnailUri!,
              sasUri: thumbnailUri,
            });
            newSasUris.push({
              uri: parsedPicture.blobUri!,
              sasUri: fullUri,
            });
          } catch (err) {
            //failed getting sasuri
          }
        }

        setSasUris(newSasUris);
        setPicture(parsedPicture);
      }
    };

    getAttachmentUris();
  }, []);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function viewPicture(): void {
    if (!picture || !picture.blobUri) return;

    if (picture) setPreview(picture.blobUri!);
  }

  async function showFilePicker(): Promise<void> {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }

  async function selectFiles(files: FileList | null): Promise<void> {
    if (files && files[0]) {
      try {
        props.setIsLoading(true);

        let maxByteSize = maxSize * 1024 * 1024;
        if (files[0].size >= maxByteSize) {
          props.setIsLoading(false);
          setError('File size must be less than ' + maxSize + 'MB');
          return;
        }

        let fileType = files[0].type;
        let fileName = files[0].name.replaceAll(' ', '_');

        let uris: string[] = [];
        try {
          let fullUri = await attachmentService.pushAttachmentChunks(
            props.profileId,
            props.profileType,
            fileName,
            files[0],
          );
          uris.push(fullUri);

          let thumbnailFile = await attachmentService.createThumbnail(files[0]);
          let thumbnailUri = await attachmentService.pushAttachmentChunks(
            props.profileId,
            props.profileType,
            thumbnailFile.name,
            thumbnailFile,
          );
          uris.push(thumbnailUri);
        } catch (err: any | AxiosError) {
          props.setIsLoading(false);

          if (axios.isAxiosError(err)) {
            if (err.response?.status === 413) {
              setError('File exceeds size limits.');
              return;
            }
          }

          setError('Please try re-uploading your files');
          return;
        }

        if (
          (files[0].type === 'image/heic' || files[0].type === 'image/heif') &&
          uris[0].match(/.+\.png$/)
        ) {
          //Rename type/extension if heic/heif file was converted to png
          fileType = 'image/png';
          fileName = fileName.split('.')[0] + '.png';
        }

        //Something wrong upload failed
        if (uris.length != 2) {
          props.setIsLoading(false);
          setError('Please try re-uploading your files');
          return;
        }

        let newPicture: DynamicAttachment = {
          refId: props.profileId,
          refType: props.profileType,
          controlId: props.controlId,
          uri: '',
          name: fileName,
          type: fileType,
          size: files[0].size,
          blobUri: uris[0],
          blobThumbnailUri: uris[1],
        };

        let blobUri = await attachmentService.getSasUri(uris[0]!);
        let thumbnailUri = await attachmentService.getSasUri(uris[1]!);

        let newSasUris: UriSasPair[] = [];
        newSasUris.push({ uri: uris[0], sasUri: blobUri });
        newSasUris.push({ uri: uris[1], sasUri: thumbnailUri });
        setSasUris(newSasUris);

        setPicture(newPicture);
        let newValue = JSON.stringify(newPicture);

        props.onChange!(
          props.controlId,
          props.controlTypeId,
          newValue,
          valid(),
        );

        props.setIsLoading(false);
      } catch (error) {
        /* TODO: Log errors. */
        props.setIsLoading(false);
        console.log(error);
      }
    }
  }

  async function removePicture() {
    setPicture(undefined);

    if (picture) {
      if (picture.blobUri)
        await attachmentService.deleteBlobAttachment(picture.blobUri);

      if (picture.blobThumbnailUri)
        await attachmentService.deleteBlobAttachment(picture.blobThumbnailUri);
    }

    props.onChange!(props.controlId, props.controlTypeId, '', valid());
  }

  async function downloadPicture(): Promise<void> {
    if (picture && picture.blobUri && picture.name)
      await attachmentService.downloadAttachment(picture.blobUri, picture.name);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && !picture) {
      isValid = false;
      error = 'You need to add a picture';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderPicture = (): React.ReactElement => {
    if (!picture) return <></>;

    let sasUri = sasUris.find(x => x.uri == picture.blobThumbnailUri);
    let uri = '';

    if (sasUri) uri = sasUri.sasUri;

    return (
      <ImageBackground
        source={{ uri: uri }}
        style={[
          ControlsStyleSheet.attachmentsItem,
          { flexBasis: '100%', minHeight: 200 },
        ]}>
        <View style={ControlsStyleSheet.attachmentsActions}>
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsAction,
              pressed && {
                backgroundColor: Colors.lightTeal,
              },
            ]}
            onPress={() => viewPicture()}>
            <Icon icon={'eye'} color={Colors.green} size={20} />
          </Pressable>
          {!props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => removePicture()}>
              <Icon icon={'trash'} color={Colors.green} size={20} />
            </Pressable>
          )}
          {props.disabled && (
            <Pressable
              style={({ pressed }) => [
                ControlsStyleSheet.attachmentsAction,
                pressed && {
                  backgroundColor: Colors.lightTeal,
                },
              ]}
              onPress={() => downloadPicture()}>
              <Icon icon={'download'} color={Colors.green} size={20} />
            </Pressable>
          )}
        </View>
      </ImageBackground>
    );
  };

  const renderPreview = (): React.ReactElement => {
    let sasUri = sasUris.find(x => x.uri == picture?.blobUri);
    let uri = '';

    if (sasUri) uri = sasUri.sasUri;

    return (
      <Modal
        visible={preview !== ''}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setPreview('')}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginVertical: 24,
              }}>
              <Image
                resizeMode="contain"
                style={{ width: '100%', height: '100%' }}
                source={{ uri: uri }}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View style={ControlsStyleSheet.attachmentsContainer}>
        {!props.disabled && !picture && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.attachmentsItem,
              { flexBasis: '100%', minHeight: 200, justifyContent: 'center' },
              pressed && {
                opacity: 0.6,
              },
            ]}
            onPress={showFilePicker}>
            <Icon icon={'picture'} color={Colors.green} size={24} />
            <Text style={ControlsStyleSheet.attachmentsText}>
              Upload an image
            </Text>
          </Pressable>
        )}
        {picture && renderPicture()}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept={imageTypes.join(',')}
        onChange={event => selectFiles(event.target.files)}
      />
      {renderPreview()}
    </View>
  );
};

export default DynamicProfilePicture;
