import React, { useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  Text,
  Image,
  Pressable,
  Animated,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId, UUID } from 'bson';
import {
  Submission,
  Submission_answer,
} from '../../Models/RealmModels/Submission';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { SubmissionLink } from '../../Models/RealmModels/SubmissionLink';
import { TemplateVersion } from '../../Models/RealmModels/TemplateVersion';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { Navigation, Widgets } from '../../Constants/Navigation';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import Icon from '../Shared/Icon';
import { TextInput } from 'react-native';
import { METADATA_KEYS } from '../../Constants/AppConstants';

type FormConfirmationProps = {
  navigation: any;
  submissionId: ObjectId;
  title?: string;
  project?: string;
};

const FormConfirmation = (props: FormConfirmationProps): React.ReactElement => {
  const circleSize = useRef(new Animated.Value(80)).current;
  const backgroundOpacity = useRef(new Animated.Value(0)).current;
  const textBottom = useRef(new Animated.Value(-100)).current;

  const { getRealmApp } = useSync();
  const {
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissions,
    getSubmissionStatuses,
    getSQLUsers,
    upsertSubmission,
    addSubmissionLink,
  } = useSync();

  useEffect(() => {
    Animated.timing(circleSize, {
      toValue: 600,
      duration: 1000,
      delay: 400,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(backgroundOpacity, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start(() => {
        Animated.timing(textBottom, {
          toValue: 24,
          duration: 1000,
          useNativeDriver: false,
        }).start();
      });
    });
  }, []);

  const goToDashboard = () => {
    props.navigation.pop();

    props.navigation.navigate(Navigation.HOME, { widget: Widgets.HOME });
  };

  const closeForm = () => {
    props.navigation.pop();
  };

  async function followUp(): Promise<void> {
    props.navigation.pop();

    let currentUser = getRealmApp().currentUser;
    let org = '';
    let currentEmail = '';
    if (currentUser && currentUser.profile) {
      if (typeof currentUser.profile.organisation === 'string') {
        let organisation = JSON.parse(currentUser.profile.organisation);
        org = organisation.Id.toString();
      }
      if (typeof currentUser.profile.email === 'string') {
        currentEmail = currentUser.profile.email;
      }
    }

    let template = (await getFilteredTemplates()).find(t => t.name === 'Create a Task');
    if (template) {
      let templateId = template._id;
      let versions = (await getFilteredTemplateVersions())
        .filter(x => x.templateId.equals(templateId))
        .sort(x => x.version)
        .reverse();
      if (versions[0]) {
        let newDraftId: ObjectId = new ObjectId();
        const draftStatus: SubmissionStatus = (
          await getSubmissionStatuses()
        ).find(x => x.name == SubmissionStatuses.DRAFT)!;

        let title = 'Follow up on ' + props.title;
        if (props.project) title += ' at ' + props.project;

        let answers = await getFollowUpAnswers(versions[0]);
        let newSubmission: Submission = {
          _id: newDraftId,
          partition: org,
          createdBy: currentEmail as string,
          updatedBy: currentEmail as string,
          templateVersion: {
            _id: versions[0]._id,
            name: versions[0].name,
          },
          templateType: {
            _id: template!.templateType._id,
            name: template!.templateType.name,
          },
          submissionStatus: {
            _id: draftStatus._id,
            name: draftStatus.name,
          },
          SQLServerId: new UUID().toHexString(),
          answers: answers,
          metadataJSON: '{"' + METADATA_KEYS.TITLE + '":"' + title + '"}',
          createDateTimeStamp: new Date(),
          updateDateTimeStamp: new Date(),
        };

        await upsertSubmission(newSubmission);

        if (newDraftId == null) throw Error('Error: create draft failed!');

        let newSubmissionLink: SubmissionLink = {
          _id: new ObjectId(),
          parentSubmissionId: props.submissionId,
          childSubmissionId: newDraftId,
          partition: org,
          createDateTimeStamp: new Date(),
        };

        addSubmissionLink(newSubmissionLink);

        props.navigation.navigate(Navigation.DYNAMICFORM, {
          submissionId: newDraftId,
          showConfirmation: false,
          submissionStatus: SubmissionStatuses.DRAFT,
        });
      }
    }
  }

  async function getFollowUpAnswers(
    version: TemplateVersion,
  ): Promise<Submission_answer[]> {
    let answers: Submission_answer[] = [];

    let users = await getSQLUsers();
    const currentUser = getRealmApp().currentUser;
    let usr = users.find(u => u.email === currentUser?.profile.email);

    let title = 'Follow up on ' + props.title;
    if (props.project) title += ' at ' + props.project;

    // We are going to try find the index of metadata "title" and "assigned to"
    // to fill them in the submission later
    for (let i = 0; i < version.pages.length; i++) {
      let page = version.pages[i];
      if (page.controls)
        for (let j = 0; j < page.controls.length; j++) {
          if (page.controls[j].config) {
            let config = JSON.parse(page.controls[j].config!);
            if (config['hasMetaData'] && config['metaDataKey']) {
              if (config['metaDataKey'] === METADATA_KEYS.TITLE)
                answers.push({
                  controlId: page.controls[j].controlId,
                  controlTypeId: page.controls[j].controlId,
                  answer: title,
                } as Submission_answer);
              else if (config['metaDataKey'] === 'AssignedTo')
                answers.push({
                  controlId: page.controls[j].controlId,
                  controlTypeId: page.controls[j].controlId,
                  answer: JSON.stringify({
                    mongoId: usr?._id.toHexString(),
                    SQLServerId: usr?.SQLServerId,
                  }),
                } as Submission_answer);
            }
          }
        }
    }

    return answers;
  }

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: Colors.white,
        padding: 20,
      }}>
      <View style={DynamicPageStyleSheet.confirmationContainer}>
        <Animated.View
          style={{
            width: circleSize,
            height: circleSize,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.darkGreen,
            borderRadius: circleSize,
          }}>
          <Icon icon={'input-check'} color={Colors.white} size={48} />
        </Animated.View>
        <Animated.View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: backgroundOpacity,
          }}>
          <Image
            resizeMode="cover"
            style={{ width: '100%', height: '100%' }}
            source={Images.CONFIRMATION}
          />
        </Animated.View>
        <Animated.View
          style={{
            position: 'absolute',
            left: 24,
            bottom: textBottom,
          }}>
          <Text style={DynamicPageStyleSheet.confirmationText}>
            Submitted.{'\n'}Thank you.
          </Text>
        </Animated.View>
      </View>
      <Pressable
        style={({ pressed }) => [
          CommonStyleSheet.greenButton,
          { width: '100%', marginTop: 12 },
          pressed && {
            opacity: 0.8,
          },
        ]}
        onPress={() => goToDashboard()}>
        <Text style={CommonStyleSheet.greenButtonText}>Home</Text>
      </Pressable>
      <Pressable
        style={({ pressed }) => [
          CommonStyleSheet.whiteButton,
          { width: '100%', marginTop: 12 },
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={closeForm}>
        <Text style={CommonStyleSheet.whiteButtonText}>Return to Reports</Text>
      </Pressable>
      <Pressable
        style={({ pressed }) => [
          CommonStyleSheet.whiteButton,
          {
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 3,
            width: '100%',
            paddingHorizontal: 3,
            marginTop: 12,
          },
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => followUp()}>
        {/* TODO: Add event when follow up is implemented */}
        <Icon icon={'task'} color={Colors.darkGreen} size={48} />
        <Text style={CommonStyleSheet.whiteButtonText}>
          Remind Me To Follow Up On This Report
        </Text>
      </Pressable>
    </ScrollView>
  );
};

export default FormConfirmation;
