export class ListDataTypes {
  public static readonly ANIMALTYPES = 'AnimalTypes';
  public static readonly BODYPARTS = 'BodyParts';
  public static readonly NATURES = 'Natures';
  public static readonly CERTIFICATIONTYPES = 'CertificationTypes';
  public static readonly CITIES = 'Cities';
  public static readonly CLASSIFICATIONUNITS = 'ClassificationUnits';
  public static readonly CREWS = 'Crews';
  public static readonly DEPARTMENTS = 'Departments';
  public static readonly DISPOSTIONS = 'Dispositions';
  public static readonly EQUIPMENTS = 'Equipments';
  public static readonly EQUIPMENTSHEAVY = 'EquipmentHeavyProfiles';
  public static readonly EQUIPMENTSOTHER = 'EquipmentOtherProfiles';
  public static readonly EQUIPMENTSVEHICLE = 'EquipmentVehicleProfiles';
  public static readonly EQUIPMENTTYPES = 'EquipmentTypes';
  public static readonly HAZARDTYPES = 'HazardTypes';
  public static readonly INCIDENTCLASSIFICATIONS = 'IncidentClassifications';
  public static readonly INCIDENTPROBABILITIES = 'IncidentProbabilities';
  public static readonly INCIDENTTYPES = 'IncidentTypes';
  public static readonly JOBS = 'Jobs';
  public static readonly LOCATIONS = 'Locations';
  public static readonly LOCATIONTYPES = 'LocationTypes';
  public static readonly MECHANISMS = 'Mechanisms';
  public static readonly MEETINGTYPES = 'MeetingTypes';
  public static readonly ORGANISATIONS = 'Organisations';
  public static readonly PEOPLE = 'People';
  public static readonly PEOPLETYPES = 'PeopleTypes';
  public static readonly PROJECTS = 'Projects';
  public static readonly PROVSTATES = 'ProvStates';
  public static readonly ROLES = 'Roles';
  public static readonly SEVERITYLEVELS = 'SeverityLevels';
  public static readonly SHIFTS = 'Shifts';
  public static readonly SQLUser = 'SQLUsers';
  public static readonly TASKS = 'Tasks';
  public static readonly TASKTYPES = 'TaskTypes';
  public static readonly TRAININGTYPES = 'TrainingTypes';
  public static readonly EQUIPMENTVEHICLEPROFILES = 'EquipmentVehicleProfiles';
  public static readonly EQUIPMENTHEAVYPROFILES = 'EquipmentHeavyProfiles';
  public static readonly EQUIPMENTOTHERPROFILES = 'EquipmentOtherProfiles';
  public static readonly DOCUMENTTYPES = 'DocumentTypes';
}

export declare type ListDataType =
  | 'AnimalTypes'
  | 'BodyParts'
  | 'Natures'
  | 'CertificationTypes'
  | 'ClassificationUnits'
  | 'Crews'
  | 'Departments'
  | 'Dispositions'
  | 'EquipmentTypes'
  | 'HazardTypes'
  | 'IncidentTypes'
  | 'IncidentClassifications'
  | 'Jobs'
  | 'Locations'
  | 'LocationTypes'
  | 'Mechanisms'
  | 'MeetingTypes'
  | 'Organisations'
  | 'People'
  | 'Projects'
  | 'Shifts'
  | 'SeverityLevels'
  | 'SQLUsers'
  | 'Tasks'
  | 'TaskTypes'
  | 'TrainingTypes'
  | 'DocumentTypes';
