import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Pressable } from 'react-native';
import moment from 'moment';
import Icon from '../Shared/Icon';
import { DynamicTimePickerProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Formats } from '../../Constants/Formats';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const DynamicTimePicker = (
  props: DynamicTimePickerProps,
): React.ReactElement => {
  const [date, setDate] = useState<Date | null>(getValueFromProps(props.value));
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');

  const inputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    let newValue = date ? moment(date).format(Formats.BACKEND_TIME) : '';
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, newValue, valid());

    if (
      props.config?.hasMetaData &&
      props.config?.metaDataKey &&
      props.updateMetaData
    )
      props.updateMetaData(props.config.metaDataKey, newValue);
  }, [date]);

  useEffect(() => {
    if (isFocused === false) valid();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function getValueFromProps(propsValue: string | undefined): Date | null {
    let newValue = null;

    let momentDate = moment(propsValue, Formats.BACKEND_TIME);
    if (momentDate.isValid()) newValue = momentDate.toDate();

    return newValue;
  }

  function showPicker(show: boolean, open: Function, close: Function): void {
    if (props.disabled) return;

    if (show) open();
    else close();

    setIsFocused(show);
  }

  function change(value: string | moment.Moment): void {
    if (moment.isMoment(value) && value.isValid()) setDate(value.toDate());
  }

  function changeInput(text: string): void {
    setDate(getValueFromProps(text));
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && !date) {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);
    return isValid;
  }

  const renderInput = (
    props: any,
    openCalendar: Function,
    closeCalendar: Function,
  ): React.ReactElement => {
    return (
      <View>
        <TextInput
          style={[
            ControlsStyleSheet.input,
            isFocused && ControlsStyleSheet.inputFocused,
            error !== '' && ControlsStyleSheet.inputError,
            isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
          ]}
          placeholder={props.config?.placeholder}
          placeholderTextColor={ControlsStyleSheet.placeholder.color}
          value={date ? moment(date).format(Formats.FRONTEND_TIME) : ''}
          editable={!props.disabled}
        />
        <Pressable
          style={ControlsStyleSheet.datePicker}
          onPress={() => showPicker(!isFocused, openCalendar, closeCalendar)}>
          <View style={{ marginLeft: 'auto', marginRight: 12 }}>
            <Icon
              icon={'clock'}
              color={error !== '' ? Colors.red : Colors.green}
              size={21}
            />
          </View>
        </Pressable>
      </View>
    );
  };

  return (
    <View
      style={{
        zIndex: 10,
        display: props.visible === false ? 'none' : 'flex',
      }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <Datetime
        dateFormat={false}
        renderInput={renderInput}
        value={date ?? moment().startOf('hour').toDate()}
        onChange={value => change(value)}
        onClose={() => setIsFocused(false)}
        timeConstraints={{ minutes: { min: 0, max: 59, step: 5 } }}
      />
      {/* This input will allow to create an automated test for this component */}
      {/* Sadly it needs to be an extra input hidden since the "react-datetime" "Datetime" */}
      {/* is blocking events to be dispatched out of on renderInput */}
      <input
        ref={inputRef}
        type="time"
        style={{
          width: 0,
          height: 0,
          borderStyle: 'none',
        }}
        value={date ? moment(date).format(Formats.BACKEND_TIME) : ''}
        onChange={event => changeInput(event.target.value)}
        onInput={() => changeInput(inputRef.current?.value ?? '')}
      />
      <Text style={ControlsStyleSheet.error}>{error}</Text>
    </View>
  );
};

export default DynamicTimePicker;
