import React, { useState, useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  Image,
  Text,
  Pressable,
  Modal,
  ImageBackground,
  Linking,
} from 'react-native';
import { ObjectId, UUID } from 'bson';
import { useSync } from '../../Providers/SyncProvider';
import { useIsFocused } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import SearchBox from '../Shared/SearchBox';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import DynamicSelect from '../DynamicControls/DynamicSelect';
import DynamicLabel from '../DynamicControls/DynamicLabel';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import Icon, { IconValue } from '../Shared/Icon';
import ConfirmModal from '../Shared/ConfirmModal';
import CircularProgress from '../Shared/CircularProgress';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { RegexValidation } from '../../Constants/General';
import { DynamicAttachment } from '../../Types/ControlTypes';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import DynamicCompanyDocument from '../DynamicControls/DynamicCompanyDocument';
import { Document } from '../../Models/RealmModels/Document';
import { PERMISSIONS, DOCUMENT_LIBRARY } from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import { OrgStorage } from '../../Models/RealmModels/OrgStorage';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import AttachmentService from '../../Services/AttachmentService';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';

export declare type DocumentLibraryProps = {
  navigation: any;
};

export type DocumentType = { id: string; name: string; sortOrder: number };

export type Record = {
  id: string;
  SQLServerId: string;
  name: string;
  createdBy: string;
  documentType?: DocumentType;
  documentTypeListValue?: string;
  showOffline: boolean;
  showCompanyWide: boolean;
  attachmentJSON?: string;
  attachment?: DynamicAttachment;
  icon?: IconValue;
};

const DocumentLibrary = (props: DocumentLibraryProps): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [types, setTypes] = useState<DocumentType[]>([]);
  const [records, setRecords] = useState<Record[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<Record>();
  const [orgStorage, setOrgStorage] = useState<OrgStorage | undefined>(
    undefined,
  );

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showDocumentModalErrors, setShowDocumentModalErrors] = useState(false);
  const [documentCreator, setDocumentCreator] = useState('');

  const [addingDocument, setAddingDocument] = useState(false);
  const [documentModal, setDocumentModal] = useState<Record | undefined>(
    undefined,
  );

  const DISPLAY_INCREMENT = 5;
  const { hasPermission } = usePermission();
  const [recordsToShow, setRecordsToShow] = useState<
    { index: number; show: number }[]
  >([]);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [canViewAll, setCanViewAll] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const { realmSignOut } = useCustomRealm();
  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const {
    getDocumentTypes,
    getDocuments,
    getOrgStorage,
    upsertDocument,
    deleteDocument,
    getSQLUsers,
    getRealmApp,
  } = useSync();

  const isFocused = useIsFocused();
  const toast = useToast();
  const deleteModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  const getOrgStorageAsync = async () => {
    let storage = await getOrgStorage();
    if (storage) setOrgStorage(storage);
  };

  useEffect(() => {
    let viewAllDocs = hasPermission(PERMISSIONS.DOCUMENT_VIEW);
    setCanViewAll(viewAllDocs);

    let editDocs = hasPermission(PERMISSIONS.DOCUMENT_EDIT);
    setCanEdit(editDocs);

    getOrgStorageAsync();
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [canViewAll]);

  useEffect(() => {
    if (isFocused) fetchRecords();
  }, [isFocused]);

  const fetchRecords = async () => {
    let user = getRealmApp().currentUser;
    if (!user) return;

    let userEmail = user.customData['email'];

    let typesArray: DocumentType[] = (await getDocumentTypes())
      .map(t => ({
        id: t._id.toHexString(),
        name: t.name,
        sortOrder: t.sortOrder,
      }))
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });

    setRecordsToShow(
      typesArray.map((t, i) => ({ index: i, show: DISPLAY_INCREMENT })),
    );
    setExpandedItems(typesArray.map((t, i) => i));
    setTypes(typesArray);

    let docs = await getDocuments();

    docs = docs.filter(
      t => canViewAll || t.showCompanyWide || t.createdBy === userEmail,
    );

    let mappedRecords: Record[] = docs.map(d => ({
      id: d._id.toHexString(),
      SQLServerId: d.SQLServerId,
      name: d.name,
      documentType: typesArray.find(t => t.id == String(d.documentTypeId)),
      showOffline: d.showOffline,
      showCompanyWide: d.showCompanyWide,
      attachmentJSON: d.attachmentJSON,
      createdBy: d.createdBy,
    }));

    for (let i = 0; i < mappedRecords.length; i++) {
      if (mappedRecords[i].attachmentJSON) {
        mappedRecords[i].attachment = JSON.parse(
          mappedRecords[i].attachmentJSON!,
        ) as DynamicAttachment;

        if (mappedRecords[i].attachment?.type?.includes('image'))
          mappedRecords[i].icon = 'picture';
        else mappedRecords[i].icon = 'file';
      }
    }

    mappedRecords = sortItems(mappedRecords);
    setRecords(mappedRecords);
  };

  function expand(index: number): void {
    let newExpandedItems = [...expandedItems];

    newExpandedItems.push(index);

    setExpandedItems(newExpandedItems);
  }

  function collapse(index: number): void {
    let newExpandedItems = [...expandedItems];

    let arrayIndex = newExpandedItems.indexOf(index);
    if (arrayIndex !== -1) {
      newExpandedItems.splice(arrayIndex, 1);
      setExpandedItems(newExpandedItems);
    }
  }

  const incrementRecordsToShow = (index: number) => {
    let newRecordsToShow = [...recordsToShow];
    let recordsToShowByCategory = newRecordsToShow.filter(
      s => s.index === index,
    );
    if (recordsToShowByCategory.length > 0)
      recordsToShowByCategory[0].show += DISPLAY_INCREMENT;
    setRecordsToShow(newRecordsToShow);
  };

  function sortItems(itemsToSort: Record[]): Record[] {
    return itemsToSort.sort((a, b) =>
      (a.name?.toLowerCase() ?? '') > (b.name?.toLowerCase() ?? '')
        ? 1
        : (a.name?.toLowerCase() ?? '') < (b.name?.toLowerCase() ?? '')
        ? -1
        : 0,
    );
  }

  async function getDocTypeListValue(documentTypeId: string): Promise<string> {
    let documentType = (await getDocumentTypes()).find(t =>
      t._id.equals(documentTypeId),
    );
    if (documentType)
      return JSON.stringify({
        mongoId: documentType._id.toHexString(),
        SQLServerId: documentType.SQLServerId,
      });

    return '';
  }

  async function confirmDeleteRecord(): Promise<void> {
    deleteModalCommand.current.openModal();
  }

  const deleteDocumentCommand = async () => {
    if (selectedRecord && selectedRecord.documentType) {
      await deleteDocument(new ObjectId(selectedRecord.id!));

      let attachment = JSON.parse(
        selectedRecord.attachmentJSON!,
      ) as DynamicAttachment;

      if (attachment.blobUri)
        await attachmentService.deleteBlobAttachment(attachment.blobUri);

      if (attachment.blobThumbnailUri)
        await attachmentService.deleteBlobAttachment(
          attachment.blobThumbnailUri,
        );

      getOrgStorageAsync();
      fetchRecords();
    }
  };

  function addDocument(): void {
    let user = getRealmApp().currentUser;
    if (!user) return;

    setDocumentModal({
      id: new ObjectId().toHexString(),
      SQLServerId: new UUID().toHexString(),
      name: '',
      documentTypeListValue: '',
      showOffline: false,
      showCompanyWide: false,
      attachmentJSON: '',
      createdBy: user.customData['email'] as string,
    });

    setShowDocumentModal(true);
    setShowDocumentModalErrors(false);
    setAddingDocument(true);
    setDocumentCreator('');
  }

  async function editDocument(): Promise<void> {
    if (selectedRecord && selectedRecord.documentType) {
      let docTypeListValue = await getDocTypeListValue(
        selectedRecord.documentType.id,
      );

      setDocumentModal({
        id: selectedRecord.id,
        SQLServerId: selectedRecord.SQLServerId,
        name: selectedRecord.name,
        documentTypeListValue: docTypeListValue,
        showOffline: selectedRecord.showOffline,
        showCompanyWide: selectedRecord.showCompanyWide,
        attachmentJSON: selectedRecord.attachmentJSON,
        createdBy: selectedRecord.createdBy,
      });

      setShowDocumentModal(true);
      setShowDocumentModalErrors(false);
      setAddingDocument(false);

      let createdBy = (await getSQLUsers()).find(
        p => p.email === selectedRecord.createdBy,
      );
      if (createdBy)
        setDocumentCreator(createdBy.firstName + ' ' + createdBy.lastName);
      else setDocumentCreator('');
    }
  }

  async function onDocumentModalChange(
    propName: string,
    propValue: string,
  ): Promise<void> {
    let doc = { ...documentModal } as Record;

    if (propName === 'name') doc.name = propValue;
    else if (propName === 'type') doc.documentTypeListValue = propValue;
    else if (propName === 'attachment') doc.attachmentJSON = propValue;
    else if (propName === 'offline') doc.showOffline = propValue === 'true';
    else if (propName === 'companyWide')
      doc.showCompanyWide = propValue === 'true';

    setDocumentModal(doc);
  }

  async function saveDocument(): Promise<void> {
    if (
      !documentModal ||
      !documentModal.name ||
      !documentModal.documentTypeListValue ||
      !documentModal.attachmentJSON
    ) {
      setShowDocumentModalErrors(true);
      return;
    }

    let documentTypeId: ObjectId | undefined = undefined;
    let valObj = JSON.parse(documentModal.documentTypeListValue) as {
      mongoId: string;
      SQLServerId: string;
    };
    if (valObj) documentTypeId = new ObjectId(valObj.mongoId);
    else return;

    let org = '';
    let currentEmail = '';
    let currentUser = getRealmApp().currentUser;
    if (currentUser && currentUser.profile) {
      if (typeof currentUser.profile.organisation === 'string') {
        let organisation = JSON.parse(currentUser.profile.organisation);
        org = organisation.Id.toString();
      }

      if (typeof currentUser.profile.email === 'string') {
        currentEmail = currentUser.profile.email;
      }
    }

    if (addingDocument) {
      let newDoc: Document = {
        _id: new ObjectId(documentModal.id),
        SQLServerId: documentModal.SQLServerId,
        name: documentModal.name,
        documentTypeId: documentTypeId!,
        showOffline: documentModal.showOffline,
        showCompanyWide: documentModal.showCompanyWide,
        attachmentJSON: documentModal.attachmentJSON!,
        partition: org,
        createdBy: currentEmail as string,
        createDateTimeStamp: new Date(),
      };
      await upsertDocument(newDoc);
    } else {
      let doc = (await getDocuments()).filter(d =>
        d._id.equals(documentModal.id),
      )[0];

      if (doc) {
        doc.name = documentModal.name;
        doc.documentTypeId = documentTypeId!;
        doc.showOffline = documentModal.showOffline;
        doc.showCompanyWide = documentModal.showCompanyWide;
        doc.attachmentJSON = documentModal.attachmentJSON!;

        await upsertDocument(doc);
      }
    }
    getOrgStorageAsync();
    toast.show('Document saved successfully', { type: 'success' });

    setSelectedRecord(undefined);
    setShowDocumentModal(false);
    fetchRecords();
  }

  const renderDocumentType = (
    documentType: DocumentType,
    index: number,
  ): React.ReactElement => {
    let filteredRecords = records.filter(
      r =>
        // Search filter
        (search === '' ||
          r.name?.toLowerCase().includes(search.toLowerCase()) ||
          documentType.name?.toLowerCase().includes(search.toLowerCase())) &&
        // Filter by category
        r.documentType &&
        r.documentType.id === documentType.id,
    );

    let itemsToShow = DISPLAY_INCREMENT;
    let recordsToShowByType = recordsToShow.filter(s => s.index === index);
    if (recordsToShowByType.length > 0)
      itemsToShow = recordsToShowByType[0].show;

    let visibleRecords = sortItems(filteredRecords).slice(0, itemsToShow);
    if (visibleRecords.length === 0)
      return <React.Fragment key={index}></React.Fragment>;

    return (
      <View key={index} style={{ paddingHorizontal: 20 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 20,
          }}>
          <Text style={CommonStyleSheet.controlLabel}>{documentType.name}</Text>
          {!expandedItems.includes(index) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 28,
                  height: 28,
                  borderRadius: 28,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 12,
                },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              onPress={() => expand(index)}>
              <Icon icon={'expand'} color={Colors.darkestGreen} size={20} />
            </Pressable>
          )}
          {expandedItems.includes(index) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 28,
                  height: 28,
                  borderRadius: 28,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 12,
                },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              onPress={() => collapse(index)}>
              <Icon icon={'collapse'} color={Colors.darkestGreen} size={20} />
            </Pressable>
          )}
        </View>
        {expandedItems.includes(index)
          ? visibleRecords.map((record, i) => {
              return renderRecord(record, i);
            })
          : null}
        {expandedItems.includes(index) &&
          filteredRecords.length > visibleRecords.length && (
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.smallGreenButton,
                { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={() => incrementRecordsToShow(index)}>
              <Text style={CommonStyleSheet.smallGreenButtonText}>
                Show More
              </Text>
            </Pressable>
          )}
      </View>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            { flex: 1 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => setSelectedRecord(record)}>
          <View style={{ marginHorizontal: 12 }}>
            <Icon
              icon={record.icon as IconValue}
              color={Colors.darkestGreen}
              size={24}
            />
          </View>
          <Text style={ReportsStyleSheet.listItemName} numberOfLines={2}>
            {record.name}
          </Text>
        </Pressable>
        {selectedRecord &&
          selectedRecord.id === record.id &&
          renderActions(record)}
      </View>
    );
  };

  const renderActions = (record: Record): React.ReactElement => {
    let user = getRealmApp().currentUser;

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {record.attachment && (
          <Pressable
            style={({ pressed }) => [
              pressed && {
                opacity: 0.6,
              },
              ReportsStyleSheet.draftAction,
            ]}
            onPress={() => editDocument()}>
            <Icon icon={'eye'} color={Colors.white} size={20} />
          </Pressable>
        )}
        {(canEdit || user!.customData['email'] === record.createdBy) && (
          <Pressable
            style={({ pressed }) => [
              pressed && {
                opacity: 0.6,
              },
              ReportsStyleSheet.draftAction,
            ]}
            onPress={() => {
              confirmDeleteRecord();
            }}>
            <Icon icon={'trash'} color={Colors.white} size={20} />
          </Pressable>
        )}
      </View>
    );
  };

  const renderDocumentModal = (): React.ReactElement => {
    let user = getRealmApp().currentUser;
    let canEditDoc =
      canEdit || documentModal?.createdBy === user!.customData['email'];

    return (
      <Modal
        visible={showDocumentModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {addingDocument ? 'Add Document' : 'Edit Document'}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => {
                  setShowDocumentModal(false);
                }}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            {documentModal ? (
              <View>
                {!addingDocument && documentCreator && (
                  <Text
                    style={[
                      ControlsStyleSheet.label,
                      {
                        color: Colors.darkGreen,
                        fontSize: 12,
                        alignSelf: 'center',
                        textTransform: 'capitalize',
                      },
                    ]}>
                    {'Created By: ' + documentCreator}
                  </Text>
                )}
                <DynamicTextBox
                  config={{
                    required: true,
                    validationRegExp: RegexValidation.NAME,
                    placeholder: 'Eg. Code Of Conduct',
                    maxLength: 50,
                  }}
                  label="Document Name"
                  value={documentModal!.name}
                  disabled={!canEditDoc}
                  onChange={(controlId, controlTypeId, value, isValid) =>
                    onDocumentModalChange('name', value)
                  }
                  showError={showDocumentModalErrors}
                />
                <DynamicSelect
                  config={{
                    required: true,
                    optionSource: ListDataTypes.DOCUMENTTYPES,
                  }}
                  label="Category"
                  value={documentModal!.documentTypeListValue}
                  disabled={!canEditDoc}
                  onChange={(controlId, controlTypeId, value, isValid) =>
                    onDocumentModalChange('type', value)
                  }
                  showError={showDocumentModalErrors}
                />
                <View style={{ marginVertical: 12 }}>
                  <DynamicCompanyDocument
                    documentId={documentModal!.SQLServerId}
                    config={{
                      required: true,
                      maxFileSizeMB: 25,
                    }}
                    value={documentModal!.attachmentJSON}
                    disabled={!canEditDoc}
                    onChange={(controlId, controlTypeId, value, isValid) => {
                      onDocumentModalChange('attachment', value);
                    }}
                    showError={showDocumentModalErrors}
                  />
                </View>
                <DynamicLabel config={{ fontSize: 18 }} value={'VISIBILITY'} />
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <DynamicLabel value={'AVAILABLE OFFLINE'} />
                  <DynamicSegmentedControl
                    config={{ segments: [{ name: 'Yes' }, { name: 'No' }] }}
                    value={documentModal!.showOffline ? 'Yes' : 'No'}
                    disabled={!canEditDoc}
                    onChange={(controlId, controlTypeId, value, isValid) => {
                      onDocumentModalChange(
                        'offline',
                        value === 'Yes' ? 'true' : 'false',
                      );
                    }}
                  />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <DynamicLabel value={'AVAILABLE COMPANY-WIDE'} />
                  <DynamicSegmentedControl
                    config={{ segments: [{ name: 'Yes' }, { name: 'No' }] }}
                    value={documentModal!.showCompanyWide ? 'Yes' : 'No'}
                    disabled={!canEditDoc}
                    onChange={(controlId, controlTypeId, value, isValid) => {
                      onDocumentModalChange(
                        'companyWide',
                        value === 'Yes' ? 'true' : 'false',
                      );
                    }}
                  />
                </View>
              </View>
            ) : null}
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 'auto', marginBottom: 24 },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              disabled={!canEditDoc}
              onPress={() => saveDocument()}>
              <Text style={CommonStyleSheet.greenButtonText}>
                {addingDocument ? 'Add Document' : 'Edit Document'}
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const renderStorageReport = (): React.ReactElement => {
    if (!orgStorage) return <></>;

    let storageSpace = DOCUMENT_LIBRARY.DEFAULT_STORAGE_SPACE;
    let storageSizeMB = orgStorage.size / 1024 / 1024;
    let storageReport = Math.trunc((storageSizeMB / storageSpace) * 100);

    let upgradeUrl = process.env.REACT_APP_UPGRADE_STORAGE_SPACE_URL;

    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 20,
          paddingHorizontal: 20,
        }}>
        {/* Hide for now */}
        {/* <CircularProgress progress={storageReport} /> */}
        <View style={{ flex: 1 }}>
          <Text
            style={[
              ReportsStyleSheet.listTitle,
              {
                textTransform: 'none',
                letterSpacing: 1,
                marginHorizontal: 0,
              },
            ]}>
            {/* You've used {storageReport}% ({storageSizeMB.toFixed(2)}/
            {storageSpace} MB) of your document storage. */}
            {/* Just show MB used for now */}
            You've used {storageSizeMB.toFixed(2)} MB of your document storage.
          </Text>
          {upgradeUrl ? (
            <Pressable onPress={() => Linking.openURL(upgradeUrl!)}>
              <Text
                style={{
                  color: Colors.green,
                  fontFamily: 'Poppins',
                  fontSize: 12,
                  fontWeight: '600',
                  textDecorationLine: 'underline',
                }}>
                Upgrade For More
              </Text>
            </Pressable>
          ) : null}
        </View>
      </View>
    );
  };

  return (
    <ScrollView>
      <View>
        <ImageBackground
          source={Images.LIBRARY}
          resizeMode="cover"
          style={{ height: 260 }}>
          <Text style={ReportsStyleSheet.headerTitle}>Document Library</Text>
        </ImageBackground>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          {types.map((type, i) => {
            return renderDocumentType(type, i);
          })}
        </View>
        <View style={{ flex: 1 }}>
          <View style={CommonStyleSheet.screenButtonsContainer}>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.screenButton,
                pressed && { opacity: 0.6 },
              ]}
              onPress={() => addDocument()}>
              <Icon icon={'file'} color={Colors.green} size={24} />
              <Text style={CommonStyleSheet.screenButtonText}>
                Add a document
              </Text>
            </Pressable>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.screenButton,
                { display: 'none' },
                pressed && { opacity: 0.6 },
              ]}>
              <Icon icon={'library'} color={Colors.green} size={24} />
              <Text style={CommonStyleSheet.screenButtonText}>
                Add a category
              </Text>
            </Pressable>
          </View>
          {renderStorageReport()}
          <View style={CommonStyleSheet.bcsfFooter}>
            <Image
              source={Images.BCSF}
              style={CommonStyleSheet.bcsfFooterImage}
            />
            <Text style={CommonStyleSheet.bcsfFooterText}>
              BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
            </Text>
          </View>
        </View>
      </View>
      {renderDocumentModal()}
      <ConfirmModal
        title="Delete this Document"
        message="Are you sure you want to delete this document?"
        cancelClick={() => {}}
        cancelText="No"
        okClick={() => {
          deleteDocumentCommand();
        }}
        okText="Delete"
        setOpenModal={(func: () => void) => {
          deleteModalCommand.current.setOpenModal(func);
        }}
      />
    </ScrollView>
  );
};

export default DocumentLibrary;
