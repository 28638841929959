export class Images {
  public static readonly BCSF = require('../Images/bcfs.png');
  public static readonly FIRS_LOGO_DARK = require('../Images/firs-logo-dark.png');
  public static readonly FIRS_LOGO_LIGHT = require('../Images/firs-logo-light.png');
  public static readonly GOOD_MORNING = require('../Images/good-morning.jpg');
  public static readonly GOOD_AFTERNOON = require('../Images/good-afternoon.jpg');
  public static readonly GOOD_EVENING = require('../Images/good-evening.jpg');
  public static readonly REPORTS = require('../Images/reports.jpg');
  public static readonly PEOPLE = require('../Images/people.jpg');
  public static readonly EQUIPMENT = require('../Images/equipment.jpg');
  public static readonly OPERATIONS = require('../Images/operations.jpg');
  public static readonly CONFIRMATION = require('../Images/confirmation.jpg');
  public static readonly FAB = require('../Images/fab.png');
  public static readonly GOOGLE_SIGNIN = require('../Images/google-signin.png');
  public static readonly CLOSE = require('../Images/close.png');
  public static readonly PDF = require('../Images/pdf.png');
  public static readonly COMING_SOON = require('../Images/coming-soon.png');
  public static readonly LIBRARY = require('../Images/library.jpg');
}
