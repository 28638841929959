import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import useDynamicData from '../../Hooks/useDynamicData';
import SearchBoxComponent from '../Shared/SearchBox';
import { DynamicTagItem, DynamicTagItemsProps } from '../../Types/ControlTypes';
import Icon from '../Shared/Icon';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';

const DynamicTagItems = (props: DynamicTagItemsProps): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '[]');
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');

  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState(new Array<string>());
  const [options, setOptions] = useState<DynamicTagItem[]>(
    props.config?.options ?? [],
  );

  const defaultItemsToShow = 10;
  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);

  const { getMultiselectionData } = useDynamicData();

  useEffect(() => {
    if (props.config?.optionSource)
      getOptionsFromSource().then(result => setOptions(result));
  }, [props.config?.optionSource]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (isFocused === false) valid();
    else if (isFocused) Keyboard.dismiss();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  async function getOptionsFromSource(): Promise<DynamicTagItem[]> {
    let newOptions = new Array<DynamicTagItem>();

    newOptions = await getMultiselectionData(
      props.config?.optionSource!,
      props.value,
    );

    return newOptions;
  }

  function showModal(): void {
    if (props.disabled) return;
    Keyboard.dismiss();

    let selectedValues: string[] = [];
    if (value) {
      let valueArray = JSON.parse(value) as Array<string>;
      valueArray.forEach(val => {
        let valObj = JSON.parse(val) as MongoIdSqlId;
        let option = options.find(
          o =>
            o.value &&
            (JSON.parse(o.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
        );
        if (option) selectedValues.push(option.value);
        else selectedValues.push(val);
      });
    }
    setSelection(selectedValues);

    setSearch('');
    setItemsToShow(defaultItemsToShow);
    setIsFocused(true);
  }

  function hideModal(): void {
    setIsFocused(false);
  }

  function remove(val: string): void {
    let valueArray = JSON.parse(value) as string[];

    let index = valueArray.indexOf(val);
    if (index !== -1) valueArray.splice(index, 1);

    setValue(JSON.stringify(valueArray));
  }

  function changeSearch(newSearch: string): void {
    setSearch(newSearch);
    setItemsToShow(defaultItemsToShow);
  }

  function select(item: DynamicTagItem): void {
    Keyboard.dismiss();

    let newSelection = [...selection];

    let index = newSelection.indexOf(item.value);
    if (index === -1) newSelection.push(item.value);
    else newSelection.splice(index, 1);

    setSelection(newSelection);
  }

  function save(): void {
    setValue(JSON.stringify(selection));
    setIsFocused(false);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === '[]') {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError || isFocused !== null) setError(error);

    return isValid;
  }

  function sortOptions(options: DynamicTagItem[]): DynamicTagItem[] {
    return options.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : a.label.toLowerCase() < b.label.toLowerCase()
        ? -1
        : 0,
    );
  }

  const renderSelectedItems = (): React.ReactElement => {
    let selectedValues: string[] = [];

    let valueArray = JSON.parse(value) as Array<string>;
    valueArray.forEach(val => {
      let valObj = JSON.parse(val) as MongoIdSqlId;
      let option = options.find(
        o =>
          o.value &&
          (JSON.parse(o.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
      );
      if (option) selectedValues.push(option.value);
    });

    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginRight: 76,
          marginVertical: 4,
        }}>
        {selectedValues.map((v, i) => {
          return renderTagItem(v, i);
        })}
      </View>
    );
  };

  const renderTagItem = (val: string, index?: number): React.ReactElement => {
    let text = '';
    let valObj = JSON.parse(val) as MongoIdSqlId;
    let option = options.find(
      o =>
        o.value &&
        (JSON.parse(o.value) as MongoIdSqlId).mongoId === valObj!.mongoId,
    );
    if (option) text = option.label;
    else text = valObj.label ?? '';

    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          gap: 8,
          backgroundColor: Colors.lightTeal,
          borderRadius: 32,
          paddingHorizontal: 12,
          paddingTop: 4,
          paddingBottom: 8,
          marginHorizontal: 6,
          marginVertical: 2,
        }}>
        <Text
          style={{
            color: Colors.darkGreen,
            fontFamily: 'Lato',
            fontSize: 16,
            fontWeight: '700',
          }}>
          {text}
        </Text>
        {!props.disabled && (
          <Pressable
            style={({ pressed }) => [
              pressed && {
                backgroundColor: Colors.whiteTransparent,
              },
            ]}
            onPress={() => remove(val)}>
            <Icon icon={'input-clear'} color={Colors.darkGreen} size={14} />
          </Pressable>
        )}
      </View>
    );
  };

  const renderModal = (): React.ReactElement => {
    let availableOptions = 0;
    let visibleOptions = new Array<DynamicTagItem>();

    if (search) {
      let filteredOptions = options.filter(o =>
        o.label.toLowerCase().includes(search.toLowerCase()),
      );

      availableOptions = filteredOptions.length;
      visibleOptions = filteredOptions.slice(0, itemsToShow);
    } else {
      availableOptions = options.length;

      selection.forEach(val => {
        let item = options.find(item => item.value === val);
        if (item) visibleOptions.push(item);
      });

      visibleOptions = sortOptions(visibleOptions);

      //Complete the list with the top # elements
      for (
        let i = 0;
        i < options.length && visibleOptions.length < itemsToShow;
        i++
      ) {
        if (!visibleOptions.some(o => o.value === options[i].value))
          visibleOptions.push(options[i]);
      }

      availableOptions = options.length;
    }

    return (
      <Modal
        visible={isFocused ?? false}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label ?? ''}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => hideModal()}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <SearchBoxComponent
              onChangeSearch={newSearch => changeSearch(newSearch)}
            />
            {visibleOptions.length === 0 && (
              <Text
                style={[
                  ControlsStyleSheet.checkBoxText,
                  { alignSelf: 'center', marginTop: 12 },
                ]}>
                No Results Found
              </Text>
            )}
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 20 }}>
              {visibleOptions.map(option => {
                return renderItem(option);
              })}
              {visibleOptions.length < availableOptions && (
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.smallGreenButton,
                    { alignSelf: 'center', marginVertical: 12 },
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  onPress={() =>
                    setItemsToShow(itemsToShow + defaultItemsToShow)
                  }>
                  <Text style={CommonStyleSheet.smallGreenButtonText}>
                    Show More
                  </Text>
                </Pressable>
              )}
            </ScrollView>
            <Text
              style={[
                ControlsStyleSheet.checkBoxText,
                { alignSelf: 'center', marginBottom: 12 },
              ]}>
              Showing {visibleOptions.length} Of {availableOptions} Records
            </Text>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={save}>
              <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const renderItem = (item: DynamicTagItem): React.ReactElement => {
    let checked = selection.includes(item.value);
    return (
      <Pressable
        key={item.value}
        style={({ pressed }) => [
          ControlsStyleSheet.groupSelectorOptionContainer,
          { marginTop: 0, padding: 8 },
          pressed && {
            backgroundColor: Colors.lightTealPressed,
          },
        ]}
        onPress={() => select(item)}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            gap: 24,
            paddingRight: 4,
            minHeight: 32,
          }}>
          <Text
            style={[ControlsStyleSheet.checkBoxText, { flex: 1 }]}
            numberOfLines={1}>
            {item.label}
          </Text>
        </View>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            checked && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon icon={'checked'} color={Colors.white} size={16} />
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <View style={{ minHeight: 43, flexDirection: 'row' }}>
        <TextInput
          showSoftInputOnFocus={false}
          style={[
            ControlsStyleSheet.input,
            isFocused && ControlsStyleSheet.inputFocused,
            error !== '' && ControlsStyleSheet.inputError,
            isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
            { position: 'absolute', width: '100%', minHeight: '100%' },
          ]}
          placeholder={props.config?.placeholder}
          placeholderTextColor={ControlsStyleSheet.placeholder.color}
          editable={!props.disabled}
        />
        {renderSelectedItems()}
        {!props.disabled && (
          <Pressable
            style={({ pressed }) => [
              {
                height: 24,
                backgroundColor: Colors.white,
                borderRadius: 6,
                borderWidth: 1,
                borderColor: Colors.green,
                paddingHorizontal: 6,
                paddingVertical: 2,
                marginTop: 9,
                marginRight: 8,
              },
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
              },
            ]}
            onPress={showModal}
            disabled={props.disabled}>
            <Text style={ControlsStyleSheet.groupSelectorButtonText}>
              {value && value !== '[]' ? 'Change' : 'Select'}
            </Text>
          </Pressable>
        )}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      {renderModal()}
    </View>
  );
};

export default DynamicTagItems;
