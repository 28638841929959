import React from "react";
import { View, Text, Pressable, ViewStyle, StyleProp } from "react-native";
import Colors from "../../Styles/Shared/Colors";
import { CommonStyleSheet } from "../../Styles/Shared/CommonStyles";

type PageControlFooterProps = {
  currentPage: number;
  maxPages: number;
  backOnPress: () => void;
  nextOnPress: () => void;
};

const PageControlFooter = (
  props: PageControlFooterProps
): React.ReactElement => {
  const backButton = (
    <Pressable
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.2 : 1,
        },
        CommonStyleSheet.whiteButton,
        { alignSelf: "flex-start" },
      ]}
      android_ripple={{ color: Colors.whiteTransparent }}
      onPress={() => {
        props.backOnPress();
      }}
    >
      <Text style={CommonStyleSheet.whiteButtonText}>Back</Text>
    </Pressable>
  );
  const nextButton = (
    <Pressable
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.2 : 1,
        },
        CommonStyleSheet.greenButton,
        { alignSelf: "flex-end" },
      ]}
      android_ripple={{ color: Colors.whiteTransparent }}
      onPress={() => {
        props.nextOnPress();
      }}
    >
      <Text style={CommonStyleSheet.greenButtonText}>Next</Text>
    </Pressable>
  );

  let style: StyleProp<ViewStyle> = {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
    paddingTop: 0,
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.white,
    justifyContent: "space-between",
  };

  if (props.currentPage == 0) style.justifyContent = "flex-end";

  return (
    <View style={style}>
      {props.currentPage != 0 && backButton}
      {props.currentPage != props.maxPages - 1 && nextButton}
    </View>
  );
};

export default PageControlFooter;
