import React, { useEffect, useState, useRef } from 'react';
import { View, Text, Image, Pressable, ActivityIndicator } from 'react-native';
import moment from 'moment';
import {
  usePDF,
  BlobProvider,
  Document,
  View as PDFView,
  Page,
  Text as PDFText,
  Image as PDFImage,
  pdf,
} from '@react-pdf/renderer';
import { useSync } from '../../Providers/SyncProvider';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import AttachmentService from '../../Services/AttachmentService';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Icon from '../Shared/Icon';
import { Submission } from '../../Models/RealmModels/Submission';
import { DynamicAttachment } from '../../Types/ControlTypes';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { PDFStyles } from './DynamicPDF';

type DynamicBundlePDFProps = {
  bundleId: string;
  autoDownload?: boolean;
};

const DynamicBundlePDF = (props: DynamicBundlePDFProps): React.ReactElement => {
  const { realmSignOut } = useCustomRealm();
  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const [orgName, setOrgName] = useState('');
  const [documentTitle, setDocumentTitle] = useState('');
  const [date, setDate] = useState('');
  const [isAudit, setIsAudit] = useState(false);
  const [auditHeader, setAuditHeader] = useState('');
  const [internalRecipients, setInternalRecipients] = useState('');
  const [externalOrg, setExternalOrg] = useState('');
  const [externalRecipients, setExternalRecipients] = useState('');
  const [documents, setDocuments] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<DynamicAttachment[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  const hasPDFAttachments = useRef(false);

  const [blobDocument, setBlobDocument] = useState<Blob | undefined>(undefined);

  const {
    getSubmissionBundles,
    getOrganisations,
    getSubmissions,
    getFilteredTemplateVersions,
    getPeople,
    getOrgRecipients,
  } = useSync();

  useEffect(() => {
    const getBundle = async () => {
      if (props.bundleId) {
        let realmBundle = (await getSubmissionBundles()).find(s =>
          s._id.equals(props.bundleId!),
        );
        if (realmBundle) {
          let org = (await getOrganisations()).find(
            o => o.SQLServerId === realmBundle!.partition,
          );
          if (org) setOrgName(org.name);

          setDocumentTitle(realmBundle.name ?? '');

          if (realmBundle.submitDateTimeStamp)
            setDate(
              moment(realmBundle.submitDateTimeStamp).format(
                Formats.FRONTEND_DATE,
              ),
            );

          if (realmBundle.isAudit) {
            setIsAudit(realmBundle.isAudit);
            let auditSubmission = (await getSubmissions()).find(
              x =>
                realmBundle!.auditSubmissionId &&
                x._id.equals(realmBundle!.auditSubmissionId),
            );

            if (auditSubmission)
              setAuditHeader(await getReportTitle(auditSubmission));
          }

          let intRec = '';
          if (realmBundle.internalRecipients) {
            let intRecipients = (await getPeople({})).filter(
              r =>
                r.email &&
                realmBundle!.internalRecipients!.indexOf(r.email) != -1,
            );

            intRecipients.forEach((r, i) => {
              intRec += (i === 0 ? '' : ', ') + r.firstName + ' ' + r.lastName;
            });
          }
          setInternalRecipients(intRec);

          if (realmBundle.externalOrgId) {
            let extOrgObj = (await getOrganisations()).find(
              o => o.SQLServerId === realmBundle!.externalOrgId,
            );
            if (extOrgObj) setExternalOrg(extOrgObj.name);
          }

          let extRec = '';
          if (realmBundle.externalRecipients) {
            let extRecipients = (await getOrgRecipients()).filter(
              r =>
                r.email &&
                realmBundle!.externalRecipients!.indexOf(r.email) != -1,
            );

            extRecipients.forEach((r, i) => {
              extRec += (i === 0 ? '' : ', ') + r.firstName + ' ' + r.lastName;
            });
          }
          setExternalRecipients(extRec);

          let docs: string[] = [];
          if (realmBundle.submissions)
            for (let i = 0; i < realmBundle.submissions.length; i++) {
              docs.push(
                await getReportTitle(realmBundle.submissions[i] as Submission),
              );
            }
          setDocuments(docs);

          // Get Attachments
          let newAttachments: DynamicAttachment[] = [];
          if (realmBundle.bundleAttachments) {
            let attachmentsList: DynamicAttachment[] = [];

            try {
              attachmentsList = JSON.parse(
                realmBundle.bundleAttachments,
              ) as DynamicAttachment[];
              attachmentsList = attachmentsList.filter(x => x.blobUri);
            } catch (err) {
              console.log('Error: Bad control value serialization');
            }

            for (let i = 0; i < attachmentsList.length; i++) {
              try {
                let uri = await attachmentService.getSasUri(
                  attachmentsList[i].blobUri!,
                );
                attachmentsList[i].uri = uri;

                if (attachmentsList[i].type?.includes('pdf'))
                  hasPDFAttachments.current = true;
              } catch (err) {
                console.log('Error: Failed getting sasuri');
              }
            }

            newAttachments = [...attachmentsList];
          }
          setAttachments(newAttachments);
        }
      }

      setIsMounted(true);
    };

    getBundle();
  }, [props.bundleId]);

  useEffect(() => {
    if (isMounted) {
      const getPdfBlob = async () => {
        let blob = await pdf(renderPDF()).toBlob();
        setBlobDocument(blob);
      };

      getPdfBlob();
    }
  }, [isMounted]);

  useEffect(() => {
    if (props.autoDownload) downloadAll();
  }, [props.autoDownload]);

  async function getReportTitle(submission: Submission): Promise<string> {
    let template = (await getFilteredTemplateVersions()).find(x =>
      x._id.equals(submission!.templateVersion!._id!),
    )!;

    let title = template.name ?? '';
    if (submission.metadataJSON) {
      let meta = JSON.parse(submission.metadataJSON);
      if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];
      if (meta[METADATA_KEYS.PROJECT_NAME])
        title += ' at ' + meta[METADATA_KEYS.PROJECT_NAME];
    }

    let date = '';
    let submissionDate =
      submission.updateDateTimeStamp ?? submission.createDateTimeStamp;
    if (submissionDate)
      date = moment(submissionDate).format(Formats.FRONTEND_DATE);

    return title + ' - ' + date;
  }

  async function downloadAll() {
    downloadDocument();

    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        if (!attachments[i].type?.includes('image')) {
          downloadAttachment(attachments[i]);
        }
      }
    }
  }

  async function downloadDocument() {
    if (blobDocument) {
      var element = document.createElement('a');
      element.href = URL.createObjectURL(blobDocument);
      element.download = documentTitle + '.pdf';
      element.click();
    }
  }

  async function downloadAttachment(
    attachment: DynamicAttachment,
  ): Promise<void> {
    if (attachment && attachment.blobUri && attachment.name)
      await attachmentService.downloadAttachment(
        attachment.blobUri,
        attachment.name,
      );
  }

  async function downloadReport(url: string, fileName: string) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.open('GET', url, true);

    xhr.onreadystatechange = function () {
      if (xhr.readyState == xhr.DONE) {
        var blob = new Blob([xhr.response]);

        var element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = fileName;
        element.click();
      }
    };

    xhr.send();
  }

  const renderPDF = (): React.ReactElement => {
    return (
      <Document title={documentTitle}>
        {renderCover()}
        {renderDocument()}
        {attachments.map(attachment => {
          return <>{renderAttachment(attachment)}</>;
        })}
      </Document>
    );
  };

  const renderCover = (): React.ReactElement => {
    return (
      <Page size="A4">
        <PDFView
          style={{
            alignItems: 'center',
            paddingHorizontal: 120,
            marginTop: 200,
          }}>
          <PDFText style={PDFStyles.title}>{orgName}</PDFText>
          <PDFText style={[PDFStyles.label, { textAlign: 'center' }]}>
            {documentTitle}
          </PDFText>
          <PDFText style={PDFStyles.value}>{date}</PDFText>
        </PDFView>
      </Page>
    );
  };

  const renderDocument = (): React.ReactElement => {
    return (
      <Page size="A4">
        <PDFView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingHorizontal: 24,
            paddingTop: 24,
          }}>
          <PDFView
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <PDFText style={PDFStyles.label}>
              IS THIS AN AUDIT SUBMISSION BUNDLE?
            </PDFText>
            <PDFText style={PDFStyles.value}>{isAudit ? 'Yes' : 'No'}</PDFText>
          </PDFView>
          {isAudit && auditHeader && (
            <PDFView
              style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
              wrap={false}>
              <PDFText style={PDFStyles.label}>AUDIT HEADER</PDFText>
              <PDFText style={PDFStyles.value}>{auditHeader}</PDFText>
              <PDFText style={[PDFStyles.value, { fontSize: 10 }]}>
                *Audit header will be printed on a separated file.
              </PDFText>
            </PDFView>
          )}
          {internalRecipients && (
            <PDFView
              style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
              wrap={false}>
              <PDFText style={PDFStyles.label}>INTERNAL RECIPIENTS</PDFText>
              <PDFText style={PDFStyles.value}>{internalRecipients}</PDFText>
            </PDFView>
          )}
          {externalOrg && (
            <PDFView
              style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
              wrap={false}>
              <PDFText style={PDFStyles.label}>EXTERNAL ORGANISATION</PDFText>
              <PDFText style={PDFStyles.value}>{externalOrg}</PDFText>
            </PDFView>
          )}
          {externalRecipients && (
            <PDFView
              style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
              wrap={false}>
              <PDFText style={PDFStyles.label}>EXTERNAL RECIPIENTS</PDFText>
              <PDFText style={PDFStyles.value}>{externalRecipients}</PDFText>
            </PDFView>
          )}
          {documents && documents.length > 0 && (
            <PDFView
              style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
              wrap={false}>
              <PDFText style={PDFStyles.label}>DOCUMENTS INCLUDED</PDFText>
              {documents.map((docTitle, i) => {
                return (
                  <PDFText key={i} style={PDFStyles.value}>
                    {docTitle}
                  </PDFText>
                );
              })}
              <PDFText style={[PDFStyles.value, { fontSize: 10 }]}>
                *Documents included will be printed on separated files.
              </PDFText>
            </PDFView>
          )}
        </PDFView>
      </Page>
    );
  };

  const renderAttachment = (
    attachment: DynamicAttachment,
  ): React.ReactElement => {
    return (
      <>
        {attachment.type?.includes('image') && attachment.uri ? (
          <Page size="A4">
            <PDFView
              style={{
                flex: 1,
                padding: 24,
              }}>
              <PDFText style={[PDFStyles.label, { marginBottom: 12 }]}>
                {attachment.name}
              </PDFText>
              <PDFImage
                style={{ objectFit: 'contain', maxHeight: 700 }}
                source={attachment.uri}
              />
            </PDFView>
          </Page>
        ) : null}
      </>
    );
  };

  return (
    <>
      {isMounted ? (
        <>
          <View style={ControlsStyleSheet.attachmentsItem}>
            <Image style={{ width: 32, height: 32 }} source={Images.PDF} />
            <Text style={ControlsStyleSheet.attachmentsText}>
              {documentTitle}.pdf
            </Text>
            <View style={ControlsStyleSheet.attachmentsActions}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.attachmentsAction,
                  pressed && {
                    backgroundColor: Colors.lightTeal,
                  },
                ]}
                onPress={() => downloadDocument()}>
                <Icon icon={'download'} color={Colors.green} size={20} />
              </Pressable>
            </View>
          </View>
          {hasPDFAttachments.current && (
            <>
              {attachments.map((attachment, i) => {
                if (attachment.type?.includes('image')) return <></>;
                return (
                  <View key={i} style={ControlsStyleSheet.attachmentsItem}>
                    {attachment.type?.includes('pdf') ? (
                      <Image
                        style={{ width: 32, height: 32 }}
                        source={Images.PDF}
                      />
                    ) : (
                      <Icon icon={'file'} color={Colors.green} size={20} />
                    )}
                    <Text style={ControlsStyleSheet.attachmentsText}>
                      {attachment.name}
                    </Text>
                    <View style={ControlsStyleSheet.attachmentsActions}>
                      <Pressable
                        style={({ pressed }) => [
                          ControlsStyleSheet.attachmentsAction,
                          pressed && {
                            backgroundColor: Colors.lightTeal,
                          },
                        ]}
                        onPress={() => downloadAttachment(attachment)}>
                        <Icon
                          icon={'download'}
                          color={Colors.green}
                          size={20}
                        />
                      </Pressable>
                    </View>
                  </View>
                );
              })}
            </>
          )}
        </>
      ) : (
        <LoadingSpinner message="Generating Documents..." visible={true} />
      )}
    </>
  );
};

export default DynamicBundlePDF;
