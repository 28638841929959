import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ObjectId } from 'bson';
import { ScrollView, View, Text, Pressable, Modal } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { SvgXml } from 'react-native-svg';
import moment from 'moment';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionLink } from '../../Models/RealmModels/SubmissionLink';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';
import { Formats } from '../../Constants/Formats';
import Icon, { IconValue } from '../Shared/Icon';
import { Icons } from '../../Constants/Icons';
import CustomPressable from '../Shared/CustomPressable';
import SearchBox, { Filter, FilterValue } from '../Shared/SearchBox';
import DynamicPDF from '../DynamicForm/DynamicPDF';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Navigation } from '../../Constants/Navigation';
import Colors from '../../Styles/Shared/Colors';
import { useTemplateConversion } from '../../Providers/TemplateConversionProvider';
import ConfirmModal from './ConfirmModal';
import { useSync } from '../../Providers/SyncProvider';
import { TemplateVersion } from '../../Models/RealmModels/TemplateVersion';
import { Template } from '../../Models/RealmModels/Template';
import {
  CATEGORY,
  METADATA_KEYS,
  PERMISSIONS,
} from '../../Constants/AppConstants';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { usePermission } from '../../Providers/PermissionProvider';
import { Person } from '../../Models/RealmModels/Person';
import { SubmissionRelation } from '../../Models/RealmModels/SubmissionRelation';
import { FilterTypes } from '../../Constants/General';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { SQLUser } from '../../Models/RealmModels/SQLUser';

export declare type RecordListProps = {
  title?: string;
  records?: Submission[];
  formattedRecords?: Record[];
  showSearch?: boolean;
  showDrafts?: boolean;
  showGroup?: boolean;
  showDueDate?: boolean;
  showAssignedTo?: boolean;
  hasMetaData?: boolean;
  metaDataKey_title?: string;
  metaDataKey_project?: string;
  metaDataKey_date?: string;
  metaDataKey_time?: string;
  showFilters?: boolean;
  filters?: Filter[];
  onRecordPressed?: (recordId: string) => void;
  setUnselectCommand?: (func: () => void) => void;
  navigation: any;
  disabled?: boolean;
  useLocalIcons?: boolean;
  category?: string;
};

export declare type Record = {
  key: number;
  id: string;
  templateId?: string;
  personId?: string;
  projectId?: string;
  equipmentType?: string;
  equipment?: string;
  name: string;
  status: string;
  datetime: Date;
  icon: string;
  assignedText: string;
  createdBy: string;
};

const RecordList = (props: RecordListProps): React.ReactElement => {
  const {
    deleteSubmission,
    getEquipmentsHeavy,
    getEquipmentsOther,
    getEquipmentsVehicle,
    getSubmissions,
    getSubmissionRelations,
    people,
    getRealmApp,
  } = useSync();
  const [records, setRecords] = useState<Record[]>(new Array<Record>());
  const [selectedRecord, setSelectedRecord] = useState<Record>();
  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState<FilterValue>({
    startDate: '',
    endDate: '',
    statuses: [],
    types: [],
    person: '',
    project: '',
    equipmentType: '',
    equipment: '',
  });
  const { convertSubmission, canConvertSubmission } = useTemplateConversion();
  const [canConvertSelected, setCanConvertSelected] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showGroup, setShowGroup] = useState(props.showGroup ?? true);

  const [isShowingPDFModal, setIsShowingPDFModal] = useState(false);
  const [recordToPDF, setRecordToPDF] = useState<Record>();

  const [canElevate, setCanElevate] = useState<boolean>();
  const [canDelete, setCanDelete] = useState<boolean>();
  const [peopleNeeded, setPeopleNeeded] = useState<Person[]>([]);
  const [usersNeeded, setUsersNeeded] = useState<SQLUser[]>([]);
  const [relationsNeeded, setRelationsNeeded] = useState<SubmissionRelation[]>(
    [],
  );
  const [hasDeletePermission, setHasDeletePermission] =
    useState<boolean>(false);
  const [versions, setVersions] = useState<TemplateVersion[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [sqlUserId, setSqlUserId] = useState('');

  const {
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getOrgId,
    getSubmissionLinks,
    addSubmissionLink,
    removeSubmissionLinks,
    deleteSubmissionAttachments,
    getSQLUsers,
  } = useSync();

  const { hasPermission } = usePermission();

  const DISPLAY_INCREMENT = 10;
  const [draftsToShow, setDraftsToShow] = useState(DISPLAY_INCREMENT);

  const isFocused = useIsFocused();
  const deleteModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  //Obj for accessing open modal func in child
  const elevateModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  const noDeleteModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  const allVersionsNeeded = useMemo<
    { id: string; name: string; icon: string }[]
  >(() => {
    let result: { id: string; name: string; icon: string }[] = [];

    let versionsMapped = versions.map(v => ({
      id: v._id.toHexString(),
      templateId: v.templateId.toHexString(),
      name: v.displayName ?? v.name,
    }));

    versionsMapped.forEach(v => {
      let template = templates.find(x => x._id.equals(v.templateId));

      if (template && template.iconSvg) {
        let templateIcon = template.iconSvg
          // First: For dark mode icons (Eg. Site Inspections in home) replace darkestGreen with white
          .replaceAll(Colors.darkestGreen, Colors.white)
          //Second: For all other icons (Eg. Site Inspections in operations) replace green/currentColor with darkestGreen
          .replaceAll(Colors.green, Colors.darkestGreen)
          .replaceAll('currentColor', Colors.darkestGreen);

        result.push({ id: v.id, name: v.name, icon: templateIcon });
      }
    });

    return result;
  }, [versions, templates]);

  const templatesNeeded = useMemo<
    { versionId: string; templateId: string }[]
  >(() => {
    let result: { versionId: string; templateId: string }[] = [];

    let versionsMapped = versions.map(v => ({
      id: v._id.toHexString(),
      templateId: v.templateId.toHexString(),
    }));

    versionsMapped.forEach(v => {
      let template = templates.find(x => x._id.equals(v.templateId));

      if (template && template.iconSvg)
        result.push({
          versionId: v.id,
          templateId: template._id.toHexString(),
        });
    });

    return result;
  }, [versions, templates]);

  useEffect(() => {
    const getPeopleNeeded = async () => {
      let result: Person[] = [];

      const currentUser = getRealmApp().currentUser;
      if (currentUser && currentUser.profile.email) {
        let sqlUser = (await getSQLUsers()).find(
          p => p.email === currentUser.profile.email,
        );
        if (sqlUser) setSqlUserId(sqlUser?._id.toHexString());
      }

      if (props.records) {
        let peopleIds: string[] = [];
        let usersIds: string[] = [];
        let usersEmailss: string[] = [];

        props.records.forEach(function (r, i) {
          try {
            if (props.hasMetaData === true && r.metadataJSON) {
              let meta = JSON.parse(r.metadataJSON);

              // Search the metadata for all keys that may contain people needed

              if (meta[METADATA_KEYS.WORKERID]) {
                let mongoSqlId: MongoIdSqlId = JSON.parse(
                  meta[METADATA_KEYS.WORKERID].toString(),
                );
                if (mongoSqlId && mongoSqlId.mongoId)
                  peopleIds.push(mongoSqlId.mongoId);
              }

              if (meta[METADATA_KEYS.PERSON_INVOLVED]) {
                let mongoSqlId: MongoIdSqlId = JSON.parse(
                  meta[METADATA_KEYS.PERSON_INVOLVED].toString(),
                );
                if (mongoSqlId && mongoSqlId.mongoId)
                  peopleIds.push(mongoSqlId.mongoId);
              }

              if (meta[METADATA_KEYS.ASSIGNED_TO]) {
                let mongoSqlId: MongoIdSqlId = JSON.parse(
                  meta[METADATA_KEYS.ASSIGNED_TO].toString(),
                );

                if (mongoSqlId && mongoSqlId.mongoId)
                  usersIds.push(mongoSqlId.mongoId);
              }
            }
          } catch (err) {
            //Invalid meta data
          }

          usersEmailss.push(r.updatedBy ?? r.createdBy);
        });

        let usersResult = (await getSQLUsers()).filter(
          x =>
            usersIds.includes(x._id.toHexString()) ||
            usersEmailss.includes(x.email ?? ''),
        );
        let personUsersIds = usersResult.map(x => x.SQLServerPersonId);
        result = people.filter(
          x =>
            peopleIds.includes(x._id.toHexString()) ||
            personUsersIds.includes(x.SQLServerId),
        );

        setPeopleNeeded(result);
        setUsersNeeded(usersResult);
      }
    };

    if (people.length > 0) getPeopleNeeded();
  }, [props.records, people]);

  useEffect(() => {
    const getRelationsNeeded = async () => {
      let result: SubmissionRelation[] = [];

      if (props.records) {
        let submissionIds = props.records.map(r => r._id.toHexString());
        result = (await getSubmissionRelations()).filter(x =>
          submissionIds.includes(x.submissionId.toHexString()),
        );
      }

      setRelationsNeeded(result);
    };

    getRelationsNeeded();
  }, [props.records]);

  const equipmentNeeded = useMemo<
    { id: string; name: string; type: string }[]
  >(() => {
    let result: { id: string; name: string; type: string }[] = [];
    if (props.records) {
      let equipmentIds: string[] = [];

      props.records.forEach(function (r, i) {
        if (props.hasMetaData === true && r.metadataJSON) {
          let meta = JSON.parse(r.metadataJSON);

          if (
            props.metaDataKey_title &&
            props.metaDataKey_title == METADATA_KEYS.EQUIPMENTID &&
            meta[props.metaDataKey_title]
          )
            equipmentIds.push(meta[props.metaDataKey_title!].toString());
        }
      });

      result = getEquipmentsVehicle()
        .filter(x => equipmentIds.includes(x._id.toHexString()))
        .map(e => ({
          id: e._id.toHexString(),
          name: e.name + ' ' + e.number,
          type: ListDataTypes.EQUIPMENTVEHICLEPROFILES,
        }));

      result = result.concat(
        getEquipmentsHeavy()
          .filter(x => equipmentIds.includes(x._id.toHexString()))
          .map(e => ({
            id: e._id.toHexString(),
            name: e.name + ' ' + e.number,
            type: ListDataTypes.EQUIPMENTHEAVYPROFILES,
          })),
      );

      result = result.concat(
        getEquipmentsOther()
          .filter(x => equipmentIds.includes(x._id.toHexString()))
          .map(e => ({
            id: e._id.toHexString(),
            name: e.name + ' ' + e.number,
            type: ListDataTypes.EQUIPMENTOTHERPROFILES,
          })),
      );
    }

    return result;
  }, [props.records]);

  useEffect(() => {
    fetchVersionsAndTemplates();

    if (props.setUnselectCommand)
      props.setUnselectCommand(() => {
        setSelectedRecord(undefined);
      });

    let elevateReport = false;
    let deleteReport = false;
    if (props.category == CATEGORY.REPORTS) {
      elevateReport = hasPermission(PERMISSIONS.REPORT_ELEVATE);
      deleteReport = hasPermission(PERMISSIONS.REPORT_DELETE);
    } else if (props.category == CATEGORY.EQUIPMENT) {
      deleteReport = hasPermission(PERMISSIONS.REPORT_EQUIPMENT_DELETE);
    } else if (props.category == CATEGORY.OPERATIONS) {
      deleteReport = hasPermission(PERMISSIONS.REPORT_OPERATION_DELETE);
    } else if (props.category == CATEGORY.PEOPLE) {
      deleteReport = hasPermission(PERMISSIONS.REPORT_PEOPLE_DELETE);
    }

    setCanElevate(elevateReport);
    setHasDeletePermission(deleteReport);
  }, []);

  useEffect(() => {
    if (!isFocused) {
      setSelectedRecord(undefined);
      setCanConvertSelected(false);
    }
  }, [isFocused]);

  useEffect(() => {
    if (props.records && versions.length > 0 && templates.length > 0) {
      const mappedRecords: Record[] = [];

      props.records.forEach(function (r, i) {
        let version = allVersionsNeeded.find(v =>
          r.templateVersion._id?.equals(v.id),
        );

        let title = version?.name!.trim() ?? '';
        let date = moment(
          r.updateDateTimeStamp ?? r.createDateTimeStamp,
        ).toDate();
        let personId = '';
        let equipmentType = '';
        let equipmentId = '';
        let assignedText = '';
        if (props.hasMetaData === true) {
          if (r.metadataJSON) {
            let meta = JSON.parse(r.metadataJSON);

            if (props.metaDataKey_title && meta[props.metaDataKey_title]) {
              if (props.metaDataKey_title == METADATA_KEYS.EQUIPMENTID) {
                let equipment = equipmentNeeded.find(
                  x => x.id === meta[props.metaDataKey_title!].toString(),
                );

                if (equipment) {
                  equipmentType = equipment.type;
                  equipmentId = equipment.id;
                  title += ': ' + equipment.name;
                }
              } else if (props.metaDataKey_title == METADATA_KEYS.WORKERID) {
                let mongoSqlId: MongoIdSqlId = JSON.parse(
                  meta[props.metaDataKey_title!].toString(),
                );

                let worker = peopleNeeded.find(
                  x => x._id.toHexString() == mongoSqlId.mongoId,
                );

                if (worker)
                  title += ': ' + worker.firstName + ' ' + worker.lastName;

                personId = mongoSqlId.mongoId ?? '';
              } else title = meta[props.metaDataKey_title];
            }

            if (props.metaDataKey_project && meta[props.metaDataKey_project])
              title += ' at ' + meta[props.metaDataKey_project];

            let datePart = '';
            if (props.metaDataKey_date && meta[props.metaDataKey_date])
              datePart = meta[props.metaDataKey_date];

            let timePart = '';
            if (props.metaDataKey_time && meta[props.metaDataKey_time])
              timePart = meta[props.metaDataKey_time];

            if (datePart && timePart) {
              let time = moment(timePart, Formats.BACKEND_TIME);
              let duedate = moment(datePart, Formats.BACKEND_DATE)
                .add(time.hours(), 'hours')
                .add(time.minutes(), 'minutes')
                .toDate();

              date = duedate;
            }

            if (props.showAssignedTo) {
              if (meta[METADATA_KEYS.ASSIGNED_TO]) {
                try {
                  let mongoSqlId: MongoIdSqlId = JSON.parse(
                    meta[METADATA_KEYS.ASSIGNED_TO],
                  );
                  // Is Assigned To Me
                  if (mongoSqlId.mongoId == sqlUserId) {
                    let assignedBy = usersNeeded.find(
                      x => x.email == r.createdBy,
                    );

                    if (assignedBy && assignedBy.firstName)
                      assignedText =
                        'Assigned By: ' +
                        assignedBy.firstName +
                        ' ' +
                        (assignedBy.lastName ?? '');
                  } else if (mongoSqlId.label)
                    assignedText = 'Assigned To: ' + mongoSqlId.label;
                } catch (error) {
                  console.log('Error: Bad metadata serialization');
                }
              }
            }
          }
        }

        let template = templatesNeeded.find(t =>
          r?.templateVersion._id?.equals(t.versionId),
        );

        let project = relationsNeeded.find(
          x => r._id.equals(x.submissionId) && x.relationType === 'Project',
        );

        mappedRecords.push({
          key: i,
          id: r._id.toHexString(),
          templateId: template?.templateId,
          personId: personId,
          projectId:
            project && project.relationId
              ? project.relationId.toHexString()
              : '',
          equipmentType: equipmentType,
          equipment: equipmentId,
          name: title,
          status: r.submissionStatus.name!,
          datetime: date,
          icon: version?.icon ?? Icons.TASK,
          assignedText: assignedText,
          createdBy: r.createdBy,
        });
      });

      mappedRecords.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());

      setRecords(mappedRecords);
    }
  }, [props.records, versions, templates, peopleNeeded]);

  useEffect(() => {
    if (props.formattedRecords) setRecords(props.formattedRecords);
  }, [props.formattedRecords]);

  useEffect(() => {
    let checkIsConvertible = async () => {
      let canConvert = await canConvertSubmission(
        new ObjectId(selectedRecord?.id!),
      );
      if (canConvert) setCanConvertSelected(true);
      else setCanConvertSelected(false);
    };

    if (selectedRecord) checkIsConvertible();

    let checkCanDelete = async () => {
      //If user can delete all reports set canDelete to true if it isn't already true
      //If they can't delete all reports check if they created the report and set it to true, false otherwise
      if (hasDeletePermission) {
        if (canDelete) return;
        else {
          setCanDelete(true);
          return;
        }
      }

      let userCreatedRecord = false;
      let currentUser = getRealmApp().currentUser;

      if (
        selectedRecord &&
        currentUser &&
        currentUser.profile &&
        selectedRecord.createdBy === (currentUser.profile.email as string)
      )
        userCreatedRecord = true;

      setCanDelete(userCreatedRecord);
    };

    checkCanDelete();
  }, [selectedRecord]);

  const fetchVersionsAndTemplates = async () => {
    let templates = await getFilteredTemplates();
    setTemplates(templates);

    let templateVersions = await getFilteredTemplateVersions();
    setVersions(templateVersions);
  };

  const recordPressed = (record: Record) => {
    if (props.onRecordPressed) {
      props.onRecordPressed(record.id);
    } else {
      props.navigation.navigate(Navigation.DYNAMICFORM, {
        submissionId: record.id,
      });
    }
  };

  const showPDFModal = (record: Record) => {
    setRecordToPDF(record);
    setIsShowingPDFModal(true);
  };

  const convertRecord = async (submissionId: string) => {
    if (!canElevate) {
      elevateModalCommand.current.openModal();
      return;
    }

    let childId = new ObjectId(submissionId);
    let parentId = await convertSubmission(childId);
    if (parentId) {
      let orgid = getOrgId();
      let newSubmissionLink: SubmissionLink = {
        _id: new ObjectId(),
        parentSubmissionId: parentId,
        childSubmissionId: childId,
        partition: orgid.toString(),
        createDateTimeStamp: new Date(),
      };

      addSubmissionLink(newSubmissionLink);

      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: parentId.toHexString(),
        showConfirmation: false,
      });
    }
  };

  const deleteRecord = async () => {
    if (selectedRecord) {
      let links = await getSubmissionLinks();

      // Remove submission links by parent
      if (links.some(s => s.parentSubmissionId.equals(selectedRecord.id)))
        removeSubmissionLinks(new ObjectId(selectedRecord.id), true);

      // Remove submission links by child
      if (links.some(s => s.childSubmissionId.equals(selectedRecord.id)))
        removeSubmissionLinks(new ObjectId(selectedRecord.id), false);

      await deleteSubmissionAttachments(new ObjectId(selectedRecord.id!));
      await deleteSubmission(new ObjectId(selectedRecord.id!));

      let newRecords = [...records.filter(x => x.id != selectedRecord.id!)];
      setRecords(newRecords);
    }
  };

  const renderDrafts = (): React.ReactElement => {
    let drafts: Record[] = records.filter(
      r =>
        r.status == SubmissionStatuses.DRAFT &&
        r.name.toLowerCase().includes(search.toLowerCase()),
    );

    let dateRangeFilter = props.filters?.find(
      f => f.type === FilterTypes.DATE_RANGE,
    );
    if (dateRangeFilter) {
      if (filterValue.startDate) {
        let startDate = moment(
          filterValue.startDate,
          Formats.BACKEND_DATE,
        ).toDate();
        drafts = drafts.filter(r => r.datetime > startDate);
      }

      if (filterValue.endDate) {
        let endDate = moment(
          filterValue.endDate,
          Formats.BACKEND_DATE,
        ).toDate();
        drafts = drafts.filter(r => r.datetime < endDate);
      }
    }

    let reportTypeFilter = props.filters?.find(
      f => f.type === FilterTypes.REPORT_TYPE,
    );
    if (reportTypeFilter && filterValue.types && filterValue.types.length > 0)
      drafts = drafts.filter(
        r => r.templateId && filterValue.types!.includes(r.templateId),
      );

    let personFilter = props.filters?.find(f => f.type === FilterTypes.PERSON);
    if (personFilter && filterValue.person)
      drafts = drafts.filter(
        r => r.personId && r.personId == filterValue.person,
      );

    let projectFilter = props.filters?.find(
      f => f.type === FilterTypes.PROJECT,
    );
    if (projectFilter && filterValue.project)
      drafts = drafts.filter(
        r => r.projectId && r.projectId == filterValue.project,
      );

    let equipmentFilter = props.filters?.find(
      f => f.type === FilterTypes.EQUIPMENT,
    );
    if (equipmentFilter && (filterValue.equipmentType || filterValue.equipment))
      drafts = drafts.filter(
        r =>
          r.equipmentType &&
          r.equipmentType == filterValue.equipmentType &&
          (r.equipment == filterValue.equipment || !filterValue.equipment),
      );

    if (!drafts || drafts.length === 0) return <></>;

    let draftsLength = drafts.length;
    drafts = drafts.slice(0, draftsToShow);

    return (
      <View style={ReportsStyleSheet.list}>
        <Text style={ReportsStyleSheet.listTitle}>Draft</Text>
        {drafts.map((record, index) => {
          return renderRecord(true, record, index);
        })}
        {draftsLength > draftsToShow && (
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.smallGreenButton,
              { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() =>
              setDraftsToShow(prevState => prevState + DISPLAY_INCREMENT)
            }
            disabled={props.disabled}>
            <Text style={CommonStyleSheet.smallGreenButtonText}>Show More</Text>
          </Pressable>
        )}
      </View>
    );
  };

  const renderRecordGroup = (
    records: Record[],
    title: string,
  ): React.ReactElement => {
    return (
      <>
        {records.length > 0 && (
          <>
            <Text style={ReportsStyleSheet.listSubtitle}>{title}</Text>
            {records.map((record, index) => {
              return renderRecord(false, record, index);
            })}
          </>
        )}
      </>
    );
  };

  const renderSubmitted = (): React.ReactElement => {
    let submitted: Record[] = records.filter(
      r =>
        (r.status === SubmissionStatuses.SUBMITTED ||
          r.status === SubmissionStatuses.COMPLETE ||
          r.status === SubmissionStatuses.DISMISSED) &&
        r.name.toLowerCase().includes(search.toLowerCase()),
    );

    let hasFilters =
      filterValue.startDate !== '' ||
      filterValue.endDate !== '' ||
      (filterValue.statuses && filterValue.statuses.length > 0) ||
      (filterValue.types && filterValue.types.length > 0) ||
      filterValue.person !== '' ||
      filterValue.project !== '' ||
      filterValue.equipmentType !== '' ||
      filterValue.equipment !== '';

    let dateRangeFilter = props.filters?.find(
      f => f.type === FilterTypes.DATE_RANGE,
    );
    if (dateRangeFilter) {
      if (filterValue.startDate) {
        let startDate = moment(
          filterValue.startDate,
          Formats.BACKEND_DATE,
        ).toDate();
        submitted = submitted.filter(r => r.datetime > startDate);
      }

      if (filterValue.endDate) {
        let endDate = moment(
          filterValue.endDate,
          Formats.BACKEND_DATE,
        ).toDate();
        submitted = submitted.filter(r => r.datetime < endDate);
      }
    }

    let taskStatusFilter = props.filters?.find(
      f => f.type === FilterTypes.TASK_STATUS,
    );
    if (
      taskStatusFilter &&
      filterValue.statuses &&
      filterValue.statuses.length > 0
    ) {
      let filtered: Record[] = [];

      if (filterValue.statuses?.includes('in-progress'))
        filtered.push(
          ...submitted.filter(
            r =>
              r.datetime >= moment().toDate() &&
              (r.status === SubmissionStatuses.DRAFT ||
                r.status === SubmissionStatuses.SUBMITTED),
          ),
        );

      if (filterValue.statuses?.includes('completed'))
        filtered.push(
          ...submitted.filter(r => r.status === SubmissionStatuses.COMPLETE),
        );

      if (filterValue.statuses?.includes('overdue'))
        filtered.push(
          ...submitted.filter(
            r =>
              r.datetime < moment().toDate() &&
              (r.status === SubmissionStatuses.DRAFT ||
                r.status === SubmissionStatuses.SUBMITTED),
          ),
        );

      if (filterValue.statuses?.includes('dismissed'))
        filtered.push(
          ...submitted.filter(r => r.status === SubmissionStatuses.DISMISSED),
        );

      filtered.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
      submitted = filtered;
    }

    let reportTypeFilter = props.filters?.find(
      f => f.type === FilterTypes.REPORT_TYPE,
    );
    if (reportTypeFilter && filterValue.types && filterValue.types.length > 0)
      submitted = submitted.filter(
        r => r.templateId && filterValue.types!.includes(r.templateId),
      );

    let personFilter = props.filters?.find(f => f.type === FilterTypes.PERSON);
    if (personFilter && filterValue.person)
      submitted = submitted.filter(
        r => r.personId && r.personId == filterValue.person,
      );

    let projectFilter = props.filters?.find(
      f => f.type === FilterTypes.PROJECT,
    );
    if (projectFilter && filterValue.project)
      submitted = submitted.filter(
        r => r.projectId && r.projectId == filterValue.project,
      );

    let equipmentFilter = props.filters?.find(
      f => f.type === FilterTypes.EQUIPMENT,
    );
    if (equipmentFilter && (filterValue.equipmentType || filterValue.equipment))
      submitted = submitted.filter(
        r =>
          r.equipmentType &&
          r.equipmentType == filterValue.equipmentType &&
          (r.equipment == filterValue.equipment || !filterValue.equipment),
      );

    if (!submitted || submitted.length === 0) return <></>;

    const today = moment().startOf('day').toDate();
    const yesterday = moment().subtract(1, 'days').startOf('day').toDate();
    const week = moment().subtract(7, 'days').startOf('day').toDate();

    const todayList = submitted.filter(s => s.datetime >= today);
    const yesterdayList = submitted.filter(
      s => s.datetime >= yesterday && s.datetime < today,
    );
    const weekList = submitted.filter(
      s => s.datetime >= week && s.datetime < yesterday,
    );
    let topTenList = new Array<Record>();
    let result: React.ReactElement = <></>;

    if (showAll || search || hasFilters) {
      result = (
        <>
          {submitted.map((record, index) => {
            return renderRecord(false, record, index);
          })}
        </>
      );
    } else if (!showGroup) {
      result = (
        <>
          {submitted.slice(0, 10).map((record, index) => {
            return renderRecord(false, record, index);
          })}
        </>
      );
    } else {
      if (
        todayList.length === 0 &&
        yesterdayList.length === 0 &&
        weekList.length === 0
      )
        topTenList = submitted.slice(0, 10);

      result = (
        <>
          {renderRecordGroup(todayList, 'Today')}
          {renderRecordGroup(yesterdayList, 'Yesterday')}
          {renderRecordGroup(weekList, 'Last 7 Days')}
          {renderRecordGroup(topTenList, '')}
        </>
      );
    }

    return (
      <View style={ReportsStyleSheet.list}>
        <Text style={ReportsStyleSheet.listTitle}>
          {props.title ?? 'Submitted'}
        </Text>
        {result}
        {!showAll && !search && !hasFilters && submitted.length > 10 && (
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.smallGreenButton,
              { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() => setShowAll(true)}
            disabled={props.disabled}>
            <Text style={CommonStyleSheet.smallGreenButtonText}>Show All</Text>
          </Pressable>
        )}
      </View>
    );
  };

  const renderConvertAction = (index: number): React.ReactElement => {
    let submissionId = selectedRecord?.id;

    if (!canConvertSelected) return <></>;

    return (
      <CustomPressable
        key="convertaction"
        onPress={() => convertRecord(submissionId!)}
        style={({ pressed }) => [
          !canElevate && {
            opacity: 0.4,
          },
          pressed && {
            opacity: 0.6,
          },
          ReportsStyleSheet.draftAction,
          { paddingLeft: 5, paddingRight: 9 },
        ]}
        tooltip="Elevate to Full">
        <Icon icon={'convert'} color={Colors.white} size={20} />
      </CustomPressable>
    );
  };

  const confirmDeleteRecord = () => {
    if (canDelete) deleteModalCommand.current.openModal();
    else noDeleteModalCommand.current.openModal();
  };

  const renderActions = (
    canConvert: boolean,
    canDeleteDraft: boolean,
    index: number,
  ): React.ReactElement => {
    const actions = (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {canConvert && renderConvertAction(index)}
        {canDeleteDraft && (
          <CustomPressable
            onPress={() => {
              confirmDeleteRecord();
            }}
            style={({ pressed }) => [
              pressed && {
                opacity: 0.6,
              },
              !canDelete && {
                opacity: 0.4,
              },
              ReportsStyleSheet.draftAction,
            ]}
            tooltip="Delete">
            <Icon icon={'trash'} color={Colors.white} size={20} />
          </CustomPressable>
        )}
        <CustomPressable
          onPress={() => showPDFModal(selectedRecord!)}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'Print PDF'}>
          <Icon icon={'print'} color={Colors.white} size={20} />
        </CustomPressable>
        <CustomPressable
          onPress={() => {
            recordPressed(selectedRecord!);
          }}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'View' + (canDelete ? ' / Edit' : '')}>
          <Icon icon={'eye'} color={Colors.white} size={20} />
        </CustomPressable>
      </View>
    );

    return actions;
  };

  const renderDate = (record: Record): React.ReactElement => {
    let sub = props.records?.find(x => String(x._id) == record.id);
    if (!sub) return <></>;

    let date = moment(record.datetime).format('MMM DD');
    let time = moment(record.datetime).format('HH:mm');

    let backgroundColor = Colors.white;
    let color = Colors.darkGreen;

    // If "props.showDueDate === true" paint dates based on that
    if (props.showDueDate === true) {
      let status = sub?.submissionStatus.name;
      if (status === SubmissionStatuses.COMPLETE) {
        backgroundColor = Colors.teal;
        color = Colors.white;
      } else if (status === SubmissionStatuses.DISMISSED) {
        backgroundColor = Colors.gray;
        color = Colors.white;
      } else if (record.datetime < moment().toDate()) {
        backgroundColor = Colors.red;
        color = Colors.white;
      }
    }

    return (
      <View
        style={[
          ReportsStyleSheet.listItemDateContent,
          { backgroundColor: backgroundColor },
        ]}>
        <Text style={[ReportsStyleSheet.listItemDate, { color: color }]}>
          {date}
        </Text>
        <Text style={[ReportsStyleSheet.listItemDate, { color: color }]}>
          {time}
        </Text>
      </View>
    );
  };

  const renderRecord = (
    isDraft: boolean,
    record: Record,
    index: number,
  ): React.ReactElement => {
    let canConvert = false,
      canDeleteDraft = false;

    if (isDraft) canDeleteDraft = true;
    else canConvert = true;
    return (
      <View
        key={record.key}
        style={{
          flexDirection: 'row',
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            { flex: 1 },
            props.showAssignedTo && { paddingVertical: 18 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => setSelectedRecord(record)}
          disabled={props.disabled}>
          <View style={{ marginHorizontal: 12 }}>
            {props.useLocalIcons ? (
              <Icon
                icon={record.icon as IconValue}
                color={Colors.darkestGreen}
                size={24}
              />
            ) : (
              <>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    record.icon,
                  )}`}
                  style={{ color: Colors.darkGreen }}
                />
              </>
            )}
          </View>
          <Text style={ReportsStyleSheet.listItemName} numberOfLines={2}>
            {record.name}
          </Text>
          {props.showAssignedTo ? (
            <Text
              style={[
                ReportsStyleSheet.listItemDate,
                {
                  position: 'absolute',
                  left: 46,
                  bottom: 4,
                  fontSize: 12,
                  paddingHorizontal: 6,
                  backgroundColor: Colors.lightTeal,
                  borderRadius: 6,
                },
              ]}
              numberOfLines={1}>
              {record.assignedText}
            </Text>
          ) : null}
          {renderDate(record)}
        </Pressable>
        {selectedRecord?.id == record.id &&
          renderActions(canConvert, canDeleteDraft, index)}
      </View>
    );
  };

  const renderPDFModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingPDFModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Download Documents
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsShowingPDFModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 24 }}>
              <View style={ControlsStyleSheet.attachmentsContainer}>
                {isShowingPDFModal && recordToPDF && (
                  <DynamicPDF
                    submissionId={recordToPDF.id}
                    type="submission"></DynamicPDF>
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View>
      {props.showSearch !== false && (
        <SearchBox
          onChangeSearch={newSearch => setSearch(newSearch)}
          onChangeFilter={filterValue => setFilterValue(filterValue)}
          showFilters={props.showFilters}
          filters={props.filters}
          category={props.category}
          disabled={props.disabled}
        />
      )}
      <ConfirmModal
        title="No Access"
        message="You do not have access to elevate this report"
        okClick={() => {}}
        okText="Ok"
        setOpenModal={(func: () => void) => {
          elevateModalCommand.current.setOpenModal(func);
        }}
      />
      <ConfirmModal
        title="No Access"
        message="You do not have access to delete this report"
        okClick={() => {}}
        okText="Ok"
        setOpenModal={(func: () => void) => {
          noDeleteModalCommand.current.setOpenModal(func);
        }}
      />
      <ConfirmModal
        title="Delete this record"
        message="Are you sure you want to delete this record?"
        cancelClick={() => {}}
        cancelText="No"
        okClick={() => {
          deleteRecord();
        }}
        okText="Delete"
        setOpenModal={(func: () => void) => {
          deleteModalCommand.current.setOpenModal(func);
        }}
      />
      {props.showDrafts && renderDrafts()}
      {renderSubmitted()}
      {renderPDFModal()}
    </View>
  );
};

export default RecordList;
