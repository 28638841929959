import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Pressable, Modal, ActivityIndicator } from 'react-native';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Geolocation from '@react-native-community/geolocation';
import DynamicTextBox from './DynamicTextBox';
import Icon from '../Shared/Icon';
import { DynamicLocationMapProps } from '../../Types/ControlTypes';
import { ControlTypes } from '../../Constants/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';

const DynamicLocationMap = (
  props: DynamicLocationMapProps,
): React.ReactElement => {
  const LATLNG_DECIMALS = 7;

  const [value, setValue] = useState(getValueFromProps(props.value));
  const [error, setError] = useState('');
  const [myLocation, setMyLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [region] = useState(getRegionFromProps(props.value));
  const [isEditing, setIsEditing] = useState(false);
  const [editingLatitude, setEditingLatitude] = useState('');
  const [editingLongitude, setEditingLongitude] = useState('');
  const [editingValue, setEditingValue] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({ latitude: null, longitude: null });

  const [mapStatus, setMapStatus] = useState<Status | null>(null);
  const mapMounted = useRef(false);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [marker, setMarker] = useState<google.maps.Marker | undefined>(
    undefined,
  );
  const [myLocationMarker, setMyLocationMarker] = useState<
    google.maps.Marker | undefined
  >(undefined);
  const mapRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (mapRef.current && mapStatus === Status.SUCCESS && !mapMounted.current) {
      let lat = 0,
        long = 0;
      if (region) {
        lat = region.latitude;
        long = region.longitude;
      }

      let newMap = new window.google.maps.Map(mapRef.current, {
        center: { lat: lat, lng: long },
        zoom: 5,
        minZoom: 5,
        panControl: true,
        mapTypeControl: false,
        streetViewControl: false,
      });

      google.maps.event.addListener(newMap, 'click', function (event: any) {
        saveValue({
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        });
      });

      mapMounted.current = true;
      setMap(newMap);

      if (value) placeValueMarker(value.latitude, value.longitude, newMap);

      Geolocation.getCurrentPosition(info =>
        setMyLocation({
          latitude: info.coords.latitude,
          longitude: info.coords.longitude,
        }),
      );
    }
  }, [mapRef, mapStatus]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(
        props.controlId,
        props.controlTypeId,
        value ? JSON.stringify(value) : '',
        valid(),
      );

    if (value && map) placeValueMarker(value.latitude, value.longitude, map);
  }, [value]);

  useEffect(() => {
    if (myLocation) {
      if (myLocationMarker)
        myLocationMarker.setPosition({
          lat: myLocation.latitude,
          lng: myLocation.longitude,
        });
      else {
        let newMarker = new google.maps.Marker({
          position: { lat: myLocation.latitude, lng: myLocation.longitude },
          map: map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            strokeColor: '#4285F4',
          },
          clickable: false,
          zIndex: 1,
        });

        setMyLocationMarker(newMarker);
      }
    }
  }, [myLocation]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  useEffect(() => {
    //Validating latitude input
    if (!editingLatitude)
      //If it's empty clear the value
      setEditingValue({ ...editingValue, latitude: null });
    else if (
      !isNaN(+editingLatitude) &&
      +editingLatitude <= 90 &&
      +editingLatitude >= -90
    )
      //If it's valid safe the value
      setEditingValue({ ...editingValue, latitude: +editingLatitude });
    //If it's not valid, go back to the latest valid saved value
    else setEditingLatitude(editingValue?.latitude?.toString() ?? '');
  }, [editingLatitude]);

  useEffect(() => {
    //Validating longitude input
    if (!editingLongitude)
      //If it's empty clear the value
      setEditingValue({ ...editingValue, longitude: null });
    else if (
      !isNaN(+editingLongitude) &&
      +editingLongitude <= 180 &&
      +editingLongitude >= -180
    )
      //If it's valid safe the value
      setEditingValue({ ...editingValue, longitude: +editingLongitude });
    //If it's not valid, go back to the latest valid saved value
    else setEditingLongitude(editingValue?.longitude?.toString() ?? '');
  }, [editingLongitude]);

  function getValueFromProps(
    propsValue: string | undefined,
  ): { latitude: number; longitude: number } | null {
    let newValue = null;

    if (propsValue) {
      try {
        let location = JSON.parse(propsValue) as {
          latitude: number;
          longitude: number;
        };

        if (location && location.latitude && location.longitude)
          newValue = {
            latitude: +location.latitude.toFixed(LATLNG_DECIMALS),
            longitude: +location.longitude.toFixed(LATLNG_DECIMALS),
          };
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad location value serialization');
      }
    }

    return newValue;
  }

  function getRegionFromProps(propsValue: string | undefined): {
    latitude: number;
    longitude: number;
  } {
    // Canada coordinates as default region
    let newRegion = { latitude: 54.2217134, longitude: -116.7579707 };

    if (propsValue) {
      try {
        let location = JSON.parse(propsValue) as {
          latitude: number;
          longitude: number;
        };

        if (location)
          newRegion = {
            latitude: location?.latitude,
            longitude: location?.longitude,
          };
      } catch (error) {
        /* TODO: Log errors. */
        console.log('Error: Bad location value serialization');
      }
    } else if (props.config?.initialRegion) {
      newRegion = {
        latitude: props.config?.initialRegion?.latitude,
        longitude: props.config?.initialRegion?.longitude,
      };
    }

    return newRegion;
  }

  function saveValue(location: { latitude: number; longitude: number }): void {
    let newValue = {
      latitude: +location.latitude.toFixed(LATLNG_DECIMALS),
      longitude: +location.longitude.toFixed(LATLNG_DECIMALS),
    };

    setValue(newValue);
  }

  function placeValueMarker(
    latitude: number,
    longitude: number,
    markerMap: google.maps.Map,
  ): void {
    if (mapRef.current) {
      if (marker) marker.setPosition({ lat: latitude, lng: longitude });
      else {
        let newMarker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: markerMap,
          clickable: false,
          zIndex: 10,
        });

        setMarker(newMarker);
      }
    }
  }

  function useMyLocation(): void {
    if (myLocation) {
      saveValue(myLocation);

      if (map)
        map.setCenter({ lat: myLocation.latitude, lng: myLocation.longitude });
    }
  }

  function showEditModal(): void {
    setIsEditing(true);

    setEditingLatitude(value?.latitude.toString() ?? '');
    setEditingLongitude(value?.longitude.toString() ?? '');
    setEditingValue({
      latitude: value?.latitude ?? null,
      longitude: value?.longitude ?? null,
    });
  }

  function edit(): void {
    if (editingValue.latitude && editingValue.longitude) {
      let newValue = {
        latitude: editingValue.latitude,
        longitude: editingValue.longitude,
      };

      saveValue(newValue);
      setIsEditing(false);

      if (map)
        map.setCenter({ lat: newValue.latitude, lng: newValue.longitude });
    }
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value === null) {
      isValid = false;
      error = 'This field is required';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderMap = (status: Status): React.ReactElement => {
    switch (status) {
      case Status.LOADING:
        return (
          <div style={ControlsStyleSheet.mapView}>
            <ActivityIndicator color={Colors.white} size={140} />
          </div>
        );
      case Status.SUCCESS:
        return <div ref={mapRef} style={ControlsStyleSheet.mapView} />;
      case Status.FAILURE:
        return (
          <div style={ControlsStyleSheet.mapView}>
            <Text style={{ color: Colors.white }}>Unable to load the map</Text>
          </div>
        );
    }
  };

  const renderEditModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isEditing}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.mapEditModal}>
            <View style={ControlsStyleSheet.mapEditModalBar}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsEditing(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <View style={{ flexDirection: 'row', gap: 20, marginTop: 24 }}>
              <View style={{ flex: 1 }}>
                <DynamicTextBox
                  label="Latitude"
                  value={editingLatitude ?? ''}
                  onChange={(controlId, controlTypeId, value) =>
                    setEditingLatitude(value)
                  }
                />
              </View>
              <View style={{ flex: 1 }}>
                <DynamicTextBox
                  label="Longitude"
                  value={editingLongitude ?? ''}
                  onChange={(controlId, controlTypeId, value) =>
                    setEditingLongitude(value)
                  }
                />
              </View>
            </View>
            <Pressable
              disabled={
                editingValue.latitude === null ||
                editingValue.longitude === null
              }
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                (editingValue.latitude === null ||
                  editingValue.longitude === null) && { opacity: 0.4 },
                { alignSelf: 'center', marginTop: 20, marginBottom: 20 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={edit}>
              <Text style={CommonStyleSheet.greenButtonText}>Done</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View style={ControlsStyleSheet.mapContainer}>
        <Wrapper
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
          render={renderMap}
          callback={status => setMapStatus(status)}
        />
      </View>
      {mapStatus !== Status.SUCCESS ? null : (
        <View>
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.mapCoordinatesContainer,
              pressed && {
                backgroundColor: Colors.lightTealPressed,
              },
            ]}
            disabled={props.disabled}
            onPress={() => showEditModal()}>
            <View style={ControlsStyleSheet.mapCoordinatesContent}>
              <Text style={ControlsStyleSheet.mapCoordinatesText}>
                Lat: {value?.latitude ?? '-'}
              </Text>
              <Text style={ControlsStyleSheet.mapCoordinatesText}>
                Long: {value?.longitude ?? '-'}
              </Text>
              {!props.disabled && (
                <Icon icon={'pencil'} color={Colors.green} size={18} />
              )}
            </View>
          </Pressable>
        </View>
      )}
      {!props.disabled && (
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.whiteButton,
            { marginTop: 32 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={useMyLocation}>
          <Text style={CommonStyleSheet.whiteButtonText}>
            Use My Current Location
          </Text>
        </Pressable>
      )}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      {renderEditModal()}
    </View>
  );
};

export default DynamicLocationMap;
