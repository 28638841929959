import React, { useState, useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  Image,
  ImageBackground,
  Text,
  Animated,
  Pressable,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId, UUID } from 'bson';
import RecordList from '../Shared/RecordList';
import QuickAddList from '../Shared/QuickAddList';
import Icon from '../Shared/Icon';
import CustomPressable from '../Shared/CustomPressable';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';
import { HomeStyleSheet } from '../../Styles/HomeStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { Navigation } from '../../Constants/Navigation';
import { DynamicScreenButton } from '../../Types/ControlTypes';
import Colors from '../../Styles/Shared/Colors';
import { Images } from '../../Constants/Images';
import {
  METADATA_KEYS,
  COMMON_TEMPLATES,
  CATEGORY,
  PERMISSIONS,
} from '../../Constants/AppConstants';
import { useIsFocused } from '@react-navigation/native';
import { usePermission } from '../../Providers/PermissionProvider';
import { useRelation } from '../../Providers/SubmissionRelationProvider';
import { FilterTypes } from '../../Constants/General';

export declare type DashboardProps = {
  navigation: any;
};

const Dashboard = (props: DashboardProps): React.ReactElement => {
  const [submitted, setSubmitted] = useState<Submission[]>();
  const [headerImage, setHeaderImage] = useState(Images.GOOD_MORNING);
  const [headerText, setHeaderText] = useState('');
  const [selectedTask, setSelectedTask] = useState<Submission | null>(null);
  const [screenButtons, setScreenButtons] = useState<DynamicScreenButton[]>([]);

  const opacityHeader = useRef(new Animated.Value(0)).current;

  const {
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissionStatuses,
    getRealmApp,
    upsertSubmission,
    submissions,
    people,
  } = useSync();
  const { hasPermission } = usePermission();
  const { getRelatedSubmissions } = useRelation();
  const isFocused = useIsFocused();

  const unselectCommand = useRef({
    unselect: () => {},
    setUnselectCommand(func: () => void) {
      this.unselect = func;
    },
  });

  useEffect(() => {
    const hours = new Date().getHours();

    if (hours >= 5 && hours < 12) {
      setHeaderImage(Images.GOOD_MORNING);
      setHeaderText('Good morning');
    } else if (hours >= 12 && hours < 18) {
      setHeaderImage(Images.GOOD_AFTERNOON);
      setHeaderText('Good afternoon');
    } else {
      setHeaderImage(Images.GOOD_EVENING);
      setHeaderText('Good evening');
    }

    Animated.timing(opacityHeader, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();

    const fetchScreenButtons = async () => {
      let filteredTemplates = (await getFilteredTemplates())
        .filter(
          t =>
            t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.HOME,
        )
        .sort((a, b) => a.row - b.row);

      let newScreenButtons: DynamicScreenButton[] = [];

      for (let i = 0; i < filteredTemplates.length; i++) {
        let templateId: ObjectId = filteredTemplates[i]._id;
        let versions = (await getFilteredTemplateVersions())
          .filter(x => x.templateId.equals(templateId))
          .sort(x => x.version)
          .reverse();

        if (versions && versions.length > 0) {
          newScreenButtons.push({
            templateName: filteredTemplates[i].name,
            label: filteredTemplates[i].name,
            icon: filteredTemplates[i].iconSvg ?? '',
            version: versions[0].version,
          });
        }
      }

      setScreenButtons(newScreenButtons);
    };

    fetchScreenButtons();
  }, []);

  useEffect(() => {
    if (submissions.length > 0 && people.length > 0) fetchList();
  }, [submissions, people]);

  useEffect(() => {
    if (submissions.length > 0 && people.length > 0 && isFocused) fetchList();
  }, [isFocused]);

  const fetchList = async () => {
    let viewReport = hasPermission(PERMISSIONS.TASK_VIEW);
    let templates = await getFilteredTemplates();
    let filteredTemplates = templates
      .filter(
        t =>
          t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.TASKS ||
          // Include task created on operations page
          t.name.toLowerCase().trim() ===
            COMMON_TEMPLATES.TASK.toLowerCase().trim(),
      )
      .map(t => t.name);

    let filteredSubmissions: Submission[] = [];
    if (viewReport) {
      filteredSubmissions = submissions.filter(
        s =>
          (s.submissionStatus.name === SubmissionStatuses.SUBMITTED ||
            s.submissionStatus.name === SubmissionStatuses.COMPLETE ||
            s.submissionStatus.name === SubmissionStatuses.DISMISSED) &&
          filteredTemplates.includes(s.templateVersion.name!),
      );
    }
    //If no view permissions can only see forms that user created or that they can manage
    else {
      //Special permissions for PM/Supervisor
      let user = getRealmApp().currentUser;
      if (user) {
        let userEmail = user.customData['email'];
        let personLoggedIn = people.find(x => x.email == userEmail);

        if (personLoggedIn) {
          let submissionIds = await getRelatedSubmissions(personLoggedIn._id);
          filteredSubmissions = submissions.filter(
            x =>
              submissionIds.includes(x._id.toHexString()) ||
              x.createdBy == user?.customData['email'],
          );
        }
      }
    }

    setSubmitted(filteredSubmissions);
  };

  async function openDynamicForm(
    templateName: string,
    showConfirmation?: boolean,
  ): Promise<void> {
    let template = (await getFilteredTemplates()).find(
      t => t.name === templateName,
    );

    if (template) {
      let templateId = template._id;
      let versions = (await getFilteredTemplateVersions())
        .filter(x => x.templateId.equals(templateId))
        .sort(x => x.version)
        .reverse()
        .map(x => ({ _id: x._id, name: x.name }));

      let newDraftId: ObjectId = new ObjectId();
      const draftStatus: SubmissionStatus = (
        await getSubmissionStatuses()
      ).find(x => x.name == SubmissionStatuses.DRAFT)!;

      let currentUser = getRealmApp().currentUser;
      let org = '';
      let currentEmail = '';
      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }
        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let newSubmission: Submission = {
        _id: newDraftId,
        partition: org,
        createdBy: currentEmail as string,
        updatedBy: currentEmail as string,
        templateVersion: {
          _id: versions[0]._id,
          name: versions[0].name,
        },
        templateType: {
          _id: template!.templateType._id,
          name: template!.templateType.name,
        },
        submissionStatus: {
          _id: draftStatus._id,
          name: draftStatus.name,
        },
        SQLServerId: new UUID().toHexString(),
        answers: [],
        metadataJSON: '',
        createDateTimeStamp: new Date(),
        updateDateTimeStamp: new Date(),
      };

      await upsertSubmission(newSubmission);

      if (newDraftId == null) throw Error('Error: create draft failed!');

      props.navigation.navigate(Navigation.DYNAMICFORM, {
        submissionId: newDraftId,
        showConfirmation: showConfirmation,
        submissionStatus: SubmissionStatuses.DRAFT,
      });
    }
  }

  function recordPressed(recordId: string): void {
    props.navigation.navigate(Navigation.COMPLETIONFORM, {
      submissionId: recordId,
      title: 'Task',
    });
  }

  const renderScreenButton = (
    button: DynamicScreenButton,
    index: number,
  ): React.ReactElement => {
    return (
      <React.Fragment key={index}>
        <CustomPressable
          style={({ pressed }) => [
            CommonStyleSheet.screenButton,
            { overflow: 'visible' },
            pressed && { opacity: 0.6 },
          ]}
          onPress={() => openDynamicForm(button.templateName, false)}>
          {button.icon !== '' && (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                //Workaround: Mobile home buttons are white with dark background
                //web are green with white background
                //img doesn't work with currentColor
                button.icon
                  .replaceAll(Colors.darkestGreen, Colors.white)
                  .replaceAll('currentColor', Colors.green),
              )}`}
            />
          )}
          <Text style={CommonStyleSheet.screenButtonText}>{button.label}</Text>
        </CustomPressable>
        {index % 2 === 1 && <View style={{ flexBasis: '100%' }}></View>}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ScrollView style={{ backgroundColor: Colors.white }}>
        <Pressable
          style={{
            //@ts-ignore
            cursor: 'auto',
          }}
          onPress={() => {
            unselectCommand.current.unselect();
          }}>
          <>
            <Animated.View style={{ opacity: opacityHeader }}>
              <ImageBackground
                source={headerImage}
                resizeMode="cover"
                style={{ height: 260 }}>
                <Text style={HomeStyleSheet.headerTitle}>{headerText}</Text>
              </ImageBackground>
            </Animated.View>
            <View style={{ flexDirection: 'row' }}>
              <View style={HomeStyleSheet.tasksList}>
                <RecordList
                  records={submitted}
                  title={'Tasks'}
                  category={CATEGORY.HOME}
                  showDrafts={false}
                  showGroup={false}
                  showDueDate={true}
                  showAssignedTo={true}
                  hasMetaData={true}
                  metaDataKey_title={METADATA_KEYS.TITLE}
                  metaDataKey_date={METADATA_KEYS.DUEDATE}
                  metaDataKey_time={METADATA_KEYS.DUETIME}
                  showFilters={true}
                  filters={[
                    { label: 'Due Date Range', type: FilterTypes.DATE_RANGE },
                    { label: '', type: FilterTypes.TASK_STATUS },
                  ]}
                  navigation={props.navigation}
                  onRecordPressed={recordId => recordPressed(recordId)}
                  setUnselectCommand={(func: () => void) => {
                    unselectCommand.current.setUnselectCommand(func);
                  }}
                />
              </View>
              <View style={{ flex: 1 }}>
                <View style={CommonStyleSheet.screenButtonsContainer}>
                  {screenButtons.map((screenButton, index) => {
                    return renderScreenButton(screenButton, index);
                  })}
                </View>
                <View style={CommonStyleSheet.bcsfFooter}>
                  <Image
                    source={Images.BCSF}
                    style={CommonStyleSheet.bcsfFooterImage}
                  />
                  <Text style={CommonStyleSheet.bcsfFooterText}>
                    BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
                  </Text>
                </View>
              </View>
            </View>
          </>
        </Pressable>
      </ScrollView>
      <QuickAddList navigation={props.navigation} />
    </React.Fragment>
  );
};

export default Dashboard;
