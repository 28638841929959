import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text, Pressable } from 'react-native';
import { ObjectId, UUID } from 'bson';
import moment from 'moment';
import { useSync } from '../../Providers/SyncProvider';
import { useToast } from 'react-native-toast-notifications';
import Icon from '../Shared/Icon';
import { Record, RecordType } from '../Views/TrainingBreakdown';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Person } from '../../Models/RealmModels/Person';
import { Navigation } from '../../Constants/Navigation';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import { SubmissionTraining } from '../../Models/RealmModels/SubmissionTraining';
import { TemplateTypes } from '../../Constants/TemplateCategoryTypes';
import useQRCode from '../../Hooks/useQRCode';
import ProfilePicture from '../Shared/ProfilePicture';

type ProfileProps = {
  navigation: any;
  personId?: string;
};

const Profile = (props: ProfileProps): React.ReactElement => {
  const [profileId, setProfileId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [job, setJob] = useState('');
  const [ehsId, setEHSId] = useState('');

  const [records, setRecords] = useState<Record[]>([]);

  const { renderQRCode } = useQRCode();
  const {
    getPeople,
    upsertPerson,
    getOrganisations,
    getJobs,
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissionTrainings,
    getSubmissionStatuses,
    getRealmApp,
    getSQLUsers,
    upsertSubmissionTraining,
  } = useSync();
  const toast = useToast();

  useEffect(() => {
    const getPerson = async () => {
      let person: Person | undefined = undefined;
      if (props.personId)
        person = (await getPeople({})).find(x => x._id.equals(props.personId!));
      else {
        const currentUser = getRealmApp().currentUser;
        if (currentUser && currentUser.profile.personId)
          person = (await getPeople({})).find(
            x =>
              x.SQLServerId.toLowerCase() ===
              String(currentUser!.profile.personId).toLowerCase(),
          );
      }

      if (person) {
        setProfileId(person.SQLServerId);
        setName(person.firstName + ' ' + person.lastName);
        setEmail(person.email ?? '');
        setPhone(getPhone(person));

        let org = (await getOrganisations()).find(
          o => o.SQLServerId === person!.partition,
        );
        if (org) setOrganisation(org.name);

        if (person?.jobId) {
          let personJob = (await getJobs()).find(
            j => j._id.toHexString() === person!.jobId,
          );
          if (personJob) setJob(personJob.name);
        }

        let user = (await getSQLUsers()).find(
          x => x.SQLServerPersonId == person!.SQLServerId,
        );
        if (user) setEHSId(user.ehsId);

        fetchRecords(person._id);
      }
    };
    getPerson();
  }, []);

  const fetchRecords = async (personId: ObjectId) => {
    let filteredRecords = (await getSubmissionTrainings()).filter(
      s =>
        s.submissionStatus.name === SubmissionStatuses.SUBMITTED &&
        s.person.id?.equals(personId),
    );

    let mappedRecords: Record[] = [];
    filteredRecords.forEach(s => {
      let expiryDate: Date | undefined = undefined;
      if (s.metadataJSON) {
        let meta = JSON.parse(s.metadataJSON);

        let expiryDateMeta = meta[METADATA_KEYS.EXPIRY_DATE]
          ? meta[METADATA_KEYS.EXPIRY_DATE]
          : '';
        if (expiryDateMeta) {
          let momentDate = moment(expiryDateMeta, Formats.BACKEND_DATE);
          if (momentDate.isValid()) expiryDate = momentDate.toDate();
        }
      }

      let status = 'Valid';
      if (expiryDate) {
        if (expiryDate <= moment().startOf('day').toDate()) status = 'Expired';
        else if (expiryDate <= moment().startOf('day').add(1, 'month').toDate())
          status = 'Expiring Soon';
      } else status = 'Never Expires';

      let recordType: RecordType | undefined = undefined;
      if (s.trainingType.id && s.trainingType.name)
        recordType = {
          id: s.trainingType.id,
          name: s.trainingType.name ?? '',
          source: 'training',
        };
      else if (s.certificateType.id)
        recordType = {
          id: s.certificateType.id,
          name: s.certificateType.name ?? '',
          source: 'certificate',
        };

      if (recordType)
        mappedRecords.push({
          id: s._id,
          person: {
            id: s.person.id,
            name: s.person.firstName + ' ' + s.person.lastName,
          },
          status: status,
          expiryDate: expiryDate,
          type: recordType,
          icon: '',
        });
    });

    setRecords(mappedRecords);
  };

  function getPhone(person: Person): string {
    let phone = person.phone ?? '';

    let parsedValue = parseInt(person.phone ?? '');
    if (!isNaN(parsedValue) && String(parsedValue).length === 10) {
      let cleanedPhone = String(parsedValue);
      phone =
        '(' +
        cleanedPhone.substring(0, 3) +
        ') ' +
        cleanedPhone.substring(3, 6) +
        '-' +
        cleanedPhone.substring(6);
    }

    return phone;
  }

  const goToTrainingAndCertificates = async (record?: Record) => {
    if (record)
      props.navigation.push(Navigation.TRAININGFORM, {
        submissionId: record.id,
      });
    else {
      let template = (await getFilteredTemplates()).find(
        t => t.templateType.name === TemplateTypes.TRAINING_BREAKDOWN,
      );

      if (template) {
        let templateId = template._id;
        let versions = (await getFilteredTemplateVersions())
          .filter(x => x.templateId.equals(templateId))
          .sort(x => x.version)
          .reverse()
          .map(x => ({ _id: x._id, name: x.name }));

        let newDraftId: ObjectId = new ObjectId();
        const draftStatus: SubmissionStatus = (
          await getSubmissionStatuses()
        ).find(x => x.name == SubmissionStatuses.DRAFT)!;

        let currentUser = getRealmApp().currentUser;
        let org = '';
        let currentEmail = '';
        if (currentUser && currentUser.profile) {
          if (typeof currentUser.profile.organisation === 'string') {
            let organisation = JSON.parse(currentUser.profile.organisation);
            org = organisation.Id.toString();
          }
          if (typeof currentUser.profile.email === 'string') {
            currentEmail = currentUser.profile.email;
          }
        }

        let newSubmission: SubmissionTraining = {
          _id: newDraftId,
          partition: org,
          createdBy: currentEmail as string,
          updatedBy: currentEmail as string,
          templateVersion: {
            _id: versions[0]._id,
            name: versions[0].name,
          },
          templateType: {
            _id: template!.templateType._id,
            name: template!.templateType.name,
          },
          submissionStatus: {
            _id: draftStatus._id,
            name: draftStatus.name,
          },
          SQLServerId: new UUID().toHexString(),
          answers: [],
          metadataJSON: '',
          createDateTimeStamp: new Date(),
          updateDateTimeStamp: new Date(),
          person: {},
          trainingType: {},
          certificateType: {},
        };

        await upsertSubmissionTraining(newSubmission);

        if (newDraftId == null) throw Error('Error: create draft failed!');

        props.navigation.navigate(Navigation.TRAININGFORM, {
          submissionId: newDraftId,
        });
      }
    }
  };

  const onChangeProfilePicture = async (
    photo: string,
    photoThumbnail: string,
  ) => {
    let realmPerson = (await getPeople({})).find(
      x => x.SQLServerId.toLowerCase() == profileId.toLowerCase(),
    );
    if (realmPerson) {
      realmPerson.photo = photo;
      realmPerson.photoThumbnail = photoThumbnail;
      await upsertPerson(realmPerson);

      toast.show('Profile picture saved successfully', {
        type: 'success',
      });
    }
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => goToTrainingAndCertificates(record)}>
        <Icon
          icon={
            record.type.source === 'training' ? 'training' : 'certification'
          }
          color={Colors.darkestGreen}
          size={24}
        />
        <Text
          style={[ReportsStyleSheet.listItemName, { paddingLeft: 12 }]}
          numberOfLines={1}>
          {record.type.name}
        </Text>
        {renderExpiryDate(record)}
        <Icon icon={'list-item-nav'} color={Colors.darkestGreen} size={24} />
      </Pressable>
    );
  };

  const renderExpiryDate = (record: Record): React.ReactElement => {
    let date = record.expiryDate
      ? moment(record.expiryDate).format('MMM DD YYYY')
      : '';

    let backgroundColor = '';
    let color = '';

    switch (record.status) {
      case 'Valid':
      case 'Never Expires':
        backgroundColor = Colors.lightgray;
        color = Colors.darkGreen;
        break;
      case 'Expiring Soon':
        backgroundColor = Colors.yellow;
        color = Colors.darkGreen;
        break;
      case 'Expired':
        backgroundColor = Colors.red;
        color = Colors.white;
        break;
      default:
        break;
    }

    return (
      <View
        style={[
          ReportsStyleSheet.listItemDateContent,
          { backgroundColor: backgroundColor, marginRight: 12 },
        ]}>
        <Text style={[ReportsStyleSheet.listItemDate, { color: color }]}>
          {date ? date : 'Never Expires'}
        </Text>
      </View>
    );
  };

  return (
    <ScrollView>
      <View
        style={{
          flexDirection: 'row',
          gap: 24,
          height: 260,
          backgroundColor: Colors.darkestGreen,
          paddingHorizontal: 24,
        }}>
        {profileId ? (
          <ProfilePicture
            profileId={profileId}
            onChange={onChangeProfilePicture}
          />
        ) : null}
        <View style={{ justifyContent: 'center' }}>
          <Text style={CommonStyleSheet.profileName}>{name}</Text>
          <Text style={CommonStyleSheet.profileEmail}>{email}</Text>
          <Text style={CommonStyleSheet.profilePhone}>{phone}</Text>
          <Text style={CommonStyleSheet.profileOrganisation}>
            {organisation}
            {job ? '   |   ' + job : ''}
          </Text>
        </View>
        {ehsId ? (
          <View style={CommonStyleSheet.profileQRCode}>
            {renderQRCode(ehsId)}
          </View>
        ) : null}
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          <View style={{ marginHorizontal: 12 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginVertical: 24,
              }}>
              <Text style={ControlsStyleSheet.labelLarge}>
                Training And Certificates
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.linkRecordAddButton,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                  },
                ]}
                onPress={() => goToTrainingAndCertificates()}>
                <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <View style={{ marginBottom: 24 }}>
              {records.map((record, index) => {
                return renderRecord(record, index);
              })}
            </View>
          </View>
        </View>
        <View style={{ flex: 1 }}></View>
      </View>
    </ScrollView>
  );
};

export default Profile;
