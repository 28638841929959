import React, { useContext } from 'react';
import { ObjectId } from 'bson';

import { useSync } from './SyncProvider';
import { PERMISSIONS, RELATION_TYPE } from '../Constants/AppConstants';
import { usePermission } from './PermissionProvider';
import { SubmissionRelation } from '../Models/RealmModels/SubmissionRelation';

type SubmissionRelationProviderProps = {
  children: React.ReactNode;
};

type RelationContextValue = {
  updateSubmissionRelation: (
    submissionId: ObjectId,
    relationId: ObjectId,
    relationType: string,
  ) => void;
  getRelatedSubmissions: (id: ObjectId) => Promise<string[]>;
};

const RelationContext = React.createContext<RelationContextValue>(
  {} as RelationContextValue,
);

//This provider handles permissions regarding to items selected in a record in the Submissions table
//
const SubmissionRelationProvider: React.FunctionComponent<
  SubmissionRelationProviderProps
> = ({ children }): React.ReactElement => {
  const {
    getSubmissions,
    getSubmissionRelations,
    deleteSubmissionRelation,
    upsertSubmissionRelation,
    getPeople,
    getProjectSites,
    getSQLUsers,
    getOrgId,
    getRealmApp,
  } = useSync();
  const { hasPermission } = usePermission();

  //This method updates the relation table
  //It also  by type removes the previous relation if it exists
  const updateSubmissionRelation = async (
    submissionId: ObjectId,
    relationId: ObjectId,
    relationType: string,
  ) => {
    //Remove old submission relation if it exists
    let oldRelation = (await getSubmissionRelations()).find(
      x =>
        x.submissionId.equals(submissionId) && x.relationType === relationType,
    );

    if (oldRelation) deleteSubmissionRelation(oldRelation._id);

    let newSubmissionRelation: SubmissionRelation = {
      _id: new ObjectId(),
      partition: getOrgId(),
      relationId: relationId,
      submissionId: submissionId,
      relationType: relationType,
      createDateTimeStamp: new Date(),
    };

    upsertSubmissionRelation(newSubmissionRelation);
  };

  //Get submissions related to the id arg
  const getRelatedSubmissions = async (id: ObjectId) => {
    let submissionIds: string[] = [];
    let isSupervisor = hasPermission(PERMISSIONS.SUPERVISOR);
    let isProjectManager = hasPermission(PERMISSIONS.PROJECT_MANAGER);

    let supervisedPeople = (await getPeople({})).filter(
      x => x.supervisorId === id.toHexString() || x._id.equals(id),
    );

    if (isSupervisor || isProjectManager) {
      // person involved
      let supervisedSubmissions = (await getSubmissionRelations()).filter(x =>
        supervisedPeople
          .map(x => x._id.toHexString())
          .includes(x.relationId!.toHexString()),
      );

      submissionIds = supervisedSubmissions.map(x =>
        x.submissionId!.toHexString(),
      );
    }

    if (isProjectManager) {
      //project
      let managedProjects: string[] = [];

      managedProjects = (await getProjectSites({}))
        .filter(
          x =>
            x.projectManagerId === id.toHexString() ||
            x.safetyResourceId === id.toHexString() ||
            x._id.equals(id),
        )
        .map(x => x._id.toHexString());

      let managedSubmissions = (await getSubmissionRelations()).filter(x =>
        managedProjects.includes(x.relationId!.toHexString()),
      );

      submissionIds.push(
        ...managedSubmissions.map(x => x.submissionId!.toHexString()),
      );
    }

    if (isSupervisor) {
      //Userinvovled
      let supervisedUsers = (await getSQLUsers())
        .filter(x =>
          supervisedPeople
            .map(x => x.SQLServerId)
            .includes(x.SQLServerPersonId),
        )
        .map(x => x._id.toHexString());

      let supervisedSubmissions = (await getSubmissionRelations()).filter(x =>
        supervisedUsers.includes(x.relationId!.toHexString()),
      );

      submissionIds.push(
        ...supervisedSubmissions.map(x => x.submissionId!.toHexString()),
      );

      //Also add submissions that were created by people being supervised
      let supervisedPeopleEmails = supervisedPeople.map(x =>
        x.email?.toLowerCase(),
      );
      let createdBySupervisedPeople = (await getSubmissions())
        .filter(x => supervisedPeopleEmails.includes(x.createdBy.toLowerCase()))
        .map(x => x._id.toHexString());

      submissionIds.push(...createdBySupervisedPeople);
    }

    const currentUser = getRealmApp().currentUser;
    if (currentUser && currentUser.profile.email) {
      let sqlUser = (await getSQLUsers()).find(
        p => p.email === currentUser.profile.email,
      );

      if (sqlUser) {
        let userRelations = (await getSubmissionRelations()).filter(
          x =>
            x.relationId.equals(sqlUser!._id) &&
            x.relationType == RELATION_TYPE.USER_INVOLVED,
        );

        submissionIds.push(
          ...userRelations.map(x => x.submissionId!.toHexString()),
        );
      }
    }

    return submissionIds;
  };

  const value = {
    updateSubmissionRelation: updateSubmissionRelation,
    getRelatedSubmissions: getRelatedSubmissions,
  };

  return (
    <RelationContext.Provider value={value}>
      {children}
    </RelationContext.Provider>
  );
};

const useRelation = () => {
  const relationContext = useContext(RelationContext);

  if (relationContext === null)
    throw new Error('useRelation() called outside of a PermissionProvider?');

  return relationContext;
};

export { SubmissionRelationProvider, RelationContext, useRelation };
