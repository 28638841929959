import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import {
  DynamicGroupSelectorItem,
  DynamicGroupSelectorGroup,
  DynamicGroupSelectorProps,
} from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Icon from '../Shared/Icon';
import Colors from '../../Styles/Shared/Colors';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';

const DynamicGroupSelector = (
  props: DynamicGroupSelectorProps,
): React.ReactElement => {
  const [options, setOptions] = useState(
    props.config?.options ?? new Array<DynamicGroupSelectorGroup>(),
  );
  const [selection, setSelection] = useState(new Array<string>());
  const [value, setValue] = useState(props.value ?? '[]');
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const [error, setError] = useState('');

  const lineHeight = 39;
  const [numberOfLines, setNumberOfLines] = useState(1);

  useEffect(() => {
    setOptions(props.config?.options ?? new Array<DynamicGroupSelectorGroup>());
  }, [props.config?.options]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (isFocused === false) valid();
    else if (isFocused) Keyboard.dismiss();
  }, [isFocused]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function getText(): string {
    let text = '';

    if (value) {
      try {
        let valueArray = JSON.parse(value) as Array<string>;
        let availableOptions = options.map(group => group.items).flat();

        valueArray.forEach(value => {
          let option = availableOptions.find(o => o.value === value);
          if (option) {
            text += (text && ', ') + option.label;
          }
        });
      } catch (error) {
        console.log('Error: Bad groupselect serialization');
      }
    }

    return text;
  }

  function onContentSizeChange(newContentHeight: number) {
    let newNumberOfLines = Math.floor(newContentHeight / lineHeight) + 1;
    if (newNumberOfLines != numberOfLines) setNumberOfLines(newNumberOfLines);
  }

  function showModal(): void {
    if (props.disabled) return;
    Keyboard.dismiss();

    let valueArray = new Array<string>();
    if (value) {
      try {
        valueArray = JSON.parse(value) as Array<string>;
      } catch (error) {
        console.log('Error: Bad groupselect serialization');
      }
    }

    setSelection(valueArray);
    setIsFocused(true);
  }

  function hideModal(): void {
    setIsFocused(false);
  }

  function select(item: DynamicGroupSelectorItem): void {
    let newSelection = [...selection];
    let index = newSelection.indexOf(item.value);
    if (index === -1) newSelection.push(item.value);
    else newSelection.splice(index, 1);
    setSelection(newSelection);
  }

  function save(): void {
    setValue(JSON.stringify(selection));
    setIsFocused(false);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && (!value || value === '[]')) {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if ((props.showError || isFocused !== null) && !props.disabled)
      setError(error);

    return isValid;
  }

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isFocused ?? false}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label ?? ''}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => hideModal()}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView style={{ marginVertical: 20 }}>
              {options.map((group, index) => {
                return renderGroup(group, index);
              })}
            </ScrollView>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={save}>
              <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const renderGroup = (
    group: DynamicGroupSelectorGroup,
    index: number,
  ): React.ReactElement => {
    return (
      <View key={index}>
        <Text
          style={[
            ControlsStyleSheet.groupSelectorGroupTitle,
            index === 0 && { marginTop: 20 },
          ]}>
          {group.groupName}
        </Text>
        {group.items.map(item => {
          return renderItem(item);
        })}
      </View>
    );
  };

  const renderItem = (item: DynamicGroupSelectorItem): React.ReactElement => {
    let checked = selection.includes(item.value);
    return (
      <Pressable
        key={item.value}
        style={({ pressed }) => [
          ControlsStyleSheet.groupSelectorOptionContainer,
          pressed && {
            backgroundColor: Colors.lightTealPressed,
          },
        ]}
        onPress={() => select(item)}>
        <Text style={ControlsStyleSheet.checkBoxText}>{item.label}</Text>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            checked && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon icon={'checked'} color={Colors.white} size={16} />
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.config?.required ? '*' : ''}
        </Text>
      </Text>
      <View>
        <TextInput
          multiline={true}
          showSoftInputOnFocus={false}
          style={[
            ControlsStyleSheet.groupSelectorInput,
            isFocused && ControlsStyleSheet.inputFocused,
            error !== '' && ControlsStyleSheet.inputError,
            isFocused && error !== '' && ControlsStyleSheet.inputErrorFocused,
            { height: lineHeight * numberOfLines },
          ]}
          placeholder={props.config?.placeholder}
          placeholderTextColor={ControlsStyleSheet.placeholder.color}
          value={getText()}
          editable={!props.disabled}
          onContentSizeChange={event =>
            onContentSizeChange(event.nativeEvent.contentSize.height)
          }
        />
        {!props.disabled && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.groupSelector,
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
                borderRadius: 4,
              },
            ]}
            onPress={showModal}
            disabled={props.disabled}>
            <View style={ControlsStyleSheet.groupSelectorButton}>
              <Text style={ControlsStyleSheet.groupSelectorButtonText}>
                {value && value !== '[]' ? 'Change' : 'Select'}
              </Text>
            </View>
          </Pressable>
        )}
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      {renderModal()}
    </View>
  );
};

export default DynamicGroupSelector;
