import { ILogService } from './Interfaces/ILogService';
import ApiService from './ApiService';
import { AxiosRequestConfig } from 'axios';
import { LogDTO } from '../Types/DtoTypes';
import { browserName } from 'react-device-detect';

class LogService implements ILogService {
  sendLog = async (msg: string) => {
    let apiService = new ApiService(() => {});
    console.log('sengind log ' + msg);
    try {
      let logData: LogDTO = {
        Message: msg,
        AppVersion: process.env.REACT_APP_VERSION_NAME!,
        DeviceSource: 'FIRS Web',
        DevicePlatform: browserName,
      };

      let config: AxiosRequestConfig = {};

      config.data = logData;
      config.method = 'POST';
      config.url = process.env.REACT_APP_LOG_URL;
      config.headers = {
        'Content-Type': 'application/json',
      };

      let response = await apiService.sendRequest<any>(config);
      if (!response || response.status !== 200) {
        throw new Error('Error sending log');
      }
    } catch (err) {}
  };
}

export default LogService;
