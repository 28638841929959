import React, { useContext, useEffect } from 'react';
import { ObjectId, Decimal128, UUID } from 'bson';
import { useSync } from './SyncProvider';
import { Submission } from '../Models/RealmModels/Submission';
import { SubmissionStatus } from '../Models/RealmModels/SubmissionStatus';
import { Submission_answer } from '../Models/RealmModels/Submission';
import IConditionsHelperService from '../Services/Interfaces/IConditionsHelperService';
import ConditionsHelperService from '../Services/ConditionsHelperService';
import { MongoIdSqlId, SubmissionAnswerDTO } from '../Types/DtoTypes';
import {
  TemplateVersion,
  TemplateVersion_pages_controls,
} from '../Models/RealmModels/TemplateVersion';

type TemplateConversionProviderProps = {
  children: React.ReactNode;
};

type TemplateConversionContextValue = {
  canConvertSubmission: (submissionId: ObjectId) => Promise<boolean>;
  convertSubmission: (submissionId: ObjectId) => Promise<ObjectId | null>;
};

const TemplateConversionContext =
  React.createContext<TemplateConversionContextValue>(
    {} as TemplateConversionContextValue,
  );

//TemplateConversionProvider has 2 methods to handle converting documents
//hasConversion return true if a submission can be converted to another submission with a different template version
//convertSubmission creates a new submission with the new template version and copies matching answers by controlid over to from the
//old submission to the new submission
const TemplateConversionProvider: React.FunctionComponent<
  TemplateConversionProviderProps
> = ({ children }): React.ReactElement => {
  const {
    getRealmApp,
    getSubmissions,
    getFilteredTemplateVersions,
    getTemplateVersionConversions,
    getSubmissionStatuses,
    upsertSubmission,
    getFilteredTemplates,
    getPeople,
    getProjectSites,
  } = useSync();

  const conditionsHelper: IConditionsHelperService =
    new ConditionsHelperService();

  const canConvertSubmission = async (
    submissionId: ObjectId,
  ): Promise<boolean> => {
    let submissions = await getSubmissions();
    let submission = submissions.find(x => x._id.equals(submissionId));
    if (submission == null) throw new Error('Submission does not exist ');

    let conversions = await getTemplateVersionConversions();
    let conversion = conversions.find(x =>
      x.fromTemplateVersionId.equals(submission!.templateVersion!._id!),
    );

    if (conversion == null) return false;

    //Check if submission was already converted
    if (submission.metadataJSON) {
      let metaData = JSON.parse(submission.metadataJSON);
      if (metaData && metaData.converted) return false;
    }

    return true;
  };

  const convertSubmission = async (
    submissionId: ObjectId,
  ): Promise<ObjectId | null> => {
    let submissions = await getSubmissions();
    let submission = submissions.find(x => x._id.equals(submissionId));

    if (submission == null)
      throw new Error('Submission does not exist for conversion');

    let conversions = await getTemplateVersionConversions();
    let conversion = conversions.find(x =>
      x.fromTemplateVersionId.equals(submission!.templateVersion!._id!),
    );

    if (!conversion) throw new Error('Conversion config does not exist');

    let templateVersions = await getFilteredTemplateVersions();

    let newTemplateVersion = templateVersions.find(x =>
      x._id.equals(conversion?.toTemplateVersionId!),
    );

    const draftStatus: SubmissionStatus = (await getSubmissionStatuses()).find(
      x => x.name == 'Draft',
    )!;

    let org = '';
    let currentEmail = '';

    let currentUser = getRealmApp().currentUser;

    if (currentUser && currentUser.profile) {
      if (typeof currentUser.profile.organisation === 'string') {
        let organisation = JSON.parse(currentUser.profile.organisation);
        org = organisation.Id.toString();
      }

      if (typeof currentUser.profile.email === 'string') {
        currentEmail = currentUser.profile.email;
      }
    }
    let templates = await getFilteredTemplates();
    let newTemplate = templates.find(x =>
      x._id.equals(newTemplateVersion?.templateId!),
    );

    //Build new submission
    let newSubmission: Submission = {
      _id: new ObjectId(),
      partition: org,
      createdBy: currentEmail as string,
      updatedBy: currentEmail as string,
      templateVersion: {
        _id: newTemplateVersion!._id,
        name: newTemplateVersion!.name,
      },
      templateType: {
        _id: newTemplate!.templateType._id,
        name: newTemplate!.templateType.name,
      },
      submissionStatus: {
        _id: draftStatus._id,
        name: draftStatus.name,
      },
      SQLServerId: new UUID().toHexString(),
      answers: [],
      metadataJSON: '',
      createDateTimeStamp: new Date(),
      updateDateTimeStamp: new Date(),
    };

    //Populate new submissions with answers from old submission
    for (let i = 0; i < conversion!.conversions.length!; i++) {
      let conversionItem = conversion!.conversions[i];
      let fromAnswer = submission?.answers.find(
        x => x.controlId == conversionItem.controlIdFrom,
      );

      if (fromAnswer == null) continue;

      let newAnswer = {
        answer: fromAnswer?.answer,
        controlTypeId: fromAnswer?.controlTypeId,
        controlId: conversionItem.controlIdTo,
      } as Submission_answer;

      newSubmission.answers.push(newAnswer);
    }

    let autofillAnswers = await getAutofillAnswers(newTemplateVersion!, [
      ...newSubmission.answers,
    ]);
    if (autofillAnswers) newSubmission.answers.push(...autofillAnswers);

    //create new submission
    await upsertSubmission(newSubmission);

    //Tag old submission as converted
    if (submission?.metadataJSON) {
      let newMetaJSON = JSON.parse(submission?.metadataJSON!);
      newMetaJSON.converted = true;
      submission!.metadataJSON = JSON.stringify(newMetaJSON);
    } else {
      submission!.metadataJSON = JSON.stringify({ converted: true });
    }

    await upsertSubmission(submission);

    return newSubmission._id;
  };

  const getAutofillAnswers = async (
    templateVersion: TemplateVersion,
    answers: Submission_answer[],
  ): Promise<Submission_answer[]> => {
    let autofillAnswers: Submission_answer[] = [];

    let controlHash = new Map<number, TemplateVersion_pages_controls>();
    for (let i = 0; i < templateVersion.pages.length!; i++) {
      for (let j = 0; j < templateVersion.pages[i].controls!.length!; j++) {
        controlHash.set(
          templateVersion.pages[i].controls![j].controlId!,
          templateVersion.pages[i].controls![j]!,
        );
      }
    }

    await controlHash.forEach(async control => {
      let autofill = conditionsHelper.getAutofillCondition(
        control,
        templateVersion.conditions ?? [],
        answers,
        controlHash,
      );
      if (autofill && autofill.value) {
        if (autofill.condition === 'Supervisor') {
          try {
            let valObj = JSON.parse(autofill.value) as MongoIdSqlId;

            let person = (
              await getPeople({ _id: new ObjectId(valObj.mongoId) }, 1)
            )[0];

            let supervisor = (
              await getPeople({ _id: new ObjectId(person.supervisorId) }, 1)
            )[0];

            let newObjValue = {
              mongoId: supervisor._id.toHexString(),
              label: supervisor.firstName + ' ' + supervisor.lastName,
              SQLServerId: supervisor.SQLServerId,
            };

            let newAnswer = {
              answer: JSON.stringify(newObjValue),
              controlTypeId: control.controlTypeId,
              controlId: control.controlId,
            } as Submission_answer;
            autofillAnswers.push(newAnswer);
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }
        } else if (autofill.condition === 'ProjectManager') {
          try {
            let valObj = JSON.parse(autofill.value) as MongoIdSqlId;

            let project = (
              await getProjectSites({ _id: new ObjectId(valObj.mongoId) }, 1)
            )[0];

            let projectManager = (
              await getPeople(
                { _id: new ObjectId(project.projectManagerId!) },
                1,
              )
            )[0];

            let newObjValue = {
              mongoId: projectManager._id.toHexString(),
              label: projectManager.firstName + ' ' + projectManager.lastName,
              SQLServerId: projectManager.SQLServerId,
            };

            let newAnswer = {
              answer: JSON.stringify(newObjValue),
              controlTypeId: control.controlTypeId,
              controlId: control.controlId,
            } as Submission_answer;
            autofillAnswers.push(newAnswer);
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }
        } else if (autofill.condition === 'FirstAidAttendant') {
          try {
            let valObj = JSON.parse(autofill.value) as MongoIdSqlId;

            let project = (
              await getProjectSites({ _id: new ObjectId(valObj.mongoId) }, 1)
            )[0];

            let safetyResource = (
              await getPeople(
                { _id: new ObjectId(project.safetyResourceId!) },
                1,
              )
            )[0];

            let newObjValue = {
              mongoId: safetyResource._id.toHexString(),
              label: safetyResource.firstName + ' ' + safetyResource.lastName,
              SQLServerId: safetyResource.SQLServerId,
            };

            let newAnswer = {
              answer: JSON.stringify(newObjValue),
              controlTypeId: control.controlTypeId,
              controlId: control.controlId,
            } as Submission_answer;
            autofillAnswers.push(newAnswer);
          } catch (error) {
            console.log('Error: Bad Autocomplete value serialization');
          }
        }
      }
    });

    return autofillAnswers;
  };

  const value = React.useMemo(() => {
    return {
      canConvertSubmission: canConvertSubmission,
      convertSubmission: convertSubmission,
    };
  }, [canConvertSubmission, convertSubmission]);

  return (
    <TemplateConversionContext.Provider value={value}>
      {children}
    </TemplateConversionContext.Provider>
  );
};

const useTemplateConversion = () => {
  const conversionContext = useContext(TemplateConversionContext);

  if (conversionContext === null)
    throw new Error(
      'useTemplateConversion() called outside of a TemplateConversionProvider?',
    );

  return conversionContext;
};

export {
  TemplateConversionProvider,
  TemplateConversionContext,
  useTemplateConversion,
};
