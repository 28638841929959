import Config from 'react-native-config';
import { QRCodeSVG } from 'qrcode.react';

/**
 * Hook to handle qrcode generation
 */

type QRCodeType = {
  renderQRCode: (personcCode: string) => React.ReactElement;
};
const useQRCode = (): QRCodeType => {
  const renderQRCode = (ehsId: string) => {
    let profileUrl = process.env.REACT_APP_PUBLIC_PROFILE_URL;
    let url = profileUrl + '?ehsId=' + ehsId;
    return <QRCodeSVG value={url} />;
  };

  return {
    renderQRCode: renderQRCode,
  };
};

export default useQRCode;
