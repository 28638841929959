import React, { useState, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';
import { DynamicRatingProps } from '../../Types/ControlTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { METADATA_KEYS } from '../../Constants/AppConstants';

const DynamicRating = (props: DynamicRatingProps): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());

    if (value !== props.value && props.updateMetaData) {
      if (props.config?.hasMetaData && props.config?.metaDataKey) {
        if (props.config.metaDataKey === METADATA_KEYS.POTENTIAL_SEVERITY) {
          let level = props.config.ratingLevels.find(
            x => x.value.toString() == value,
          );
          if (level) props.updateMetaData(props.config.metaDataKey, level.name);
        } else props.updateMetaData(props.config.metaDataKey, value);
      }
    }
  }, [value]);

  const valid = () => {
    return !!value;
  };

  const renderDots = (): React.ReactElement => {
    let ratings = [];
    for (let i = 0; i < props.config?.ratingLevels.length!; i++) {
      let ratingStyle = ControlsStyleSheet.ratingDot;
      if (parseInt(value) >= i + 1)
        ratingStyle = ControlsStyleSheet.ratingDotSelected;

      ratings.push(
        <Pressable
          key={'dot' + i.toString()}
          style={({ pressed }) => [
            ControlsStyleSheet.ratingOutline,
            pressed && {
              opacity: 0.4,
            },
          ]}
          disabled={props.disabled}
          onPress={() => {
            setValue((i + 1).toString());
          }}>
          <View style={ratingStyle}></View>
        </Pressable>,
      );
    }

    return (
      <View style={ControlsStyleSheet.ratingLevelContainer}>{ratings}</View>
    );
  };

  const renderRatingLabel = () => {
    let labelValue = '';
    let labelColor = Colors.white;

    if (value) {
      const config = props.config?.ratingLevels.find(
        x => x.value.toString() === value,
      );
      labelValue = config?.name!;
      labelColor = config?.color!;
    }

    return (
      <View
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <Text
          style={[
            {
              backgroundColor: labelColor,
              borderColor: labelColor,
            },
            ControlsStyleSheet.ratingLabel,
          ]}>
          {labelValue}
        </Text>
      </View>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <Text style={ControlsStyleSheet.label}>
        {props.label ?? ''}
        <Text style={ControlsStyleSheet.required}>
          {props.required ? '*' : ''}
        </Text>
      </Text>
      <View style={ControlsStyleSheet.ratingContainer}>
        {renderDots()}
        {renderRatingLabel()}
      </View>
    </View>
  );
};

export default DynamicRating;
