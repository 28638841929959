import React, { useEffect, useRef, useState } from 'react';
import { Text, Pressable, ScrollView } from 'react-native';
import { ObjectId, Decimal128, UUID } from 'bson';
import Colors from '../../Styles/Shared/Colors';
import { Icons } from '../../Constants/Icons';
import CustomPressable from '../Shared/CustomPressable';
import { useSync } from '../../Providers/SyncProvider';
import { Navigation } from '../../Constants/Navigation';
import { TemplateCategoryType } from '../../Constants/TemplateCategoryTypes';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { Template } from '../../Models/RealmModels/Template';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CATEGORY, PERMISSIONS } from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import ConfirmModal from './ConfirmModal';

type FormListProps = {
  templateCategoryName: TemplateCategoryType;
  navigation: any;
  category: string;
};

const FormList = (props: FormListProps): React.ReactElement => {
  const {
    getRealmApp,
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getSubmissionStatuses,
    upsertSubmission,
  } = useSync();
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const { hasPermission } = usePermission();
  const [canCreate, setCanCreate] = useState<boolean>();

  const createModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    let createReport = false;
    if (props.category == CATEGORY.REPORTS) {
      createReport = hasPermission(PERMISSIONS.REPORT_CREATE);
    } else if (props.category == CATEGORY.EQUIPMENT) {
      createReport = hasPermission(PERMISSIONS.REPORT_EQUIPMENT_CREATE);
    } else if (props.category == CATEGORY.OPERATIONS) {
      createReport = hasPermission(PERMISSIONS.REPORT_OPERATION_CREATE);
    } else if (props.category == CATEGORY.PEOPLE) {
      createReport = hasPermission(PERMISSIONS.REPORT_PEOPLE_CREATE);
    }
    setCanCreate(createReport);
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      const filteredTemplates = (await getFilteredTemplates()).filter(
        x =>
          x.templateCategory.templateCategoryName == props.templateCategoryName,
      );

      setFilteredTemplates(filteredTemplates);
    };

    fetchTemplates();
  }, []);

  const onPress = async (templateId: ObjectId) => {
    if (!canCreate) {
      createModalCommand.current.openModal();
      return;
    }

    let filteredTemplates = (await getFilteredTemplateVersions())
      .filter(x => x.templateId.equals(templateId))
      .sort(x => x.version)
      .reverse()
      .map(x => ({ _id: x._id, name: x.name }));

    if (filteredTemplates.length == 0) {
      //throw Error('No forms found!');
      console.error('No forms found!');
      return;
    }

    let newDraftId: ObjectId = new ObjectId();

    const draftStatus: SubmissionStatus = (await getSubmissionStatuses()).find(
      x => x.name == 'Draft',
    )!;

    let currentUser = getRealmApp().currentUser;
    let org = '';
    let currentEmail = '';

    if (currentUser && currentUser.profile) {
      if (typeof currentUser.profile.organisation === 'string') {
        let organisation = JSON.parse(currentUser.profile.organisation);
        org = organisation.Id.toString();
      }

      if (typeof currentUser.profile.email === 'string') {
        currentEmail = currentUser.profile.email;
      }
    }

    let newTemplateVersion = filteredTemplates[0];

    let templates = await getFilteredTemplates();
    let newTemplate = templates.find(x => x._id.equals(templateId!));

    let newSubmission: Submission = {
      _id: newDraftId,
      partition: org,
      createdBy: currentEmail as string,
      updatedBy: currentEmail as string,
      templateVersion: {
        _id: newTemplateVersion!._id,
        name: newTemplateVersion!.name,
      },
      templateType: {
        _id: newTemplate!.templateType._id,
        name: newTemplate!.templateType.name,
      },
      submissionStatus: {
        _id: draftStatus._id,
        name: draftStatus.name,
      },
      SQLServerId: new UUID().toHexString(),
      answers: [],
      metadataJSON: '',
      createDateTimeStamp: new Date(),
      updateDateTimeStamp: new Date(),
    };

    await upsertSubmission(newSubmission);

    if (newDraftId == null) throw Error('Error: create draft failed!');

    props.navigation.navigate(Navigation.DYNAMICFORM, {
      submissionId: newDraftId,
      submissionStatus: 'Draft',
    });
  };

  const forms: React.ReactElement[] = [];

  filteredTemplates.map((x, index) => {
    let svgxml = '';

    //bit hacky maybe theres another way or xml icons should have color hardcoded
    if (x.iconSvg) svgxml = x.iconSvg?.replaceAll('currentColor', 'white');

    forms.push(
      <CustomPressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.fabItem,
          pressed && {
            backgroundColor: Colors.teal,
          },
          !canCreate && {
            opacity: 0.4,
          },
        ]}
        onPress={() => {
          onPress(x._id);
        }}
        tooltip={x.name.replace('Full - ', '').replace('/', ' / ') + ' Report'}>
        {x.iconSvg && (
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(svgxml)}`}
            style={ReportsStyleSheet.fabIcon}
            alt="React Logo"
          />
        )}
      </CustomPressable>,
    );
  });

  /*  Hidden For now
  forms.push(
  
    <Pressable
      key={"other"}
      style={[ReportsStyleSheet.fabItem, { marginRight: 20, display: "none" }]}
      android_ripple={fabRipple}
    >
      <SvgXml xml={Icons.MORE} style={ReportsStyleSheet.fabIcon} />
    </Pressable>
  ); */

  return (
    <>
      <ConfirmModal
        title="No Access"
        message="You do not have access to create reports"
        okClick={() => {}}
        okText="Ok"
        setOpenModal={(func: () => void) => {
          createModalCommand.current.setOpenModal(func);
        }}
      />
      {forms}
    </>
  );
};

export default FormList;
