import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId, UUID } from 'bson';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionLink } from '../../Models/RealmModels/SubmissionLink';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import {
  DynamicAddRecordProps,
  DynamicLinkRecordProps,
} from '../../Types/ControlTypes';
import { DynamicLinkType } from '../../Constants/General';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Icon from '../Shared/Icon';
import Colors from '../../Styles/Shared/Colors';
import { Navigation } from '../../Constants/Navigation';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import moment from 'moment';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS, COMMON_TEMPLATES } from '../../Constants/AppConstants';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { Icons } from '../../Constants/Icons';
import { Template } from '../../Models/RealmModels/Template';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionBundle } from '../../Models/RealmModels/SubmissionBundle';

type Record = {
  id: string;
  name: string;
  status: string;
  datetime: Date;
  icon: string;
};

type TemplateRecord = {
  id: string;
  name: string;
  icon: string;
};

//Component for adding one record (used for adding audits)
const DynamicAddRecord = (props: DynamicAddRecordProps): React.ReactElement => {
  const [selectedRecord, setSelectedRecord] = useState<Record | null>(null);
  const [templateRecords, setTemplateRecords] = useState<TemplateRecord[]>([]);
  const [isShowingModal, setIsShowingModal] = useState(false);

  const {
    submissions,
    getFilteredTemplateVersions,
    getSubmissions,
    getSubmissionBundles,
    upsertSubmission,
    getSubmissionStatuses,
    getSubmissionLinks,
    addSubmissionLink,
    removeSubmissionLinks,
    getFilteredTemplates,
    getOrgId,
    getRealmApp,
  } = useSync();

  useEffect(() => {
    const loadFilteredTemplates = async () => {
      let fitleredTemplates: Template[] = [];

      if (props.config?.templateCategory) {
        fitleredTemplates = (await getFilteredTemplates()).filter(
          t =>
            t.templateCategory.templateCategoryName ===
            props.config?.templateCategory,
        );
      } else if (props.config?.templateType) {
        fitleredTemplates = (await getFilteredTemplates()).filter(
          t => t.templateType.name == props.config?.templateType,
        );
      }

      let templateRecords: TemplateRecord[] = [];

      templateRecords = fitleredTemplates.map(
        x =>
          ({
            id: x._id.toHexString(),
            name: x.name,
            icon: x.iconSvg,
          } as TemplateRecord),
      );

      setTemplateRecords(templateRecords);
    };

    loadFilteredTemplates();
  }, []);

  useEffect(() => {
    if (!props.value) return;

    const getRecord = async () => {
      if (props.isExternal) {
        let bundleSubmision = (await getSubmissionBundles()).find(
          x => x._id?.toHexString() === props.bundleId,
        );
        if (!bundleSubmision) return;
        let record = await getRecordFromSubmissionBundle(bundleSubmision);
        setSelectedRecord(record);
      } else {
        let submission = submissions.find(x => x._id.equals(props.value!));
        if (!submission) return;
        let record = await getRecordFromSubmission(submission!);
        setSelectedRecord(record);
      }
    };

    getRecord();
  }, [props.value]);

  async function getTemplateIcon(templateVersionId: string) {
    let templateVersion = (await getFilteredTemplateVersions())
      .sort(x => x.version)
      .reverse()
      .find(x => x._id.toHexString() === templateVersionId);
    let template = (await getFilteredTemplates()).find(
      x => x._id.toHexString() === templateVersion!.templateId?.toHexString(),
    );

    return template?.iconSvg ?? '';
  }

  function getTitleAndDateFromMetaData(metaData: string) {
    let title = '';
    let date: Date | null = null;
    let meta = JSON.parse(metaData);
    if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];

    let datePart = '';
    if (meta[METADATA_KEYS.DUEDATE]) datePart = meta[METADATA_KEYS.DUEDATE];
    let timePart = '';
    if (meta[METADATA_KEYS.DUETIME]) timePart = meta[METADATA_KEYS.DUETIME];
    if (datePart && timePart) {
      let time = moment(timePart, Formats.BACKEND_TIME);
      let duedate = moment(datePart, Formats.BACKEND_DATE)
        .add(time.hours(), 'hours')
        .add(time.minutes(), 'minutes')
        .toDate();
      date = duedate;
    }

    return { title: title, date: date };
  }

  async function getRecordFromSubmission(
    submission: Submission,
  ): Promise<Record> {
    let title = submission.templateVersion.name!;
    let date = moment(submission.createDateTimeStamp).toDate();
    if (submission.metadataJSON) {
      let metaDateTitle = getTitleAndDateFromMetaData(submission.metadataJSON);

      if (metaDateTitle.title) title = metaDateTitle.title;

      if (metaDateTitle.date) date = metaDateTitle.date;
    }

    let templateIcon = await getTemplateIcon(
      submission.templateVersion!._id!.toHexString(),
    );

    return {
      id: submission._id.toHexString(),
      name: title,
      status: submission.submissionStatus.name!,
      datetime: date,
      icon: templateIcon,
    };
  }

  async function getRecordFromSubmissionBundle(
    submissionBundle: SubmissionBundle,
  ): Promise<Record | null> {
    if (!submissionBundle?.submissions) return null;
    let submission = submissionBundle?.submissions.find(
      x => x.submissionId?.toHexString() === props.value!,
    );

    if (!submission) return null;
    let title = submission.templateVersion!.name!;
    let date = moment(submission.updateDateTimeStamp).toDate();

    if (submission.metadataJSON) {
      let metaDateTitle = getTitleAndDateFromMetaData(submission.metadataJSON);

      if (metaDateTitle.title) title = metaDateTitle.title;

      if (metaDateTitle.date) date = metaDateTitle.date;
    }

    let templateIcon = await getTemplateIcon(
      submission.templateVersion!._id!.toHexString(),
    );

    return {
      id: submission.submissionId!.toHexString(),
      name: title,
      status: SubmissionStatuses.SUBMITTED,
      datetime: date,
      icon: templateIcon,
    };
  }

  function showModal(): void {
    if (props.disabled) return;

    if (props.config?.templateId) {
      //Just add the new report since there's only one option if templateId is configured
      addNew(props.config?.templateId);
      return;
    }

    Keyboard.dismiss();

    setIsShowingModal(true);
  }

  function hideModal(): void {
    setIsShowingModal(false);
  }

  async function addNew(templateId: string): Promise<void> {
    if (templateId) {
      let versions = (await getFilteredTemplateVersions())
        .filter(x => x.templateId.toHexString() === templateId)
        .sort((a, b) => a.version - b.version)
        .reverse()
        .map(x => ({
          _id: x._id,
          name: x.name,
          templateId: x.templateId,
          v: x.version,
        }));

      if (versions.length == 0) throw Error('No forms found!');
      let newTemplateVersion = versions[0];

      let template = (await getFilteredTemplates()).find(
        x => x._id.toHexString() === templateId,
      );

      if (!template) throw Error('No forms found!');

      let newDraftId: null | ObjectId = null;
      const draftStatus: SubmissionStatus = (
        await getSubmissionStatuses()
      ).find(x => x.name == SubmissionStatuses.DRAFT)!;

      let org = '';
      let currentEmail = '';

      let currentUser = getRealmApp().currentUser;

      if (currentUser && currentUser.profile) {
        if (typeof currentUser.profile.organisation === 'string') {
          let organisation = JSON.parse(currentUser.profile.organisation);
          org = organisation.Id.toString();
        }

        if (typeof currentUser.profile.email === 'string') {
          currentEmail = currentUser.profile.email;
        }
      }

      let newSubmission: Submission = {
        _id: new ObjectId(),
        partition: org,
        createdBy: currentEmail as string,
        updatedBy: currentEmail as string,
        templateVersion: {
          _id: newTemplateVersion._id,
          name: newTemplateVersion.name,
        },
        templateType: {
          _id: template!.templateType._id,
          name: template!.templateType.name,
        },
        submissionStatus: {
          _id: draftStatus._id,
          name: draftStatus.name,
        },
        SQLServerId: new UUID().toHexString(),
        answers: [],
        metadataJSON: '',
        createDateTimeStamp: new Date(),
        updateDateTimeStamp: new Date(),
      };

      newDraftId = newSubmission._id;

      await upsertSubmission(newSubmission);

      if (newDraftId == null) throw Error('Error: create draft failed!');

      props.onRecordAdded(String(newDraftId));

      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: String(newDraftId),
        showConfirmation: false,
      });
    }
  }

  function goToRecord(record: Record): void {
    let submission = submissions.find(x => x._id.equals(record.id));

    if (submission && submission.templateVersion.name === COMMON_TEMPLATES.TASK)
      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: record.id,
        headerTitle: 'Task',
      });
    else if (props.isExternal) {
      props.navigation.push(Navigation.BUNDLEFORM, {
        submissionId: record.id,
        bundleId: props.bundleId,
        submissionType: props.type,
        externalView: props.isExternal,
      });
    } else
      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: record.id,
      });
  }

  function removeRecord() {
    props.removeRecord();
    setSelectedRecord(null);
  }

  function renderTemplates(): React.ReactElement {
    let templateElements: React.ReactElement[] = [];
    for (let i = 0; i < templateRecords.length; i++) {
      templateElements.push(
        <Pressable
          key={i}
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            { gap: 12 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => {
            addNew(templateRecords[i].id);
            hideModal();
          }}>
          {templateRecords[i].icon && (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                templateRecords[i]
                  .icon! //First: For dark mode icons (Eg. Site Inspections in home) replace darkestGreen with white
                  .replaceAll(Colors.darkestGreen, Colors.white)
                  //Second: For all other icons (Eg. Site Inspections in operations) replace green with darkestGreen
                  .replaceAll(Colors.green, Colors.darkestGreen)
                  .replaceAll('currentColor', Colors.darkestGreen),
              )}`}
              style={{ width: 32 }}
            />
          )}
          <Text
            style={[
              ReportsStyleSheet.listItemName,
              { marginTop: 5 },

              { alignContent: 'center' },
            ]}
            numberOfLines={2}>
            {templateRecords[i].name}
          </Text>
        </Pressable>,
      );
    }

    return <>{templateElements}</>;
  }

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="slide">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label ?? 'Add a record'}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={hideModal}>
                <Icon icon={'close'} color={Colors.darkGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView style={{ marginVertical: 20 }}>
              {renderTemplates()}
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  const renderSelectedRecord = (): React.ReactElement => {
    if (!selectedRecord) return <></>;

    return (
      <View style={ReportsStyleSheet.listItemContainer}>
        <Pressable
          style={({ pressed }) => [
            {
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => goToRecord(selectedRecord)}>
          {selectedRecord.icon ? (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                selectedRecord
                  .icon! //First: For dark mode icons (Eg. Site Inspections in home) replace darkestGreen with white
                  .replaceAll(Colors.darkestGreen, Colors.white)
                  //Second: For all other icons (Eg. Site Inspections in operations) replace green with darkestGreen
                  .replaceAll(Colors.green, Colors.darkestGreen)
                  .replaceAll('currentColor', Colors.darkestGreen),
              )}`}
              style={{ width: 32 }}
            />
          ) : (
            <></>
          )}
          <Text
            style={[ReportsStyleSheet.listItemName, { paddingTop: 6 }]}
            numberOfLines={1}>
            {selectedRecord.name}
          </Text>
          <View style={ReportsStyleSheet.listItemDateContent}>
            <Text style={ReportsStyleSheet.listItemDate}>
              {moment(selectedRecord.datetime).format('MMM DD')}
            </Text>
            <Text style={ReportsStyleSheet.listItemTime}>
              {moment(selectedRecord.datetime).format('HH:ss')}
            </Text>
          </View>
        </Pressable>
        {!props.disabled && (
          <Pressable style={{ flexShrink: 1 }} onPress={() => removeRecord()}>
            <Icon icon={'trash'} color={Colors.darkestGreen} size={24} />
          </Pressable>
        )}
      </View>
    );
  };

  return (
    <View
      style={{
        display:
          props.visible === false ||
          props.visible === undefined ||
          props.config?.disabled
            ? 'none'
            : 'flex',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 12,
        }}>
        <Text style={ControlsStyleSheet.labelLarge}>
          {props.label ?? 'Add a record'}
        </Text>
        {!selectedRecord && (
          <Pressable
            style={({ pressed }) => [
              ControlsStyleSheet.linkRecordAddButton,
              pressed && {
                backgroundColor: Colors.darkGreenTransparent,
              },
              //props.disabled means the DynamicForm is in read only mode
              //props.config?.disabled means the component is read only
              (props.disabled || props.config?.disabled) && { display: 'none' },
            ]}
            disabled={props.disabled}
            onPress={showModal}>
            <Icon icon={'plus'} color={Colors.darkGreen} size={24} />
          </Pressable>
        )}
      </View>
      {renderSelectedRecord()}
      {renderModal()}
    </View>
  );
};

export default DynamicAddRecord;
