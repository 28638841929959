import React, { useState, useEffect, useRef } from 'react';
import { ScrollView, View, Text, Pressable, Image, Modal } from 'react-native';
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import { useSync } from '../../Providers/SyncProvider';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import { useToast } from 'react-native-toast-notifications';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import AttachmentService from '../../Services/AttachmentService';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Icon from '../Shared/Icon';
import { DynamicAttachment } from '../../Types/ControlTypes';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import 'react-image-crop/dist/ReactCrop.css';
import { Icons } from '../../Constants/Icons';

type ProfilePictureProps = {
  profileId: string;
  editable?: boolean;
  onChange: (photo: string, photoThumbnail: string) => void;
};

const ProfilePicture = (props: ProfilePictureProps): React.ReactElement => {
  const [blobUri, setBlobUri] = useState('');
  const [blobThumbnailUri, setBlobThumbnailUri] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [profileIconText, setProfileIconText] = useState('');

  const [showEditPictureModal, setShowEditPictureModal] = useState(false);
  const [pictureModal, setPictureModal] = useState('');
  const [pictureModalError, setPictureModalError] = useState('');
  const [maxSize, setMaxSize] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = React.createRef<HTMLInputElement>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const { getPeople } = useSync();
  const { realmSignOut } = useCustomRealm();

  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  useEffect(() => {
    const getPerson = async () => {
      if (process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB)
        setMaxSize(parseInt(process.env.REACT_APP_MAX_ATTACHMENTS_SIZE_MB));

      let person = (await getPeople({})).find(
        x => x.SQLServerId.toLowerCase() == props.profileId.toLowerCase(),
      );
      if (person) {
        let match = (person.firstName + ' ' + person.lastName).match(/\b(\w)/g);
        let acronym = match?.join('');
        if (acronym) setProfileIconText(acronym.substring(0, 2));
        if (person.photo) getProfilePicture(person.photo);
      }
    };

    getPerson();
  }, []);

  const getProfilePicture = async (photo: string) => {
    try {
      let photoAttachment = JSON.parse(photo) as DynamicAttachment;
      if (photoAttachment.blobUri) {
        setBlobUri(photoAttachment.blobUri);
        setBlobThumbnailUri(photoAttachment.blobThumbnailUri ?? '');

        let sasUri = await attachmentService.getSasUri(photoAttachment.blobUri);
        setProfilePicture(sasUri);
      }
    } catch (error) {}
  };

  async function showFilePicker(): Promise<void> {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }

  async function onSelectFiles(files: FileList | null): Promise<void> {
    if (files && files[0]) {
      try {
        let maxByteSize = maxSize * 1024 * 1024;
        if (files[0].size >= maxByteSize) {
          setPictureModalError('File size must be less than ' + maxSize + 'MB');
          return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setPictureModal(reader.result?.toString() || '');
          setShowEditPictureModal(true);
        });
        reader.readAsDataURL(files[0]);
      } catch (error) {
        console.log(error);
      }
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 40,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  async function closePictureModal(): Promise<void> {
    setCrop(undefined);
    setPictureModalError('');
    setPictureModal('');
    setShowEditPictureModal(false);
  }

  async function onSavePictureModal() {
    try {
      const image = imgRef.current;
      const canvas = document.createElement('canvas');
      if (image && canvas && completedCrop) {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          setIsLoading(true);

          ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height,
          );

          canvas.toBlob(blob => {
            if (blob) {
              let newFileName = Date.now() + '.jpg';
              let file = new File([blob], newFileName);

              saveProfilePicture(file);
            }
          }, 'image/jpeg');
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function saveProfilePicture(file: File) {
    try {
      if (blobUri) await attachmentService.deleteBlobAttachment(blobUri);
      if (blobThumbnailUri)
        await attachmentService.deleteBlobAttachment(blobThumbnailUri);

      let uris = [];

      let fullImageUri = await attachmentService.pushAttachmentChunks(
        props.profileId,
        'person-profile',
        file.name,
        file,
      );
      uris.push(fullImageUri);

      if (file.type.includes('image/')) {
        let thumbnailFile = await attachmentService.createThumbnail(file);

        let thumbnailUri = await attachmentService.pushAttachmentChunks(
          props.profileId,
          'person-profile',
          thumbnailFile.name,
          thumbnailFile,
        );

        uris.push(thumbnailUri);
      }

      let photoAttachment: DynamicAttachment = {
        refId: props.profileId,
        refType: 'person-profile',
        controlId: undefined,
        uri: '',
        name: file.name,
        type: file.type,
        size: file.size,
        blobUri: uris[0],
        blobThumbnailUri: uris[1],
      };

      let sasUri = await attachmentService.getSasUri(uris[0]);

      // Compress image
      const image = imgRef.current;
      let thumbnailCanvas = document.createElement('canvas');
      if (image && thumbnailCanvas && completedCrop) {
        thumbnailCanvas.width = 32;
        thumbnailCanvas.height = 32;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = thumbnailCanvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            32,
            32,
          );

          thumbnailCanvas.toBlob(blob => {
            if (blob) {
              const reader = new FileReader();
              reader.addEventListener('load', async () => {
                let compressedPicture = reader.result?.toString() ?? '';

                setProfilePicture(sasUri);
                setIsLoading(false);
                setShowEditPictureModal(false);

                let photoAttachmentJson = JSON.stringify(photoAttachment);
                if (props.onChange)
                  props.onChange(photoAttachmentJson, compressedPicture);
              });
              reader.readAsDataURL(blob);
            }
          }, 'image/jpeg');
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  const renderEditPictureModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showEditPictureModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Edit Profile Picture
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => closePictureModal()}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 20 }}>
              {pictureModal && (
                <View
                  style={{
                    borderWidth: 2,
                    borderColor: Colors.darkGreenTransparent,
                  }}>
                  <ReactCrop
                    crop={crop}
                    aspect={1}
                    onChange={c => setCrop(c)}
                    onComplete={c => setCompletedCrop(c)}>
                    <img ref={imgRef} src={pictureModal} onLoad={onImageLoad} />
                  </ReactCrop>
                </View>
              )}
              <Text style={ControlsStyleSheet.error}>{pictureModalError}</Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.greenButton,
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  onPress={closePictureModal}>
                  <Text style={CommonStyleSheet.greenButtonText}>Cancel</Text>
                </Pressable>
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.greenButton,
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  onPress={onSavePictureModal}>
                  <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
                </Pressable>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ alignSelf: 'center' }}>
      <View
        style={[
          CommonStyleSheet.profileIcon,
          {
            width: 140,
            height: 140,
            borderRadius: 140,
          },
        ]}>
        {profilePicture ? (
          <Image
            style={{ width: 140, height: 140, resizeMode: 'contain' }}
            source={{ uri: profilePicture }}
          />
        ) : (
          <>
            {profileIconText ? (
              <Text
                style={[CommonStyleSheet.profileIconText, { fontSize: 72 }]}>
                {profileIconText}
              </Text>
            ) : (
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  Icons.PERSON_ACTIVE.replaceAll('currentColor', Colors.gray),
                )}`}
                width={124}
                height={124}
              />
            )}
          </>
        )}
      </View>
      {props.editable != false ? (
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.profileEditButton,
            pressed && {
              backgroundColor: Colors.lightTeal,
            },
          ]}
          onPress={showFilePicker}>
          <Icon icon={'pencil'} color={Colors.white} size={26} />
        </Pressable>
      ) : null}
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/png,image/jpg,image/jpeg"
        onChange={event => onSelectFiles(event.target.files)}
      />
      {renderEditPictureModal()}
      {isLoading && (
        <LoadingSpinner message="Saving Profile Picture" visible={true} />
      )}
    </View>
  );
};

export default ProfilePicture;
