import React, { useState, useEffect } from 'react';
import { ObjectId, UUID } from 'bson';
import { ScrollView, View, TextInput, Text, Pressable } from 'react-native';
import MaskInput, { Masks } from 'react-native-mask-input';
import { useToast } from 'react-native-toast-notifications';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';
import { useSync } from '../../Providers/SyncProvider';
import {
  EquipmentHeavyFieldName,
  EquipmentOtherFieldName,
  EquipmentVehicleFieldName,
  LabelValue,
  LatLng,
  PersonFieldName,
} from '../../Types/DtoTypes';
import { Person } from '../../Models/RealmModels/Person';
import { EditPersonStyles } from '../../Styles/EditPersonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Navigation } from '../../Constants/Navigation';
import { EquipmentHeavyProfile } from '../../Models/RealmModels/EquipmentHeavyProfile';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import { SegmentConfig } from '../../Types/ControlConfigTypes';
import { EquipmentVehicleProfile } from '../../Models/RealmModels/EquipmentVehicleProfile';
import { EquipmentOtherProfile } from '../../Models/RealmModels/EquipmentOtherProfile';
import { GeneralDynamicConfig, RegexValidation } from '../../Constants/General';
import moment from 'moment';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { Icons } from '../../Constants/Icons';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import LoadingSpinner from '../Shared/LoadingSpinner';

type EditEquipmentOtherProps = {
  navigation: any;
  equipmentId: string | null;
};

type MetaRecord = {
  id: string;
  metadata: string;
  templateVersionId: string;
  templateName: string;
  createDate: Date;
};

type Record = {
  id: string;
  name: string;
  templateName: string;
  datetime: Date;
  icon: string;
};

const EditEquipmentOther = (
  props: EditEquipmentOtherProps,
): React.ReactElement => {
  const {
    upsertOtherEquipment,
    getEquipmentsOther,
    getSubmissions,
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getOrgId,
  } = useSync();
  const [addingEquipment, setAddingEquipment] = useState(false);
  const [equipment, setEquipment] = useState<null | EquipmentOtherProfile>(
    null,
  );
  const [numberExists, setNumberExists] = useState(false);
  const [nameError, setNameError] = useState(true);
  const [numberError, setNumberError] = useState(true);
  const [descriptionError, setDescriptionError] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [linkedSubmissions, setLinkedSubmissions] = useState<Record[]>([]);
  const [linkedSubmissionsLoaded, setLinkedSubmissionsLoaded] = useState(false);
  const [profileView, setProfileView] = useState(true);

  const DISPLAY_INCREMENT = 5;
  const [inspectionToShow, setInspectionToShow] = useState(DISPLAY_INCREMENT);
  const [maintToShow, setMaintToShow] = useState(DISPLAY_INCREMENT);
  const [incidentToShow, setIncidentToShow] = useState(DISPLAY_INCREMENT);

  const toast = useToast();

  const MAINT_TEMPLATENAME = 'Maintenance Record';

  useEffect(() => {
    //No project site id, adding new project site
    if (!props.equipmentId) {
      setAddingEquipment(true);
      setProfileView(false);
      let newEquipment = getEmptyEquipment();
      setEquipment(newEquipment);
      return;
    }

    const loadEquipment = () => {
      let realmEquipment = getEquipmentsOther().filter(x =>
        x._id.equals(props.equipmentId!),
      )[0];

      if (!realmEquipment) {
        //Log error project not found
        goBack();
        return;
      }

      setEquipment(realmEquipment);
      setNameError(realmEquipment?.name === '' ?? true);
      setNumberError(realmEquipment?.number === '' ?? true);
      setDescriptionError(realmEquipment?.description === undefined ?? true);

      const callback = async () => {
        await setTimeout(() => {
          getLinkedSubmissions(realmEquipment.name, realmEquipment.number);
        }, 1);
      };
      callback();
    };

    loadEquipment();
  }, [props.equipmentId]);

  const getLinkedSubmissions = async (name: string, number: string) => {
    let newLinkedSubmissions: Record[] = [];
    let realmSubmissions = (await getSubmissions())
      .filter(x => x.partition === getOrgId())
      .map<MetaRecord>(x => ({
        metadata: x.metadataJSON,
        id: x._id.toHexString(),
        createDate: x.createDateTimeStamp,
        templateVersionId: x.templateVersion?._id!.toHexString(),
        templateName: x.templateVersion?.name!,
      }));

    let templateVersions = await getFilteredTemplateVersions();
    let templates = await getFilteredTemplates();

    for (let i = 0; i < realmSubmissions.length; i++) {
      let metaData = realmSubmissions[i].metadata;

      if (!metaData || metaData === '') continue;
      let parsedMetadata = JSON.parse(metaData);

      let equipmentId = '';
      let title = name + ' - ' + number;

      if (parsedMetadata[METADATA_KEYS.EQUIPMENTID])
        equipmentId = parsedMetadata[METADATA_KEYS.EQUIPMENTID];

      //report not for this equipment so skip
      if (!props.equipmentId || equipmentId !== props.equipmentId) continue;

      if (parsedMetadata[METADATA_KEYS.TITLE])
        title = parsedMetadata[METADATA_KEYS.TITLE];
      else if (
        realmSubmissions[i].templateName.toLowerCase() !=
        MAINT_TEMPLATENAME.toLowerCase().trim()
      )
        title = realmSubmissions[i].templateName;

      let icon = '';
      let version = templateVersions.find(
        v => String(v._id) == String(realmSubmissions[i].templateVersionId),
      );
      let template = templates.find(
        x => x._id.toHexString() == version?.templateId.toHexString(),
      );

      if (template && template.iconSvg) icon = template.iconSvg;

      newLinkedSubmissions.push({
        id: realmSubmissions[i].id,
        name: title,
        templateName: realmSubmissions[i].templateName,
        datetime: realmSubmissions[i].createDate,
        icon: icon,
      });
    }

    setLinkedSubmissions(newLinkedSubmissions);
    setLinkedSubmissionsLoaded(true);
  };

  const checkExists = (propName: EquipmentOtherFieldName) => {
    if (!equipment?.name) return false;

    let existingField = getEquipmentsOther().find(
      x => x[propName] === equipment[propName] && !x._id.equals(equipment._id),
    );

    if (existingField) return true;

    return false;
  };

  const getEmptyEquipment = () => {
    let orgid = getOrgId();

    let equipment: EquipmentOtherProfile = {
      _id: new ObjectId(),
      name: '',
      number: '',
      description: '',
      ownershipType: 'Owned',
      photo: '',
      status: 'Active',
      partition: orgid.toString(),
      SQLServerId: new UUID().toHexString(),
      dataHubVersion: Math.round(Date.now() / 1000),
      createDateTimeStamp: new Date(),
    };

    return equipment;
  };

  const saveEquipment = async () => {
    setShowErrors(true);

    let checkNumber = checkExists('number');
    setNumberExists(checkNumber);

    if (checkNumber || nameError || numberError || descriptionError) return;

    equipment!.dataHubVersion = Math.round(Date.now() / 1000);
    await upsertOtherEquipment(equipment!);
    goBack();
    toast.show('Equipment saved successfully', { type: 'success' });
  };

  const renderHeader = () => {
    let title = '';
    if (profileView && equipment?.name) title = equipment?.name;
    else if (addingEquipment) title = 'Add Other';
    else title = 'Edit Other';

    return <Text style={DynamicPageStyleSheet.headerText}>{title}</Text>;
  };

  const renderProfileButton = () => {
    if (!props.equipmentId) return <></>;

    let icon = Icons.EYE;

    if (profileView) icon = Icons.PENCIL;

    return (
      <View style={{ top: -24, marginLeft: 'auto', marginRight: 28 }}>
        <View
          style={{
            backgroundColor: Colors.white,
            borderRadius: 100,
            borderStyle: 'solid',
            borderWidth: 5,
            borderColor: Colors.white,
          }}>
          <Pressable
            style={({ pressed }) => [
              {
                backgroundColor: Colors.green,
                width: 42,
                height: 42,
                borderRadius: 42,
                padding: 5,
              },
              pressed && {
                backgroundColor: Colors.teal,
              },
            ]}
            onPress={() => setProfileView(!profileView)}>
            <>
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  icon.replaceAll('currentColor', Colors.white),
                )}`}
                style={{ color: Colors.darkGreen }}
              />
            </>
          </Pressable>
        </View>
      </View>
    );
  };

  const renderRecord = (record: Record, callback: () => void) => {
    let date = moment(record.datetime).format('MMM DD');
    let time = moment(record.datetime).format('HH:mm');

    return (
      <View
        key={record.id}
        style={{
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            {
              flex: 1,
              alignItems: 'center',
            },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => callback()}>
          <View style={{ marginRight: 12 }}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                record.icon.replaceAll('currentColor', Colors.darkGreen),
              )}`}
              style={{ color: Colors.darkGreen }}
            />
          </View>
          <Text style={ReportsStyleSheet.listItemName} numberOfLines={2}>
            {record.name}
          </Text>

          <View style={[ReportsStyleSheet.listItemDateContent]}>
            <Text style={[ReportsStyleSheet.listItemDate]}>{date}</Text>
            <Text style={[ReportsStyleSheet.listItemTime]}>{time}</Text>
          </View>
          <View>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                Icons.LIST_ITEM_NAV.replaceAll(
                  'currentColor',
                  Colors.darkGreen,
                ),
              )}`}
              style={{ color: Colors.darkGreen }}
            />
          </View>
        </Pressable>
      </View>
    );
  };

  const renderShowMore = (callback: () => void) => {
    return (
      <Pressable
        style={({ pressed }) => [
          CommonStyleSheet.smallGreenButton,
          { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
          pressed && {
            opacity: 0.8,
          },
        ]}
        onPress={() => callback()}>
        <Text style={CommonStyleSheet.smallGreenButtonText}>Show More</Text>
      </Pressable>
    );
  };

  const renderSubmissions = () => {
    if (!equipment || !equipment._id || !linkedSubmissionsLoaded) return <></>;

    ///Need to trim since we have 2 templates with different cases/white spaces for the same template since it is in different categories

    let maintSubmissions = linkedSubmissions
      .filter(
        x =>
          x.templateName.toLowerCase().trim() ===
          MAINT_TEMPLATENAME.toLowerCase().trim(),
      )
      .sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
    let incidentSubmissions = linkedSubmissions
      .filter(
        x =>
          x.templateName.toLowerCase().trim() !==
          MAINT_TEMPLATENAME.toLowerCase().trim(),
      )
      .sort((a, b) => b.datetime.getTime() - a.datetime.getTime());

    let maintList = maintSubmissions.slice(0, maintToShow).map(x => {
      return renderRecord(x, () => {
        props.navigation.navigate(Navigation.DYNAMICFORM, {
          submissionId: x.id,
        });
      });
    });

    if (!maintList || maintList.length == 0)
      maintList.push(<Text style={{ color: 'black' }}>No items found</Text>);

    let submissionList = incidentSubmissions.slice(0, incidentToShow).map(x => {
      return renderRecord(x, () => {
        props.navigation.navigate(Navigation.DYNAMICFORM, {
          submissionId: x.id,
        });
      });
    });

    if (!submissionList || submissionList.length == 0)
      submissionList.push(
        <Text style={{ color: 'black' }}>No items found</Text>,
      );

    if (maintSubmissions.length > maintToShow) {
      maintList.push(
        renderShowMore(() => {
          setMaintToShow(maintToShow + DISPLAY_INCREMENT);
        }),
      );
    }

    if (incidentSubmissions.length > incidentToShow) {
      submissionList.push(
        renderShowMore(() => {
          setIncidentToShow(incidentToShow + DISPLAY_INCREMENT);
        }),
      );
    }

    return (
      <View>
        <View>
          <Text style={[CommonStyleSheet.controlLabel, { marginTop: 24 }]}>
            Maintenance Records
          </Text>
        </View>
        {maintList}
        <View>
          <Text style={[CommonStyleSheet.controlLabel, { marginTop: 24 }]}>
            Incidents
          </Text>
        </View>
        {submissionList}
      </View>
    );
  };

  const goBack = () => {
    props.navigation.pop();
  };

  //Typescript complains about indexing props by string so checking it manually
  //Maybe there's an easier way to do this?
  const onChange = (propName: EquipmentOtherFieldName, propValue: string) => {
    equipment![propName] = propValue;

    let newEquipment = { ...equipment } as EquipmentOtherProfile;
    setEquipment(newEquipment);
  };

  if (!equipment) return <LoadingSpinner message="Loading" visible={true} />;

  return (
    <ScrollView>
      <View>
        <View style={{ backgroundColor: Colors.darkGreen }}>
          <Pressable
            hitSlop={40}
            style={({ pressed }) => [
              {
                width: 42,
                height: 42,
                borderRadius: 42,
                padding: 5,
              },
              pressed && {
                backgroundColor: Colors.teal,
              },
            ]}
            onPress={() => goBack()}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                Icons.CHEVRON_LEFT.replaceAll('currentColor', Colors.white),
              )}`}
            />
          </Pressable>
          {renderHeader()}
        </View>
        {renderProfileButton()}
        <View style={{ paddingHorizontal: 28 }}>
          <DynamicTextBox
            config={{
              required: true,
              validationRegExp: RegexValidation.NAME,
              placeholder: 'Eg. Office iPad 10',
              maxLength: 50,
            }}
            label={'Name'}
            value={equipment?.name}
            disabled={profileView}
            onChange={(controlId, controlTypeId, value, isValid) => {
              onChange('name', value);
              setNameError(!isValid);
            }}
            showError={showErrors}
          />
          <DynamicTextBox
            config={{
              required: true,
              validationRegExp: RegexValidation.NAME,
              maxLength: 50,
            }}
            label={'Equipment Number'}
            value={equipment?.number}
            disabled={profileView}
            onChange={(controlId, controlTypeId, value, isValid) => {
              onChange('number', value);
              setNumberExists(false);
              setNumberError(!isValid);
            }}
            showError={showErrors}
          />
          {numberExists && (
            <Text style={ControlsStyleSheet.error}>
              This equipment number already exists!
            </Text>
          )}
          <DynamicTextBox
            config={{
              required: true,
              maxLength: 50,
            }}
            label={'Description'}
            value={equipment?.description}
            disabled={profileView}
            onChange={(controlId, controlTypeId, value, isValid) => {
              onChange('description', value);
              setDescriptionError(!isValid);
            }}
            showError={showErrors}
          />
          <DynamicSegmentedControl
            config={GeneralDynamicConfig.SEGMENT_STATUS}
            label={'Status'}
            value={equipment?.status}
            disabled={profileView}
            onChange={(controlId, controlTypeId, value, isValid) => {
              onChange('status', value);
            }}
          />
          <DynamicSegmentedControl
            config={GeneralDynamicConfig.SEGMENT_OWNER}
            label={'Ownership Type'}
            value={equipment?.ownershipType}
            disabled={profileView}
            onChange={(controlId, controlTypeId, value, isValid) => {
              onChange('ownershipType', value);
            }}
          />
          {!profileView && (
            <View style={{ padding: 10 }}>
              <Pressable
                style={CommonStyleSheet.greenButton}
                android_ripple={{ color: Colors.whiteTransparent }}
                onPress={() => {
                  saveEquipment();
                }}>
                <Text style={CommonStyleSheet.greenButtonText}>
                  {addingEquipment ? 'Create Other' : 'Save Other'}
                </Text>
              </Pressable>
            </View>
          )}
          {profileView && renderSubmissions()}
        </View>
      </View>
    </ScrollView>
  );
};

export default EditEquipmentOther;
