import React, { useState, useEffect } from 'react';
import { Modal, View, Text, ActivityIndicator } from 'react-native';

type LoadingProps = {
  message?: string;
  color?: string;
  visible: boolean;
};

const LoadingSpinner = (props: LoadingProps): React.ReactElement => {
  const [visible, setVisible] = useState(false);

  // This useEffect is needed when the screen should show the spinner on the first render
  // I guess "Modals" needs to start hidden and then make them visible
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType={'none'}
      statusBarTranslucent={true}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}>
        <View style={{ flex: 1, alignItems: 'center' }}>
          <ActivityIndicator color={props.color ?? 'white'} size={140} />
          <Text
            style={{
              color: props.color ?? 'white',
              fontSize: 20,
              fontWeight: '600',
              marginTop: 24,
            }}>
            {props.message ?? 'Loading'}
          </Text>
        </View>
      </View>
    </Modal>
  );
};

export default LoadingSpinner;
