import React from 'react';
import { View, Text, Keyboard, Pressable } from 'react-native';
import Colors from '../../Styles/Shared/Colors';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { DynamicSubmitButtonProps } from '../../Types/ControlTypes';

const DynamicSubmitButton = (
  props: DynamicSubmitButtonProps
): React.ReactElement => {
  const submitButton = (
    <Pressable
      style={CommonStyleSheet.greenButton}
      android_ripple={{ color: Colors.whiteTransparent }}
      onPress={() => {
        props.onSubmit();
      }}
    >
      <Text style={CommonStyleSheet.greenButtonText}>
        {props.label ?? 'Submit'}
      </Text>
    </Pressable>
  );
  return (
    <View style={{ alignSelf: 'flex-start' }}>
      {!props.disabled && submitButton}
    </View>
  );
};

export default DynamicSubmitButton;
