import IConditionsHelperService, {
  ConditionValue,
} from './Interfaces/IConditionsHelperService';
import {
  TemplateVersion_conditions,
  TemplateVersion_pages_controls,
} from '../Models/RealmModels/TemplateVersion';
import { SubmissionAnswerDTO } from '../Types/DtoTypes';

class ConditionsHelperService implements IConditionsHelperService {
  isControlVisible(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controlHash: Map<number, TemplateVersion_pages_controls>,
  ): boolean {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        (c.condition === 'Show' || c.condition === 'Hide'),
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controlHash.get(c.controlId!);
        if (condControl) {
          let condVal = condControl.value;
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          if (condAnswer && condAnswer.answer) condVal = condAnswer.answer;

          if (c.condition === 'Show') return c.value == condVal;
          else if (c.condition === 'Hide') return !(c.value == condVal);
        }
      }

    return true;
  }
  isControlDisabled(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controlHash: Map<number, TemplateVersion_pages_controls>,
  ): boolean {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        (c.condition === 'Enabled' || c.condition === 'Disabled'),
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controlHash.get(c.controlId!);
        if (condControl) {
          let condVal = condControl.value;
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          if (condAnswer && condAnswer.answer) condVal = condAnswer.answer;

          if (c.condition === 'Enabled') return !(c.value == condVal);
          else if (c.condition === 'Disabled') return c.value == condVal;
        }
      }

    return false;
  }
  getBeforeCondition(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controls: TemplateVersion_pages_controls[],
  ): string {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        c.condition === 'Before',
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controls.find(x => x.controlId == c.controlId);
        if (condControl) {
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          if (condAnswer && condAnswer.answer) return condAnswer.answer;
        }
      }

    return '';
  }
  getAfterCondition(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controls: TemplateVersion_pages_controls[],
  ): string {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        c.condition === 'After',
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controls.find(x => x.controlId == c.controlId);
        if (condControl) {
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          if (condAnswer && condAnswer.answer) return condAnswer.answer;
        }
      }

    return '';
  }
  getLessThanCondition(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controls: TemplateVersion_pages_controls[],
  ): number | undefined {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        c.condition === 'LessThan',
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controls.find(x => x.controlId == c.controlId);
        if (condControl) {
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          if (condAnswer && condAnswer.answer) {
            let condValue = Number(condAnswer.answer);
            if (!isNaN(condValue)) return condValue;
          }
        }
      }

    return undefined;
  }
  getGreaterThanCondition(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controls: TemplateVersion_pages_controls[],
  ): number | undefined {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        c.condition === 'GreaterThan',
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controls.find(x => x.controlId == c.controlId);
        if (condControl) {
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          if (condAnswer && condAnswer.answer) {
            let condValue = Number(condAnswer.answer);
            if (!isNaN(condValue)) return condValue;
          }
        }
      }

    return undefined;
  }
  getAutofillCondition(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controlHash: Map<number, TemplateVersion_pages_controls>,
  ): ConditionValue | undefined {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        (c.condition === 'Supervisor' ||
          c.condition === 'ProjectManager' ||
          c.condition === 'FirstAidAttendant'),
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controlHash.get(c.controlId!);
        if (condControl) {
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          // It should only have one Autofill Condition, if it has more return the first found
          if (condAnswer && condAnswer.answer)
            return { condition: c.condition!, value: condAnswer.answer };
        }
      }

    return undefined;
  }
  getFilterCondition(
    control: TemplateVersion_pages_controls,
    conditions: TemplateVersion_conditions[],
    answers: SubmissionAnswerDTO[],
    controlHash: Map<number, TemplateVersion_pages_controls>,
  ): ConditionValue | undefined {
    let controlConditions = conditions.filter(
      c =>
        c.controlsToAffect?.includes(control.controlId!) &&
        c.condition === 'FilterTasks',
    );

    if (controlConditions)
      for (let c of controlConditions) {
        let condControl = controlHash.get(c.controlId!);
        if (condControl) {
          let condAnswer = answers.find(x => x.controlId == c.controlId);
          // It should only have one Filter Condition, if it has more return the first found
          if (condAnswer && condAnswer.answer)
            return { condition: c.condition!, value: condAnswer.answer };
        }
      }

    return undefined;
  }
}

export default ConditionsHelperService;
