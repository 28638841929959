import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TextInput,
  Pressable,
  Modal,
  Keyboard,
} from 'react-native';
import moment from 'moment';
import { useSync } from '../../Providers/SyncProvider';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { DynamicInputProps, DynamicSelectItem } from '../../Types/ControlTypes';
import DynamicSelect from './DynamicSelect';
import DynamicAutocomplete from './DynamicAutocomplete';
import CustomPressable from '../Shared/CustomPressable';
import Icon from '../Shared/Icon';
import DynamicPDF from '../DynamicForm/DynamicPDF';
import { ObjectId } from 'bson';
import { TemplateVersion } from '../../Models/RealmModels/TemplateVersion';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { SubmissionBundle_submissions } from '../../Models/RealmModels/SubmissionBundle';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { Navigation } from '../../Constants/Navigation';
import { Icons } from '../../Constants/Icons';
import Colors from '../../Styles/Shared/Colors';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS, COMMON_TEMPLATES } from '../../Constants/AppConstants';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';

export declare type DynamicBundleLinkRecordProps = {
  navigation: any;
  label?: string;
  bundleId: ObjectId;
  config?: { required?: boolean };
  showError?: boolean;
} & DynamicInputProps;

type Record = {
  id: string;
  name: string;
  category: string;
  type: 'submission' | 'submissionTraining';
  datetime: Date;
  icon: string;
  org?: string;
  project?: string;
  person?: string;
};

const DynamicBundleLinkRecord = (
  props: DynamicBundleLinkRecordProps,
): React.ReactElement => {
  const [value, setValue] = useState<string[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [error, setError] = useState('');

  const [selectedRecord, setSelectedRecord] = useState<Record>();
  const [isShowingPDFModal, setIsShowingPDFModal] = useState(false);
  const [recordToPDF, setRecordToPDF] = useState<Record>();

  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [records, setRecords] = useState<Record[]>([]);
  const [valueRecords, setValueRecords] = useState<Record[]>([]);

  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [categories, setCategories] = useState<DynamicSelectItem[]>([]);
  const [filteredCategory, setFilteredCategory] = useState('');
  const [filteredProject, setFilteredProject] = useState('');
  const [filteredPerson, setFilteredPerson] = useState('');

  const defaultItemsToShow = 10;
  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);

  const [myKey, setMyKey] = useState('');
  const [navKey, setNavKey] = useState('');

  const {
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getTemplates,
    getTemplateVersions,
    getSubmissions,
    getSubmissionTrainings,
    getSubmissionBundles,
    upsertSubmissionBundle,
    getOrgId,
    getRealmApp,
  } = useSync();

  useEffect(() => {
    setMyKey(currentKey());
    setNavKey(currentKey());

    if (navigationRef.current) {
      const unsubscribe = navigationRef.current.addListener('state', () => {
        setNavKey(currentKey());
      });

      return unsubscribe;
    }
  }, []);

  useEffect(() => {
    fetchValueRecords();

    const getValue = async () => {
      let newValue: string[] = [];
      let bundle = (await getSubmissionBundles()).find(
        b => b._id && b._id.equals(props.bundleId),
      );
      if (bundle && bundle.submissions) {
        bundle.submissions.forEach(s => {
          if (s.submissionId) newValue.push(s.submissionId?.toHexString());
        });
      }
      setValue(newValue);
    };

    getValue();
    fetchRecords();
  }, []);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, '', valid());

    fetchValueRecords();
  }, [value]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  async function fetchRecords(): Promise<void> {
    let orgId = getOrgId();

    const mappedRecords: Record[] = [];

    // Fetch Submissions
    let filteredTemplates = await getFilteredTemplates();
    let filteredVersions = await getFilteredTemplateVersions();
    let filteredSubmissions = (await getSubmissions()).filter(
      s =>
        s.partition === orgId &&
        s.submissionStatus &&
        s.submissionStatus.name === SubmissionStatuses.SUBMITTED,
    );
    filteredSubmissions.forEach(function (s, i) {
      let version = filteredVersions.find(v =>
        v._id.equals(s.templateVersion._id ?? ''),
      );
      let template = filteredTemplates.find(t =>
        t._id.equals(version?.templateId ?? ''),
      );

      if (template)
        mappedRecords.push(
          getRecordFromSubmission(
            s._id.toHexString(),
            template.name,
            template.templateCategory.templateCategoryName,
            'submission',
            s.createDateTimeStamp,
            template.iconSvg ?? Icons.TASK,
            s.partition,
            s.metadataJSON,
          ),
        );
    });

    // Fetch Submission Trainings
    let filteredTrainings = (await getSubmissionTrainings()).filter(
      s =>
        s.partition === orgId &&
        s.submissionStatus &&
        s.submissionStatus.name === SubmissionStatuses.SUBMITTED,
    );
    filteredTrainings.forEach(function (t, i) {
      let category = t.trainingType.name ? 'Training' : 'Certificate';
      let title = '';
      if (category === 'Training') title = t.trainingType.name ?? '';
      else title = t.certificateType.name ?? '';
      title += ' - ' + t.person.firstName + ' ' + t.person.lastName;

      mappedRecords.push(
        getRecordFromSubmission(
          t._id.toHexString(),
          title,
          category,
          'submissionTraining',
          t.createDateTimeStamp,
          category === 'Training' ? Icons.TRAINING : Icons.CERTIFICATION,
          t.partition,
          '',
          t.person.id?.toHexString(),
        ),
      );
    });

    let recordCategories = Array.from(
      new Set(mappedRecords.map(r => r.category).filter(c => c !== 'Home')),
    ).sort();
    setCategories(
      recordCategories
        .filter(x => x != TemplateCategoryTypes.AUDIT)
        .map(c => ({ label: c, value: c })),
    );

    mappedRecords.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
    setRecords(mappedRecords);
  }

  async function fetchValueRecords(): Promise<void> {
    const mappedValueRecords: Record[] = [];

    let filteredTemplates = await getTemplates();
    let filteredVersions = await getTemplateVersions();

    let bundle = (await getSubmissionBundles()).find(
      b => b._id && b._id.equals(props.bundleId),
    );
    if (bundle && bundle.submissions) {
      for (let i = 0; i < bundle.submissions.length; i++) {
        let s = bundle.submissions[i];
        let version = filteredVersions.find(v =>
          v._id.equals(s.templateVersion?._id ?? ''),
        );
        let template = filteredTemplates.find(t =>
          t._id.equals(version?.templateId ?? ''),
        );

        if (template) {
          let title = template.name;
          if (s.templateType?.name === 'Training Breakdown') {
            let training = (await getSubmissionTrainings()).find(
              t => s.submissionId && t._id.equals(s.submissionId),
            );

            if (training && training.trainingType) {
              let category = training.trainingType.name
                ? 'Training'
                : 'Certificate';
              title =
                (category === 'Training'
                  ? training.trainingType.name
                  : training.certificateType.name) +
                ' - ' +
                training.person.firstName +
                ' ' +
                training.person.lastName;
            }
          }

          mappedValueRecords.push(
            getRecordFromSubmission(
              s.submissionId?.toHexString() ?? '',
              title,
              template.templateCategory.templateCategoryName,
              s.templateType?.name === 'Training Breakdown'
                ? 'submissionTraining'
                : 'submission',
              s.createDateTimeStamp!,
              template.iconSvg ?? Icons.TASK,
              bundle!.partition ?? '',
              s.metadataJSON ?? '',
            ),
          );
        }
      }
    }

    mappedValueRecords.sort(
      (a, b) => a.datetime.getTime() - b.datetime.getTime(),
    );
    setValueRecords(mappedValueRecords);
  }

  function getRecordFromSubmission(
    id: string,
    title: string,
    category: string,
    type: 'submission' | 'submissionTraining',
    timeStamp: Date,
    icon: string,
    org: string,
    metadata: string,
    person?: string,
  ): Record {
    let date = moment(timeStamp).toDate();
    let project = '';
    if (metadata) {
      let meta = JSON.parse(metadata);
      if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];

      let datePart = '';
      if (meta[METADATA_KEYS.DUEDATE]) datePart = meta[METADATA_KEYS.DUEDATE];
      let timePart = '';
      if (meta[METADATA_KEYS.DUETIME]) timePart = meta[METADATA_KEYS.DUETIME];
      if (datePart && timePart) {
        let time = moment(timePart, Formats.BACKEND_TIME);
        let duedate = moment(datePart, Formats.BACKEND_DATE)
          .add(time.hours(), 'hours')
          .add(time.minutes(), 'minutes')
          .toDate();
        date = duedate;
      }

      if (meta[METADATA_KEYS.PROJECT_NAME])
        project = meta[METADATA_KEYS.PROJECT_NAME];

      if (person === '' && meta[METADATA_KEYS.WORKERID]) {
        try {
          let personObj = JSON.parse(
            meta[METADATA_KEYS.WORKERID],
          ) as MongoIdSqlId;

          person = personObj.mongoId ?? '';
        } catch (error) {
          console.log('Error: Bad metadata serialization');
        }
      }
    }

    return {
      id: id,
      name: title,
      category: category,
      type: type,
      datetime: date,
      icon: icon,
      org: org,
      project: project,
      person: person,
    };
  }

  function showModal(): void {
    if (props.disabled) return;

    Keyboard.dismiss();

    if (value) {
      setSelection(JSON.parse(JSON.stringify(value)));
    } else setSelection([]);

    setItemsToShow(defaultItemsToShow);
    setSearchFilter('');
    setIsShowingFilters(false);
    setFilteredCategory('');
    setFilteredProject('');
    setFilteredPerson('');
    setIsShowingModal(true);
  }

  function hideModal(): void {
    setIsShowingModal(false);
  }

  function clearSearchFilter(): void {
    Keyboard.dismiss();
    setSearchFilter('');
  }

  function showFilters(): void {
    if (isShowingFilters) {
      setIsShowingFilters(false);
    } else {
      setIsShowingFilters(true);
    }
  }

  function select(record: Record): void {
    let newSelection = [...selection];

    let index = newSelection.indexOf(record.id);
    if (index === -1) newSelection.push(record.id);
    else newSelection.splice(index, 1);

    setSelection(newSelection);
  }

  async function goToRecord(record: Record): Promise<void> {
    let externalView = record.org !== getOrgId();

    props.navigation.push(Navigation.BUNDLEFORM, {
      bundleId: props.bundleId,
      submissionId: record.id,
      submissionType: record.type,
      externalView: externalView,
    });
  }

  const showPDFModal = (record: Record) => {
    setRecordToPDF(record);
    setIsShowingPDFModal(true);
  };

  async function save(): Promise<void> {
    let currentUser = getRealmApp().currentUser;
    let org = '';
    let currentEmail = '';
    if (currentUser && currentUser.profile) {
      if (typeof currentUser.profile.organisation === 'string') {
        let organisation = JSON.parse(currentUser.profile.organisation);
        org = organisation.Id.toString();
      }
      if (typeof currentUser.profile.email === 'string') {
        currentEmail = currentUser.profile.email;
      }
    }

    let selectedSubmissions = (await getSubmissions()).filter(
      s => s.partition === org && selection.includes(s._id.toHexString()),
    );
    let selectedTrainings = (await getSubmissionTrainings()).filter(
      s => s.partition === org && selection.includes(s._id.toHexString()),
    );

    let bundle = (await getSubmissionBundles()).find(
      b => b._id && b._id.equals(props.bundleId),
    );
    if (bundle) {
      let bundleSubmissions: SubmissionBundle_submissions[] = [];
      for (let i = 0; i < selection.length; i++) {
        let record = records.find(r => r.id === selection[i]);
        if (record) {
          if (record.type === 'submission') {
            let submission = selectedSubmissions.find(s =>
              s._id.equals(record!.id),
            );

            if (submission) {
              bundleSubmissions.push({
                submissionId: submission._id,
                submissionSQLServerId: submission.SQLServerId,
                answers: submission.answers,
                metadataJSON: submission.metadataJSON,
                templateType: submission.templateType,
                templateVersion: submission.templateVersion,
                updatedBy: currentEmail as string,
                updateDateTimeStamp: new Date(),
              } as SubmissionBundle_submissions);
            }
          } else {
            let training = selectedTrainings.find(s =>
              s._id.equals(record!.id),
            );

            if (training) {
              bundleSubmissions.push({
                submissionId: training._id,
                answers: training.answers,
                metadataJSON: training.metadataJSON,
                templateType: training.templateType,
                templateVersion: training.templateVersion,
                updatedBy: currentEmail as string,
                updateDateTimeStamp: new Date(),
              } as SubmissionBundle_submissions);
            }
          }
        }
      }

      bundle.submissions = bundleSubmissions;
      await upsertSubmissionBundle(bundle);
    }

    setValue(selection);
    setIsShowingModal(false);
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && value.length === 0) {
      isValid = false;
      error = (props.label ?? 'Documents') + ' are required';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderModal = (): React.ReactElement => {
    let availableRecords = 0;
    let visibleRecords: Record[] = [];

    if (searchFilter || filteredCategory || filteredProject || filteredPerson) {
      let project = '';
      try {
        if (filteredProject) {
          let projectObj = JSON.parse(filteredProject) as MongoIdSqlId;
          project = projectObj.label ?? '';
        }
      } catch (error) {
        console.log('Error: Bad control serialization');
      }

      let person = '';
      try {
        if (filteredPerson) {
          let personObj = JSON.parse(filteredPerson) as MongoIdSqlId;
          person = personObj.mongoId ?? '';
        }
      } catch (error) {
        console.log('Error: Bad control serialization');
      }

      let filteredRecords = records.filter(
        r =>
          r.name.toLowerCase().includes(searchFilter.toLowerCase()) &&
          (filteredCategory === '' || r.category == filteredCategory) &&
          (project === '' || r.project == project) &&
          (person === '' || r.person?.toLowerCase() == person.toLowerCase()),
      );

      availableRecords = filteredRecords.length;
      visibleRecords = filteredRecords.slice(0, itemsToShow);
    } else {
      availableRecords = records.length;

      selection.forEach(val => {
        let item = records.find(r => r.id === val);
        if (item) visibleRecords.push(item);
      });

      visibleRecords = visibleRecords.filter(
        x => x.category !== TemplateCategoryTypes.AUDIT,
      );

      visibleRecords.sort(
        (a, b) => b.datetime.getTime() - a.datetime.getTime(),
      );

      //Complete the list with the top # elements
      for (
        let i = 0;
        i < records.length && visibleRecords.length < itemsToShow;
        i++
      ) {
        if (!visibleRecords.some(r => r.id === records[i].id))
          visibleRecords.push(records[i]);
      }
    }

    return (
      <Modal
        visible={isShowingModal && myKey === navKey}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label ?? 'Documents Included'}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={hideModal}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            {renderModalFilters()}
            <ScrollView style={{ marginVertical: 20 }}>
              {visibleRecords.map((record, index) => {
                return renderRecord(record, index);
              })}
              {visibleRecords.length < availableRecords && (
                <Pressable
                  style={({ pressed }) => [
                    CommonStyleSheet.smallGreenButton,
                    { alignSelf: 'center', marginVertical: 12 },
                    pressed && {
                      opacity: 0.8,
                    },
                  ]}
                  onPress={() =>
                    setItemsToShow(itemsToShow + defaultItemsToShow)
                  }>
                  <Text style={CommonStyleSheet.smallGreenButtonText}>
                    Show More
                  </Text>
                </Pressable>
              )}
            </ScrollView>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={save}>
              <Text style={CommonStyleSheet.greenButtonText}>Ok</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  const renderModalFilters = (): React.ReactElement => {
    return (
      <View>
        <View
          style={[
            CommonStyleSheet.searchContainer,
            { paddingHorizontal: 0, marginTop: 12 },
          ]}>
          <View style={CommonStyleSheet.searchBoxContainer}>
            <View style={CommonStyleSheet.searchBoxIcon}>
              <Icon icon={'input-search'} color={Colors.darkGreen} size={24} />
            </View>
            <TextInput
              returnKeyType="search"
              style={CommonStyleSheet.searchBoxInput}
              placeholder="Search"
              placeholderTextColor={Colors.gray}
              value={searchFilter}
              onChangeText={newText => setSearchFilter(newText)}
            />
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.searchBoxClear,
                { display: searchFilter === '' ? 'none' : 'flex' },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                  borderRadius: 14,
                },
              ]}
              onPress={clearSearchFilter}>
              <Icon icon={'input-clear'} color={Colors.darkGreen} size={18} />
            </Pressable>
          </View>
          <View style={[CommonStyleSheet.searchFilters]}>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.searchFiltersContent,
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                  borderRadius: 40,
                },
              ]}
              onPress={showFilters}>
              <Text style={CommonStyleSheet.searchFiltersText}>Filters</Text>
              <Icon icon={'input-filters'} color={Colors.green} size={18} />
            </Pressable>
          </View>
        </View>
        {isShowingFilters && (
          <View
            style={{
              borderWidth: 1,
              borderColor: Colors.green,
              borderRadius: 12,
              padding: 12,
              marginTop: 12,
            }}>
            <Text
              style={{
                color: Colors.darkGreen,
                fontFamily: 'Poppins',
                fontSize: 14,
                fontWeight: '700',
                alignSelf: 'center',
              }}>
              FILTERS
            </Text>
            <DynamicSelect
              label="Document Category"
              config={{ options: categories }}
              value={filteredCategory}
              onChange={(controlId, controlTypeId, value) => {
                setFilteredCategory(value);
              }}
            />
            <DynamicAutocomplete
              label="Project"
              config={{ optionSource: ListDataTypes.PROJECTS }}
              value={filteredProject ? filteredProject : ''}
              onChange={(controlId, controlTypeId, value) =>
                setFilteredProject(value)
              }
            />
            <DynamicAutocomplete
              label="Person Involved"
              config={{ optionSource: ListDataTypes.PEOPLE }}
              value={filteredPerson ? filteredPerson : ''}
              onChange={(controlId, controlTypeId, value) =>
                setFilteredPerson(value)
              }
            />
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.smallGreenButton,
                { alignSelf: 'center', marginVertical: 12 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={() => {
                setFilteredCategory('');
                setFilteredProject('');
                setFilteredPerson('');
                setIsShowingFilters(false);
              }}>
              <Text style={CommonStyleSheet.smallGreenButtonText}>
                Clear Filters
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    let checked = selection.some(s => s === record.id);

    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          { paddingTop: 16 },
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => select(record)}>
        <Text
          style={[
            ReportsStyleSheet.listItemDate,
            { position: 'absolute', left: 36, top: 4 },
          ]}
          numberOfLines={1}>
          {record.category}
        </Text>
        <img
          src={`data:image/svg+xml;utf8,${encodeURIComponent(
            record.icon
              //First: For dark mode icons (Eg. Site Inspections in home) replace darkestGreen with white
              .replaceAll(Colors.darkestGreen, Colors.white)
              //Second: For all other icons (Eg. Site Inspections in operations) replace green with darkestGreen
              .replaceAll(Colors.green, Colors.darkestGreen)
              .replaceAll('currentColor', Colors.darkestGreen),
          )}`}
        />
        <Text
          style={[ReportsStyleSheet.listItemName, { paddingLeft: 12 }]}
          numberOfLines={1}>
          {record.name}
        </Text>
        <View style={ReportsStyleSheet.listItemDateContent}>
          <Text style={ReportsStyleSheet.listItemDate}>
            {moment(record.datetime).toDate().toLocaleDateString('en-us', {
              day: '2-digit',
              month: 'short',
            })}
          </Text>
          <Text style={ReportsStyleSheet.listItemTime}>
            {moment(record.datetime).toDate().toLocaleTimeString('en-us', {
              hourCycle: 'h24',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        </View>
        <View
          style={[
            ControlsStyleSheet.checkBox,
            { marginHorizontal: 12 },
            checked && {
              backgroundColor: Colors.teal,
              borderColor: Colors.teal,
            },
          ]}>
          <Icon icon={'checked'} color={Colors.white} size={16} />
        </View>
      </Pressable>
    );
  };

  const renderValueRecords = (): React.ReactElement => {
    let selectedRecords = valueRecords.filter(
      s => s.category != TemplateCategoryTypes.AUDIT && value.includes(s.id),
    );

    return (
      <View style={{ marginBottom: 24 }}>
        {selectedRecords.map((record, index) => {
          return renderValueRecord(record, index);
        })}
      </View>
    );
  };

  const renderValueRecord = (
    record: Record,
    index: number,
  ): React.ReactElement => {
    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => setSelectedRecord(record)}>
        <Text
          style={[
            ReportsStyleSheet.listItemDate,
            { position: 'absolute', left: 36, top: 4 },
          ]}
          numberOfLines={1}>
          {record.category}
        </Text>
        <img
          src={`data:image/svg+xml;utf8,${encodeURIComponent(
            record.icon
              //First: For dark mode icons (Eg. Site Inspections in home) replace darkestGreen with white
              .replaceAll(Colors.darkestGreen, Colors.white)
              //Second: For all other icons (Eg. Site Inspections in operations) replace green with darkestGreen
              .replaceAll(Colors.green, Colors.darkestGreen)
              .replaceAll('currentColor', Colors.darkestGreen),
          )}`}
        />
        <Text
          style={[ReportsStyleSheet.listItemName, { paddingLeft: 12 }]}
          numberOfLines={1}>
          {record.name}
        </Text>
        <View style={ReportsStyleSheet.listItemDateContent}>
          <Text style={ReportsStyleSheet.listItemDate}>
            {moment(record.datetime).toDate().toLocaleDateString('en-us', {
              day: '2-digit',
              month: 'short',
            })}
          </Text>
          <Text style={ReportsStyleSheet.listItemTime}>
            {moment(record.datetime).toDate().toLocaleTimeString('en-us', {
              hourCycle: 'h24',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        </View>
        {selectedRecord?.id == record.id && renderActions()}
        {/* <Icon icon={'list-item-nav'} color={Colors.darkestGreen} size={24} /> */}
      </Pressable>
    );
  };

  const renderActions = (): React.ReactElement => {
    const actions = (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <CustomPressable
          onPress={() => showPDFModal(selectedRecord!)}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'Print PDF'}
          tooltipAlign="left">
          <Icon icon={'print'} color={Colors.white} size={20} />
        </CustomPressable>
        <CustomPressable
          onPress={() => {
            goToRecord(selectedRecord!);
          }}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'View'}
          tooltipAlign="left">
          <Icon icon={'eye'} color={Colors.white} size={20} />
        </CustomPressable>
      </View>
    );

    return actions;
  };

  const renderPDFModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingPDFModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Download Documents
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsShowingPDFModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 24 }}>
              <View style={ControlsStyleSheet.attachmentsContainer}>
                {isShowingPDFModal && recordToPDF && (
                  <DynamicPDF
                    submissionId={recordToPDF.id}
                    type={selectedRecord!.type}></DynamicPDF>
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginVertical: 24,
        }}>
        <Text style={ControlsStyleSheet.label}>
          {props.label ?? 'Documents Included'}
          <Text style={ControlsStyleSheet.required}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
        <Pressable
          style={({ pressed }) => [
            ControlsStyleSheet.linkRecordAddButton,
            props.disabled && { display: 'none' },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          disabled={props.disabled}
          onPress={showModal}>
          <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
        </Pressable>
      </View>
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      {renderValueRecords()}
      {renderModal()}
      {renderPDFModal()}
    </View>
  );
};

export default DynamicBundleLinkRecord;
