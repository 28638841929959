import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import * as Realm from 'realm-web';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './Components/Views/Login';
import MainContainer from './Components/Shared/MainContainer';
import { TokenPair } from './Types/DtoTypes';
import { SyncProvider } from './Providers/SyncProvider';
import { ToastProvider } from 'react-native-toast-notifications';
import { createStackNavigator } from '@react-navigation/stack';
import { Navigation } from './Constants/Navigation';
import { NavigationContainer } from '@react-navigation/native';
import EditProjectSite from './Components/Views/EditProjectSite';
import { TemplateConversionProvider } from './Providers/TemplateConversionProvider';
import { CustomRealmProvider } from './Providers/CustomRealmProvider';
import Colors from './Styles/Shared/Colors';
import { PermissionProvider } from './Providers/PermissionProvider';
import { SubmissionRelationProvider } from './Providers/SubmissionRelationProvider';

function App() {
  return (
    <div style={{ height: '100vh', backgroundColor: Colors.darkestGreen }}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"></link>

      {/* TODO: Implement navigation */}

      <GoogleOAuthProvider clientId={process.env.REACT_APP_WEB_CLIENT_ID!}>
        <CustomRealmProvider>
          <SyncProvider>
            <PermissionProvider>
              <SubmissionRelationProvider>
                <TemplateConversionProvider>
                  <ToastProvider successColor={Colors.teal}>
                    <MainContainer navigation={null} />
                  </ToastProvider>
                </TemplateConversionProvider>
              </SubmissionRelationProvider>
            </PermissionProvider>
          </SyncProvider>
        </CustomRealmProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
