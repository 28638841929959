import { Dimensions, StyleSheet } from 'react-native';
import Colors from './Colors';

const ControlsStyleSheet = StyleSheet.create({
  label: {
    color: Colors.darkGreen,
    fontFamily: 'Poppins',
    fontSize: 11,
    fontWeight: '700',
    letterSpacing: 1.87,
    textTransform: 'uppercase',
    paddingBottom: 0,
    marginTop: 12,
    marginBottom: 6,
  },
  labelLarge: {
    color: Colors.darkGreen,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 18,
    letterSpacing: 3,
    textTransform: 'uppercase',
  },
  required: {
    color: Colors.red,
  },
  placeholder: {
    color: '#20373388',
  },
  input: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 0.7,
    backgroundColor: 'rgba(32, 55, 51, 0.05)',
    borderRadius: 6,
    borderColor: 'rgba(32, 55, 51, 0.05)',
    borderWidth: 1,
    padding: 12,
    paddingTop: 8,
    outlineStyle: 'none',
  },
  inputArea: {
    maxHeight: 92,
    textAlignVertical: 'top',
  },
  inputFocused: {
    borderColor: Colors.darkGreen,
  },
  inputError: {
    color: Colors.red,
    backgroundColor: 'rgba(191, 106, 88, 0.05)',
    borderColor: 'rgba(191, 106, 88, 0.05)',
  },
  inputErrorFocused: {
    borderColor: Colors.red,
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 12,
    marginVertical: 6,
  },
  //Add for new roles options design
  checkBoxContainerRoles: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkBoxContainerDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '49%',
    gap: 12,
    marginVertical: 6,
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: Colors.white,
    borderColor: Colors.green,
    marginRight: 1,
    padding: 20,
    fontSize: 18,
    fontFamily: 'Lato',
  },
  checkBox: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Colors.darkGreen,
    borderRadius: 20,
  },
  checkBoxText: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 18,
    color: Colors.darkGreen,
  },
  error: {
    color: Colors.red,
    fontFamily: 'Lato',
    fontSize: 10,
    fontWeight: '700',
    letterSpacing: 0.5,
    marginTop: 4,
    marginBottom: 6,
    height: 14,
  },
  modalBackground: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
  },
  select: {
    //     backgroundColor: '#rgba(32, 55, 51, 0.05)',
    //     borderColor: '#rgba(32, 55, 51, 0.05)',
    //     borderRadius: 4,
    //     borderWidth: 1,
    //     paddingHorizontal: 12,
  },
  selectFocused: {
    //     borderColor: Colors.darkGreen,
  },
  selectError: {
    //     backgroundColor: '#rgba(191, 106, 88, 0.05)',
    //     borderColor: '#rgba(191, 106, 88, 0.05)',
  },
  selectErrorFocused: {
    //     borderColor: Colors.red,
  },
  selectText: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 0.7,
    backgroundColor: '#F4F5F5',
  },
  selectBadgeText: {
    //     color: Colors.darkGreen,
  },
  dropDownContainer: {
    //     backgroundColor: '#E8E9E9',
    //     borderRadius: 4,
    //     borderWidth: 1,
    //     borderColor: Colors.darkGreen,
    //     //Fix DropDownPicker nested scroll and overlapping
    //     position: 'relative',
    //     top: -2,
  },
  dropDownContainerError: {
    //     backgroundColor: '#F0EBEA',
    //     borderColor: Colors.red,
  },
  datePicker: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  //   locationIcon: {
  //     display: 'flex',
  //     color: Colors.green,
  //   },
  //   locationIconError: {
  //     display: 'flex',
  //     color: Colors.red,
  //   },
  groupSelector: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  groupSelectorInput: {
    height: 39,
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 0.7,
    backgroundColor: '#rgba(32, 55, 51, 0.05)',
    borderRadius: 6,
    borderColor: '#rgba(32, 55, 51, 0.05)',
    borderWidth: 1,
    padding: 12,
    paddingTop: 8,
    paddingRight: 86,
    outlineStyle: 'none',
  },
  groupSelectorButton: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 8,
    backgroundColor: Colors.white,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.green,
    paddingHorizontal: 6,
    paddingVertical: 2,
  },
  groupSelectorButtonText: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    color: Colors.green,
    textTransform: 'uppercase',
  },
  groupSelectorModal: {
    width: 600,
    height: '90%',
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 20,
    marginTop: Dimensions.get('screen').height / 20,
    paddingHorizontal: 20,
  },
  groupSelectorModalBar: {
    alignItems: 'center',
    paddingTop: 16,
  },
  groupSelectorModalTitle: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '700',
    letterSpacing: 3,
    color: Colors.darkGreen,
    textTransform: 'uppercase',
    marginHorizontal: 24,
  },
  groupSelectorModalClose: {
    position: 'absolute',
    top: 16,
    right: 0,
  },
  groupSelectorGroupTitle: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '700',
    letterSpacing: 3,
    color: Colors.darkGreen,
    textTransform: 'uppercase',
    marginTop: 40,
  },
  groupSelectorOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 0.4,
    borderBottomColor: Colors.darkGreen,
    paddingVertical: 12,
    marginTop: 6,
    marginHorizontal: 10,
  },
  mapContainer: {
    marginTop: 24,
    borderRadius: 20,
    overflow: 'hidden',
  },
  mapView: {
    width: '100%',
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.gray,
  },
  mapCoordinatesContainer: {
    width: 'auto',
    paddingHorizontal: 10,
    backgroundColor: Colors.lightTeal,
    borderRadius: 20,
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: -20,
  },
  mapCoordinatesContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    paddingHorizontal: 24,
    paddingVertical: 6,
  },
  mapCoordinatesText: {
    color: Colors.green,
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: '700',
  },
  mapEditModal: {
    width: 340,
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 20,
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingHorizontal: 20,
  },
  mapEditModalBar: {
    alignItems: 'center',
    paddingTop: 16,
  },
  mapEditModalClose: {
    position: 'absolute',
    top: 16,
    right: 0,
  },
  ratingOutline: {
    width: 32,
    height: 32,
    borderRadius: 32,
    borderColor: Colors.darkGreen,
    borderWidth: 3,
  },
  ratingDot: {
    left: 3,
    top: 3,
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: Colors.white,
  },
  ratingDotSelected: {
    left: 3,
    top: 3,
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: Colors.darkGreen,
  },
  ratingLevelContainer: { display: 'flex', flexDirection: 'row', gap: 10 },
  ratingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  ratingLabel: {
    color: 'white',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: 'poppins',
    fontSize: 11,
    fontWeight: '700',
    letterSpacing: 1.87,
    textAlign: 'center',
    textAlignVertical: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    minWidth: 130,
  },
  attachmentsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    rowGap: 10,
    paddingLeft: 10,
  },
  attachmentsItem: {
    flexBasis: '48%',
    minHeight: 100,
    backgroundColor: Colors.darkGreenTransparent,
    overflow: 'hidden',
    alignItems: 'center',
    borderRadius: 12,
    paddingTop: 18,
    padding: 12,
  },
  attachmentsText: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    letterSpacing: 2,
    color: Colors.green,
    textTransform: 'uppercase',
    marginTop: 12,
  },
  attachmentsActions: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    flexDirection: 'row',
    gap: 4,
  },
  attachmentsAction: {
    backgroundColor: Colors.white,
    width: 26,
    height: 26,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 26,
  },
  facepileContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  facepile: {
    width: 32,
    height: 32,
    backgroundColor: Colors.lightTeal,
    borderRadius: 32,
    borderWidth: 1,
    borderColor: Colors.darkGreen,
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: -12,
  },
  facepileText: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  segmentLeft: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  segmentRight: {
    borderRightWidth: 1,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  segment: {
    backgroundColor: Colors.green,
    borderStyle: 'solid',
    borderColor: 'green',
    borderWidth: 1,
    borderRightWidth: 0,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
  },
  segmentText: {
    fontSize: 14,
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: 15,
    letterSpacing: 0.7,
  },
  linkRecordAddButton: {
    padding: 6,
    borderRadius: 30,
  },
  reportDescription: {
    height: 184,
    textAlignVertical: 'top',
    paddingBottom: 24,
    marginBottom: 16,
  },
  reportDescriptionFrame: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 22,
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingTop: 6,
    paddingBottom: 8,
  },
  reportDescriptionGood: {
    borderWidth: 2,
    borderColor: Colors.green,
  },
  reportDescriptionPoor: {
    borderWidth: 2,
    borderColor: Colors.red,
  },
  autocompleteList: {
    height: 234,
    backgroundColor: '#rgba(32, 55, 51, 0.05)',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.darkGreen,
    marginTop: 2,
  },
  autocompleteListItem: {
    color: Colors.darkGreen,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 0.7,
    lineHeight: 14,
    borderBottomWidth: 0.4,
    borderBottomColor: Colors.darkGreen,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginHorizontal: 12,
  },
  autocompleteButtonContainer: {
    position: 'absolute',
    right: 8,
    top: 8,
    backgroundColor: Colors.white,
    borderRadius: 6,
  },
  autocompleteButton: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.green,
    paddingHorizontal: 6,
    paddingVertical: 2,
  },
  autocompleteButtonText: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '700',
    color: Colors.green,
    textTransform: 'uppercase',
  },
  riskRatingContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  riskRatingRow: {
    color: Colors.darkGreen,
    textAlign: 'center',
    verticalAlign: 'middle',
    fontFamily: 'Poppins',
    fontSize: 11,
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingHorizontal: 8,
    marginTop: 8,
  },
  riskRatingRowVertical: {
    position: 'absolute',
    transform: [{ rotate: '-90deg' }],
    color: Colors.darkGreen,
    fontFamily: 'Poppins',
    fontSize: 11,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  riskRatingItem: {
    width: 30,
    height: 30,
    color: Colors.white,
    borderWidth: 3,
    borderColor: Colors.white,
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 11,
    fontWeight: '700',
    textTransform: 'uppercase',
    padding: 0,
    paddingTop: 4,
    margin: 0,
  },
  tipContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 12,
    borderRadius: 12,
    padding: 24,
    marginVertical: 24,
  },
});

export { ControlsStyleSheet };
