import { ObjectId } from 'bson';
import React, { useContext, useEffect, useState } from 'react';
import * as Realm from 'realm-web';
import { ListDataType, ListDataTypes } from '../Constants/ListDataTypes';
import { Project, ProjectSearch } from '../Models/RealmModels/Projects';
import { Person, PersonSearch } from '../Models/RealmModels/Person';
import { TemplateVersion } from '../Models/RealmModels/TemplateVersion';
import { Submission } from '../Models/RealmModels/Submission';
import { SubmissionStatus } from '../Models/RealmModels/SubmissionStatus';
import { Template } from '../Models/RealmModels/Template';
import { TemplateVersionConversion } from '../Models/RealmModels/TemplateVersionConversion';
import { SubmissionLink } from '../Models/RealmModels/SubmissionLink';
import { ILocalStorageService } from '../Services/Interfaces/ILocalStorageService';
import LocalStorageService from '../Services/LocalStorageService';

type CustomRealmProviderProps = {
  children: React.ReactNode;
};

type CustomRealmContextValue = {
  getRealmApp: () => Realm.App<
    globalThis.Realm.DefaultFunctionsFactory &
      globalThis.Realm.BaseFunctionsFactory
  >;

  realmSignIn: (token: string) => Promise<void>;
  realmSignOut: () => Promise<void>;
  isLoggedIn: () => boolean;
  loggedIn: boolean;
  setLoggedIn: (loggedInValue: boolean) => void;
};

// create the fact context
const CustomRealmContext = React.createContext<CustomRealmContextValue>(
  {} as CustomRealmContextValue,
);

const CustomRealmProvider: React.FunctionComponent<
  CustomRealmProviderProps
> = ({ children }): React.ReactElement => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {}, []);

  const getRealmApp = (): Realm.App<
    globalThis.Realm.DefaultFunctionsFactory &
      globalThis.Realm.BaseFunctionsFactory
  > => {
    const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID! });
    return app;
  };

  const realmSignIn = async (token: string) => {
    const credentials = Realm.Credentials.jwt(token);
    let app = getRealmApp();
    await app.logIn(credentials);
  };

  const realmSignOut = async () => {
    let app = getRealmApp();
    setLoggedIn(false);

    const storageService: ILocalStorageService = new LocalStorageService();
    storageService.clearData();

    await app.currentUser?.logOut();
  };

  const isLoggedIn = (): boolean => {
    let app = getRealmApp();

    if (!app.currentUser) {
      setLoggedIn(false);
      return false;
    }

    setLoggedIn(app.currentUser?.isLoggedIn);

    return app.currentUser?.isLoggedIn;
  };

  const value = {
    getRealmApp: getRealmApp,
    realmSignIn: realmSignIn,
    realmSignOut: realmSignOut,
    isLoggedIn: isLoggedIn,
    loggedIn: loggedIn,
    setLoggedIn: setLoggedIn,
  };

  return (
    <CustomRealmContext.Provider value={value}>
      {children}
    </CustomRealmContext.Provider>
  );
};

const useCustomRealm = () => {
  const customRealmContext = useContext(CustomRealmContext);

  if (customRealmContext === null)
    throw new Error(
      'useCustomRealm() called outside of a CustomRealmProvider?',
    );

  return customRealmContext;
};

export { CustomRealmProvider, CustomRealmContext, useCustomRealm };
