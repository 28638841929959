import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Config from 'react-native-config';
import { IAuthenticationService } from './Interfaces/IAuthenticationService';
import { TokenPair } from '../Types/DtoTypes';
import { ILocalStorageService } from './Interfaces/ILocalStorageService';
import LocalStorageService from './LocalStorageService';
import { STORAGE_KEYS } from '../Constants/AppConstants';
import moment from 'moment';
export const ALLOWED_ROLES: string[] = ['FORMS ADMIN', 'FORMS BASIC USER'];

class AuthenticationService implements IAuthenticationService {
  axiosInstance: AxiosInstance;
  refreshPromise: Promise<TokenPair> | null;

  storageService: ILocalStorageService;

  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest', //Header needed for server to respond with 401 instead of 302 when not logged in
      },
    });

    this.storageService = new LocalStorageService();

    this.refreshPromise = null;

    this.axiosInstance.interceptors.response.use(
      response => {
        return Promise.resolve(response);
      },
      error => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
          return Promise.reject(error);
        } else if (status === 403) {
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      },
    );
  }

  authorize(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  login(username: string, password: string) {
    //Config not working
    let config: AxiosRequestConfig = {
      url: process.env.REACT_APP_AUTH_API_URL,
      method: 'POST',
      data: {
        username: username,
        password: password,
        grant_type: 'password',
        client_id: process.env.REACT_APP_AUTH_CLIENTID,
        client_secret: process.env.REACT_APP_AUTH_CLIENTSECRET,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let loginPromise = new Promise<TokenPair>((resolve, reject) => {
      this.axiosInstance(config)
        .then(
          response => {
            this.storageService.set(
              STORAGE_KEYS.TOKEN_KEY,
              response.data.access_token,
            );
            this.storageService.set(
              STORAGE_KEYS.REFRESH_TOKEN_KEY,
              response.data.refresh_token,
            );

            let tokenExpiration = moment().add(response.data.expires_in, 'seconds').format();
            this.storageService.set(
              STORAGE_KEYS.TOKEN_EXPIRATION_KEY,
              tokenExpiration,
            );
            
            //Return token/refresh token from AuthAPI
            resolve({
              token: response.data.access_token,
              refreshToken: response.data.refresh_token,
            });
          },
          error => {
            reject(error);
          },
        )
        .catch(error => {
          reject(error);
        });
    });

    return loginPromise;
  }

  async googleSignIn(googleJwt: string): Promise<TokenPair> {
    const axiosConfig: AxiosRequestConfig = {
      url: process.env.REACT_APP_GOOGLE_LOGIN_URL,
      data: {
        token: googleJwt ?? '',
        clientId: process.env.REACT_APP_AUTH_CLIENTID ?? '',
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const { data } = await this.axiosInstance.request(axiosConfig);

    this.storageService.set(STORAGE_KEYS.TOKEN_KEY, data.access_token);
    this.storageService.set(STORAGE_KEYS.REFRESH_TOKEN_KEY, data.refresh_token);

    // TODO: Make sure AuthAPI returns expires_in for google sign in
    let expiration = data.expires_in ?? 100;
    this.storageService.set(STORAGE_KEYS.TOKEN_EXPIRATION_KEY, moment().add(expiration, 'seconds').format());

    return {
      token: data.access_token,
      refreshToken: data.refresh_token,
    };
  }

  async appleSignIn(appleJwt: string): Promise<TokenPair> {
    const axiosConfig: AxiosRequestConfig = {
      url: process.env.REACT_APP_APPLE_LOGIN_URL,
      data: {
        token: appleJwt ?? '',
        clientId: process.env.REACT_APP_AUTH_CLIENTID ?? '',
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const { data } = await this.axiosInstance.request(axiosConfig);

    this.storageService.set(STORAGE_KEYS.TOKEN_KEY, data.access_token);
    this.storageService.set(STORAGE_KEYS.REFRESH_TOKEN_KEY, data.refresh_token);

    // TODO: Make sure AuthAPI returns expires_in for apple sign in
    let expiration = data.expires_in ?? 100;
    this.storageService.set(STORAGE_KEYS.TOKEN_EXPIRATION_KEY, moment().add(expiration, 'seconds').format());

    return {
      token: data.access_token,
      refreshToken: data.refresh_token,
    };
  }

  /**
   * Forgot password function. sends email and source to API to generate a reset password token to be emailed to the user
   * @param email
   * @returns
   */
  forgotPassword(email: string): Promise<number> {
    let config: AxiosRequestConfig = {
      url: process.env.REACT_APP_FORGOT_PASSWORD_URL,
      method: 'POST',
      data: {
        email: email,
        source: 'MQA',
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let forgotPasswordPromise = new Promise<number>((resolve, reject) => {
      this.axiosInstance(config)
        .then(
          (response: AxiosResponse) => {
            resolve(response.status);
          },
          (error: AxiosError) => {
            reject(error.response?.status);
          },
        )
        .catch((error: AxiosError) => {
          reject(error.response?.status);
        });
    });

    return forgotPasswordPromise;
  }

  refresh(refreshToken: string) {
    let that = this;
    let config: AxiosRequestConfig = {
      url: process.env.REACT_APP_AUTH_API_URL,
      method: 'POST',
      data: {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_secret: process.env.REACT_APP_AUTH_CLIENTSECRET!,
        client_id: process.env.REACT_APP_AUTH_CLIENTID!,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    let refreshPromise: Promise<TokenPair> | null = null;

    if (this.refreshPromise == null) {
      refreshPromise = new Promise<TokenPair>((resolve, reject) => {
        that
          .axiosInstance(config)
          .then(
            response => {
              that.storageService.set(
                STORAGE_KEYS.TOKEN_KEY,
                response.data.access_token,
              );
              that.storageService.set(
                STORAGE_KEYS.REFRESH_TOKEN_KEY,
                response.data.refresh_token,
              );

              let tokenExpiration = moment().add(response.data.expires_in, 'seconds').format();
              that.storageService.set(
                STORAGE_KEYS.TOKEN_EXPIRATION_KEY,
                tokenExpiration,
              );

              resolve({
                token: response.data.access_token,
                refreshToken: response.data.refresh_token,
              });
            },
            error => {
              reject(error);
            },
          )
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            that.refreshPromise = null;
          });
      });

      this.refreshPromise = refreshPromise;
    }
    return this.refreshPromise!;
  }
}

export default AuthenticationService;
