import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  Image,
  Pressable,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { ObjectId } from 'bson';
import moment from 'moment';
import {
  BlobProvider,
  Document,
  View as PDFView,
  Page,
  Text as PDFText,
  Image as PDFImage,
  Font,
  pdf,
} from '@react-pdf/renderer';
import { useSync } from '../../Providers/SyncProvider';
import { useCustomRealm } from '../../Providers/CustomRealmProvider';
import { IAttachmentService } from '../../Services/Interfaces/IAttachmentService';
import AttachmentService from '../../Services/AttachmentService';
import DynamicPDFSubmission from './DynamicPDFSubmission';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Icon from '../Shared/Icon';
import { TemplateVersion } from '../../Models/RealmModels/TemplateVersion';
import { Submission } from '../../Models/RealmModels/Submission';
import { ControlTypes } from '../../Constants/ControlTypes';
import { DynamicAttachment } from '../../Types/ControlTypes';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { SubmissionTraining } from '../../Models/RealmModels/SubmissionTraining';

type DynamicPDFProps = {
  submissionId: string;
  type: 'submission' | 'submissionTraining';
  autoDownload?: boolean;
  bundleId?: string;
};

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: '/fonts/Poppins-Regular.ttf',
      fontWeight: 'normal',
    },
    {
      src: '/fonts/Poppins-Bold.ttf',
      fontWeight: 'bold',
    },
  ],
});

export const PDFStyles = StyleSheet.create({
  title: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 24,
  },
  subTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 'bold',
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    fontFamily: 'Poppins',
    fontSize: 12,
  },
});

export type Report = {
  title: string;
  date: string;
  template: TemplateVersion;
  submission: Submission;
  attachments: DynamicAttachment[];
  isLinkedReport: boolean;
};

const DynamicPDF = (props: DynamicPDFProps): React.ReactElement => {
  const { realmSignOut } = useCustomRealm();
  const attachmentService: IAttachmentService = new AttachmentService(() => {
    realmSignOut();
  });

  const [orgName, setOrgName] = useState('');
  const [documentTitle, setDocumentTitle] = useState('');
  const [reports, setReports] = useState<Report[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  const hasAttachments = useRef(false);

  const [blobDocument, setBlobDocument] = useState<Blob | undefined>(undefined);

  const {
    getFilteredTemplateVersions,
    getSubmissions,
    getSubmissionTrainings,
    getSubmissionLinks,
    getSubmissionBundles,
    getOrganisations,
    getPeople,
    getEquipmentsVehicle,
    getEquipmentsHeavy,
    getEquipmentsOther,
    people,
    projects,
    cities,
    getProvStates,
    getJobs,
  } = useSync();

  useEffect(() => {
    const getReports = async () => {
      let newReports: Report[] = [];
      if (props.submissionId) {
        let mainReport = await getReport(props.submissionId, props.type, false);
        if (mainReport) {
          newReports.push(mainReport);

          // Get Linked Reports
          let submissionLinks = (await getSubmissionLinks()).filter(x =>
            x.parentSubmissionId.equals(props.submissionId),
          )!;
          submissionLinks.forEach(async link => {
            let linkedReport = await getReport(
              link.childSubmissionId.toHexString(),
              props.type,
              true,
            );
            if (linkedReport) newReports.push(linkedReport);
          });
        }
      }
      setReports(newReports);
      setIsMounted(true);
    };

    getReports();
  }, [props.submissionId]);

  useEffect(() => {
    if (isMounted) {
      const getPdfBlob = async () => {
        let blob = await pdf(renderDocument()).toBlob();
        setBlobDocument(blob);
      };

      getPdfBlob();
    }
  }, [isMounted]);

  useEffect(() => {
    if (props.autoDownload) downloadAll();
  }, [props.autoDownload]);

  async function getEquipmentName(id: string): Promise<string> {
    let vehicle = getEquipmentsVehicle().find(x => x._id.equals(id));
    if (vehicle) return vehicle.name + ' ' + vehicle.number;

    let heavyObj = getEquipmentsHeavy().find(x => x._id.equals(id));
    if (heavyObj) return heavyObj.name + ' ' + heavyObj.number;

    let otherObj = getEquipmentsOther().find(x => x._id.equals(id));
    if (otherObj) return otherObj.name + ' ' + otherObj.number;

    return '';
  }

  async function getPersonName(id: string): Promise<string> {
    let person = (await getPeople({})).find(x => x._id.equals(id));
    if (person) return person.firstName + ' ' + person.lastName;

    return '';
  }

  async function getReport(
    submissionId: string,
    type: 'submission' | 'submissionTraining',
    isLinkedReport: boolean,
  ): Promise<Report | undefined> {
    let submission: Submission | SubmissionTraining | undefined = undefined;
    if (props.bundleId) {
      let bundle = (await getSubmissionBundles()).find(s =>
        s._id.equals(props.bundleId!),
      );
      if (bundle && bundle.submissions) {
        let submissionInBundle = bundle.submissions.find(
          s => s.submissionId && s.submissionId.equals(submissionId),
        );

        if (submissionInBundle) {
          submission = {
            _id: submissionInBundle.submissionId,
            partition: bundle.partition,
            createDateTimeStamp: submissionInBundle.createDateTimeStamp,
            createdBy: submissionInBundle.createdBy,
            updateDateTimeStamp: submissionInBundle.updateDateTimeStamp,
            updatedBy: submissionInBundle.updatedBy,
            SQLServerId: submissionInBundle.submissionSQLServerId,
            templateVersion: submissionInBundle.templateVersion,
            templateType: submissionInBundle.templateType,
            metadataJSON: submissionInBundle.metadataJSON,
            answers: submissionInBundle.answers,
          } as Submission;
        }
      }
    } else if (type === 'submission') {
      submission = (await getSubmissions()).find(x =>
        x._id.equals(submissionId),
      );
    } else {
      submission = (await getSubmissionTrainings()).find(x =>
        x._id.equals(submissionId),
      );
    }

    if (submission) {
      let template = (await getFilteredTemplateVersions()).find(x =>
        x._id.equals(submission!.templateVersion!._id!),
      )!;

      if (!isLinkedReport) {
        let org = (await getOrganisations()).find(
          o => o.SQLServerId === submission!.partition,
        );
        if (org) setOrgName(org.name);
      }

      let title = template.name ?? '';
      if (submission.metadataJSON) {
        let meta = JSON.parse(submission.metadataJSON);
        if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];

        if (meta[METADATA_KEYS.EQUIPMENTID]) {
          let equipmentName = await getEquipmentName(
            meta[METADATA_KEYS.EQUIPMENTID],
          );

          if (equipmentName) title += ': ' + equipmentName;
        } else if (meta[METADATA_KEYS.WORKERID]) {
          let personName = await getPersonName(meta[METADATA_KEYS.WORKERID]);

          if (personName) title += ': ' + personName;
        }

        if (meta[METADATA_KEYS.PROJECT_NAME])
          title += ' at ' + meta[METADATA_KEYS.PROJECT_NAME];
      }
      if (!isLinkedReport) setDocumentTitle(title);

      let date = '';
      let submissionDate =
        submission.updateDateTimeStamp ?? submission.createDateTimeStamp;
      if (submissionDate)
        date = moment(submissionDate).format(Formats.FRONTEND_DATE);

      // Get Attachments
      let attachments: DynamicAttachment[] = [];
      for (let i = 0; i < template.pages.length; i++) {
        let page = template.pages[i];
        if (page.controls)
          for (let j = 0; j < page.controls.length; j++) {
            let control = page.controls[j];
            if (control.controlTypeId === ControlTypes.PHOTOS_AND_ATTACHMENTS) {
              const submissionAnswer = submission.answers.find(
                x => x.controlId == control.controlId,
              );

              let answer = control.value;
              if (submissionAnswer && submissionAnswer.answer)
                answer = submissionAnswer.answer;

              let attachmentsList: DynamicAttachment[] = [];
              try {
                if (answer) {
                  attachmentsList = JSON.parse(answer) as DynamicAttachment[];
                  attachmentsList = attachmentsList.filter(x => x.blobUri);
                }
              } catch (err) {
                console.log('Error: Bad control value serialization');
              }

              for (let i = 0; i < attachmentsList.length; i++) {
                try {
                  let uri = await attachmentService.getSasUri(
                    attachmentsList[i].blobUri!,
                  );
                  attachmentsList[i].uri = uri;
                } catch (err) {
                  console.log('Error: Failed getting sasuri');
                }
              }
              attachments = [...attachmentsList];
            }
          }
      }

      if (attachments.length > 0) hasAttachments.current = true;

      return {
        title: title,
        date: date,
        template: template,
        submission: submission,
        attachments: attachments,
        isLinkedReport: isLinkedReport,
      };
    }

    return undefined;
  }

  async function downloadAll() {
    downloadDocument();

    for (let i = 0; i < reports.length; i++) {
      if (reports[i].attachments) {
        for (let j = 0; j < reports[i].attachments.length; j++) {
          if (!reports[i].attachments[j].type?.includes('image')) {
            downloadAttachment(reports[i].attachments[j]);
          }
        }
      }
    }
  }

  async function downloadDocument() {
    if (blobDocument) {
      var element = document.createElement('a');
      element.href = URL.createObjectURL(blobDocument);
      element.download = documentTitle + '.pdf';
      element.click();
    }
  }

  async function downloadAttachment(
    attachment: DynamicAttachment,
  ): Promise<void> {
    if (attachment && attachment.blobUri && attachment.name)
      await attachmentService.downloadAttachment(
        attachment.blobUri,
        attachment.name,
      );
  }

  const renderDocument = (): React.ReactElement => {
    //Apparently children inside Document node doesn't have access to the context of providers so passing data in manually.

    let listData = {
      projects: projects,
      people: people,
      cities: cities,
      getJobs: getJobs,
      getProvStates: getProvStates,
    };

    return (
      <Document title={documentTitle}>
        {reports.map((report, i) => {
          return (
            <DynamicPDFSubmission
              key={i}
              orgName={orgName}
              title={report.title}
              date={report.date}
              template={report.template}
              submission={report.submission}
              listData={listData}
              linkedReports={
                report.isLinkedReport
                  ? undefined
                  : reports
                      .filter(r => r.isLinkedReport)
                      .map(r => ({ title: r.title, date: r.date }))
              }
            />
          );
        })}
        {reports.map((report, i) => {
          return report.attachments.map((attachment, i) => {
            return <>{renderAttachment(attachment)}</>;
          });
        })}
      </Document>
    );
  };

  const renderAttachment = (
    attachment: DynamicAttachment,
  ): React.ReactElement => {
    return (
      <>
        {attachment.type?.includes('image') && attachment.uri ? (
          <Page size="A4">
            <PDFView
              style={{
                flex: 1,
                padding: 24,
              }}>
              <PDFText style={[PDFStyles.label, { marginBottom: 12 }]}>
                {attachment.name}
              </PDFText>
              <PDFImage
                style={{ objectFit: 'contain', maxHeight: 700 }}
                source={attachment.uri}
              />
            </PDFView>
          </Page>
        ) : null}
      </>
    );
  };

  return (
    <>
      {isMounted ? (
        <>
          <View style={ControlsStyleSheet.attachmentsItem}>
            <Image style={{ width: 32, height: 32 }} source={Images.PDF} />
            <Text style={ControlsStyleSheet.attachmentsText}>
              {documentTitle}.pdf
            </Text>
            <View style={ControlsStyleSheet.attachmentsActions}>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.attachmentsAction,
                  pressed && {
                    backgroundColor: Colors.lightTeal,
                  },
                ]}
                onPress={downloadDocument}>
                <Icon icon={'download'} color={Colors.green} size={20} />
              </Pressable>
            </View>
          </View>
          {hasAttachments.current && (
            <>
              {reports.map((report, i) => {
                return (
                  <>
                    {report.attachments.map((attachment, j) => {
                      if (attachment.type?.includes('image')) return <></>;
                      return (
                        <View
                          key={i.toString() + '-' + j.toString()}
                          style={ControlsStyleSheet.attachmentsItem}>
                          {attachment.type?.includes('pdf') ? (
                            <Image
                              style={{ width: 32, height: 32 }}
                              source={Images.PDF}
                            />
                          ) : (
                            <Icon
                              icon={'file'}
                              color={Colors.green}
                              size={20}
                            />
                          )}
                          <Text style={ControlsStyleSheet.attachmentsText}>
                            {attachment.name}
                          </Text>
                          <View style={ControlsStyleSheet.attachmentsActions}>
                            <Pressable
                              style={({ pressed }) => [
                                ControlsStyleSheet.attachmentsAction,
                                pressed && {
                                  backgroundColor: Colors.lightTeal,
                                },
                              ]}
                              onPress={() => downloadAttachment(attachment)}>
                              <Icon
                                icon={'download'}
                                color={Colors.green}
                                size={20}
                              />
                            </Pressable>
                          </View>
                        </View>
                      );
                    })}
                  </>
                );
              })}
            </>
          )}
        </>
      ) : (
        <LoadingSpinner message="Generating Documents..." visible={true} />
      )}
    </>
  );
};

export default DynamicPDF;
